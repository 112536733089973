<template>
  <main class="navbar-offset profile-page">
    <div class="container">
      <div class="row privacy-main-info">
        <div class="d-flex flex-wrap">
          <div class="col-lg-4 col-md-2 navigationBlock pt-md-6 pt-2">
            <div class="mb-md-5 mb-1">
              <h1 class="mb-1">{{ $t('terms.agb_page-title') }}</h1>
              <font-awesome-icon :icon="['far', 'clock']" class="text-primary"> </font-awesome-icon>
              <span class="text-black pl-0-5">{{ $t('terms.updated') }} {{ getFormattedData }}</span>
            </div>
            <PrivacyNavigationTabs class="mt-0-5" />
          </div>
          <div class="col-xxl-6 col-xl-8 col-lg-9 col-md-8 mx-auto py-md-7 py-1 mt-md-11 mt-0">
            <h1 class="mb-3 mt-md-0 mt-3">{{ $t('terms.agb-page.title') }}</h1>
            <p class="mb-2">
              {{ $t('terms.agb-page.title-1') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.agb-page.title-2') }}
            </p>
            <ul>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-page.title-3') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-4') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-page.title-5') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-6') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-7') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-8') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-9') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-10') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-11') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-12') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-13') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-14') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-page.title-15') }}</h3>
                <ul>
                  <li>
                    <p class="mb-2">
                      {{ $t('terms.agb-page.title-16') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-page.title-17') }}
                        </p>
                      </li>

                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-page.title-18.text-1') }}
                          <a href="mailto:info@quiff.ch" title="info@quiff.ch">info@quiff.ch</a>
                          {{ $t('terms.agb-page.title-18.text-2') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-page.title-19') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-page.title-20') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-page.title-21') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-22') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-page.title-23') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-24') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-25') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-26') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-page.title-27') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-28') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-29') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-page.title-30') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-31') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-32') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-33') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-34') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-35') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-36') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-page.title-37') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-38') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-page.title-39') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-40') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-41') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-42') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-page.title-43') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-page.title-44') }}
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PrivacyNavigationTabs from './PrivacyNavigationTabs'
import moment from 'moment'

export default {
  components: {
    PrivacyNavigationTabs
  },
  data() {
    return {}
  },
  computed: {
    getFormattedData() {
      return moment('2020-05-25 09:00:00')
        .locale(this.$root.$i18n.locale)
        .format('MMMM, DD YYYY')
    }
  }
}
</script>
