import Repository from './repository'

const products = '/products'

export const ShopRepository = {
  getProducts(payload) {
    return Repository.get(`${products}/${payload.id}`)
  },
  getSalonProducts(payload) {
    return Repository.get(`/products-client-index`, { params: payload })
  },
  getSalonCategories(payload) {
    return Repository.get(`/categories-select`, { params: payload })
  },
  getProduct(payload) {
    return Repository.get(`/product-details-client`, { params: payload })
  }
}
