import { ParamsRepository } from '@/api/params.repository'
import { ServicesRepository } from '@/api/services.repository'

const setCommonHaircutParams = ({ commit }, payload) => {
  commit('SET_COMMON_HAIRCUT_PARAMS', payload)
}
const setChildHaircutParams = ({ commit }, payload) => {
  commit('SET_CHILD_HAIRCUT_PARAMS', payload)
}
const clearGenderParam = ({ commit }) => {
  commit('CLEAR_GENDER_PARAM')
}
const clearChoosenServices = ({ commit }) => {
  commit('CLEAR_CHOOSEN_SERVICES')
}
const clearDate = ({ commit }) => {
  commit('CLEAR_DATE')
}
const clearChoosenDate = ({ commit }) => {
  commit('CLEAR_CHOOSEN_DATE')
}

const removeExistingService = ({ commit }, index) => {
  commit('REMOVE_EXISTING_SERVICE', index)
}
const addService = ({ commit }, services) => {
  commit('ADD_SERVICE', services)
}
const addExistingServiceBack = ({ commit }, service) => {
  commit('ADD_EXISTING_SERVICE_BACK', service)
}
const getApiServices = ({ commit }) => {
  return ServicesRepository.get().then(({ data }) => {
    commit('SET_EXISTING_SERVICES', data.data)
  })
}
const getServicesByName = ({ commit }, payload) => {
  return ServicesRepository.getServicesByName(payload).then(({ data }) => {
    if (payload) {
      commit('SET_EXISTING_SERVICES', data.data)
    }
    return data.data
  })
}
const getParamsValues = ({ commit }) => {
  return ParamsRepository.get().then(({ data }) => {
    const result = data.data

    const payload = {
      genders: result[0],
      age: result[1],
      hairLength: result[2]
    }

    commit('SET_PARAMETERS_VALUES', payload)

    return payload
  })
}
const clearChildHaircutParams = ({ commit }) => {
  commit('CLEAR_CHILD_HAIRCUT_PARAMS')
}

export default {
  setCommonHaircutParams,
  setChildHaircutParams,
  clearGenderParam,
  clearChoosenServices,
  clearDate,
  removeExistingService,
  addService,
  addExistingServiceBack,
  getApiServices,
  getParamsValues,
  clearChildHaircutParams,
  clearChoosenDate,
  getServicesByName
}
