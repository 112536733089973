<template>
  <div class="px-1 px-md-3 pb-3">
    <div class="text-center mb-3 d-none d-md-block">
      <h5>{{ $t('main.section.reviews_modal.title') }}</h5>
      <p>{{ $t('main.section.reviews_modal.subtitle') }}</p>
    </div>
    <form v-if="reviewData">
      <div class="row mb-2-5 feedback-btn-list">
        <div class="col-auto">
          <img :src="reviewData.avatar" class="staff-img img-cover rounded" alt="Staff" />
        </div>
        <div class="col">
          <p class="text-black">{{ reviewData.fullName }}</p>
          <p v-if="reviewData.salonName" class="text-black">
            {{ `${$t('main.section.reviews_modal.salon-name')} ${reviewData.salonName}` }}
          </p>
          <p v-if="servicesListString" class="text-black">
            {{ `${$t('main.section.reviews_modal.service-name')} ${servicesListString}` }}
          </p>
          <p v-if="reviewData.staffName" class="text-black">
            {{ `${$t('main.section.reviews_modal.staff-name')} ${reviewData.staffName}` }}
          </p>
          <div class="row btn-list align-items-center">
            <div class="col mb-1-5 mb-md-0">
              <button
                v-for="rate in rateList"
                :key="`rate-${rate}`"
                :class="{
                  active: selectedRate === rate,
                  'mr-0-5': rate !== 5
                }"
                class="btn btn-checkbox btn-rate"
                @click.prevent="setRate(rate)"
              >
                {{ rate }}
              </button>
            </div>
            <div class="col-md-auto">
              <img :src="require('@/assets/images/icons/star_lg.svg')" alt="Start" />
              {{ $t('main.section.reviews_modal.stars') }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <label class="control-label" for="feedback">
          {{ $t('main.section.reviews_modal.feedback_label') }}
        </label>
        <textarea
          id="feedback"
          v-model="feedback"
          v-validate="'max:255'"
          name="feedback"
          class="form-control feeback-area"
          rows="10"
          :class="{ 'mb-1': errors.has('feedback') }"
        ></textarea>
        <span v-if="errors.has('feedback')" class="text-danger mt-1">{{ errors.first('feedback') }}</span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    disabledSubmutBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      dataSent: false,
      selectedRate: 0,
      feedback: '',
      rateList: [1, 2, 3, 4, 5],
      reviewData: null
    }
  },
  watch: {
    disabledSubmit(val) {
      this.$emit('set-status-review-form', val)
    }
  },
  computed: {
    ...mapGetters({
      feedbackRequest: 'user/getFeedbackRequest'
    }),
    disabledSubmit() {
      return this.selectedRate === 0
    },
    payloadData() {
      let data = {}

      if (this.reviewData) {
        data = {
          booking_id: this.reviewData.booking_id,
          client_id: this.reviewData.client_id,
          description: this.feedback
        }
      }

      if (this.selectedRate) {
        data = {
          ...data,
          ...{ rating: this.selectedRate }
        }
      }

      return data
    },
    servicesListString() {
      if (!this.reviewData) return ''
      if (Array.isArray(this.reviewData.services)) {
        return this.reviewData.services.join(', ')
      } else {
        return Object.values(this.reviewData.services).join(', ')
      }
    }
  },
  mounted() {
    this.loadReviewData()

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'reviews-modal' && bvEvent.trigger === 'ok') {
        bvEvent.preventDefault()
        this.submitForm()
      }

      if (modalId === 'reviews-modal' && !this.dataSent && bvEvent.trigger !== 'ok') {
        let modal = document.getElementById('reviews-modal___BV_modal_content_')

        modal.classList.add('d-none')
        modal.classList.remove('d-block')

        bvEvent.preventDefault()
        this.confirmMessage()
          .then(result => {
            modal.classList.add('d-block')
            modal.classList.remove('d-none')
            if (result) {
              this.addReviewRequest()
            }
          })
          .catch(error => {})
      }
    })
  },
  methods: {
    ...mapActions({
      addReviews: 'user/addReviews',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    setRate(val) {
      return (this.selectedRate = val)
    },
    hideModal() {
      this.$bvModal.hide('reviews-modal')
    },
    loadReviewData() {
      this.reviewData = {
        booking_id: this.feedbackRequest.id,
        client_id: this.feedbackRequest.client.id,
        avatar:
          this.feedbackRequest.salon_staff.avatar !== null
            ? this.feedbackRequest.salon_staff.avatar.path
            : require('@/assets/images/default/default_avatar.png'),
        fullName: `${this.feedbackRequest.salon_staff.user.first_name} ${this.feedbackRequest.salon_staff.user.last_name}`,
        salonName: this.feedbackRequest.salonName,
        services: this.feedbackRequest.services,
        staffName: this.feedbackRequest.staffName
      }
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.addReviewRequest()
        }
      })
    },
    addReviewRequest() {
      this.addReviews(this.payloadData)
        .then(data => {
          this.dataSent = true
          this.hideModal()
          this.setSuccess({
            type: 'Sucess',
            messageI18Key: 'common.forms.alert.another.send_feedback'
          })
        })
        .catch(error => {
          this.setError({
            type: 'Error',
            messageI18Key: 'common.forms.alert.another.global_error'
          })
        })
    },
    confirmMessage() {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', {
        domProps: { innerHTML: 'Title from <i>HTML<i> string' }
      })
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('common.confirm.title')]),
        h('p', { class: ['text-center text-small text-black pb-0 mb-0 lh-1-5'] }, [
          this.$t('common.confirm.leave_review_description')
        ])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light'
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    }
  }
}
</script>
<style scoped>
.feeback-area {
  min-height: 175px;
  resize: none;
}
.btn-rate {
  width: 48px;
  height: 48px;
}
</style>
