<template>
  <div class="col-md-6 col-12 px-0">
    <div
      v-for="(option, idx) in combinations"
      :key="idx"
      class="col-12 d-flex justify-content-center text-center p-0 text-nowrap text-truncate table-row-height combination-column"
    >
      <ColumnText
        :is-combination-with-border="idx === combinations.length - 1 && !isMobileWidth ? false : true"
        :combination="option.combination[0]"
        @set-choosen-combination="setChoosenCombination"
      />
    </div>
  </div>
</template>
<script>
import ColumnText from './../ColumnText'
export default {
  components: {
    ColumnText
  },
  props: {
    combinations: {
      type: Array,
      default: () => []
    },
    isMobileWidth: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
