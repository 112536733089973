<template>
  <b-modal
    id="tab-modal"
    header-class="modal-header pt-0 px-0 pb-0 pr-0 border-bottom-0 staff-modal"
    centered
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <div class="modal-container w-100" style="padding:0.7rem">
        <div class="top-block d-block d-md-none">
          <div class="d-flex justify-content-between mb-0-5">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="close()"
            ></font-awesome-icon>

            <h4 class="topText">
              {{ $t('salon.section.content.specialists_title') }}
            </h4>
            <div></div>
          </div>
        </div>
        <button type="button" class="close outline-none d-none d-md-block pb-2 " aria-label="Close" @click="close()">
          <span aria-hidden="true" class="close-icon align-top"></span>
        </button>

        <div class="modal-header justify-content-start align-items-center border-0">
          <img :src="avatar" alt="avatar" class="img img-cover rounded" />
          <div style="margin-left:5%">
            <h5>{{ fullName }}</h5>
            <p class="text-dark mb-1">{{ specialization }}</p>
            <p class="mb-0"><i class="barbershop-icon"></i> {{ specializationsParametersString() }}</p>
          </div>
        </div>

        <div class="body">
          <b-button-group class="nav nav-tabs staff-tabs justify-content-center mb-3">
            <b-button
              variant="link"
              :class="[
                'nav-link d-flex justify-content-center border-top border-right border-left py-0-7',
                showExperience ? 'active' : ''
              ]"
              @click.prevent="showExperience = true"
            >
              {{ $t('salon.section.booking.experience') }}
              <strong class="text-dark ml-0-5">{{ experience }}</strong>
            </b-button>
            <b-button
              variant="link"
              :class="[
                'nav-link d-flex justify-content-center border-top border-right border-left py-0-7',
                !showExperience ? 'active' : ''
              ]"
              @click.prevent="showExperience = false"
            >
              {{ $t('salon.section.booking.reviews') }}
            </b-button>
          </b-button-group>

          <div v-if="showExperience">
            <p>
              {{ staff.description }}
            </p>

            <b-tabs justified class="staffModalTabs">
              <b-tab v-if="genderServicesParameter.Lady.length" active title-link-class="d-flex justify-content-center">
                <template v-slot:title>
                  <i class="lady-icon mr-md-1 mr-0-5"></i>
                  <span class="tab-text">
                    {{ $t('common.tab.ladies') }}
                  </span>
                </template>
                <div class="d-flex mt-1-5 flex-wrap">
                  <div
                    v-for="(service, key) in genderServicesParameter.Lady"
                    :key="`lady-${key}`"
                    class="d-flex ml-0-5 ml-md-0"
                  >
                    <span class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5 mt-1">
                      {{ service }}
                    </span>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="genderServicesParameter.Gentleman.length" title-link-class="d-flex justify-content-center">
                <template v-slot:title>
                  <i class="gent-icon mr-md-1 mr-0-5"></i>
                  <span class="tab-text">
                    {{ $t('common.tab.gents') }}
                  </span>
                </template>
                <div class="d-flex mt-1-5 flex-wrap">
                  <div
                    v-for="(service, key) in genderServicesParameter.Gentleman"
                    :key="`gent-${key}`"
                    class="d-flex ml-0-5 ml-md-0"
                  >
                    <span
                      class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5  mt-1"
                      >{{ service }}</span
                    >
                  </div>
                </div>
              </b-tab>

              <b-tab
                v-if="genderServicesParameter.Boy.length || genderServicesParameter.Girl.length"
                title-link-class="d-flex justify-content-center"
              >
                <template v-slot:title>
                  <i class="child-icon mr-md-1 mr-0-5"></i>
                  <span class="tab-text">
                    {{ $t('common.tab.child') }}
                  </span>
                </template>
                <div class="d-flex mt-1-5 flex-wrap">
                  <div v-for="(service, key) in genderServicesParameter.Boy" :key="`boy-${key}`" class="ml-0-5 ml-md-0">
                    <span
                      class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5  mt-1"
                      >{{ service }}</span
                    >
                  </div>
                </div>
                <div class="d-flex mt-1-5 flex-wrap">
                  <div
                    v-for="(service, key) in genderServicesParameter.Girl"
                    :key="`girl-${key}`"
                    class="ml-0-5 ml-md-0"
                  >
                    <span
                      class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5  mt-1"
                      >{{ service }}</span
                    >
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-else class="reviews-container custom-scroll">
            <ReviewsTabs :type="'staff'" :staff-id="staff.id" :class="'px-1'" />
          </div>
        </div>

        <div class="border-0 mb-2 d-none d-md-block">
          <button type="button" class="btn btn-outline-secondary btn-block close-btn" @click="close()">Close</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import ReviewsTabs from '@/components/salon/ReviewsTabs'

export default {
  components: {
    ReviewsTabs
  },
  props: {
    staff: {
      type: Object,
      default: () => ({})
    },
    reviewsTotal: {
      type: Number
    }
  },
  data() {
    return {
      isActive: false,
      showExperience: true,
      parametersStr: '',
      genderServicesParameter: {
        Lady: [],
        Gentleman: [],
        Boy: [],
        Girl: []
      }
    }
  },
  computed: {
    avatar() {
      return this.staff.avatar ? this.staff.avatar.path : require('@/assets/images/default/default_avatar.png')
    },
    fullName() {
      return this.staff.user ? `${this.staff.user.first_name} ${this.staff.user.last_name}` : ''
    },
    specialization() {
      return this.staff.specialization ? this.staff.specialization : ''
    },
    spec_parameters() {
      return this.staff.spec_parameters ? this.staff.spec_parameters : ''
    },
    experience() {
      return this.staff.experience ? this.staff.experience : ''
    },
    description() {
      return this.staff.description ? this.staff.description : ''
    },
    parameters() {
      return this.staff.parameters ? this.staff.parameters : ''
    }
  },
  watch: {
    staff: {
      deep: true,
      handler(newVal) {
        this.genderServicesParameter = {
          Lady: [],
          Gentleman: [],
          Boy: [],
          Girl: []
        }
        let genderObjectKeys = Object.keys(this.genderServicesParameter)
        if (Object.keys(newVal)) {
          newVal.services.forEach(el => {
            for (let i = 0; i < genderObjectKeys.length; i++) {
              if (el.parameter_value.value === genderObjectKeys[i]) {
                this.genderServicesParameter[genderObjectKeys[i]].push(el.service.title)
              }
            }
          })
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === `tab-modal`) {
        this.showExperience = true
      }
    })
  },
  methods: {
    specializationsParametersString() {
      let str = ''
      let genderObjectKeys = Object.keys(this.genderServicesParameter)
      genderObjectKeys.forEach(el => {
        if (this.genderServicesParameter[el].length) {
          if (!str.includes(this.$t(`common.tab_for_staff.Kids`))) {
            if (el === 'Boy' || el === 'Girl') {
              str += `${this.$t(`common.tab_for_staff.Kids`)}/`
            } else str += `${this.$t(`common.tab_for_staff.${el}`)}/`
          }
        }
      })
      return str.substring(0, str.length - 1)
    }
  }
}
</script>
<style scoped>
.close {
  height: 2rem;
}
#tab-4 div,
#tab-5 div,
#tab-6 div {
  display: inline-block;
}
.img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  border: 3px solid white;
  box-shadow: 0 20px 40px 0 rgba(155, 159, 162, 0.35);
}

.experience__block__1 {
  border: 1px solid #e0e0e0;
  border-left: none;
  padding: 10px 9px;
}
.experience__block__2 {
  border: 1px solid #e0e0e0;
  border-right: none;
  border-left: none;
  padding: 10px 9px;
}
.body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.close-btn {
  border: solid 1px #e0e0e0;
  height: 48px !important;
}
.btn-block {
  display: block;
  width: 92%;
  margin-left: 4% !important;
}
</style>
