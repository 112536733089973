<template>
  <form :class="{ 'centered-icons': centeredIcons }">
    <div class="form-group">
      <p v-if="!hideSubTitle" class="group-label font-weight-medium mb-1 ml-md-0 ml-0-3 responseModalText">
        {{ $t('common.forms.label.choose_whom') }}
      </p>
      <div class="row text-center">
        <div
          v-for="genderOption in adultOptions"
          :key="genderOption.id"
          class="col pl-md-0-5 pr-md-1 pr-md-1 common-icon"
        >
          <label
            class="w-100 responsiveIcon position-relative btn-params"
            :class="{
              active: gender && gender.value === genderOption.value && !isChildOption
            }"
          >
            <i
              :class="{
                'btn-params--lady icon': genderOption.value === 'Lady',
                'btn-params--gents icon': genderOption.value === 'Gentleman'
              }"
            ></i>
            <input
              v-model="gender"
              type="radio"
              name="radio-1"
              class="btn-checkbox"
              autocomplete="off"
              :value="genderOption"
            />
            <span class="btn btn-checkbox btn-block">
              <span v-if="!centeredIcons" class="d-none d-md-inline-block">
                {{ $t(`common.whom_key.${genderOption.value}`) }}
              </span>
            </span>
          </label>
        </div>
        <div class="col-auto d-flex flex-column col-md pl-md-0-5 pr-md-0-5">
          <label
            class="w-100 position-relative btn-params childIconElement"
            :class="{ active: isChildOption }"
            @click="openChildOptions"
          >
            <i class="btn-params--child icon"></i>
            <span class="btn btn-checkbox btn-block px-3" :class="{ 'active-background': isChildOption }">
              <span v-if="!centeredIcons" class="d-none d-md-inline-block">
                {{ $t('common.forms.button.child') }}
              </span>
            </span>
          </label>
          <span class="text-gray d-inline-block d-md-none">1-16 {{ $t('common.forms.label.year') }}</span>
        </div>
      </div>
    </div>
    <div v-if="isChildOption" class="form-group">
      <p class="group-label font-weight-medium mb-1 ml-md-0 ml-0-3 responseModalText">
        {{ $t('common.forms.label.child_info') }}
      </p>
      <div class="row text-center">
        <div class="col-12" :class="isDeutchLang ? 'col-md-5' : 'col-md-4'">
          <div class="row">
            <div v-for="genderOption in childOptions" :key="genderOption.id" class="col-6 pl-md-0-8 child-option-label">
              <label class="w-100 mb-0 pr-md-0">
                <input
                  v-model="gender"
                  type="radio"
                  name="radio-2"
                  class="btn-checkbox "
                  autocomplete="off"
                  :value="genderOption"
                />
                <span class="btn btn-checkbox btn-block px-md-0-5 px-0">{{
                  $t(`common.whom_key.${genderOption.value}`)
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <AgeSlider :gender="gender" :age-data="ageData"></AgeSlider>
      </div>
    </div>
  </form>
</template>
<script>
import AgeSlider from './AgeSlider'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'GenderOptions',
  components: {
    AgeSlider
  },
  props: {
    adultOptions: {
      type: Array,
      default: () => []
    },
    childOptions: {
      type: Array,
      default: () => []
    },
    haircutParams: {
      type: Object,
      default: () => ({})
    },
    clearDataFlag: {
      type: Boolean,
      default: false
    },
    loadingCombinationData: {
      type: Boolean,
      default: false
    },
    hideSubTitle: Boolean,
    hideChildLabel: Boolean,
    centeredIcons: Boolean
  },
  data() {
    return {
      gender: null,
      isChildOption: false,
      dataIsClean: false
    }
  },
  computed: {
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    },
    ageData() {
      return this.haircutParams.age
    }
  },
  watch: {
    gender: {
      deep: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.haircutParams.gender = newVal
        }
        this.handleGenderOptionClick(newVal)
      }
    },
    haircutParams: {
      deep: true,
      handler(newVal) {
        if (!newVal.gender && !newVal.length && !newVal.age && this.loadingCombinationData) {
          this.gender = newVal.gender
        } else if (newVal.gender && Object.keys(newVal.gender).length && this.loadingCombinationData) {
          this.gender = newVal.gender
        }
      }
    },
    clearDataFlag(newVal) {
      this.isChildOption = false
    }
  },
  mounted() {
    this.gender = this.haircutParams.gender
  },
  methods: {
    ...mapActions('haircutParams', {
      setCommonHaircutParams: 'setCommonHaircutParams'
    }),
    ...mapMutations({
      clearChildHaircutParams: 'haircutParams/CLEAR_CHILD_HAIRCUT_PARAMS'
    }),
    openChildOptions() {
      this.isChildOption = true
      this.gender = null
    },
    handleGenderOptionClick(gender) {
      const genderOption = gender ? gender.value : null
      const genderParam = { paramName: 'gender', value: gender }
      if (genderOption === 'Lady' || genderOption === 'Gentleman') {
        this.isChildOption = false
      } else if (genderOption === 'Girl' || genderOption === 'Boy') {
        this.isChildOption = true
      }

      if (!this.isChildOption) this.clearChildHaircutParams()
      this.setCommonHaircutParams(genderParam)
    }
  }
}
</script>
