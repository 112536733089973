<template>
  <div class="tab-content d-flex flex-column w-100" :class="{ 'm-auto': templates.length === 0 }">
    <div
      role="tabpanel"
      aria-labelledby="v-pills-info-tab"
      class="templates-content d-flex flex-column justify-content-center h-100"
    >
      <div v-if="templates.length === 0" class="text-center pb-8">
        <i class="template-icon mb-2"></i>
        <h1 class="h3 mb-1-5">{{ $t('common.profile_menu.link.profile_templates') }}</h1>
        <p class="mb-2-5 font-weight-medium poppins-font text-black text-center">
          {{ $t('profile_templates.empty_content_text') }}
        </p>
        <button v-b-modal.template-modal data-toggle="modal" class="btn btn-primary btn-lg">
          <p class="h6 font-weight-medium mb-0 text-white template-btn">
            {{ $t('common.forms.button.create_template_short') }}
          </p>
        </button>
      </div>

      <div v-else class="d-flex flex-column pb-md-6 ml-md-0  pb-3 h-100">
        <h1 class="h3 mb-0">{{ $t('common.profile_menu.link.profile_templates') }}</h1>
        <div id="templateAccordion" class="accordion mb-3">
          <div
            v-for="(template, key) in templates"
            :key="`template-${key}`"
            class="card mt-1-5 border-bottom rounded"
            :class="{ main: isMainTemplate(template) }"
          >
            <div class="card-header py-1">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <div class="mr-md-2 mr-0">
                    <img
                      :src="userAvatar(template)"
                      alt
                      class="template-image rounded image--cover d-none d-md-block"
                    />
                  </div>
                  <div>
                    <label
                      v-if="template.first_name && template.last_name"
                      class="d-block poppins-font text-black mb-0 font-weight-medium"
                    >
                      {{ $t('common.forms.label.full_name') }}
                    </label>
                    <label class="d-block poppins-font text-black mb-0 font-weight-medium">
                      {{ $t('common.forms.label.whom') }}
                    </label>
                    <label class="d-block poppins-font text-black mb-0 font-weight-medium">
                      {{ $t('common.forms.label.length') }}
                    </label>
                  </div>
                  <div class="ml-1-5">
                    <p v-if="template.first_name && template.last_name" class="mb-0">
                      {{ template.first_name }} {{ template.last_name }}
                    </p>
                    <p class="mb-0">
                      <span>{{ findParamertValue('genders', template.gender_parameter) }}</span>
                      <span v-if="template.age_parameter"
                        >; {{ findParamertValue('age', template.age_parameter) }}
                      </span>
                    </p>
                    <p class="mb-0 pt-0-5">{{ findParamertValue('hairLength', template.hair_length_parameter) }}</p>
                  </div>
                </div>
                <div
                  class="ellipsis-block collapsed"
                  :class="{ 'text-primary': collapsedTemplate === template.id }"
                  @click="collapseTemplate(template.id)"
                >
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                </div>
              </div>
            </div>

            <b-collapse :id="`collapse-${template.id}`" accordion="templateAccordion" role="tabpanel">
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <button
                      class="btn btn-lg px-0 btn-checkbox btn-block"
                      :class="{ active: isMainTemplate(template) }"
                      @click="setMainTemplate(template)"
                    >
                      <span class="h6 roboto-font font-weight-regular mb-0">
                        {{ $t('common.forms.button.main') }}
                      </span>
                    </button>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-lg px-0 btn-checkbox btn-block" @click="editTemplate(template)">
                      <span class="h6 roboto-font font-weight-regular mb-0">
                        {{ $t('common.forms.button.edit') }}
                      </span>
                    </button>
                  </div>
                  <div class="col-4">
                    <button class="btn btn-lg px-0 btn-checkbox btn-block" @click="deleteTemplateFromPage(template)">
                      <span class="h6 roboto-font font-weight-regular mb-0">
                        {{ $t('common.forms.button.delete') }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>

        <button v-b-modal.template-modal class="btn btn-lg btn-primary w-100 mt-auto mb-md-3 mb-0 template-btn">
          <p class="h6 font-weight-medium mb-0 text-white">
            {{ $t('common.forms.button.create_template') }}
          </p>
        </button>
      </div>

      <b-modal
        id="template-modal"
        ref="templateMobileModal"
        header-class="modal-header pt-4 px-3 pb-0 border-bottom-0 mt-1-5 mt-md-0"
        body-class="templateModalBody px-md-3 px-2 pb-md-3 pb-2"
        centered
        hide-footer
      >
        <template v-slot:modal-header="{ close }">
          <div class="top-block d-block d-md-none w-100">
            <div class="d-flex justify-content-between">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-mobile ml-1-5"
                @click="close()"
              ></font-awesome-icon>

              <h4 class="pl-2 text-center">{{ $t(i18KeyModalTitle) }}</h4>
              <p class="collapsed change-pass-btn mr-2-5 text-nowrap" @click="activatedClearAllFlag()">
                {{ $t('common.forms.button.clear_all') }}
              </p>
            </div>
          </div>
          <h5
            id="createTemplateLabel"
            class="modal-title text-center w-100 font-weight-medium mb-2-5 d-none d-md-block"
          >
            {{ $t(i18KeyModalTitle) }}
          </h5>

          <button type="button" class="close position-absolute d-none d-md-block" @click="close()">
            <span aria-hidden="true" class="close-icon"></span>
          </button>
        </template>
        <div class="h-100">
          <TemplateForm
            :template="selectedTemplate"
            :clear-template-flag="clearAllFlag"
            class="h-100"
            @handle-submit-template-form="handleSubmitTemplateForm"
            @set-upload-image="setUploadImage"
            @activated-clear-all-flag="activatedClearAllFlag"
            @disabled-clear-all-flag="disabledClearAllFlag"
          />
        </div>
      </b-modal>
    </div>
    <VerificationPopup />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TemplateForm from '@/components/profile/TemplateForm'
import VerificationPopup from '@/views/verification/VerificationPopup'
import { isEmpty } from 'lodash'
export default {
  name: 'UserTemplates',
  components: {
    TemplateForm,
    VerificationPopup
  },
  data() {
    return {
      selectedTemplate: {},
      updateMainTemplateSuccessText: {
        type: this.$t('common.forms.alert.template.type_update_main'),
        messageI18Key: 'common.forms.alert.template.update_message_main'
      },
      updateTemplateSuccessText: {
        type: this.$t('common.forms.alert.template.type_update'),
        messageI18Key: 'common.forms.alert.template.update_message'
      },
      createTemplateSuccessText: {
        type: this.$t('common.forms.alert.template.type_create'),
        messageI18Key: 'common.forms.alert.template.type_create_message'
      },
      imageToUpload: null,
      collapsedTemplate: '',
      clearAllFlag: false,
      i18KeyModalTitle: 'common.modal.add_template_title',
      haircutParams: null
    }
  },
  computed: {
    ...mapGetters({
      templates: 'user/getTemplates',
      parametersValues: 'haircutParams/getParametersValues',
      commonHaircutParams: 'haircutParams/getCommonHaircutParams',
      childHaircutParams: 'haircutParams/getChildHaircutParams'
    })
  },
  mounted() {
    this.disabledClearAllFlag()

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'template-modal') {
        this.selectedTemplate = {}

        this.resetChildHaircutParams()
        this.resetParams()
      }
    })
    let isIos = navigator.platform.includes('iPhone') ? true : false
    if (isIos && this.$route.path === '/profile-page/templates') {
      window.innerWidth < 400
        ? this.$refs.templateMobileModal.bodyClasses.push('mt-1-5')
        : this.$refs.templateMobileModal.bodyClasses.push('mt-2')
    }
  },
  methods: {
    ...mapActions({
      deleteTemplate: 'user/deleteTemplate',
      createTemplate: 'user/createTemplate',
      updateTemplate: 'user/updateTemplate',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    ...mapMutations({
      resetParams: 'haircutParams/RESET_COMMON_PARAMS',
      resetChildHaircutParams: 'haircutParams/CLEAR_CHILD_HAIRCUT_PARAMS',
      deleteMainTemplateParameters: 'haircutParams/RESET_MAIN_TEMPLATE_PARAMETERS',
      clearCommonHaircutParams: 'haircutParams/CLEAR_ALL_COMMON_HAIRCUT_PARAMS'
    }),
    isMainTemplate(template) {
      return template.main_template === 1
    },
    userAvatar(template) {
      return template.avatar !== null && template.avatar !== undefined
        ? template.avatar.path
        : require('@/assets/images/default/default_avatar.png')
    },
    collapseTemplate(id) {
      this.collapsedTemplate = id
      this.$root.$emit('bv::toggle::collapse', `collapse-${id}`)
    },
    findParamertValue(key, id) {
      let params = this.parametersValues[key].values.find(parameter => parameter.id === id)
      if (
        params &&
        (params.value === 'Lady' || params.value === 'Gentleman' || params.value === 'Boy' || params.value === 'Girl')
      ) {
        if (params.value === 'Boy' || params.value === 'Girl') {
          return params
            ? this.$t(`common.template_gender_key.child`) + '; ' + this.$t(`common.template_gender_key.${params.value}`)
            : ''
        } else return params ? this.$t(`common.template_gender_key.${params.value}`) : ''
      } else if (params && (params.value === 'Long' || params.value === 'Short' || params.value === 'Medium')) {
        return params ? this.$t(`common.length_key.${params.value}`) : ''
      } else if (params && params.value && Number(params.value)) {
        return `${params.value} ` + this.$t('common.template_year_key.year') + `;`
      }
    },
    selectTemplate(template) {
      const {
        id,
        gender_parameter,
        hair_length_parameter,
        age_parameter,
        main_template,
        avatar,
        first_name,
        last_name
      } = template

      this.selectedTemplate = {
        id: id,
        main_template: main_template,
        avatar: avatar,
        first_name: first_name,
        last_name: last_name,
        gender: {
          id: gender_parameter,
          value: this.getEditedFormattedParameterValue('genders', gender_parameter)
        }
      }

      if (hair_length_parameter) {
        this.selectedTemplate = {
          ...this.selectedTemplate,
          ...{
            hairLength: {
              id: hair_length_parameter,
              value: this.getEditedFormattedParameterValue('hairLength', hair_length_parameter)
            }
          }
        }
      }

      if (age_parameter) {
        this.selectedTemplate = {
          ...this.selectedTemplate,
          ...{
            age: {
              id: age_parameter,
              value: this.getEditedFormattedParameterValue('age', age_parameter)
            }
          }
        }
      }
    },
    getEditedFormattedParameterValue(key, id) {
      const params = this.parametersValues[key].values.find(parameter => parameter.id === id)
      if (
        params &&
        (params.value === 'Lady' || params.value === 'Gentleman' || params.value === 'Boy' || params.value === 'Girl')
      ) {
        return this.$t(`common.template_gender_edit_key.${params.value}`)
      } else if (params && Number(params.value)) {
        return params ? Number(params.value) : null
      } else if (params && (params.value === 'Long' || params.value === 'Short' || params.value === 'Medium')) {
        return params ? params.value : ''
      }
    },
    editTemplate(template) {
      this.selectTemplate(template)
      this.$bvModal.show('template-modal')
    },
    handleSubmitTemplateForm(sometimesData) {
      const formData = new FormData()
      let mainTemplate = Object.keys(this.selectedTemplate).length ? this.selectedTemplate.main_template : 0

      formData.append('_method', 'POST')

      formData.append('main_template', mainTemplate)

      if (!isEmpty(this.commonHaircutParams.gender)) {
        formData.append('values[gender]', this.commonHaircutParams.gender.id)
      }

      if (!isEmpty(this.commonHaircutParams.length)) {
        formData.append('values[length]', this.commonHaircutParams.length.id)
      }

      if (!isEmpty(this.childHaircutParams.age)) {
        formData.append('values[age]', this.childHaircutParams.age.id)
      }

      if (sometimesData.first_name) {
        formData.append('first_name', sometimesData.first_name)
      }

      if (sometimesData.last_name) {
        formData.append('last_name', sometimesData.last_name)
      }

      if (this.imageToUpload) {
        formData.append('avatar', this.imageToUpload)
      }

      if (Object.keys(this.selectedTemplate).length) {
        formData.append('client_id', this.selectedTemplate.id)
        this.updateTemplate(formData)
          .then(() => {
            this.$bvModal.hide('template-modal')
            this.imageToUpload = null
            this.setSuccess(this.updateTemplateSuccessText)
          })
          .catch(err => {
            if (err.message && err.message === 'Your email address is not verified.') {
              this.$bvModal.show('verification-modal')
            } else {
              const errorPayload = {
                type: err.message,
                message: err.data.message,
                code: err.code
              }
              this.isPasswordUpdating = false
              this.setError(errorPayload)
            }
          })
      } else {
        this.createTemplate(formData)
          .then(() => {
            this.$bvModal.hide('template-modal')
            this.imageToUpload = null
            this.setSuccess(this.createTemplateSuccessText)
          })
          .catch(err => {
            if (err.message && err.message === 'Your email address is not verified.') {
              this.$bvModal.show('verification-modal')
            } else {
              const errorPayload = {
                type: err.message,
                message: err.data.message,
                code: err.code
              }
              this.isPasswordUpdating = false
              this.setError(errorPayload)
            }
          })
      }
    },
    setMainTemplate(template) {
      if (template.main_template === 1) {
        return
      }

      let bodyParams = {
        client_id: template.id,
        main_template: 1,
        values: {
          gender: template.gender_parameter,
          length: template.hair_length_parameter
        }
      }

      if (template.age_parameter) {
        bodyParams.values.age = template.age_parameter
      }

      this.updateTemplate(bodyParams).then(data => {
        this.$root.$emit('bv::toggle::collapse', `collapse-${data.id}`)
        this.setSuccess(this.updateMainTemplateSuccessText)
      })
    },
    setUploadImage(image) {
      this.imageToUpload = image
    },
    deleteTemplateFromPage(template) {
      this.deleteTemplate(template.id)
      if (template.main_template === 1) {
        this.deleteMainTemplateParameters()
        this.clearCommonHaircutParams()
      }
    },
    activatedClearAllFlag() {
      this.clearAllFlag = true
    },
    disabledClearAllFlag() {
      this.clearAllFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion .card:first-child .card-header:before {
  content: none;
}
.accordion .card.main .card-header:before {
  content: '';
  display: block;
  width: 0.1875rem;
  height: 100%;
  background: #ff6b09;
  position: absolute;
  left: 0;
  top: 0;
}
.template-image {
  width: 80px;
  height: 80px;
}
.top-block {
  top: 0;
  left: 0;
  width: 100%;
  /* border-bottom: 1px solid #8d9fa7; */
  border-bottom: solid 2px #e0e0e0;
}
</style>
