<template>
  <div class="tab-content h-100 w-100" :class="{ 'm-auto': data.length === 0 }">
    <div role="tabpanel" aria-labelledby="v-pills-info-tab" class="d-flex flex-column justify-content-center h-100">
      <div v-if="data.length === 0" class="text-center pb-8">
        <i class="credit-card-icon"></i>
        <p class="my-2-5 font-weight-medium poppins-font text-black text-center">
          {{ $t('profile_credit-card.empty_content_text') }}
        </p>
        <button class="btn btn-primary btn-lg">
          <p class="h6 font-weight-medium mb-0 text-white">
            {{ $t('common.forms.button.create_creadit_card') }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserCreditCard',
  data() {
    return {
      data: []
    }
  }
}
</script>
