<template>
  <footer class="bg-black black-bg">
    <div class="container py-6">
      <div class="row align-items-center">
        <div class="col-xl-2">
          <a class="navbar-brand" href="#">
            <img src="@/assets/images/logo_quiff_white.svg" alt="Quiff" />
            <p class="mb-0 mt-1">© All rights reserved</p>
          </a>
        </div>
        <div class="col-xl-8">
          <ul class="nav justify-content-center mb-1 footer-nav">
            <li class="nav-item">
              <a class="nav-link" :href="salonAppUrl" :title="$t('common.footer.salon_owner')" target="_blank">
                {{ $t('common.footer.salon_owner') }}
              </a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/agb`" :title="$t('terms.agb_page-title')">
                {{ $t('terms.agb_page-title') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/agb-for-partners`" :title="$t('terms.agb_for_partners_page-title')">
                {{ $t('terms.agb_for_partners_page-title') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/privacy-policy`" :title="$t('terms.privacy_page-title')">
                {{ $t('terms.privacy_page-title') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/impressum`" :title="$t('terms.impressum_page-title')">
                {{ $t('terms.impressum_page-title') }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact-us" class="nav-link" :title="$t('contact-us.title')">
                {{ $t('contact-us.title') }}
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://blog.quiff.ch/" target="_blank" title="Blog">
                Blog
              </a>
            </li>
          </ul>
          <ul class="nav justify-content-center social-links">
            <li class>
              <a class href="https://www.linkedin.com/company/quiff-ch" target="_blank" title="Linkedin">
                <font-awesome-icon :icon="['fab', 'linkedin-in']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.instagram.com/quiff.ch/" target="_blank" title="Instagram">
                <font-awesome-icon :icon="['fab', 'instagram']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.facebook.com/quiff.ch" target="_blank" title="Facebook">
                <font-awesome-icon :icon="['fab', 'facebook-f']" class="text-white"></font-awesome-icon>
              </a>
            </li>
            <li class>
              <a class href="https://www.pinterest.ch/quiff_ch/" target="_blank" title="Pinterest">
                <font-awesome-icon :icon="['fab', 'pinterest-p']" class="text-white"></font-awesome-icon>
              </a>
            </li>
          </ul>
          <ul class="nav justify-content-center mt-1-5 footer-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="`/Coiffeur-Bern`" title="Coiffeur Bern">
                Coiffeur Bern
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/Coiffeur-Aarau`" title="Coiffeur Aarau">
                Coiffeur Aarau
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/Coiffeur-Basel`" title="Coiffeur Basel">
                Coiffeur Basel
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/Coiffeur-Zürich`" title="Coiffeur Zürich">
                Coiffeur Zürich
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="`/Coiffeur-St-Gallen`" title="Coiffeur St Gallen">
                Coiffeur St Gallen
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-xl-2 justify-content-end d-flex">
          <a
            href="#"
            class="scroll-to-top d-flex align-items-center justify-content-center"
            @click.prevent="scrollToTop"
          >
            <font-awesome-icon
              :icon="['far', 'long-arrow-up']"
              class="text-white w-24"
              style="font-size:24px"
            ></font-awesome-icon>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import config from 'config'

export default {
  name: 'Footer',
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      })
    }
  }
}
</script>
