<template>
  <main>
    <section class="navbar-offset landing-page main-bg">
      <div class="container-md px-0 px-md-1">
        <div class="haircut-params-page-bg w-100 pt-1-5 pt-md-4 pl-1-5 pl-md-0">
          <i class="mobile-haircut-logo-icon mb-1-5 d-block d-md-none"></i>
          <h1 class="mob-h1 font-weight-semibold mb-5" v-html="$t('main.section.search.title')"></h1>
        </div>
      </div>
      <div class="container pt-4 pt-md-0 pb-md-4 pb-lg-14">
        <div class="row">
          <div class="col col-lg-6 col-xl-5">
            <form v-if="storageReady && areParamsLoaded" class="params-form">
              <GenderOptions
                v-if="storageReady && areParamsLoaded"
                :adult-options="adultGenderOptions"
                :child-options="childGenderOptions"
                :haircut-params="haircutParams"
                :hide-child-label="true"
                :centered-icons="isCenteredIcons"
              />
              <p class="group-label font-weight-medium mb-1 ml-md-0 ml-0-3 responseModalText">
                {{ $t('common.forms.label.select_service') }}
              </p>
              <Services> </Services>
              <HairLengthSelect v-if="isHairLengthVisible" :options="hairLengthOptions" :params="haircutParams" />
              <div class="form-group">
                <p class="group-label font-weight-medium mb-1 ml-md-0 ml-0-3 responseModalText">
                  {{ $t('common.forms.label.select_day') }}
                </p>
                <Calendar></Calendar>
              </div>
              <LocationModalBody
                :is-haircut-params-empty="isHaircutParamsEmpty"
                :apply="isApllyForSearch"
                @applyAndSearchFlag="setApplyAndSearchFlag"
              ></LocationModalBody>
              <div class="form-group pb-md-0">
                <button
                  class="btn btn-primary font-weight-medium btn-block font-poppins border-0 btn-lg"
                  @click.prevent="searchForSalons"
                >
                  {{ $t('common.forms.button.start_search') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <b-modal
        id="reviews-modal"
        ref="reviewsModal"
        header-class="modal-header border-0 px-0 pt-md-1"
        footer-class="lg-btn px-0-5 px-md-2-5"
        ok-only
        ok-title="Leave feedback"
        ok-variant="primary"
        :ok-disabled="statusReviewForm"
      >
        <template v-slot:modal-header="{ close }">
          <div class="d-none d-md-flex justify-content-end w-100">
            <b-button variant="link" class="p-0 mr-1-5" @click.prevent="close">
              <font-awesome-icon :icon="['fal', 'times']" class="icon-close-mobile text-gray"></font-awesome-icon>
            </b-button>
          </div>
          <div class="top-block d-block d-md-none">
            <div class="d-flex justify-content-between mb-0-5">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-mobile ml-1-5"
                @click="close"
              ></font-awesome-icon>

              <h4 class="topText">{{ $t('main.section.reviews_modal.title') }}</h4>
              <div></div>
            </div>
            <p class="px-1">{{ $t('main.section.reviews_modal.subtitle') }}</p>
          </div>
        </template>
        <ReviewsForm :send-form-review="sendReview" @set-status-review-form="setStatusReviewForm"></ReviewsForm>
      </b-modal>
    </section>

    <firstSection class="text-center text-md-left"></firstSection>
    <secondSection class="text-center text-md-left"></secondSection>
    <thirdSection class="text-center text-md-left"></thirdSection>
    <fourthSection class="text-center text-md-left"></fourthSection>
    <VerificationPopup />
  </main>
</template>
<script>
import Services from '../components/Services'
import Calendar from '../components/Calendar'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GenderOptions from '../components/GenderOptions'
import HairLengthSelect from './../components/HairLengthSelect'
import LocationModalBody from '@/components/LocationModalBody'
import ReviewsForm from '@/components/ReviewsForm'
import firstSection from '@/components/mainPageSections/firstSection'
import secondSection from '@/components/mainPageSections/secondSection'
import thirdSection from '@/components/mainPageSections/thirdSection'
import fourthSection from '@/components/mainPageSections/fourthSection'
import VerificationPopup from '@/views/verification/VerificationPopup'
import { isEmpty } from 'lodash'

export default {
  name: 'HaircutParamsPage',
  components: {
    Services,
    Calendar,
    GenderOptions,
    HairLengthSelect,
    LocationModalBody,
    ReviewsForm,
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
    VerificationPopup
  },
  data() {
    return {
      haircutParams: {
        gender: {},
        date: null,
        length: {},
        age: {}
      },
      areParamsLoaded: false,
      isChildOption: false,
      isApllyForSearch: false,
      statusReviewForm: true,
      sendReview: false
    }
  },
  watch: {
    isHairLengthVisible(newVal) {
      if (newVal === 0) {
        this.clearChoosenLegth()
      }
    }
  },
  computed: {
    ...mapGetters({
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      getParametersKeysNameByApiKey: 'haircutParams/getParametersKeysNameByApiKey',
      parametersValues: 'haircutParams/getParametersValues',
      currentPlace: 'salons/getCurrentPlace',
      getChoosenServices: 'haircutParams/getChoosenServices',
      mainTemplate: 'user/getMainTemplate',
      feedbackRequest: 'user/getFeedbackRequest',
      authInfo: 'user/getAuthInfo',
      accountInfo: 'user/getAccountInfo'
    }),
    isCenteredIcons() {
      return window.innerWidth > 767 ? false : true
    },
    storageReady() {
      return this.adultGenderOptions.length && this.childGenderOptions.length && this.hairLengthOptions.length
    },
    hairGenderOptions() {
      return this.parametersValues.genders.values
    },
    hairLengthOptions() {
      return this.parametersValues.hairLength.values
    },
    adultGenderOptions() {
      return [this.parametersValues.genders.values[0], this.parametersValues.genders.values[1]]
    },
    childGenderOptions() {
      return [this.parametersValues.genders.values[2], this.parametersValues.genders.values[3]]
    },
    isHaircutParamsEmpty() {
      const { allSelectedHaircutParams } = this
      for (let key in allSelectedHaircutParams) {
        if (
          allSelectedHaircutParams[key] === null ||
          allSelectedHaircutParams[key] === undefined ||
          allSelectedHaircutParams[key].length === 0
        ) {
          return true
        }
      }
      if (!allSelectedHaircutParams.gender) return true
      if (this.isHairLengthVisible) {
        if (isEmpty(allSelectedHaircutParams.length)) {
          return true
        }
      }

      if (!isEmpty(allSelectedHaircutParams.age)) {
        if (isEmpty(allSelectedHaircutParams.gender)) {
          return true
        }
      }

      return false
    },
    isHairLengthVisible() {
      return this.getChoosenServices.length && this.getChoosenServicesHaircutParam() === 1
    }
  },
  beforeCreate: function() {
    this.$emit('toggle-loaded', false)
    window.scrollTo(0, -200)
    let navbar = document.getElementById('top-navbar')
    if (navbar) {
      navbar.classList.remove('bg-white')
      navbar.classList.add('bg-inherit')
    }
  },
  mounted() {
    if (this.authInfo.isAuthorized) {
      this.getAccountInfo().then(data => {
        if (this.feedbackRequest) {
          this.$bvModal.show('reviews-modal')
        }
        if (this.accountInfo && !this.accountInfo.email_verified_at) {
          this.$bvModal.show('verification-modal')
        }
      })
    }

    this.areParamsLoaded = false
    this.$emit('toggle-loaded', false)

    this.getParamsValues().then(data => {
      let params = this.collectParams(data)

      if (params) {
        this.haircutParams = params
        if (isEmpty(params.age)) {
          this.setAllCommonHaicutParams(params)
        } else {
          this.setAllCommonHaicutParams(params)
          this.setChildHaircutParams(params.age)
        }
      } else {
        this.haircutParams.gender = this.allSelectedHaircutParams.gender
        this.haircutParams.length = this.allSelectedHaircutParams.length
        this.haircutParams.age = this.allSelectedHaircutParams.age
      }

      this.$emit('toggle-loaded', true)
      this.areParamsLoaded = true
    })
  },
  // TODO: 1 method for all clear-data methods
  methods: {
    ...mapMutations('haircutParams', {
      setAllCommonHaicutParams: 'SET_ALL_COMMON_HAIRCUT_PARAMS',
      setChildHaircutParams: 'SET_CHILD_HAIRCUT_PARAMS',
      clearChoosenLegth: 'CLEAR_CHOOSEN_LENGTH'
    }),
    ...mapActions({
      getAccountInfo: 'user/getAccountInfo',
      clearGenderParam: 'haircutParams/clearGenderParam',
      setCommonHaircutParams: 'haircutParams/setCommonHaircutParams',
      clearChildHaircutParams: 'haircutParams/clearChildHaircutParams',
      getParamsValues: 'haircutParams/getParamsValues',
      setError: 'userMessages/setError'
    }),
    handleLengthOptionClick(value) {
      const hairLengthParam = { paramName: 'length', value }
      this.setCommonHaircutParams(hairLengthParam)
    },
    searchForSalons() {
      let errorInfo = {}
      if (this.isHaircutParamsEmpty) {
        errorInfo = {
          type: this.$t('common.forms.alert.empty_fields_type'),
          messageI18Key: 'common.forms.alert.empty_fields_message'
        }

        this.setError(errorInfo)
      } else {
        this.isApllyForSearch = true
      }
    },
    collectParams(params) {
      if (this.mainTemplate === undefined) return

      const { gender_parameter, hair_length_parameter, age_parameter } = this.mainTemplate
      const mainTemplateParams = Array.from([gender_parameter, hair_length_parameter, age_parameter])

      let commonParamsData = {}

      for (let key in params) {
        let i = Object.keys(params).indexOf(key)
        let paramsLength = params.hairLength.values.find(param => param.id === mainTemplateParams[i])
        let paramsAge = params.age.values.find(param => param.id === mainTemplateParams[i])
        let paramsData = params[key].values.find(param => param.id === mainTemplateParams[i])

        if (paramsLength === undefined) {
          this.clearChoosenLegth()
        }

        if (paramsData !== undefined) {
          commonParamsData[this.getParametersKeysNameByApiKey(key)] = paramsData
        } else if (paramsLength !== undefined) commonParamsData.length = paramsLength
        else if (paramsAge) commonParamsData.age = paramsAge
      }

      return commonParamsData
    },
    getChoosenServicesHaircutParam() {
      if (!this.getChoosenServices.length) return
      else {
        return this.getChoosenServices[0].service_category
          ? this.getChoosenServices[0].service_category.haircut_params
          : this.getChoosenServices[0].haircut_params
      }
    },
    setApplyAndSearchFlag(flag) {
      this.isApllyForSearch = flag
    },
    setStatusReviewForm(val) {
      this.statusReviewForm = val
    }
  }
}
</script>
<style scoped></style>
