<template>
  <div>
    <SaleAlert v-if="saleModeOn" />
    <TopNavbar
      v-if="showNavBar"
      class="d-none d-md-block"
      :has-border="navbarBordered && !isProfileTabletVariant"
      :has-full-width="maxWidthContainer"
    ></TopNavbar>
    <router-view v-show="isLoaded" @toggle-loaded="toggleLoaded" />

    <b-alert
      v-model="showCookieAlert"
      class="position-fixed fixed-bottom m-0 rounded-0 border-top row align-items-center px-0 bg-white"
      style="z-index: 2000;"
      variant="light"
    >
      <div class="col-12 col-md text-dark text-center text-md-left">
        {{ $t('common.cookie_alert.title') }}
      </div>

      <div class="col-12 col-md-auto text-center mt-1-5 mt-md-0">
        <b-button class="px-3" variant="primary" @click="showCookieAlert = false">
          {{ $t('common.cookie_alert.button.ok') }}
        </b-button>
        <b-button class="ml-1-5 px-3" variant="primary" @click="redirectToPrivacyPolicy">
          {{ $t('common.cookie_alert.button.learn_more') }}
        </b-button>
      </div>
    </b-alert>

    <Footer v-if="showFooter && isLoaded"></Footer>

    <Spinner v-show="!isLoaded" />
    <BottomNavBar
      v-if="this.$route.path !== '/profile-page/edit' && this.$route.meta.layout !== 'auth'"
      ref="bottomNavbar"
      class="bottomNavBar d-block d-md-none pt-3"
    ></BottomNavBar>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import SaleAlert from '@/components/alert/Sale'
import TopNavbar from './views/navbar/desktopNavBar'
import BottomNavBar from './views/navbar/mobileNavBar'
import Spinner from './views/Spinner'
import Footer from '@/components/Footer'
import config from 'config'

export default {
  name: 'App',
  components: { TopNavbar, Spinner, BottomNavBar, Footer, SaleAlert },
  data() {
    return {
      isInit: false,
      isAuthorizedViaGoogle: false,
      isLoaded: true,
      showCookieAlert: true,
      windowWidth: window.innerWidth,
      saleModeOn: config.saleModeOn
    }
  },
  computed: {
    ...mapGetters({
      errorInfo: 'userMessages/getErrorInfo',
      successInfo: 'userMessages/getSuccessInfo',
      authInfo: 'user/getAuthInfo',
      isRedirectedFromBooking: 'user/getRedirectedAuthFlag',
      getUserPosition: 'user/getUserPosition',
      acceptCookiesAlert: 'user/getAcceptCookiesAlert',
      siteLang: 'user/getSiteLang'
    }),
    showNavBar() {
      return this.$route.meta.layout !== 'auth'
    },
    navbarBordered() {
      return this.$route.meta.layout === 'header-bordered'
    },
    isProfileTabletVariant() {
      return this.$route.path.includes('/profile-page') && this.windowWidth < 992 && this.windowWidth > 767
    },
    maxWidthContainer() {
      return this.$route.meta.layout === 'max-width-container'
    },
    showFooter() {
      return (
        this.$route.name === 'home' ||
        this.$route.name === 'salon-page' ||
        this.$route.name === 'agb' ||
        this.$route.name === 'agb-for-partners' ||
        this.$route.name === 'privacy-policy' ||
        this.$route.name === 'impressum' ||
        this.$route.name === 'coiffeur-online-buchen' ||
        this.$route.name === 'coiffeur-aarau' ||
        this.$route.name === 'coiffeur-bern' ||
        this.$route.name === 'coiffeur-basel' ||
        this.$route.name === 'herren-coiffeur' ||
        this.$route.name === 'kinder-coiffeur' ||
        this.$route.name === 'damen-coiffeur' ||
        this.$route.name === 'Coiffeur-Zürich' ||
        this.$route.name === 'haarprodukte-coiffeur' ||
        this.$route.name === 'Coiffeur St Gallen'
      )
    }
  },
  watch: {
    showCookieAlert(newVal) {
      this.toggleCookieAlert(true)
    },
    siteLang: {
      handler(newVal) {
        this.updatelang()
      }
    },
    errorInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.errorExists) {
          this.errorMessage(newVal)
        }
      }
    },
    successInfo: {
      deep: true,
      handler(newVal) {
        if (newVal.successExists) this.successMessage(newVal)
      }
    },
    $route(to, from) {
      if (from.name === 'home' && to.name === 'salons-page') {
        this.saveCommonHaircutParams()
      }
      if (from.name === 'salon-page' && to.name === 'salons-page') {
        this.restoreCommonHaircutParams()
        this.restoreChoosenDate()
        this.restoreChoosenServices()
      }
      if (this.showNavBar && to.hash !== '#link_to_modal') {
        this.scrollCheck()
      }
      if ((to.name === 'sign-in' || to.name === 'sign-up') && this.authInfo.isAuthorized) {
        this.manualLogout()
      }
      if (from.name === 'payment' && !this.isRedirectedFromBooking) {
        this.clearClientBooking()
      }
      if (from.name === 'payment') {
        this.clearChoosenServices()
        this.clearClientBooking()
        this.setRedirectAuthFlag(false)
        this.restoreChoosenServices()
      }
      if (this.$route.meta.i18PageTitle.length) {
        if (!this.$route.name.includes('coiffeur')) document.title = `Quiff | ${this.$t(this.$route.meta.i18PageTitle)}`
        else document.title = this.$t(this.$route.meta.i18PageTitle)
      } else document.title = 'Quiff'

      const routeForSavedPaymentStore = [
        'sign-in',
        'sign-up',
        'reset-password-request',
        'reset-password-new',
        'payment',
        'marketplace',
        'product-detail-page'
      ]

      if (!routeForSavedPaymentStore.includes(to.name)) {
        this.clearClientBooking()
        this.resetShopState()
      }

      this.isLoaded = true
    }
  },
  created() {
    const checkGauthLoad = setInterval(() => {
      this.isInit = this.$gAuth.isInit
      this.isAuthorizedViaGoogle = this.$gAuth.isAuthorized

      if (this.isInit) {
        this.checkIfUserAuthorized(this.isAuthorizedViaGoogle)
        clearInterval(checkGauthLoad)
      }
    }, 1000)
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth)
    this.updatelang()
    this.showErrorAlerts()

    this.showCookieAlert = !this.acceptCookiesAlert
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowWidth)
  },
  methods: {
    ...mapActions('userMessages', {
      clearError: 'clearError',
      clearSuccess: 'clearSuccess',
      setError: 'setError'
    }),
    ...mapActions('salons', {
      clearCurrentPlace: 'clearCurrentPlace'
    }),
    ...mapActions('user', {
      getAccountInfo: 'getAccountInfo',
      setAuthStatus: 'setAuthStatus',
      setRedirectAuthFlag: 'setRedirectAuthFlag',
      manualLogout: 'logout'
    }),
    ...mapActions('payment', {
      clearClientBooking: 'clearClientBooking'
    }),
    ...mapActions('haircutParams', {
      clearChoosenServices: 'clearChoosenServices'
    }),
    ...mapMutations('haircutParams', {
      restoreChoosenServices: 'RESTORE_CHOOSEN_SERVICES',
      restoreCommonHaircutParams: 'RESTORE_COMMON_HAIRCUT_PARAMS',
      saveCommonHaircutParams: 'SAVE_COMMON_HAIRCUT_PARAMS',
      restoreChoosenDate: 'RESTORE_CHOOSEN_DATE_VALUE'
    }),
    ...mapMutations({
      resetShopState: 'shop/RESET_STATE',
      toggleCookieAlert: 'user/TOGGLE_COOKIE_ALERT'
    }),
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    toggleLoaded(val) {
      this.isLoaded = val
    },
    updatelang() {
      this.$root.$i18n.locale = this.siteLang

      this.$nextTick(() => {
        if (this.$route.meta.i18PageTitle !== undefined && this.$route.meta.i18PageTitle.length) {
          document.title = `Quiff | ${this.$t(this.$route.meta.i18PageTitle)}`
        } else {
          document.title = 'Quiff'
        }

        this.$root.$validator.locale = this.siteLang
      })
    },
    checkIfUserAuthorized(isAuthorizedViaGoogle) {
      const token = localStorage.getItem('access_token')
      const facebookAuthData = JSON.parse(localStorage.getItem('facebook_auth')) || null
      const isAuthorizedViaFacebook = facebookAuthData ? facebookAuthData.status === 'connected' : false
      if (!token) {
        this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
      } else {
        this.getAccountInfo()
          .then(() => {
            if (isAuthorizedViaGoogle) {
              this.setAuthStatus({ socialAuthType: 'google', isAuthorized: true })
            } else if (isAuthorizedViaFacebook) {
              this.setAuthStatus({ socialAuthType: 'facebook', isAuthorized: true })
            } else if (!isAuthorizedViaGoogle && !isAuthorizedViaGoogle) {
              this.setAuthStatus({ socialAuthType: 'manual', isAuthorized: true })
            }
          })
          .catch(err => {
            const errorInfo = {
              type: err.statusText,
              message: err.message,
              code: err.code
            }
            this.setError(errorInfo)
            this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
          })
      }
    },
    showErrorAlerts() {
      if (this.errorInfo.errorExists) {
        switch (this.errorInfo.type) {
          case 'info':
            this.infoMessage(this.errorInfo)
            break
          case 'error':
            this.errorMessage(this.errorInfo)
            break
        }

        this.clearSuccess()
        this.clearError()
      }
    },
    infoMessage(data) {
      let message = data.errorMessageI18Key !== '' ? this.$t(data.errorMessageI18Key) : data.errorMessage

      return this.$toasted.info(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearError()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearError()
        },
        singleton: true
      })
    },
    errorMessage(data) {
      let message = data.errorMessageI18Key !== '' ? this.$t(data.errorMessageI18Key) : data.errorMessage

      return this.$toasted.error(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearError()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearError()
        },
        singleton: true
      })
    },
    successMessage(data) {
      let message = data.successMessageI18Key !== '' ? this.$t(data.successMessageI18Key) : data.successMessage

      return this.$toasted.success(message, {
        position: 'top-center',
        duration: 2500,
        keepOnHover: true,
        action: {
          text: 'OK',
          onClick: (e, toastObject) => {
            this.clearSuccess()
            toastObject.goAway(0)
          }
        },
        onComplete: () => {
          this.clearSuccess()
        },
        singleton: true
      })
    },
    scrollCheck() {
      if (window.scrollY > 30) {
        let navbar = document.getElementById('top-navbar')
        window.scrollTo(0, -200)
        if (navbar) {
          let nav_classes = navbar.classList
          if (document.documentElement.scrollTop >= 30) {
            if (nav_classes.contains('squeezed') === false) {
              nav_classes.toggle('squeezed')
              nav_classes.toggle('bg-white')
              nav_classes.toggle('py-0-5')
            }
          } else {
            if (nav_classes.contains('squeezed') === true) {
              nav_classes.toggle('squeezed')
              nav_classes.toggle('bg-white')
              nav_classes.toggle('py-0-5')
            }
          }
        }
      }
    },
    redirectToPrivacyPolicy() {
      this.$router.push('/privacy-policy')
    }
  }
}
</script>
<style>
.image--cover {
  object-fit: cover;
}
label,
.group-label {
  color: #111517;
  font-family: Poppins;
}
.link-auth {
  position: absolute;
  right: 50px;
  top: 30px;
  z-index: 2;
}
</style>
