<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mt-5">
          Deinen Coiffeur in Basel finden und direkt online buchen
        </h1>
        <p class="mb-2">
          Coiffeure gibt es viele. Vielleicht sogar ein
          <router-link title="Coiffeur in der Nähe" to="/">Coiffeur in der Nähe</router-link>, den Du in Basel noch
          nicht kennst. Quiff bietet Dir die Wahl, denn hier findest Du garantiert Deinen Coiffeur in Basel und der
          näheren Umgebung, der Deinen Vorstellungen entspricht. Schliesslich sind Haare und die damit verbundenen
          Haarfrisuren Vertrauenssache. Wer sich dazu entschliesst, sich einen neuen Look verpassen zu lassen, der will
          sichergehen, dass er sich anschliessend auch wohl damit fühlt. Und dabei spielt es keine Rolle, ob Du nur die
          Spitzen Deiner Haare schneiden lassen möchtest oder eine radikale Typveränderung geplant ist. Du sollst Dich
          immer gut dabei fühlen. Während Deines Besuchs und auch im Anschluss. Neue Haarfrisuren, die zu Dir und Deinem
          Typ passen – darum geht es letzten Endes. Und das soll der von Dir gewählte Coiffeur in Basel leisten.
        </p>
        <p class="mb-2">
          Wir halten eine grosse Auswahl verschiedener Coiffeure in Basel für Dich bereit, sodass Du mit deinen
          Anforderungen und Wünschen garantiert fündig wirst. Du planst eine Haarverlängerung in Basel? Dir schwebt eine
          komplette Veränderung, ein ganz neuer Haarschnitt vor? Du bist auf der Durchreise und suchst einen Coiffeur am
          Bahnhof Basel? Oder Du möchtest Dich für eine Hochzeit schick stylen und noch dazu schminken lassen? Dann
          klicke Dich durch die Liste der Coiffeure in Basel und suche Dir ganz in Ruhe den für Dich richtigen Salon
          aus.
        </p>
        <h2 class="mb-2">
          Coiffeur online buchen – Treuepunkte sammeln
        </h2>
        <p class="mb-2">
          Wenn du Deine Wahl getroffen hast, bist Du nur noch einen Klick von Deiner Buchung entfernt. Du kann direkt
          Deinen Termin beim
          <router-link title="Coiffeur online buchen" to="/Coiffeur-online-buchen">
            Coiffeur online buchen.
          </router-link>
          Im Anschluss erhältst Du eine Bestätigung per Mail – und kurz vor Deinem Termin eine zusätzliche Erinnerung.
          Dann heisst es: Haare schneiden lassen, wohlfühlen und entspannen. Und ganz nebenbei erhältst Du bei jeder
          Buchung – egal ob bei einem Coiffeur in Basel oder an anderen Orten – Treuepunkte. Diese kannst Du hier im
          <router-link title="Onlineshop" to="/"> Onlineshop</router-link>
          einlösen, beispielsweise für Haarpflegeprodukte, die Du bei deinem nächsten Coiffeurbesuch mitnehmen kannst.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Du bist auf der Suche nach einem Coiffeur in Basel? Dann bist du hier genau richtig. Wir bieten dir die Möglichkeit, deinen Termin direkt online zu buchen.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  }
}
</script>
