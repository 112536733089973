import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from 'config'
import i18n from './i18n'
import en from 'vee-validate/dist/locale/en'
import de from 'vee-validate/dist/locale/de'
import fr from 'vee-validate/dist/locale/fr'

// import './registerServiceWorker'

import './assets/css/fonts.css'
import './assets/scss/bootstrap.scss'

import VeeValidate, { Validator } from 'vee-validate'
import Toasted from 'vue-toasted'
import VCalendar from 'v-calendar'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import * as VueGoogleMaps from 'vue2-google-maps'
import GAuth from 'vue-google-oauth2'
import FBSignInButton from 'vue-facebook-signin-button'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueAnalitics from 'vue-analytics'

import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCheckCircle,
  faCoins,
  faCog,
  faEllipsisV,
  faMapMarkerAlt,
  faList,
  faSignInAlt,
  faSignOutAlt,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faTwitter, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faClock, faCalendar, faBookmark, faMap } from '@fortawesome/free-regular-svg-icons'

import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import {
  faChevronLeft,
  faChevronRight,
  faAngleDown,
  faSearch,
  faChevronUp,
  faChevronDown,
  faShoppingCart,
  faDoorOpen,
  faGlobe,
  faLongArrowUp,
  faAngleLeft,
  faAngleRight,
  faArrowLeft
} from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import {} from '@fortawesome/pro-duotone-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  CollapsePlugin,
  TabsPlugin,
  ModalPlugin,
  BCollapse,
  BTooltip,
  VBToggle,
  BButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BModal,
  CarouselPlugin,
  BCarousel,
  BCarouselSlide,
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItemDropdown,
  BNavItem,
  BButtonGroup,
  BAlert
} from 'bootstrap-vue'

Vue.use(TabsPlugin)
Vue.use(CollapsePlugin)
Vue.use(ModalPlugin)
Vue.use(CarouselPlugin)
Vue.use(VueMeta)

library.add([
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faPinterestP,
  faCheckCircle,
  faCog,
  faCoins,
  faEye,
  faEyeSlash,
  faEllipsisV,
  faClock,
  faMapMarkerAlt,
  faChevronLeft,
  faAngleRight,
  faArrowLeft,
  faAngleLeft,
  faChevronRight,
  faMapMarkerAlt,
  faInfoCircle,
  faSearch,
  faTimes,
  faBookmark,
  faAngleDown,
  faChevronUp,
  faChevronDown,
  faCalendar,
  faGlobe,
  faSignInAlt,
  faSignOutAlt,
  faShoppingCart,
  faDoorOpen,
  faList,
  faMap,
  faLongArrowUp
])

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('b-collapse', BCollapse)
Vue.component('b-button', BButton)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-modal', BModal)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-carousel', BCarousel)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-nav-item-dropdown', BNavItemDropdown)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-carousel-slide', BCarouselSlide)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-tooltip', BTooltip)
Vue.component('b-alert', BAlert)

Vue.directive('b-toggle', VBToggle)
Vue.directive('b-tooltip', VBTooltip)

Vue.use(VueGoogleMaps, {
  load: {
    key: config.google_map_key,
    libraries: 'places',
    v: 3.38
  }
})
Vue.use(FBSignInButton)
Vue.use(GAuth, {
  // clientId: '846225605705-de0k4cbmdc2ks826mtrfr8mt4vo3vi26.apps.googleusercontent.com',
  clientId: config.gAuthClientId,
  scope: 'profile email',
  prompt: 'select_account'
})
Vue.use(VModal)

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.use(Toasted)

Validator.localize({ en: en, de: de, fr: fr })

Vue.use(VeeValidate, {
  locale: 'en',
  events: 'blur'
})

if (config.useAnalytics !== undefined && config.useAnalytics !== 'false') {
  Vue.use(VueFacebookPixel, { router })
  Vue.analytics.fbq.init('187912759208996')
}

if (config.useAnalytics !== undefined && config.useAnalytics !== 'false') {
  Vue.use(VueAnalitics, {
    id: 'UA-171156364-1',
    router
  })
}

Vue.config.productionTip = false

new Vue({
  i18n,
  created() {
    window.fbAsyncInit = () => {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: config.fbAppId,
        status: true,
        cookie: true,
        xfbml: true,
        version: 'v5.0'
      })
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView()
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(response => {
        localStorage.setItem('facebook_auth', JSON.stringify(response))
      })
    }
    ;((d, s, id) => {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
