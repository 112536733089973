<template>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12 col px-0-5">
        <MultiSelect
          v-if="existingServices"
          v-model="choosenServices"
          :options="existingServices"
          :searchable="true"
          :multiple="false"
          :group-select="true"
          :close-on-select="true"
          :allow-empty="false"
          :show-labels="false"
          track-by="title"
          label="title"
          :placeholder="$root.$i18n.locale === 'de' ? 'Beginne zu tippen...' : 'Start typing...'"
          @search-change="searchForServices"
          @select="getServices"
        >
          <template slot="caret" slot-scope="{ toggle }">
            <div
              class="multiselect__select d-flex align-items-center justify-content-center"
              @mousedown.prevent.stop="toggle()"
            >
              <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'
import { isEmpty } from 'lodash'

export default {
  name: 'Services',
  components: { MultiSelect },
  props: {
    isToggled: Boolean,
    isSaved: Boolean
  },
  data() {
    return {
      choosenServices: [],
      options: [],
      formData: {},
      parameters: []
    }
  },
  computed: {
    ...mapGetters({
      existingServices: 'haircutParams/getExistingServices',
      storeChoosenServices: 'haircutParams/getChoosenServices',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams'
    })
  },
  watch: {
    choosenServices: {
      deep: true,
      handler(newVal) {
        newVal ? this.$emit('choosenServicesLength', newVal) : this.$emit('choosenServicesLength', [])
      }
    },
    storeChoosenServices: {
      deep: true,
      handler(newVal) {
        this.choosenServices = newVal
        if (!newVal.length) this.options = newVal
      }
    },
    isToggled(newVal) {
      if (newVal) {
        this.choosenServices = this.options
        this.addServices(this.options)
        this.$emit('isDataSet', false)
      }
    },
    isSaved(newVal) {
      if (newVal) {
        // this.$nextTick(() => this.addServices([this.choosenServices]))
        this.options = this.choosenServices
        this.addServices(this.choosenServices)
        this.$emit('isDataSaved', false)
      }
    }
  },
  mounted() {
    this.getServices()
    this.choosenServices = this.storeChoosenServices
    this.options = this.storeChoosenServices
  },
  // TODO: 1 method for all clear-data methods
  methods: {
    ...mapActions('haircutParams', {
      addServices: 'addService',
      getServices: 'getApiServices',
      servicesSearch: 'getServicesByName'
    }),
    ...mapActions('salons', {
      getSalonServices: 'getSalonServices'
    }),
    searchForServices(service) {
      if (isEmpty(this.allSelectedHaircutParams.gender)) {
        const errorInfo = {
          type: 'Not enough data for search',
          messageI18Key: 'common.forms.alert.another.params_empty_salon_page'
        }
        this.setError(errorInfo)
      } else {
        if (!isEmpty(this.allSelectedHaircutParams.gender)) {
          this.parameters.push(this.allSelectedHaircutParams.gender.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.length)) {
          this.parameters.push(this.allSelectedHaircutParams.length.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          this.parameters.push(this.allSelectedHaircutParams.age.id)
        }

        Object.assign(this.formData, { service }, { parameters: this.parameters })

        if (service.length >= 3 && this.parameters.length) {
          this.servicesSearch(this.formData)
        }
      }
    },
    setService() {
      if (!this.choosenServices) this.addServices([])
      else {
        if (Array.isArray(this.choosenServices)) this.addServices(this.choosenServices)
        else this.addServices([this.choosenServices])
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__strong {
  color: #8d9fa7;
  font-weight: 400;
}
.multiselect__select {
  height: 48px;
}
.multiselect__select:before {
  content: none;
}
.multiselect__tag {
  margin-bottom: -8px !important;
}
</style>
