import Repository from './repository'
import axios from 'axios'
import config from 'config'

const resource = '/salons'
const favoritesResource = '/add-to-favorites'
const deleteFavoritesResource = '/remove-from-favorites'
const favoritesUrl = '/favorites'
const history = '/history'
const salonsByName = '/salons/search'
const stripeTokenResource = 'https://api.stripe.com/v1/tokens'
const paymentResource = '/payment'
const bookingResource = '/book'
const bookingSalonResource = '/book-salon'
const deleteBookingUrl = '/book-delete'
const salonStaffsSelectUrl = '/salon-staffs-select'
const salonsRequestUrl = '/client-request'
const salonsAvaliableUrl = '/salons-by-cities'
const bookingByIdUrl = '/get-book'
const removeReservedProductUrl = 'remove-reserved-product'
const removeBookingProductUrl = 'products-remove'

export const SalonsRepository = {
  getSalons(payload) {
    return Repository.post(resource, payload).then(({ data }) => data)
  },
  getSalonsByName(payload) {
    return Repository.post(salonsByName, payload)
  },
  getAddToFavorites(payload) {
    return Repository.post(favoritesResource, { salon_id: payload })
  },
  getRemoveFromFavorites(payload) {
    return Repository.post(deleteFavoritesResource, { salon_id: payload })
  },
  createClientBooking(payload) {
    return Repository.post(bookingResource, payload).then(({ data }) => data)
  },
  createClientBookingSalon(payload) {
    return Repository.post(bookingSalonResource, payload).then(({ data }) => data)
  },
  deleteClientBooking(payload) {
    return Repository.post(deleteBookingUrl, payload).then(({ data }) => data)
  },
  createStripeToken(payload, stripeKey) {
    return axios.post(stripeTokenResource, payload, {
      headers: {
        Authorization: `Bearer ${stripeKey}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  createClientPayment(payload) {
    return Repository.post(paymentResource, payload).then(({ data }) => data)
  },
  getBookingHistory(payload) {
    return Repository.get(history, { params: payload }).then(({ data }) => data)
  },
  getSalon(payload) {
    return Repository.get(`/salon`, { params: payload }).then(({ data }) => data)
  },
  getSalonServices(payload) {
    return Repository.get(`/get-salon-services?id=${payload}`)
  },
  getSalonReviews(payload) {
    return Repository.get(`/get-salon-reviews`, { params: payload }).then(({ data }) => data)
  },
  getStaffReviews(payload) {
    return Repository.get(`/get-staff-reviews`, { params: payload }).then(({ data }) => data)
  },
  getSalonCombination(payload) {
    return Repository.get(`/get-salon-combination`, { params: payload })
  },
  getSalonStaff(payload) {
    return Repository.get(`/get-salon-staff?id=${payload}`)
  },
  getSalonsBySalonName(payload) {
    return Repository.post(`${resource}/search`, payload)
  },
  getFavorites(payload) {
    return Repository.get(favoritesUrl, { params: payload }).then(({ data }) => data)
  },
  getSalonsStaffFilter(payload) {
    return Repository.post(`/salons-staff-filter`, payload)
  },
  getSalonStaffsSelect(payload) {
    return Repository.get(salonStaffsSelectUrl, { params: payload })
  },
  сreateSalonsRequestUrl(payload) {
    return Repository.post(salonsRequestUrl, payload).then(({ data }) => data)
  },
  getAvaliableSalons(payload) {
    return Repository.get(salonsAvaliableUrl).then(({ data }) => data)
  },
  getSalonProducts(payload) {
    return Repository.get(`/products-client-index`, { params: payload })
  },
  getSalonCategories(payload) {
    return Repository.get(`/categories-select`, { params: payload })
  },
  getProduct(payload) {
    return Repository.get(`/product-details-client`, { params: payload })
  },
  getBookingById(payload) {
    return Repository.get(bookingByIdUrl, { params: payload })
  },
  removeReservedProduct(payload) {
    return Repository.post(removeReservedProductUrl, payload)
  },
  removeBookingProduct(payload) {
    return Repository.post(removeBookingProductUrl, payload)
  }
}
