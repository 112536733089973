<template>
  <section class>
    <div class="container pt-lg-14 pb-lg-19 py-7">
      <div class="row align-items-start">
        <div class="col-lg-6">
          <span class="badge badge-primary comming-soon-span text-uppercase px-1 py-0-8 mb-2-5">{{ $t('main.another-sections.section_3.status') }}</span>
          <h2 class="mb-2-5" style="line-height: 1.62">{{ $t('main.another-sections.section_3.title') }}</h2>
          <p class="mb-0">{{ $t('main.another-sections.section_3.description') }}</p>
        </div>
        <div class="col-md-6 pl-lg-5-5 points-square-bg pt-3">
          <i class="icon-money mb-2"></i>
          <h5 class="mb-1-5">{{ $t('main.another-sections.section_3.card_1.title') }}</h5>
          <p class="mb-0">{{ $t('main.another-sections.section_3.card_1.description') }}</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ThirdSection'
}
</script>
