<template>
  <div class="col-12 p-0 combination-column">
    <slot v-if="serviceCategoryHaircutParams" name="rowWithLength">
      <ColumnWithLength :name="name" :combinations="combinations" @set-choosen-combination="setChoosenCombination" />
    </slot>
    <slot v-else name="rowWithoutLength">
      <ColumnWithoutLength :name="name" :combinations="combinations" @set-choosen-combination="setChoosenCombination" />
    </slot>
  </div>
</template>
<script>
import ColumnWithLength from './AdultColumn/ColumnWithLength'
import ColumnWithoutLength from './AdultColumn/ColumnWithoutLength'
export default {
  components: {
    ColumnWithLength,
    ColumnWithoutLength
  },
  props: {
    name: {
      type: String,
      default: null
    },
    combinations: {
      type: Array,
      default: () => []
    },
    serviceCategoryHaircutParams: {
      type: Number,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
