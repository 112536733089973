import emptyPaginationData from '@/_constants/emptyPaginationData.js'

const getDefaultState = () => {
  return {
    salons: JSON.parse(JSON.stringify(emptyPaginationData.DATA)),
    requestNewSalonsData: null,
    requestSalonsSent: false,
    salonsStaffFilter: [],
    salonReviews: JSON.parse(JSON.stringify(emptyPaginationData.DATA)),
    staffReviews: JSON.parse(JSON.stringify(emptyPaginationData.DATA)),
    favorites: {},
    bookingHistory: {},
    salonCombination: {},
    removeFavorites: {},
    salon: null,
    salonServices: {},
    salonStaff: {},
    salonStaffList: [],
    salonsForSearch: [],
    radius: 10,
    currentPlace: null,
    newSearchDataInfo: {
      componentThatChangedData: '',
      isNewDataSet: false
    },
    selectedSalonCardIndex: null,
    isLocationModalDataSaved: false,
    avaliableSalons: {},
    salonProducts: {},
    salonProductCategories: {},
    choosenProduct: {}
  }
}

const SET_SALONS = (state, payload) => {
  state.salons = payload

  if (!Array.isArray(payload.data)) {
    state.salons.data = Object.values(payload.data)
  }
}

const SET_REQUEST_NEW_SALONS_DATA = (state, payload) => {
  let data = {
    radius: payload.radius,
    latitude: payload.latitude,
    longitude: payload.longitude
  }

  if (state.currentPlace) {
    data.full_address = state.currentPlace.formatted_address
  }

  if (payload.categories !== undefined) {
    data.service_category_id = Number(payload.categories[0])
  }

  if (payload.services !== undefined) {
    data.service_id = Number(payload.services[0])
  }

  state.requestNewSalonsData = data
}

const SET_REQUEST_NEW_SALONS_STATUS = (state, payload) => {
  state.requestSalonsSent = payload
}
const SET_AVALIABLE_SALONS = (state, payload) => {
  if (!Array.isArray(payload)) {
    state.avaliableSalons = payload
  }
}

const CLEAR_REQUEST_NEW_SALONS_DATA = state => {
  state.requestNewSalonsData = null
}

const CLEAR_SALONS = state => {
  Object.assign(state.salons, JSON.parse(JSON.stringify(emptyPaginationData.DATA)))
}

const SET_FAVORITES = (state, payload) => {
  state.favorites = payload
}

const SET_BOOKING_HISTORY = (state, payload) => {
  state.bookingHistory = payload
}
const SET_SALON = (state, payload) => {
  state.salon = payload
}
const SET_SALON_REVIEWS = (state, payload) => {
  Object.assign(state.salonReviews, payload.data)

  if (!Array.isArray(payload.data)) {
    state.salonReviews.data = Object.values(payload.data.data)
  }
}
const SET_STAFF_REVIEWS = (state, payload) => {
  Object.assign(state.staffReviews, payload.data)

  if (!Array.isArray(payload.data)) {
    state.staffReviews.data = Object.values(payload.data.data)
  }
}
const SET_SALON_COMBINATION = (state, payload) => {
  state.salonCombination = payload.data
}
const SET_SALONS_STAFF_FILTER = (state, payload) => {
  state.salonsStaffFilter = []

  if (payload.data) {
    state.salonsStaffFilter = payload.data.items
  }
}
const SET_SALON_SERVICES = (state, payload) => {
  state.salonServices = { ...payload }
}
const CLEAR_SALON_SERVICES = state => {
  state.salonServices = {}
}
const SET_SALON_STAFF = (state, payload) => {
  Object.assign(state.salonStaff, payload)
}
const SET_SALONS_FOR_SEARCH = (state, payload) => {
  state.salonsForSearch = payload
}
const TOGGLE_SALONS_MORE_INFO = (state, salonsIndex) => {
  if (state.selectedSalonCardIndex !== salonsIndex) {
    state.selectedSalonCardIndex = salonsIndex
  } else {
    state.selectedSalonCardIndex = null
  }
}
const SET_SEARCH_RADIUS = (state, radius) => {
  state.radius = radius
}
const SET_CURRENT_PLACE = (state, currentPlace) => {
  state.currentPlace = currentPlace
}
const SET_LOCATION_MODAL_DATA_FLAG = (state, payload) => {
  state.isLocationModalDataSaved = payload
}
const CLEAR_CURRENT_PLACE = state => {
  state.currentPlace = null
}

const SET_NEW_SEARCH_DATA_FLAG = (state, payload) => {
  state.newSearchDataInfo.componentThatChangedData = payload.component
  state.newSearchDataInfo.isNewDataSet = payload.isNewDataSet
}

const SET_SALON_STAFF_LIST = (state, payload) => {
  state.salonStaffList = payload.items
}
const SET_SALON_PRODUCTS = (state, payload) => {
  state.salonProducts = payload
}
const SET_SALON_CATEGORIES = (state, payload) => {
  state.salonProductCategories = payload
}
const SET_PRODUCT = (state, payload) => {
  state.choosenProduct = payload
}
const RESET_SALONS = state => {
  state.salons = {}
}
const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_SALONS,
  SET_FAVORITES,
  SET_SALON,
  SET_SALON_REVIEWS,
  SET_STAFF_REVIEWS,
  SET_SALON_SERVICES,
  SET_SALON_STAFF,
  SET_SALONS_FOR_SEARCH,
  TOGGLE_SALONS_MORE_INFO,
  SET_SEARCH_RADIUS,
  SET_CURRENT_PLACE,
  CLEAR_CURRENT_PLACE,
  SET_NEW_SEARCH_DATA_FLAG,
  SET_SALONS_STAFF_FILTER,
  RESET_STATE,
  SET_BOOKING_HISTORY,
  SET_SALON_COMBINATION,
  CLEAR_SALONS,
  RESET_SALONS,
  SET_LOCATION_MODAL_DATA_FLAG,
  SET_SALON_STAFF_LIST,
  CLEAR_SALON_SERVICES,
  SET_REQUEST_NEW_SALONS_DATA,
  CLEAR_REQUEST_NEW_SALONS_DATA,
  SET_REQUEST_NEW_SALONS_STATUS,
  SET_AVALIABLE_SALONS,
  SET_SALON_PRODUCTS,
  SET_SALON_CATEGORIES,
  SET_PRODUCT,
  getDefaultState
}
