<template>
  <form class="d-flex flex-column" @submit.prevent="handleSubmitTemplateFormEmit">
    <div class="row">
      <div class="col-auto image-modal-block mb-2-5">
        <div class="position-relative edit-profile-img mx-auto">
          <img :src="profileImageToDisplay" alt class="w-100 h-100 d-block rounded image--cover" />
          <div class="position-absolute">
            <input
              id="customFile"
              ref="profileImage"
              v-validate="'ext:jpeg,jpg,png'"
              type="file"
              accept="image/x-png,image/jpg,image/jpeg"
              class="custom-file-upload"
              name="profile-image"
              @change="onImageUpload"
            />
            <label class="custom-file-label" for="customFile">
              <span class="photocamera-icon"></span>
            </label>
          </div>
        </div>
        <span v-if="errors.has('profile-image')" class="error-message text-sm mt-1 errorImgText">{{
          errors.first('profile-image')
        }}</span>
      </div>
      <div class="col inputs-tempate px-0">
        <div v-for="(field, index) in namesFields" :key="`${index}-field`" class="col inputTemplateField">
          <label for="firstName" class="poppins-font text-black mb-1 font-weight-medium">
            {{ $t(field.i18KeyLabel) }}
          </label>
          <div class="input-group input-group-lg mb-3">
            <input
              v-model="field.value"
              v-validate="field.validateRules"
              :data-vv-as="$t(field.i18KeyLabel)"
              :name="field.name"
              type="text"
              :placeholder="$t(field.i18KeyPlaceholder)"
              class="form-control w-100 px-1-5"
            />
            <span v-if="errors.has(field.name)" class="error-message text-sm mt-1">{{ errors.first(field.name) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="storageReady && areParamsLoaded">
      <p class="group-label font-weight-medium mb-1 poppins-font">
        <span class="text-gray mr-0-5">
          {{ $t('common.forms.label.step', { number: 1 }) }}
        </span>
        <span class="text-black">
          {{ $t('common.forms.label.choose_whom') }}
        </span>
      </p>

      <GenderOptions
        :adult-options="adultGenderOptions"
        :child-options="childGenderOptions"
        :haircut-params="haircutParams"
        :clear-data-flag="clearTemplateFlag"
        :hide-child-label="true"
        :hide-sub-title="true"
        :centered-icons="true"
      />
    </div>

    <div class="d-flex justify-content-start">
      <p class="group-label font-weight-medium mb-0 poppins-font">
        <span class="text-gray mr-0-5">
          {{ $t('common.forms.label.step', { number: 2 }) }}
        </span>
        <span class="text-black">
          {{ $t('common.forms.label.length') }}
        </span>
      </p>
      <infoPopup class="mt-0-2" />
    </div>
    <HairLengthSelect :hide-sub-title="true" :options="hairLengthOptions" :params="haircutParams" />

    <button :disabled="buttonDisabledFlag" type="submit" class="btn btn-lg btn-primary btn-block mt-auto mb-0">
      <p class="h6 font-weight-medium mb-0 text-white">{{ submitButtonText }}</p>
    </button>
  </form>
</template>

<script>
import GenderOptions from '@/components/GenderOptions'
import HairLengthSelect from '@/components/HairLengthSelect'
import infoPopup from '@/components/infoPopup'
import { mapValues, keyBy, isEmpty } from 'lodash'

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { compressedFile } from '@/_mixins/compressedFile.js'

export default {
  name: 'TemplateForm',
  components: {
    GenderOptions,
    HairLengthSelect,
    infoPopup
  },
  mixins: [compressedFile],
  props: {
    template: {
      type: Object,
      default: () => ({})
    },
    clearTemplateFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      profileImageToDisplay: '',
      areParamsLoaded: false,
      editMode: false,
      haircutParams: {
        gender: {},
        length: {},
        age: {}
      },
      buttonDisabledFlag: false,
      isDisabledFlagActive: false,
      namesFields: [
        {
          i18KeyLabel: 'common.forms.label.template.first_name',
          name: 'first_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.template.first_name'
        },
        {
          i18KeyLabel: 'common.forms.label.template.last_name',
          name: 'last_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.template.last_name'
        }
      ],
      createError: {
        type: this.$t('common.forms.alert.template.type_error'),
        messageI18Key: 'common.forms.alert.template.type_error_message'
      }
    }
  },
  watch: {
    clearTemplateFlag(newVal) {
      if (newVal) {
        this.haircutParams = {
          gender: {},
          length: {},
          age: {}
        }
        this.namesFields.forEach(el => (el.value = ''))
        this.profileImageToDisplay = require('@/assets/images/default/default_avatar.png')
        this.disabledClearAllFlag()
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues',
      commonHaircutParams: 'haircutParams/getCommonHaircutParams',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams'
    }),
    storageReady() {
      return this.adultGenderOptions.length && this.childGenderOptions.length && this.hairLengthOptions.length
    },
    hairLengthOptions() {
      return this.parametersValues.hairLength.values
    },
    adultGenderOptions() {
      return [this.parametersValues.genders.values[0], this.parametersValues.genders.values[1]]
    },
    childGenderOptions() {
      return [this.parametersValues.genders.values[2], this.parametersValues.genders.values[3]]
    },
    submitButtonText() {
      return this.editMode ? this.$t('common.forms.button.save_template') : this.$t('common.forms.button.add_template')
    },
    isHaircutParamsEmpty() {
      const { allSelectedHaircutParams } = this
      let params = Object.assign({}, allSelectedHaircutParams)

      if (isEmpty(allSelectedHaircutParams.gender)) {
        return true
      }

      if (!isEmpty(allSelectedHaircutParams.age)) {
        if (isEmpty(allSelectedHaircutParams.gender)) {
          return true
        }
      }

      return false
    }
  },
  mounted() {
    this.resetChildHaircutParams()
    this.resetParams()

    this.getParamsValues().then(() => {
      const { template } = this
      const { gender, hairLength, age, avatar, first_name, last_name } = template
      if (Object.keys(template).length) {
        this.editMode = true
        this.haircutParams = {
          gender: gender,
          length: hairLength
        }

        this.namesFields[0].value = first_name
        this.namesFields[1].value = last_name

        if (!isEmpty(age)) this.haircutParams.age = age
      }
      this.profileImageToDisplay = avatar ? avatar.path : require('@/assets/images/default/default_avatar.png')

      this.areParamsLoaded = true
    })
  },
  methods: {
    ...mapActions({
      getParamsValues: 'haircutParams/getParamsValues',
      setCommonHaircutParams: 'haircutParams/setCommonHaircutParams',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      resetParams: 'haircutParams/RESET_COMMON_PARAMS',
      resetChildHaircutParams: 'haircutParams/CLEAR_CHILD_HAIRCUT_PARAMS'
    }),
    handleLengthOptionClick(value) {
      const hairLengthParam = { paramName: 'length', value }
      this.setCommonHaircutParams(hairLengthParam)
    },
    handleSubmitTemplateFormEmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const requestData = mapValues(keyBy(this.namesFields, 'name'), 'value')
          if (this.isHaircutParamsEmpty) {
            this.setError(this.createError)
            return
          } else {
            this.$emit('handle-submit-template-form', requestData)
            this.buttonDisabledFlag = true
          }
        }
      })
    },
    onImageUpload(e) {
      this.$validator.validate('profile-image').then(result => {
        if (result) {
          if (e.target.files.length !== 0) {
            const reader = new FileReader()
            reader.onload = e => {
              this.profileImageToDisplay = e.target.result
            }

            this.compressed(e.target.files[0], 5, 370)
              .then(file => {
                reader.readAsDataURL(e.target.files[0])
                this.$emit('set-upload-image', file)
              })
              .catch(errors => console.log(errors))
          }
        }
      })
    },
    activatedClearAllFlag() {
      this.$emit('activated-clear-all-flag')
    },
    disabledClearAllFlag() {
      this.$emit('disabled-clear-all-flag')
    }
  }
}
</script>
<style scoped>
.errorImgText {
  width: 12rem !important;
}
</style>
