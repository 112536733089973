import { UserRepository } from '@/api/user.repository'

const register = ({ commit, rootState }, payload) => {
  return UserRepository.register(payload)
    .then(({ data }) => {
      rootState.payment.reservedProducts = []

      localStorage.setItem(
        'access_token',
        JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
      )
      return data
    })
    .catch(errors => {
      return Promise.reject(errors.data)
    })
}

const updateProfile = ({ commit }, payload) => {
  const profilePayload = {
    first_name: payload.firstName,
    last_name: payload.lastName,
    email: payload.email,
    phone: payload.phone
  }
  return UserRepository.updateProfile(profilePayload).then(({ data }) => {
    commit('SET_USER_ACCOUNT_INFO', data)
  })
}

const contact = ({ commit }, payload) => {
  return UserRepository.contact(payload).then(({ data }) => {
    return data
  })
}

const updatePassword = ({ commit }, payload) => {
  const passwordPayload = {
    old_password: payload.oldPassword,
    password: payload.newPassword,
    password_confirmation: payload.repeatNewPassword
  }
  return UserRepository.updatePassword(passwordPayload)
}

const socialAuth = ({ commit, rootState }, payload) => {
  return UserRepository.socialAuth(payload).then(({ data }) => {
    rootState.payment.reservedProducts = []

    localStorage.setItem(
      'access_token',
      JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
    )

    return data
  })
}

const socialLogin = ({ commit, rootState }, payload) => {
  return UserRepository.socialLogin(payload).then(({ data }) => {
    localStorage.setItem(
      'access_token',
      JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
    )

    return data
  })
}

// eslint-disale-line no-unused-vars
const login = ({ commit, rootState }, payload) => {
  return UserRepository.login(payload).then(({ data }) => {
    if (data.role === 'client') {
      localStorage.setItem(
        'access_token',
        JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
      )
    }

    return data.role
  })
}

const logout = ({ commit }) => {
  return UserRepository.logout().then(() => {
    commit('LOGOUT')
    commit('user/RESET_STATE', null, { root: true })
    commit('userMessages/RESET_STATE', null, { root: true })
    commit('shop/RESET_STATE', null, { root: true })
    commit('payment/RESET_STATE', null, { root: true })
    commit('salons/RESET_STATE', null, { root: true })
    commit('haircutParams/RESET_STATE', null, { root: true })
  })
}

const setAuthStatus = ({ commit }, authData) => {
  commit('SET_AUTH_STATUS', authData)
}

const setRedirectAuthFlag = ({ commit }, value) => {
  commit('SET_REDIRECTED_AUTH', value)
}

const getAccountInfo = ({ commit, rootState }) => {
  return UserRepository.getAccountInfo().then(({ data }) => {
    commit('SET_USER_ACCOUNT_INFO', data)

    let storeReservedProduct = rootState.payment.reservedProducts.filter(item => !item.reservedFlag)
    let reservedProdct = []

    if (data.reservedProducts.length) {
      reservedProdct = data.reservedProducts.map(item => {
        const { id, title, price, image, salon_id } = item.product

        let product = {
          id: id,
          deleteId: item.id,
          title: title,
          price: price,
          salon_id: salon_id,
          reservedFlag: true,
          images: [
            {
              path: image
            }
          ]
        }

        if (item.variation) {
          product.variation = item.variation
        }

        return product
      })
    }

    rootState.payment.reservedProducts = [...storeReservedProduct, ...reservedProdct]

    return data
  })
}

const createTemplate = ({ commit }, payload) => {
  return UserRepository.createTemplate(payload).then(response => {
    commit('ADD_TEMPLATE', response.data)
    return response.data
  })
}
const updateTemplate = ({ commit }, payload) => {
  return UserRepository.updateTemplate(payload).then(response => {
    commit('UPDATE_TEMPLATE', response.data)
    return response.data
  })
}
const deleteTemplate = ({ commit }, payload) => {
  return UserRepository.deleteTemplate(payload).then(() => {
    commit('DELETE_TEMPLATE', payload)
  })
}
const resetPasswordRequest = ({ commit }, payload) => {
  return UserRepository.resetPasswordRequest(payload).then(() => {
    commit('SET_RESET_EMAIL', payload)
  })
}

const setNewPassword = ({ commit }, payload) => {
  return UserRepository.setNewPassword(payload).then(({ data }) => {
    if (data.role !== 'owner') {
      localStorage.setItem(
        'access_token',
        JSON.stringify({ access_token: data.access_token, token_type: data.token_type })
      )
    } else {
      throw { statusCode: 401, success: false, data: { message: 'Please visit owner page.' } }
    }
  })
}

const addReviews = ({ commit }, payload) => {
  return UserRepository.addReviews(payload).then(({ data }) => {
    commit('RESET_FEEDBACK_REQUEST')
    return data
  })
}
const emailVerify = ({ commit }, payload) => {
  return UserRepository.emailVerify(payload).then(data => {
    if (data && data.code === 200) {
      if (data.data.role === 'client') {
        localStorage.setItem(
          'access_token',
          JSON.stringify({ access_token: data.data.access_token, token_type: data.data.token_type })
        )
      }
    }
    return data
  })
}
const emailResend = ({ commit }, payload) => {
  return UserRepository.resendEmail(payload)
}

export default {
  updateProfile,
  updatePassword,
  socialAuth,
  getAccountInfo,
  setAuthStatus,
  setRedirectAuthFlag,
  createTemplate,
  socialLogin,
  deleteTemplate,
  resetPasswordRequest,
  setNewPassword,
  updateTemplate,
  addReviews,
  register,
  login,
  logout,
  contact,
  emailVerify,
  emailResend
}
