<template>
  <div class="tab-content h-100 w-100 settingsModalBody">
    <div role="tabpanel" aria-labelledby="v-pills-info-tab" class="tab-pane fade h-100 position-relative active show">
      <div>
        <div class="top-block d-block d-md-none">
          <div class="d-flex pt-1-5 justify-content-between">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="profileBlockVisible"
            ></font-awesome-icon>

            <h4 class="pl-3">
              {{ $t('common.profile_menu.link.settings') }}
            </h4>

            <p class="collapsed change-pass-btn mr-1-5" @click="clearSettingsParameters">
              {{ $t('common.forms.button.clear_all') }}
            </p>
          </div>
        </div>
        <form class="pb-6 mt-md-0 mt-4" @submit.prevent="changeUserProfileData">
          <div class="d-flex">
            <div class="flex-grow-1">
              <div class="pass-input">
                <label for="firstName" class="poppins-font text-black mb-1 font-weight-medium">
                  {{ $t('common.forms.label.first_name') }}
                </label>
                <div class="input-group mb-md-2-5 mb-2">
                  <input
                    id="firstName"
                    v-model="accountInfoFields.firstName"
                    v-validate="'required|alpha'"
                    class="form-control w-100"
                    name="First Name"
                    type="text"
                    :data-vv-as="$t('common.forms.label.first_name')"
                    :placeholder="$t('common.forms.placeholder.account_first_name')"
                    autocomplete="false"
                  />
                  <span v-if="errors.has('First Name') && !isMobileWiDth" class="error-message mt-1">{{
                    errors.first('First Name')
                  }}</span>
                </div>
              </div>
              <div class="pass-input">
                <label for="lastName" class="poppins-font text-black mb-1 font-weight-medium">
                  {{ $t('common.forms.label.last_name') }}
                </label>
                <div class="input-group mb-md-2-5 mb-2">
                  <input
                    id="lastName"
                    v-model="accountInfoFields.lastName"
                    v-validate="'required|alpha'"
                    :data-vv-as="$t('common.forms.label.last_name')"
                    class="form-control w-100"
                    name="Last Name"
                    type="text"
                    :placeholder="$t('common.forms.placeholder.account_last_name')"
                    autocomplete="false"
                  />
                  <span v-if="errors.has('Last Name') && !isMobileWiDth" class="error-message mt-1">{{
                    errors.first('Last Name')
                  }}</span>
                </div>
              </div>
              <span v-if="errors.has('First Name') && isMobileWiDth" class="error-message mb-5 mb-md-2">{{
                errors.first('First Name')
              }}</span>
              <span v-if="errors.has('Last Name') && isMobileWiDth" class="error-message mt-1 mb-5 mb-md-2">{{
                errors.first('Last Name')
              }}</span>
            </div>
          </div>
          <div class>
            <label for="number" class="poppins-font text-black mb-1 font-weight-medium">
              {{ $t('common.forms.label.number') }}
            </label>
            <div class="input-group mb-md-2-5 mb-2">
              <input
                id="number"
                v-model="accountInfoFields.phone"
                v-validate="'decimal|min:5|max:13'"
                class="form-control w-100"
                name="number"
                type="text"
                :placeholder="$t('common.forms.placeholder.account_number')"
                autocomplete="false"
              />
              <span v-if="errors.has('number')" class="error-message mt-1">{{ errors.first('number') }}</span>
            </div>
          </div>
          <div class>
            <label for="email" class="poppins-font text-black mb-1 font-weight-medium">
              {{ $t('common.forms.label.email') }}
            </label>
            <div class="input-group mb-md-2-5 mb-2">
              <input
                id="email"
                v-model="accountInfoFields.email"
                v-validate="'required|email|min:5'"
                class="form-control w-100"
                name="Email"
                type="email"
                :placeholder="$t('common.forms.placeholder.account_email')"
                autocomplete="false"
                disabled="true"
              />
            </div>
          </div>
          <p
            v-b-toggle.collapsePass
            class="collapsed change-pass-btn my-xs-2"
            data-toggle="collapse"
            data-target="#changePasswordBlock"
            aria-expanded="false"
            aria-controls="changePasswordBlock"
            @click="setVisibleFlag = true"
          >
            {{ $t('common.forms.label.change_password') }}
          </p>
          <b-collapse id="collapsePass" ref="collapse">
            <div class="">
              <label for="oldPass" class="poppins-font text-black mb-1 font-weight-medium">
                {{ $t('common.forms.label.old_password') }}
              </label>
              <div class="input-group mb-2 mb-md-2-5">
                <input
                  id="oldPass"
                  v-model="passwordFields.oldPassword"
                  v-validate="'required|min:8|max:16'"
                  :data-vv-as="$t('common.forms.label.old_password')"
                  class="form-control w-100"
                  name="Old Password"
                  type="password"
                  autocomplete="false"
                />
                <span v-if="errors.has('Old Password')" class="error-message mt-1">{{
                  errors.first('Old Password')
                }}</span>
              </div>
            </div>
            <div class>
              <label for="NewPass" class="poppins-font text-black mb-1 font-weight-medium">
                {{ $t('common.forms.label.new_password') }}
              </label>
              <div class="input-group mb-2 mb-md-2-5">
                <input
                  id="newPass"
                  ref="password"
                  v-model="passwordFields.newPassword"
                  v-validate="'required|min:8|max:16'"
                  :data-vv-as="$t('common.forms.label.new_password')"
                  class="form-control w-100"
                  name="New password"
                  type="password"
                  autocomplete="false"
                />
                <span v-if="errors.has('New password')" class="error-message my-1">{{
                  errors.first('New password')
                }}</span>
              </div>
            </div>
            <div class>
              <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                {{ $t('common.forms.label.repeat_password') }}
              </label>
              <div class="input-group mb-2-5">
                <input
                  id="repeatPass"
                  v-model="passwordFields.repeatNewPassword"
                  v-validate="'required|confirmed:password|min:8|max:16'"
                  :data-vv-as="$t('common.forms.label.repeat_password')"
                  class="form-control w-100"
                  name="Repeat new password"
                  type="password"
                  autocomplete="false"
                />
                <span v-if="errors.has('Repeat new password')" class="error-message my-1">{{
                  errors.first('Repeat new password')
                }}</span>
              </div>
            </div>
          </b-collapse>
          <button type="submit" class="d-none d-md-block btn btn-lg btn-primary w-100 bottom-btn">
            <h6 class="font-weight-medium mb-0 text-white">{{ $t('common.forms.button.save_changes') }}</h6>
          </button>
        </form>
        <button
          type="submit"
          class="d-block d-md-none btn btn-lg btn-primary bottom-btn bottom-save-btn"
          @click="changeUserProfileData"
        >
          <h6 class="font-weight-medium mb-0 text-white">{{ $t('common.forms.button.save_changes') }}</h6>
        </button>
      </div>
    </div>
    <VerificationPopup />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VerificationPopup from '@/views/verification/VerificationPopup'

export default {
  name: 'UserProfileEditor',
  components: {
    VerificationPopup
  },
  data() {
    return {
      accountInfoFields: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        image: null
      },
      passwordFields: {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: ''
      },
      isImageUpdating: false,
      isPasswordUpdating: false,
      isProfileUpdating: false,
      isChangePasswordFieldsActive: false,
      setVisibleFlag: false,
      passwordsDontMatchErrorInfo: {
        // TODO: replace error messages (as well as all static data) to a separate file (constraints)
        type: this.$t('common.forms.alert.type_password_error'),
        messageI18Key: 'common.forms.alert.account.type_password_error_message'
      },
      imageSuccessInfo: {
        type: this.$t('common.forms.alert.type_success_image'),
        messageI18Key: 'common.forms.alert.account.type_success_image_message'
      },
      changePasswordSuccessInfo: {
        type: this.$t('common.forms.alert.type_success_password'),
        messageI18Key: 'common.forms.alert.account.type_success_password_message'
      },
      profileUpdateSuccessInfo: {
        type: this.$t('common.forms.alert.type_success_profile'),
        messageI18Key: 'common.forms.alert.account.type_success_profile_message'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    }),
    isNewPasswordValid() {
      return this.passwordFields.newPassword === this.passwordFields.repeatNewPassword
    },
    isEmailChanged() {
      return this.accountInfoFields.email !== this.accountInfo.email
    },
    isFirstNameChanged() {
      return this.accountInfoFields.firstName !== this.accountInfo.first_name
    },
    isLastNameChanged() {
      return this.accountInfoFields.lastName !== this.accountInfo.last_name
    },
    isPhoneChanged() {
      return this.accountInfoFields.phone !== this.accountInfo.phone
    },
    isMainInfoChanged() {
      return this.isEmailChanged || this.isFirstNameChanged || this.isLastNameChanged || this.isPhoneChanged
    },
    isProfileDataUpdating() {
      return !this.isImageUpdating && !this.isProfileUpdating && !this.isPasswordUpdating
    },
    isMobileWiDth() {
      return window.innerWidth < 767
    }
  },
  mounted() {
    if (this.$route.path === '/profile-page/edit' && window.innerWidth < 767) {
      const profileEditBlock = document.querySelector('.text-block')
      profileEditBlock.style.display = 'none'
    }
    this.accountInfoFields = {
      firstName: this.accountInfo.first_name,
      lastName: this.accountInfo.last_name,
      email: this.accountInfo.email,
      phone: this.accountInfo.phone ? this.accountInfo.phone : '',
      image: this.accountInfo.image
    }
  },
  methods: {
    ...mapActions('userMessages', {
      setError: 'setError',
      setSuccess: 'setSuccess'
    }),
    ...mapActions('user', {
      updateProfile: 'updateProfile',
      updatePassword: 'updatePassword'
    }),
    changeUserProfileData() {
      this.isPasswordUpdating = true
      this.isProfileUpdating = true
      this.isImageUpdating = true
      if (this.setVisibleFlag) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.updateUsersPassword()
          }
        })
      }
      if (this.isMainInfoChanged) {
        this.updateProfileMainData()
      }
    },
    updateUsersPassword() {
      this.updatePassword(this.passwordFields)
        .then(() => {
          this.setVisibleFlag = false
          this.$refs.collapse.toggle()
          this.setSuccess(this.changePasswordSuccessInfo)
          this.this.isPasswordUpdating = false
          this.accountInfoFields = {
            firstName: this.accountInfo.first_name,
            lastName: this.accountInfo.last_name,
            email: this.accountInfo.email,
            image: this.accountInfo.image,
            phone: this.accountInfo.phone ? this.accountInfo.phone : ''
          }
        })
        .catch(err => {
          if (err.message && err.message === 'Your email address is not verified.') {
            this.$bvModal.show('verification-modal')
          } else {
            const errorPayload = {
              type: err.message,
              message: err.data.message,
              code: err.code
            }
            this.isPasswordUpdating = false
            this.setError(errorPayload)
          }
        })
    },
    updateProfileMainData() {
      this.updateProfile(this.accountInfoFields)
        .then(() => {
          this.setVisibleFlag = false
          this.setSuccess(this.profileUpdateSuccessInfo)
          this.accountInfoFields = {
            firstName: this.accountInfo.first_name,
            lastName: this.accountInfo.last_name,
            email: this.accountInfo.email,
            phone: this.accountInfo.phone ? this.accountInfo.phone : '',
            image: this.accountInfo.image
          }
          this.isProfileUpdating = false
        })
        .catch(err => {
          this.setVisibleFlag = false
          if (err.message && err.message === 'Your email address is not verified.') {
            this.$bvModal.show('verification-modal')
          } else {
            const errorPayload = {
              type: err.message,
              message: err.data.message,
              code: err.code
            }
            this.isProfileUpdating = false
            this.setError(errorPayload)
          }
        })
    },
    profileBlockVisible() {
      if (window.innerWidth < 767) {
        const profileEditBlock = document.querySelector('.text-block')
        profileEditBlock.style.display = 'block'
        this.changeUserProfileData()
        this.$router.push('/profile-page')
      }
    },
    clearSettingsParameters() {
      this.accountInfoFields = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        image: null
      }
    }
  }
}
</script>
<style scoped>
.bottom-save-btn {
  position: fixed !important;
  z-index: 1020 !important;
  bottom: 2%;
  height: 3rem;
  width: 95%;
  left: 2.5%;
  /* left: 10px; */
}
.top-block {
  position: fixed !important;
  z-index: 1020 !important;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 2px #e0e0e0;
}
</style>
