<template>
  <Spinner />
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import Spinner from '@/views/Spinner'
export default {
  components: {
    Spinner
  },
  data() {
    return {
      verificationSuccessInfo: {
        type: this.$t('email-verify.type-success'),
        messageI18Key: 'email-verify.success-message'
      },
      verificationErrorInfo: {
        type: this.$t('email-verify.type-error'),
        messageI18Key: 'email-verify.error-message'
      },
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      },
      clientBookingData: null
    }
  },
  watch: {
    getClientBooking: {
      deep: true,
      handler(newVal) {
        this.clientBookingData = newVal
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      getClientBooking: 'payment/getClientBooking',
      getFormatedReservedProductsBySalonId: 'payment/getFormatedReservedProductsBySalonId',
      getMainTemplate: 'user/getMainTemplate',
      getFormattedHaircutData: 'haircutParams/getFormattedHaircutData',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams'
    }),
    isBookingFilled() {
      return (
        this.clientBookingData.combinations &&
        this.clientBookingData.salon_staff_id &&
        this.clientBookingData.salon_id &&
        this.clientBookingData.start_time &&
        this.clientBookingData.time
      )
    }
  },
  mounted() {
    this.clientBookingData = this.getClientBooking
    const { id, expires, signature } = this.$route.params
    if ((id, expires, signature)) {
      let payload = { id, expires, signature }
      this.emailVerify(payload).then(data => {
        if (data && data.success) {
          this.getAccountInfo()
            .then(() => {
              this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
              if (this.getClientBooking) this.onBooking()
              else this.$router.push('/')
            })
            .catch(errors => {
              if (errors.success === false) {
                this.$validator.errors.add({
                  field: 'email',
                  msg: this.$t('signup.user-messages.wrong_email_or_password')
                })
              }
            })
          setTimeout(this.setSuccess(this.verificationSuccessInfo), 100)
        } else {
          this.$router.push('/not-found')
          setTimeout(this.setError(this.verificationErrorInfo), 100)
        }
      })
    } else {
      this.$router.push('/not-found')
      setTimeout(this.setError(this.verificationErrorInfo), 100)
    }
  },
  methods: {
    ...mapActions({
      emailVerify: 'user/emailVerify',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess',
      createTemplate: 'user/createTemplate',
      createClientBooking: 'payment/createClientBooking',
      getAccountInfo: 'user/getAccountInfo',
      setAuthStatus: 'user/setAuthStatus'
    }),
    showModal() {
      this.$bvModal.show('verification-modal')
      this.$modal.show('verification-modal')
    },
    onBooking() {
      if (!this.isBookingFilled) return
      let bookingPayload = this.clientBookingData

      if (this.getFormatedReservedProductsBySalonId(this.clientBookingData.salon_id).products.length) {
        bookingPayload = {
          ...bookingPayload,
          ...this.getFormatedReservedProductsBySalonId(this.clientBookingData.salon_id)
        }
      }

      if (!this.getMainTemplate) {
        const { getFormattedHaircutData } = this
        let bodyParams = {
          main_template: '1'
        }
        if (this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1) {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: getFormattedHaircutData.parameters[1]
          }
        } else {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: null
          }
        }
        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          bodyParams.age = getFormattedHaircutData[getFormattedHaircutData.length - 1]
        }
        this.createTemplate(bodyParams)
          .then(data => {
            bookingPayload.client_id = data.id

            this.createClientBooking(bookingPayload)
              .then(data => {
                this.$router.push(`/payment/${data.id}`)
              })
              .catch(errors => {
                if (errors.code === 425) {
                  this.setError({
                    type: 'error',
                    messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                  })
                } else {
                  this.setError(this.createBookingErrorMessage)
                }
              })
          })
          .catch(() => {
            this.setError(this.createBookingErrorMessage)
            this.$router.push(`/salon-page/${this.getClientBooking.salon_id}`)
          })
      } else {
        bookingPayload.client_id = this.getMainTemplate.id

        this.createClientBooking(bookingPayload)
          .then(data => {
            this.$router.push(`/payment/${data.id}`)
          })
          .catch(() => {
            this.setError(this.createBookingErrorMessage)
            this.$router.push(`/salon-page/${this.getClientBooking.salon_id}`)
          })
      }
    }
  }
}
</script>
