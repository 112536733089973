<template>
  <div class="row">
    <div class="col-12">
      <div class="row mb-5">
        <img src="@/assets/images/bg/booking-success.png" width="472px" height="230px" />
      </div>
      <h1 class="mt-5 mb-2">
        {{ $t('payment.booking_success_title') }}
      </h1>
      <div class="text-center text-black font-weight-medium mb-3">
        {{ $t('payment.booking_point_info', { count: 20 }) }}
        <img src="@/assets/images/bg/question-mark.png" width="21px" height="21px" />
      </div>
      <div class="row justify-content-center">
        <button type="button" class="btn btn-lg btn-primary" @click="$router.push('/')">
          <p class="h6 font-weight-medium mb-0 text-white">
            {{ $t('payment.booking_home_link') }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SuccessPayment'
}
</script>
