<template>
  <div class="d-flex vh-100 signup-form-bg px-1-5 px-lg-0 desktop-reset-password-bg">
    <LanguageDropdown :class="'top-right-pos pl-1-5 d-inline-block sale-offset'"></LanguageDropdown>

    <div
      v-if="!newPasswordStatus.isSet && !emailRequestStatus.sent && !isSending"
      class="my-md-auto my-2-5 mx-auto w-100"
      style="max-width: 590px;"
    >
      <div class="text-center">
        <a class="navbar-brand mb-2-5 d-lg-none" href="#">
          <img src="@/assets/images/logo_quiff.svg" />
        </a>
        <h1 class="h2 mb-2">
          {{ $t('forgot_password.content.title') }}
        </h1>
        <p class="mb-lg-6-5 mb-2-5">
          {{ isRequestNewPassPage ? $t('forgot_password.content.subtitle') : $t('forgot_password.content.subtitle2') }}
        </p>
      </div>
      <div class="">
        <form @submit.prevent="submitForm">
          <div v-if="isRequestNewPassPage" class="col-lg-12 px-0">
            <label for="email" class="poppins-font text-black mb-1 font-weight-medium">
              {{ $t('common.forms.label.auth.email') }}
            </label>
            <div class="input-group input-group-lg mb-2-5 flex-column">
              <input
                id="email"
                v-model="email"
                v-validate="'required|email|max:100'"
                type="text"
                name="email"
                class="form-control w-100 px-1-5"
                :placeholder="$t('common.forms.placeholder.auth.email')"
              />
              <span v-if="errors.has('email')" class="text-danger mt-1">{{ errors.first('email') }}</span>
            </div>
          </div>
          <div v-if="isSetNewPassPage" class="col-lg-12 px-0">
            <label for="password" class="poppins-font text-black mb-1 font-weight-medium">
              {{ $t('common.forms.label.auth.reset_password_new') }}
            </label>
            <div class="input-group input-group-lg mb-2-5 flex-column">
              <input
                id="password"
                ref="password"
                v-model="password"
                v-validate="'required|min:8|max:100'"
                type="text"
                name="password"
                class="form-control w-100 px-1-5"
                :placeholder="$t('common.forms.placeholder.auth.reset_password_new')"
              />
              <span v-if="errors.has('password')" class="text-danger mt-1">{{ errors.first('password') }}</span>
            </div>
            <label for="confirmPassword" class="poppins-font text-black mb-1 font-weight-medium">
              {{ $t('common.forms.label.auth.reset_password_confirm') }}
            </label>
            <div class="input-group input-group-lg mb-2-5 flex-column">
              <input
                id="confirmPassword"
                v-model="confirmPassword"
                v-validate="'required|confirmed:password|min:8|max:16'"
                type="text"
                name="confirmPassword"
                class="form-control w-100 px-1-5"
                :placeholder="$t('common.forms.placeholder.auth.reset_password_confirm')"
              />
              <span v-if="errors.has('confirmPassword')" class="text-danger mt-1">{{
                errors.first('confirmPassword')
              }}</span>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-primary w-100">
            <span class="h6 mb-0">
              {{
                isRequestNewPassPage
                  ? $t('common.forms.button.send_reset_email')
                  : $t('common.forms.button.reset_password')
              }}
            </span>
          </button>
        </form>
        <div class="row log-info resetTabs">
          <div
            class="col-12 d-flex pt-lg-3 pt-1-5 justify-content-center"
            :class="isDeutchLang ? 'col-12' : 'col-lg-6 justify-content-lg-start'"
          >
            <p class="mb-0">{{ $t('signup.content.login_link_text') }}</p>
            <router-link to="/sign-in" class="ml-1">
              {{ $t('signup.content.login_link') }}
            </router-link>
          </div>
          <div
            class="col-12 d-flex pt-lg-3 pt-1-5 justify-content-center"
            :class="isDeutchLang ? 'col-12 text-left' : 'col-lg-6 justify-content-lg-end'"
          >
            <p class="mb-0">
              {{ $t('signin.content.signup_link_text') }}
            </p>
            <router-link to="/sign-up" class="ml-1">
              {{ $t('signin.content.signup_link') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSending" class="row justify-content-center w-100">
      <Spinner />
    </div>

    <div v-if="emailRequestStatus.sent" class="my-lg-auto my-2-5 mx-auto w-100" style="max-width: 590px;">
      <h1 class="text-center mb-3">{{ $t(emailRequestStatus.messageI18Key) }}</h1>
      <router-link class="btn btn-lg btn-primary btn-block" to="/"
        ><span>{{ $t(emailRequestStatus.submessageI18Key) }}</span></router-link
      >
    </div>
    <div v-if="newPasswordStatus.isSet" class="my-lg-auto my-2-5 mx-auto w-100" style="max-width: 590px;">
      <h1 class="text-center mb-3">{{ $t(newPasswordStatus.messageI18Key) }}</h1>
      <router-link class="btn btn-lg btn-primary btn-block" to="/"
        ><span>{{ $t(newPasswordStatus.submessageI18Key) }}</span></router-link
      >
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Spinner from '../../views/Spinner'
import LanguageDropdown from '@/components/LanguageDropdown'

export default {
  name: 'ResetPassword',
  components: { Spinner, LanguageDropdown },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      isSending: false,
      emailRequestStatus: {
        sent: false,
        messageI18Key: '',
        submessageI18Key: ''
      },
      newPasswordStatus: {
        isSet: false,
        messageI18Key: '',
        submessageI18Key: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      resetEmail: 'user/getResetEmail'
    }),
    isRequestNewPassPage() {
      return this.$router.currentRoute.name === 'reset-password-request'
    },
    isSetNewPassPage() {
      return this.$router.currentRoute.name === 'reset-password-new'
    },
    resetToken() {
      return this.$router.currentRoute.params.token
    },
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    }
  },
  methods: {
    ...mapActions({
      resetPasswordRequest: 'user/resetPasswordRequest',
      setNewPassword: 'user/setNewPassword',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    submitForm() {
      this.isSending = true
      if (this.isRequestNewPassPage) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.resetPasswordRequest(this.email)
              .then(() => {
                this.isSending = false
                this.emailRequestStatus = {
                  sent: true,
                  messageI18Key: 'forgot_password.content.success_email_request_message',
                  submessageI18Key: 'common.error.link_to_home'
                }
              })
              .catch(() => {
                this.isSending = false
                this.setError({
                  type: 'reset_request',
                  messageI18Key: 'forgot_password.content.error_password_reset_message',
                  submessageI18Key: 'common.error.link_to_home'
                })
              })
          } else {
            this.isSending = false
          }
        })
      } else if (this.isSetNewPassPage) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.setNewPassword({
              password_confirmation: this.confirmPassword,
              password: this.password,
              token: this.resetToken,
              email: this.resetEmail
            })
              .then(() => {
                this.isSending = false
                this.newPasswordStatus = {
                  isSet: true,
                  messageI18Key: 'forgot_password.content.success_password_reset_message',
                  submessageI18Key: 'common.error.link_to_home'
                }
              })
              .catch(() => {
                this.isSending = false
                this.setError({
                  type: 'reset_request',
                  messageI18Key: 'forgot_password.content.error_password_reset_message'
                })
              })
          } else {
            this.isSending = false
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.redirectButton {
  text-decoration: none !important;
}
.redirectButton span {
  color: white;
}
</style>
