<template>
  <div class="tab-content h-100 w-100">
    <div
      role="tabpanel"
      aria-labelledby="v-pills-info-tab"
      class="h-100"
      :class="{ 'd-flex flex-column justify-content-center': !favorites.total }"
    >
      <div v-if="!favorites.total" class="text-center m-auto">
        <i class="template-icon mb-2"></i>
        <h1 class="h3 mb-1-5 text-center">{{ $t('common.profile_menu.link.profile_favorites') }}</h1>
      </div>

      <div v-if="favorites.total">
        <h1 class="h3 mb-1-5">{{ $t('common.profile_menu.link.profile_favorites') }}</h1>

        <SalonCard
          v-for="(salon, index) in favorites.data"
          :key="salon.id"
          :salon="salon"
          :index="parseInt(index)"
          @showSalonOnMap="showSalonOnMap"
        ></SalonCard>
      </div>

      <div class="row mt-3 ml-3">
        <pagination :data="favorites" :show-disabled="true" @pagination-change-page="getFavoritesPagination">
          <span slot="prev-nav">
            <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
          </span>
          <span slot="next-nav">
            <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
          </span>
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SalonCard from './../../components/SalonCard'

export default {
  name: 'UserFavorites',
  components: {
    SalonCard
  },
  data() {
    return {
      favoriteSalonId: 0,
      showSalonData: {
        isShowOnMapActive: false,
        salonCoords: { lat: 46.948, lng: 7.4474 }
      },
      favoriteParams: {
        page: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      favorites: 'salons/getFavorites'
    })
  },
  mounted() {
    this.fetchFavorites()
    this.getFavoritesUser()
  },
  methods: {
    ...mapActions({
      fetchFavorites: 'salons/getFavorites',
      getFavoritesUser: 'salons/getFavorites'
    }),
    favoriteId(value) {
      return (this.favoriteSalonId = value)
    },
    getFavoritesPagination(page = 1) {
      this.favoriteParams.page = page
      this.fetchFavorites(this.favoriteParams)
    },
    showSalonOnMap(salonCoords) {
      this.showSalonData = {
        isShowOnMapActive: true,
        salonCoords
      }
    }
  }
}
</script>
