/** @typedef {object|null} PaymentData
 * @property {number|null} creditCardId
 * @property {string} userEmail
 * @property {string} cardHolderName
 * @property {number|null} cvvCode
 * @property {number|null} expiryDate
 * @property {boolean} userAgreement
 */
const getDefaultState = () => ({
  /** @type {PaymentData} */
  clientBooking: null,
  reservedProducts: []
})

const SET_CLIENT_BOOKING = (state, payload) => {
  state.clientBooking = payload
}

const SET_RESERVED_PRODUCT = (state, payload) => {
  state.reservedProducts.push(payload)

  let products = state.reservedProducts.filter(product => {
    return product.salon_id === payload.salon_id
  })

  state.reservedProducts = products.map(item => {
    if (!item.reservedFlag) {
      item.deleteId = item.id
    }

    return item
  })
}

const REMOVE_RESERVED_PRODUCT = (state, payload) => {
  let productIndex = state.reservedProducts.findIndex(product => product.deleteId === payload)

  state.reservedProducts.splice(productIndex, 1)
}

const CLEAR_RESERVED_PRODUCT = state => {
  state.reservedProducts = []
}

const CLEAR_CLIENT_BOOKING = state => {
  state.clientBooking = null
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  SET_CLIENT_BOOKING,
  CLEAR_CLIENT_BOOKING,
  RESET_STATE,
  SET_RESERVED_PRODUCT,
  CLEAR_RESERVED_PRODUCT,
  REMOVE_RESERVED_PRODUCT,
  getDefaultState
}
