<template>
  <main>
    <div>
      <span v-if="textLength > stringLength">
        <p v-if="!readMoreActivated" :class="textColor">{{ dataDescr.slice(0, stringLength) + '...' }}</p>
        <a v-if="!readMoreActivated" href="#" class="show-more pb-1" @click.prevent="activateReadMore">
          {{ $t('common.forms.button.read_more') }}+
        </a>
        <p v-if="readMoreActivated" :class="textColor" v-html="dataDescr"></p>
        <a v-if="readMoreActivated" href="#" @click.prevent="disActivateReadMore">
          {{ $t('common.forms.button.hide') }} -
        </a>
      </span>
      <p v-else :class="`line ${textColor}`">{{ dataDescr }}</p>
    </div>
  </main>
</template>
<script>
export default {
  name: 'Readmore',
  props: {
    dataDescr: {
      type: String,
      default: ``
    },
    stringLength: {
      type: Number,
      default: 300
    },
    textColor: {
      type: String,
      default: 'reviewText'
    }
  },
  data() {
    return {
      readMoreActivated: false
    }
  },
  computed: {
    textLength() {
      return this.dataDescr ? this.dataDescr.length : 0
    }
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true
    },
    disActivateReadMore() {
      this.readMoreActivated = false
    }
  }
}
</script>
<style scoped>
.line {
  line-height: 26px;
}
</style>
