<template>
  <div class="card w-100 mb-1-5">
    <div class="card-body">
      <div class="col px-0-3 d-flex justify-content-between d-md-none">
        <div class="pb-1">
          <h5 class="">{{ salon.title | truncate(20) }}</h5>
          <div class="d-inline-block text-nowrap locationLabel">
            <span>{{ salon.address.full_address | truncate(15) }}</span>
            <span class="text-gray ml-1">
              <i class="location-icon"></i>
              {{ distanceToSalon }}km
            </span>
          </div>
        </div>
        <div>
          <span class="rating-block rate-block">
            <FaRating
              :glyph="iconStar"
              :border-width="0"
              :active-color="'#ff6b09'"
              :inactive-color="'#FFE1CD'"
              :show-rating="false"
              :item-size="13"
              :read-only="true"
              :increment="0.01"
              :rating="salonRate"
              :max-rating="5"
              :spacing="2"
            ></FaRating>
          </span>
          <div v-if="salonPrice" class="col-auto d-flex align-items-center ml-0-5 p-0 mt-0-5">
            <span class="text-gray text-small p-0">
              {{ $t('common.forms.label.from') }}
            </span>
            <span class="text truncate h6 font-weight-medium ml-0-5 mb-0 text-nowrap" :title="salon.price_min"
              >{{ salonFormattedPrice(salonPrice) }} CHF</span
            >
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-auto">
          <button
            v-if="isAuthorized"
            :class="{ favorite: isFavorite }"
            type="button"
            class="position-absolute btn fav_btn_card  rounded-circle favoriteBtn"
            @click="toggleFavorite"
          >
            <font-awesome-icon :icon="['far', 'bookmark']" class="ml-0 favorite-icon"></font-awesome-icon>
          </button>

          <div v-if="hasImages" class="w-100 h-100 ml-md-0 mb-md-1-5 mb-xl-0">
            <SalonCardCarousel :images="salon.images" />
          </div>
          <div v-else class="carousel rounded bg-light h-100"></div>
        </div>
        <div class="col">
          <div class="row d-none d-md-block">
            <div class="w-100 d-flex justify-content-between">
              <h5 class="mb-1 pl-1">{{ salon.title | truncate(35) }}</h5>
              <span class="rating-block pr-1 ">
                <FaRating
                  :glyph="iconStar"
                  :border-width="0"
                  :active-color="'#ff6b09'"
                  :inactive-color="'#FFE1CD'"
                  :show-rating="false"
                  :item-size="13"
                  :read-only="true"
                  :increment="0.01"
                  :rating="salonRate"
                  :max-rating="5"
                  :spacing="2"
                ></FaRating>
              </span>
            </div>
          </div>
          <div class="d-none d-md-block">
            <div class=" d-flex justify-content-between flex-nowrap" style="overflow: hidden">
              <div class="col-auto text-small pl-0 mt-0-8">
                <span>{{ salon.address.full_address | truncate(25) }}</span>
                <span class="text-gray ml-1">
                  <i class="location-icon"></i>
                  {{ distanceToSalon }}km
                </span>
              </div>
              <div v-if="salonPrice" class="col-auto d-flex align-items-center ml-0-5 p-0 mt-0-5">
                <span class="text-gray text-small p-0">
                  {{ $t('common.forms.label.from') }}
                </span>
                <span class="text truncate h6 font-weight-medium ml-0-5 mb-0" :title="salonPrice"
                  >{{ salonFormattedPrice(salonPrice) }} CHF</span
                >
              </div>
            </div>
          </div>
          <hr class="my-1-5 d-none d-md-block" />
          <div class="row mt-1-5 mt-md-0 mb-0-8 mb-md-0">
            <div class="col-12 d-flex px-0 flex-wrap">
              <div v-if="showMapBtn" class="col-12 buttonChoose mb-1-5 d-block d-md-none">
                <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
                  <span> {{ $t('common.forms.button.choose') }}</span>
                </router-link>
              </div>
              <div class="col-6 button pr-md-0-3" :class="!isDeutchLang ? 'col-lg' : 'col-lg'">
                <button
                  class="btn btn-outline-secondary-custom collapsed btn-block py-1 text-nowrap"
                  role="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  :class="{ active: !isSelectedIndex, 'px-0': isDeutchLang }"
                  @click="showFavoriteCard(index)"
                >
                  <span v-show="index != selectedSalonCardIndex" class="show-more">
                    {{ $t('common.forms.button.show_more') }}
                  </span>
                  <span v-show="index == selectedSalonCardIndex" class="less-more">
                    {{ $t('common.forms.button.less_more') }}
                  </span>
                </button>
              </div>
              <div v-if="showMapBtn" class="col-6 button pr-md-1 pr-lg-0-3 col-lg white-space-nowrap text-nowrap">
                <button class="btn btn-outline-secondary-custom btn-block py-1" @click="showSalonOnMap(salon)">
                  <span> {{ $t('common.forms.button.view_on_map') }} </span>
                </button>
              </div>
              <div
                v-if="!showMapBtn || !isMobileWidth"
                class="col-6 buttonChoose text-nowrap"
                :class="{
                  'd-none d-md-block col-md mt-md-1 mt-lg-0': this.$route.name === 'salons-page',
                  'col-md': this.$route.name === 'user-favorites'
                }"
              >
                <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
                  {{ $t('common.forms.button.choose') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-collapse
        :id="`favorite-content-${index}`"
        class="collapse mt-1 px-0-6 px-md-0"
        accordion="my-accordion"
        :visible="index === selectedSalonCardIndex"
      >
        <b-tabs v-model="tabIndex" content-class="mt-2" align="between">
          <b-tab :title="$t('common.card.info_tab_title')" active>
            <div class="d-flex align-items-baseline flex-wrap">
              <p class="h6 text-dark mb-0 font-weight-medium">
                {{ $t('common.card.working_hours') }}
              </p>
              <b-button
                v-b-toggle="`wh-collapse-${salon.id}`"
                class="text-dark ml-3"
                variant="link"
                @click="changeChevronFlag"
              >
                {{ $t('common.card.open_now') }}

                <font-awesome-icon
                  v-if="workHoursChevronFlag"
                  :icon="['far', 'chevron-up']"
                  class="ml-1 icon-chevron cursor-pointer"
                ></font-awesome-icon>
                <font-awesome-icon
                  v-if="!workHoursChevronFlag"
                  :icon="['far', 'chevron-down']"
                  class="ml-1 icon-chevron cursor-pointer"
                ></font-awesome-icon>
              </b-button>
              <b-collapse :id="`wh-collapse-${salon.id}`" class="w-100">
                <div class="ml-0 mt-2 ml-md-3 grafficPosition">
                  <div
                    v-for="(day, key) in salonWorkingHours"
                    :key="`day-key ${key}`"
                    class="d-flex mt-1 px-1 px-md-0 day-title"
                  >
                    <div class="list-unstyled w-50 align-content-md-center pr-0">
                      <span class="m-0 p-0 text-nowrap" :class="currentDayClass(key)">
                        {{ weekdayList[key] }}
                      </span>
                    </div>
                    <div>
                      <span class="text-nowrap">{{ workingDayTimeText(day) }}</span>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
            <hr class="my-2" />
            <p class="h6 text-dark font-weight-medium">
              {{ $t('common.card.services') }}
            </p>
            <div v-if="salon.salonServices.length" class="tag-list d-flex wrap">
              <div v-for="(service, key) in salon.salonServices" :key="`service ${key}`">
                <span
                  v-if="!isMobileWidth"
                  class="badge badge-secondary-light mr-1 mb-1 text-gray text-small font-weight-normal"
                  >{{ service.title | truncate(80) }}</span
                >
                <span v-else class="badge badge-secondary-light mr-1 mb-1 text-gray text-small font-weight-normal">{{
                  service.title | truncate(40)
                }}</span>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('common.card.reviews_tab_title')" @click="reviewDataMethod(salon.id)">
            <div class="row align-items-center pb-2 border-bottom">
              <div class="col-4 col-md-12 col-lg-3 d-lg-block d-md-flex flex-md-wrap mb-0 mb-md-2 mb-lg-0">
                <div class="mr-1-5">
                  <span class="h1">{{ salonRate }}</span>
                  <span>/5</span>
                </div>
                <div>
                  <FaRating
                    :glyph="iconStar"
                    :border-width="0"
                    :active-color="'#ff6b09'"
                    :inactive-color="'#FFE1CD'"
                    :show-rating="false"
                    :item-size="18"
                    :read-only="true"
                    :increment="0.01"
                    :rating="salonRate"
                    :max-rating="5"
                    :spacing="3"
                  ></FaRating>
                  <p class="mb-0">
                    {{ $t('salon.section.content.feedback_rating_count', { count: salonReviewsCount }) }}
                  </p>
                </div>
              </div>

              <RatingCarousel
                :salon-rate="salon.rating"
                :total-count="salon.reviews_num"
                :width-dependence="carouselSize"
              />
            </div>
            <ReviewsTabs :salon-id="salon.id" />
          </b-tab>
          <b-tab :title="$t('common.card.about_tab_title')">
            <div>{{ salon.description }}</div>
          </b-tab>
        </b-tabs>
        <div class="row d-block d-md-none mt-2 mb-0-5">
          <div class="col-12 d-flex flex-wrap px-0">
            <div v-if="showMapBtn" class="col-12 buttonChoose px-0-3 mb-1-5 d-block d-md-none">
              <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
                {{ $t('common.forms.button.choose') }}
              </router-link>
            </div>
            <div class="col-6 col-md button pl-0-3">
              <button
                class="btn btn-outline-secondary-custom collapsed btn-block py-1 text-nowrap"
                role="button"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="collapseExample"
                :class="{ active: !isSelectedIndex }"
                @click="showFavoriteCard(index)"
              >
                <span v-show="index != selectedSalonCardIndex" class="show-more">
                  {{ $t('common.forms.button.show_more') }}
                </span>
                <span v-show="index == selectedSalonCardIndex" class="less-more">
                  {{ $t('common.forms.button.less_more') }}
                </span>
              </button>
            </div>
            <div v-if="showMapBtn" class="col-md button pr-0-3 text-nowrap col-6">
              <button class="btn btn-outline-secondary-custom btn-block py-1" @click="showSalonOnMap(salon)">
                {{ $t('common.forms.button.view_on_map') }}
              </button>
            </div>
            <div v-if="!showMapBtn" class="col col-md buttonChoose">
              <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
                {{ $t('common.forms.button.choose') }}
              </router-link>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { FaRating } from 'vue-rate-it'
import Star from 'vue-rate-it/glyphs/star'
import SalonCardCarousel from '@/components/SalonCardCarousel'
import RatingCarousel from '@/components/salon/RatingCarousel'
import ReviewsTabs from '@/components/salon/ReviewsTabs'
import moment from 'moment'

export default {
  name: 'SalonCard',
  components: {
    FaRating,
    SalonCardCarousel,
    RatingCarousel,
    ReviewsTabs
  },
  filters: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text
    }
  },
  props: {
    salon: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    showMapBtn: Boolean
  },
  data() {
    return {
      reviewsParams: {
        page: 1,
        parameters: [1]
      },
      tabIndex: 0,
      loadReviews: false,
      starSizeValue: 18,
      isFavorite: false,
      // noReviewsFlag: false,
      carouselSize: 'col-8 col-md-12 col-lg-9 pr-lg-2-5 pr-1-5 pr-0',
      workHoursChevronFlag: false
    }
  },
  computed: {
    ...mapGetters({
      selectedSalonCardIndex: 'salons/getSelectedSalonCardIndex',
      currentPlace: 'salons/getCurrentPlace',
      salonReviews: 'salons/getSalonReviews',
      userPosition: 'user/getUserPosition',
      favorites: 'salons/getFavorites',
      isAuthorized: 'user/getAuthorizedFlag',
      siteLang: 'user/getSiteLang'
    }),
    weekdayList() {
      moment.updateLocale(this.siteLang, {
        week: {
          dow: 1
        }
      })

      return moment.localeData(this.siteLang).weekdays(true)
    },
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    },
    salonPrice() {
      if (this.salon.combination !== undefined) {
        return this.salon.combination.min_price
          ? this.salon.combination.min_price
          : this.salon.combination.default_price
      } else return this.salon.price_min
    },
    isMobileWidth() {
      return window.innerWidth < 767
    },
    salonWorkingHours() {
      // TODO Need to recall something that remade the keys in the API
      return this.salon.workingHours !== undefined ? this.salon.workingHours : this.salon.working_hours
    },
    salonRate() {
      return this.salon.evaluation_rating ? this.salon.evaluation_rating.value : 0
    },
    salonReviewsCount() {
      return this.salon.reviews_num ? this.salon.reviews_num : 0
    },
    distanceToSalon() {
      const { latitude: salonLat, longitude: salonLng } = this.salon.address
      let { lat: userLat, lng: userLng } = this.userPosition

      if (this.currentPlace && this.currentPlace !== null && typeof this.currentPlace === 'object') {
        let location = this.currentPlace.geometry.location

        userLat = typeof location.lat === 'function' ? location.lat() : location.lat
        userLng = typeof location.lng === 'function' ? location.lng() : location.lng
      }

      let radius = 6378137 // Earth�s mean radius in meter
      let dLat = this.radius(salonLat - userLat)
      let dLong = this.radius(salonLng - userLng)
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.radius(salonLat)) * Math.cos(this.radius(userLat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      let d = radius * c
      let disatanceKM = Number(d / 1000)
      return disatanceKM < 1 ? disatanceKM.toFixed(1) : disatanceKM.toFixed(0)
    },
    hasImages() {
      return this.salon.images.length > 0
    },
    isSelectedIndex() {
      return this.index !== this.selectedSalonCardIndex
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', () => {
      this.tabIndex = 0
    })
    if (this.isAuthorized) this.checkFavoriteSalon()
  },
  created() {
    this.iconStar = Star
  },
  methods: {
    ...mapActions('salons', {
      fetchSalonReviews: 'getSalonReviews',
      toggleSalonsMoreInfo: 'toggleSalonsMoreInfo',
      addToFavorites: 'getAddToFavorites',
      removeFromFavorites: 'getRemoveFromFavorites'
    }),
    radius(x) {
      return (x * Math.PI) / 180
    },
    currentDayClass(key) {
      let date = new Date()

      return date.getDay() === key + 1 ? 'text-primary' : ''
    },
    workingDayTimeText(day) {
      let startTime = day.start_time
      let endTime = day.end_time

      return startTime === null || endTime === null ? this.$t('common.card.closed') : `${startTime} - ${endTime}`
    },
    showSalonOnMap(salon) {
      const salonCoords = { lat: +salon.address.latitude, lng: +salon.address.longitude, salon: this.salon }
      this.$emit('showSalonOnMap', salonCoords)
    },
    getReviews(page = 1) {
      this.reviewsParams.page = page
      this.fetchSalonReviews(this.reviewsParams)
    },
    reviewDataMethod(id, page = 1) {
      this.reviewsParams.id = id
      this.reviewsParams.page = page
      this.loadReviews = false

      this.fetchSalonReviews(this.reviewsParams).then(data => {
        if (data.data.data.length) this.loadReviews = true
        // else this.noReviewsFlag = true
      })
    },
    showFavoriteCard(key) {
      this.toggleSalonsMoreInfo(key)
      this.$root.$emit('bv::toggle::collapse', `favorite-content-${key}`)
    },
    checkFavoriteSalon() {
      if (!this.favorites.data) return
      this.favorites.data.forEach(elem => {
        if (elem.id == this.salon.id) this.isFavorite = true
      })
    },
    toggleFavorite() {
      if (this.isFavorite) {
        this.removeFromFavorites(this.salon.id).then(() => (this.isFavorite = false))
      } else {
        this.addToFavorites(this.salon.id).then(() => (this.isFavorite = true))
      }
      return false
    },
    changeChevronFlag() {
      return !this.workHoursChevronFlag ? (this.workHoursChevronFlag = true) : (this.workHoursChevronFlag = false)
    },
    salonFormattedPrice(price) {
      if (price) return Number(price).toFixed(2)
      else return 0
    }
  }
}
</script>
<style lang="scss" scoped>
.salon-catalog-wrapper .card .row img {
  width: 70% !important;
}

.salon-list {
  width: auto;
  max-height: 100%;
}
.carousel {
  width: 266px;
}
.carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.wrap {
  flex-wrap: wrap;
}
.grafficPosition {
  position: relative !important;
}
.day-title {
  justify-content: center;
  margin-right: 35%;
}
.rate-block .vue-rate-it-rating {
  justify-content: flex-end !important;
}
</style>
