<template>
  <main>
    <section class="navbar-offset contact-bg" >
      <div class="container pt-4 pb-md-4 pb-lg-14">
        <div v-if="saleModeOn" class="row">
          <div class="col col-lg-8 col-xl-6">
            <h1 class="font-weight-semibold">{{ $t('sale.contact.title') }}</h1>
            <p class="mb-5">
              {{ $t('sale.contact.sub_title_1') }}
            </p>
            <p class="mb-5">
              {{ $t('sale.contact.sub_title_2') }}
            </p>

            <label class="poppins-font text-black mb-1 font-weight-medium mb-1 ml-md-0 ml-0-3">
              {{ $t('sale.contact.form_title') }}
            </label>
            <p class="mb-2">{{ $t('sale.contact.form_subtitle_1') }}</p>
            
            <form class="pb-6 mt-md-0 mt-4" @submit.prevent="sendMessage">
              <div class="d-flex mt-2-5">
                <div class="col pl-0">
                  <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t('contact-us.name-input-title') }}
                  </label>
                  <div class="input-group mb-2-5">
                    <input
                      id="name"
                      v-model="contactMessageData.full_name"
                      v-validate="'required|alpha'"
                      class="form-control w-100"
                      name="name"
                      type="text"
                      autocomplete="false"
                    />
                    <span v-if="errors.has('name')" class="error-message my-1">{{ errors.first('name') }}</span>
                  </div>
                </div>
                <div class="col pr-0">
                  <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t('contact-us.email-input-title') }}
                  </label>
                  <div class="input-group mb-2-5">
                    <input
                      id="emal"
                      v-model="contactMessageData.email"
                      v-validate="'required|email|min:5'"
                      class="form-control w-100"
                      name="email"
                      type="emal"
                      autocomplete="false"
                    />
                    <span v-if="errors.has('email')" class="error-message my-1">{{ errors.first('email') }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                  {{ $t('contact-us.message-input-title') }}
                </label>
                <div class="input-group mb-2-5">
                  <textarea
                    id="message"
                    v-model="contactMessageData.message"
                    v-validate="'required'"
                    class="message-input form-control w-100"
                    name="message"
                    type="text"
                    autocomplete="false"
                    :data-vv-as="$t('common.forms.label.message')"
                  />
                  <span v-if="errors.has('message')" class="error-message my-1">{{ errors.first('message') }}</span>
                </div>
              </div>
              <button type="submit" class="mt-2-5 btn btn-primary btn-block">
                <span>{{ $t('contact-us.submit-button-text') }}</span>
              </button>
            </form>
          </div>
        </div>

        <div v-else class="row">
          <div class="col col-lg-8 col-xl-6">
            <h1 class="font-weight-semibold">{{ $t('contact-us.title') }}</h1>
            <p class="mb-5">
              {{ $t('contact-us.title-text') }}
            </p>
            <label class="poppins-font text-black mb-1 font-weight-medium mb-1 ml-md-0 ml-0-3">
              {{ $t('contact-us.category-title') }}
            </label>
            <form class="pb-6 mt-md-0 mt-4" @submit.prevent="sendMessage">
              <div class="d-flex flex-wrap">
                <div class="col-md col-12 px-0 common-icon">
                  <label class="w-100 responsiveIcon position-relative btn-params mr-1">
                    <input type="radio" name="radio-1" class="btn-checkbox" autocomplete="off" value="Contact us" />
                    <span
                      :class="{ active: isActiveDefaultFlag }"
                      class="btn btn-checkbox btn-block"
                      @click="setContactFlag"
                    >
                      <span> {{ $t('contact-us.category-first') }} </span>
                    </span>
                  </label>
                </div>
                <div class="col-md col-12 common-icon pl-0 pr-md-2 pr-0">
                  <label class="w-100 responsiveIcon position-relative btn-params ml-md-2 ml-0">
                    <input type="radio" name="radio-1" class="btn-checkbox" autocomplete="off" value="Contact us" />
                    <span
                      :class="{ active: !isActiveDefaultFlag }"
                      class="btn btn-checkbox btn-block"
                      @click="setFeatureFlag"
                    >
                      <span> {{ $t('contact-us.category-second') }} </span>
                    </span>
                  </label>
                </div>
              </div>
              <div class="d-flex mt-2-5">
                <div class="col pl-0">
                  <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t('contact-us.name-input-title') }}
                  </label>
                  <div class="input-group mb-2-5">
                    <input
                      id="name"
                      v-model="contactMessageData.full_name"
                      v-validate="'required|alpha'"
                      class="form-control w-100"
                      name="name"
                      type="text"
                      autocomplete="false"
                    />
                    <span v-if="errors.has('name')" class="error-message my-1">{{ errors.first('name') }}</span>
                  </div>
                </div>
                <div class="col pr-0">
                  <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t('contact-us.email-input-title') }}
                  </label>
                  <div class="input-group mb-2-5">
                    <input
                      id="emal"
                      v-model="contactMessageData.email"
                      v-validate="'required|email|min:5'"
                      class="form-control w-100"
                      name="email"
                      type="emal"
                      autocomplete="false"
                    />
                    <span v-if="errors.has('email')" class="error-message my-1">{{ errors.first('email') }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <label for="repeatPass" class="poppins-font text-black mb-1 font-weight-medium">
                  {{ $t('contact-us.message-input-title') }}
                </label>
                <div class="input-group mb-2-5">
                  <textarea
                    id="message"
                    v-model="contactMessageData.message"
                    v-validate="'required'"
                    class="message-input form-control w-100"
                    name="message"
                    type="text"
                    autocomplete="false"
                    :data-vv-as="$t('common.forms.label.message')"
                  />
                  <span v-if="errors.has('message')" class="error-message my-1">{{ errors.first('message') }}</span>
                </div>
              </div>
              <button type="submit" class="mt-2-5 btn btn-primary btn-block">
                <span>{{ $t('contact-us.submit-button-text') }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { isEmpty } from 'lodash'
import { mapActions } from 'vuex'
import config from 'config'

export default {
  data() {
    return {
      isActiveDefaultFlag: true,
      contactMessageData: {
        type: 'contact',
        full_name: '',
        email: '',
        message: ''
      },
      successSendContactRequestInfo: {
        type: 'Success!',
        messageI18Key: 'Your data is sent successfuly'
      },
      saleModeOn: config.saleModeOn
    }
  },
  computed: {
    isEmptyData() {
      return isEmpty(this.contactMessageData)
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
    let navbar = document.getElementById('top-navbar')
    if (navbar) {
      navbar.classList.remove('bg-white')
      navbar.classList.add('bg-inherit')
    }
  },
  methods: {
    sendMessage() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendContact(this.contactMessageData)
            .then(data => {
              if (data) this.setSuccess(this.successSendContactRequestInfo)
            })
            .catch(err => {
              const errorPayload = {
                type: err.message,
                message: err.data.message,
                code: err.code
              }

              this.setError(errorPayload)
            })
        }
      })
    },
    setContactFlag() {
      this.contactMessageData.type = 'contact'
      this.isActiveDefaultFlag = true
    },
    setFeatureFlag() {
      this.contactMessageData.type = 'feature'
      this.isActiveDefaultFlag = false
    },
    ...mapActions({
      sendContact: 'user/contact',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    })
  }
}
</script>
<style scoped>
.message-input {
  height: 230px;
}
@media (min-width: 1465px) {
  .contact-bg {
    background-position: right -100px top;
  }
}
</style>
