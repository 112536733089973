<template>
    <div id="sale-alert">
      <span>This platform is available for SALE.</span>
      <router-link :to="link">
        Learn more
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
            <g id="next_icon" data-name="next (3)" transform="translate(-142.222)">
                <path id="icon_3672" data-name="icon 3672" d="M88,4.422,84.012.244a.742.742,0,0,0-1.1,0l-.464.481a.807.807,0,0,0-.226.578.844.844,0,0,0,.226.584L85.418,5,82.448,8.119a.807.807,0,0,0-.226.578.844.844,0,0,0,.226.584l.464.481a.755.755,0,0,0,1.1,0L88,5.584A.844.844,0,0,0,88.222,5,.807.807,0,0,0,88,4.422Z" transform="translate(60 0)" fill="#fff"/>
            </g>
        </svg>
       </router-link>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                link: '/contact-us'
            }
        }
    }
</script>

<style lang="scss">
.navbar-offset {
    margin-top: 53px;
}
 #sale-alert {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 53px;
     width: 100%;
     color: #fff;
     background-color: #E34140;
     position: fixed;
     top: 0;
     left: 0;
     z-index: 100;
     letter-spacing: 0px;
     font-size: 16px;
     text-align: center;
     > span {
         margin-right: 6px;
     }
     > a {
         color: #fff;
         text-decoration: none;
         border-bottom: 1px solid #fff;
     }
}

.fix {
  top: 53px !important;
}
.sale-offset {
  padding-top: 53px !important;
}
</style>