<template>
  <div class="col-12 d-flex px-0 text-center">
    <div v-if="name === 'Lady'" class="d-none d-md-block col-3 border-right table-row-height">
      <div class="container p-0 h-100">
        <div class="row h-100 d-flex flex align-items-center">
          <div class="col-4 py-1 border-right"><span class="lady-icon"></span></div>
          <div class="col py-1 align-items-center text-left">
            <span>{{ $t('servicesRedesign.lady') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="name === 'Gentleman'" class="col-3 border-right d-none d-md-block table-row-height">
      <div class="container p-0 h-100">
        <div class="row h-100 d-flex align-items-center">
          <div class="col-4 py-1 border-right text-center"><span class="gent-icon"></span></div>
          <div class="col py-1 align-items-center text-left">
            <span>{{ $t('servicesRedesign.gentleman') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div :class="`col-4 col-md-3 p-0 border-right justify-content-center d-flex align-items-center  table-row-height`">
      <ColumnText :combination="returnCombination('Short')" @set-choosen-combination="setChoosenCombination" />
    </div>
    <div
      :class="`col-4 col-md-3 p-0 border-right justify-content-center combi d-flex align-items-center table-row-height`"
    >
      <ColumnText :combination="returnCombination('Medium')" @set-choosen-combination="setChoosenCombination" />
    </div>
    <div :class="`col-4 col-md-3 p-0 justify-content-center combi d-flex align-items-center table-row-height`">
      <ColumnText :combination="returnCombination('Long')" @set-choosen-combination="setChoosenCombination" />
    </div>
  </div>
</template>
<script>
import ColumnText from './../ColumnText'
export default {
  components: {
    ColumnText
  },
  props: {
    name: {
      type: String,
      default: null
    },
    combinations: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    returnCombination(length) {
      let lengths = {
        Short: {
          id: 21,
          value: 'Short'
        },
        Medium: {
          id: 22,
          value: 'Medium'
        },
        Long: {
          id: 23,
          value: 'Long'
        }
      }
      let requiredCombination = null
      this.combinations.forEach(combination => {
        if (combination.name.includes(this.name)) {
          if (combination.parameter_values.length === 1) {
            requiredCombination = {
              ...combination,
              ...{ lengthParameters: lengths[length] }
            }
          } else {
            combination.parameter_values.forEach(parameter => {
              if (parameter.id === lengths[length].id) {
                requiredCombination = {
                  ...combination
                }
              }
            })
          }
        }
      })
      return requiredCombination
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
