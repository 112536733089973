<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Dein vertrauensvoller Herren Coiffeur in der Nähe
        </h1>
        <p class="mb-2">
          Wenn es um die Suche nach einem Herren Coiffeur geht, dann geht es auch oft um Pragmatismus. Denn viele Männer
          sind genau das: pragmatisch. Schnell muss es gehen. Günstig muss es sein. Und bestenfalls ist das Ergebnis
          auch noch positiv. Das trifft bei den meisten Männern auf den Kleidungseinkauf zu. Es trifft aber eben auch
          auf die Suche nach einem Herren Coiffeur zu.
        </p>
        <p class="mb-2">
          Quiff ist hier die perfekte Plattform, weil sie eine breite Palette an Salons und Dienstleistern rund um den
          Herrenhaarschnitt bereithält. Auch für den Salonbesitzer ist es eine tolle
          <a :href="coiffeurSoftware" target="_blank"> Coiffeur Software</a>, die viele Vorteile und alles aus einer
          Hand anbietet.
        </p>
        <p class="mb-2">
          Auf Quiff findest Du nicht nur
          <a :href="coiffeurNane" title="Coiffeure in Deiner Nähe" target="_blank">Coiffeure in Deiner Nähe</a>. Jede
          grössere Stadt beherbergt unzählige Coiffeur Salons. Du kannst daher auch Deine Suche auf Deinen Wunschort
          ausrichten. Suche zum Beispiel nach
          <a :href="coiffeurZurich" target="_blank" title="Coiffeur Zürich"> Coiffeur Zürich </a>,
          <a :href="coiffeurStGallen" target="_blank" title="Coiffeur St Gallen">Coiffeur St Gallen</a>,
          <router-link to="/Coiffeur-Basel" title="Coiffeur Basel"> Coiffeur Basel</router-link> oder
          <router-link to="/Coiffeur-Bern" title="Coiffeur Bern">Coiffeur Bern</router-link>. Anschliessend kannst Dich
          bei einem Herren Coiffeur Deiner Wahl verwöhnen lassen und Dir beispielsweise einen pflegeleichten
          Kurzhaarschnitt verpassen lassen. Dazu buche direkt Deinen
          <router-link to="/" title="Coiffeur Termin online">Coiffeur Termin online</router-link>. Du bist nur wenige
          Klicks davon entfernt!
        </p>
        <h2 class="mb-2">
          Wir haben die Experten für Herrenfrisuren
        </h2>
        <p class="mb-2">
          Doch egal, was Du suchst, egal, wo Du suchst. Bei Quiff findest Du garantiert das Richtige. Hier kannst Du
          Dich durch eine Vielzahl an Angeboten klicken und das Beste für Dich auswählen. Denn auch wir wissen, dass die
          Ansprüche ganz unterschiedliche sind. Viele sind, wie gesagt, sicherlich pragmatisch. Aber es gibt auch die,
          die ihn zelebrieren: den Besuch beim Herren Coiffeur. Die sich verwöhnen lassen möchten, die sich von einem
          Barbier den Bart in Form bringen, sich die Augenbrauen zupfen lassen möchten.
        </p>
        <p class="mb-2">
          Welcher Herrenfriseur bzw Herren Coiffeur welche Dienstleistungen anbietet, das alles findest Du hier. Ein
          weiteres grosses Plus: Andere Nutzer teilen mit Dir ihre Erfahrungswerte. Davon kannst Du Dich inspirieren
          lassen – und anschliessend selbst und mit wenigen Klicks Deinen Coiffeur Termin online buchen. Wir bestätigen
          Dir den gebuchten Termin unmittelbar und erinnern Dich zusätzlich noch einmal kurz vorher daran. Und dann
          steht Deiner neuen Frisur – ob nun minimal oder radikal – nichts mehr im Weg. Wenn Dir Quiff gefällt, freuen
          wir uns über eine Empfehlung an Deine Partnerin. Ganz sicher findet Sie hier den perfekten
          <router-link to="/Herren-Coiffeur" title="Damen Coiffeur!">Damen Coiffeur!</router-link>
          Wenn Du eine Familie hast, brauchst Du auch noch den passenden
          <router-link to="/Kinder-Coiffeur" title="Kinder Coiffeur">Kinder Coiffeur</router-link>. Das tolle an Quiff
          ist, dass Du mit einem einzigen Login ein Profil für jedes Familienmitglied anlegen kannst.
        </p>
        <div class="my-5 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Quiff bietet eine grosse Auswahl und damit garantiert auch den perfekten Herren Coiffeur für Dich. Klicke Dich rein und prüfe das umfassende Angebot.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    },
    coiffeurNane() {
      return `${config.salonAppUrl}/Coiffeur-in-der-Nähe`
    },
    coiffeurSoftware() {
      return `${config.salonAppUrl}/Coiffeur-Software`
    },
    coiffeurZurich() {
      return `${config.salonAppUrl}/Coiffeur-Zürich`
    },
    coiffeurStGallen() {
      return `${config.salonAppUrl}/Coiffeur-St-Gallen`
    }
  }
}
</script>
