<template>
  <div :class="`${widthDependence ? widthDependence : 'col-auto'}`">
    <div v-for="val in rateArray" :key="`count${val}`" class="d-none d-md-block align-items-center mb-0-5">
      <div class="d-flex">
        <div class="pl-0 col-auto d-flex mr-0-8 ">
          <p class="mb-0-5 pr-0-5">{{ val + 1 }}</p>
          <i class="star_icon active" style="margin-top:0.2rem"></i>
        </div>
        <div class="col rating-scale-container position-relative bg-gray-v-light">
          <div
            v-if="salonRate.length"
            class="aling-left-top h-100 position-absolute bg-gray"
            :style="getRatePercentByNumber(salonRate[val])"
          ></div>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none mb-3">
      <div class="d-flex justify-content-end">
        <div v-for="val in rateArray" :key="`count-mobile${val}`" class="d-block d-md-none content">
          <div class="rating-scale-container position-relative w-100 h-100">
            <div
              v-if="salonRate.length"
              class="  w-100 position-absolute bg-gray range-position"
              :style="getRatePercentByNumberMobile(salonRate[val])"
            ></div>
          </div>
          <div class="d-inline-flex mt-0-5">
            <p>{{ val + 1 }}</p>
            <i class="star_icon active icon-position"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    salonRate: {
      type: Array,
      default: () => []
    },
    totalCount: {
      type: Number,
      default: 0
    },
    widthDependence: {
      type: String,
      default: () => 'col-md-4 pl-md-4'
    }
  },
  data() {
    return {
      rateArray: [...Array(5).keys()].reverse(),
      width: window.innerWidth
    }
  },
  methods: {
    getRatePercentByNumber(value) {
      let percent = (value * 100) / this.totalCount
      return {
        width: `${percent}%`
      }
    },
    getRatePercentByNumberMobile(value) {
      let percent = (value * 100) / this.totalCount
      return {
        height: `${percent}%`
      }
    },
    parseValue() {
      return Object.keys(this.salonRate).length > 0 ? Object.keys(this.salonRate) : this.salonRate
    }
  }
}
</script>
<style scoped>
.content {
  width: 10px;
  border-radius: 2px;
  height: 55px;
  background-color: #f1f1f1;
  margin-top: 10px;
  margin-left: 1.4rem;
}
.range-position {
  left: 0;
  bottom: 0;
}
.icon-position {
  margin-top: 2px;
}
</style>
