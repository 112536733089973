const getDefaultState = () => {
  return {
    commonHaircutParams: {
      gender: {},
      length: {},
      choosenServices: [],
      date: ''
    },
    params: {
      genders: [],
      hairLength: [],
      age: []
    },
    services: [],
    childHaircutParams: {
      age: {}
    },
    choosenDate: '',
    parametersFromMainTemplate: [],
    savedDuplicateData: {
      savedChoosenServicesData: [],
      savedChoosenCommonHaircutParams: {},
      savedChildHaircutParams: {},
      savedChoosenDateData: ''
    }
  }
}

const SET_COMMON_HAIRCUT_PARAMS = (state, payload) => {
  const { paramName, value } = payload
  state.commonHaircutParams[paramName] = value
}
const SET_MAIN_TEMPLATE_PARAMETERS = (state, payload) => {
  state.parametersFromMainTemplate = payload
}
const RESET_MAIN_TEMPLATE_PARAMETERS = (state, payload) => {
  state.parametersFromMainTemplate = []
}

const SET_ALL_COMMON_HAIRCUT_PARAMS = (state, payload) => {
  Object.assign(state.commonHaircutParams, payload)
}

const SET_CHILD_HAIRCUT_PARAMS = (state, payload) => {
  state.childHaircutParams = payload
}
const ADD_SERVICES = (state, services) => {
  state.commonHaircutParams.choosenServices = []
  state.commonHaircutParams.choosenServices = services
}
const ADD_SERVICE = (state, service) => {
  if (Array.isArray(service)) {
    state.commonHaircutParams.choosenServices = service
  } else {
    state.commonHaircutParams.choosenServices = [service]
  }
}
const ADD_SERVICE_FOR_SALON_PAGE = (state, service) => {
  state.commonHaircutParams.choosenServices.push(service)
}
const REMOVE_SERVICE_FOR_SALON_PAGE = (state, service) => {
  state.commonHaircutParams.choosenServices.forEach((el, index) => {
    if (el.id === service.id) state.commonHaircutParams.choosenServices.splice(index, 1)
  })
}

const REMOVE_EXISTING_SERVICE = (state, index) => {
  state.services.splice(index, 1)
}

const REMOVE_CHOOSEN_SERVICE = state => {
  state.commonHaircutParams.choosenServices = []
}

const CLEAR_CHOOSEN_SERVICES = state => {
  state.commonHaircutParams.choosenServices = []
}
const SET_EXISTING_SERVICES = (state, payload) => {
  state.services = payload
}
const ADD_EXISTING_SERVICE_BACK = (state, service) => {
  state.services.push(service)
}
const SET_PARAMETERS_VALUES = (state, payload) => {
  const { genders, hairLength, age } = payload
  state.params = { genders, hairLength, age }
}
const CLEAR_GENDER_PARAM = state => {
  state.commonHaircutParams.gender = {}
}
const CLEAR_DATE = state => {
  state.commonHaircutParams.date = ''
}
const SET_DATE = (state, payload) => {
  state.choosenDate = payload
}
const CLEAR_CHOOSEN_DATE = (state, payload) => {
  state.choosenDate = ''
}

const CLEAR_CHOOSEN_LENGTH = state => {
  state.commonHaircutParams.length = {}
}

const CLEAR_CHILD_HAIRCUT_PARAMS = state => {
  Object.assign(state.childHaircutParams, getDefaultState().childHaircutParams)
}

const RESET_COMMON_PARAMS = state => {
  Object.assign(state.commonHaircutParams, getDefaultState().commonHaircutParams)
}
const RESET_CHOOSEN_SERVICES = state => {
  state.commonHaircutParams.choosenServices = []
}
const SAVE_CHOOSEN_SERVICES = state => {
  state.savedDuplicateData.savedChoosenServicesData = state.commonHaircutParams.choosenServices
}
const RESTORE_CHOOSEN_SERVICES = state => {
  if (state.savedDuplicateData.savedChoosenServicesData.length)
    state.commonHaircutParams.choosenServices = state.savedDuplicateData.savedChoosenServicesData
}
const SAVE_COMMON_HAIRCUT_PARAMS = state => {
  state.savedDuplicateData.savedChoosenCommonHaircutParams = JSON.parse(JSON.stringify(state.commonHaircutParams))
  state.savedDuplicateData.childHaircutParams = JSON.parse(JSON.stringify(state.childHaircutParams))
}
const RESTORE_COMMON_HAIRCUT_PARAMS = state => {
  state.commonHaircutParams = JSON.parse(JSON.stringify(state.savedDuplicateData.savedChoosenCommonHaircutParams))
  state.childHaircutParams = JSON.parse(JSON.stringify(state.savedDuplicateData.childHaircutParams))
}
const RESTORE_CHOOSEN_DATE_VALUE = state => {
  state.choosenDate = state.savedDuplicateData.savedChoosenDateData
  state.commonHaircutParams.date = state.savedDuplicateData.savedChoosenDateData
}
const SAVE_CHOOSEN_DATE_VALUE = state => {
  state.savedDuplicateData.savedChoosenDateData = state.choosenDate
}
const CLEAR_ALL_COMMON_HAIRCUT_PARAMS = state => {
  state.commonHaircutParams = {
    gender: {},
    length: {},
    choosenServices: [],
    date: ''
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}
export default {
  SET_COMMON_HAIRCUT_PARAMS,
  SET_ALL_COMMON_HAIRCUT_PARAMS,
  SET_CHILD_HAIRCUT_PARAMS,
  ADD_SERVICES,
  ADD_SERVICE,
  ADD_SERVICE_FOR_SALON_PAGE,
  REMOVE_EXISTING_SERVICE,
  CLEAR_CHOOSEN_SERVICES,
  CLEAR_CHOOSEN_LENGTH,
  SET_EXISTING_SERVICES,
  ADD_EXISTING_SERVICE_BACK,
  SET_PARAMETERS_VALUES,
  CLEAR_GENDER_PARAM,
  CLEAR_DATE,
  CLEAR_CHILD_HAIRCUT_PARAMS,
  RESET_COMMON_PARAMS,
  RESET_STATE,
  SET_DATE,
  REMOVE_CHOOSEN_SERVICE,
  RESET_CHOOSEN_SERVICES,
  SET_MAIN_TEMPLATE_PARAMETERS,
  RESET_MAIN_TEMPLATE_PARAMETERS,
  CLEAR_CHOOSEN_DATE,
  SAVE_CHOOSEN_SERVICES,
  RESTORE_CHOOSEN_SERVICES,
  SAVE_CHOOSEN_DATE_VALUE,
  CLEAR_ALL_COMMON_HAIRCUT_PARAMS,
  SAVE_COMMON_HAIRCUT_PARAMS,
  RESTORE_COMMON_HAIRCUT_PARAMS,
  RESTORE_CHOOSEN_DATE_VALUE,
  REMOVE_SERVICE_FOR_SALON_PAGE,
  getDefaultState
}
