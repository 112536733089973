import axios from 'axios'
import config from 'config'

const http = axios.create({
  baseURL: `${config.apiUrl}/api`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }
})

const errorHandler = error => {
  return Promise.reject({ ...error.response.data })
}

const requestHandler = request => {
  const token = localStorage.getItem('access_token') ? JSON.parse(localStorage.getItem('access_token')) : null
  if (token) request.headers.Authorization = `Bearer ${token.access_token}`

  return request
}

const successHandler = response => {
  return response
}

http.interceptors.request.use(request => requestHandler(request))

http.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export default http
