<template>
  <b-navbar>
    <b-navbar-nav class="align-items-center bottom-mobile-nav fixed-bottom d-flex justify-content-around">
      <b-nav-item class="bottomLink mr-0-5">
        <router-link to="/">
          <div class="d-flex justify-content-center">
            <font-awesome-icon
              :icon="['far', 'calendar']"
              :class="
                $route.name === 'home' || $route.name === 'salons-page' || $route.name === 'salon-page'
                  ? `icon-calendar-active`
                  : `icon-calendar`
              "
            ></font-awesome-icon>
          </div>
          <p
            class="text-uppercase fz-12"
            :class="
              $route.name === 'home' || $route.name === 'salons-page' || $route.name === 'salon-page'
                ? 'activeLink'
                : ''
            "
          >
            {{ $t('common.navbar.book_link') }}
          </p>
        </router-link>
      </b-nav-item>
      <b-nav-item v-b-modal.language-modal class="bottomLink mr-0-5" @click="setDropDownFlag">
        <router-link to="#">
          <div class="d-flex justify-content-center">
            <font-awesome-icon
              :icon="['far', 'globe']"
              :class="
                isDropDownActive
                  ? `icon-globeMob-active
`
                  : `icon-globeMob`
              "
            ></font-awesome-icon>
          </div>
          <p class="text-uppercase fz-12" :class="isDropDownActive ? 'activeLink' : ''">
            {{ fullChoosenLanguage() }}
          </p>
        </router-link>
      </b-nav-item>
      <b-nav-item v-if="getAuthInfo.isAuthorized" class="bottomLink mr-0-5">
        <router-link to="/profile-page/templates">
          <div class="d-flex justify-content-center">
            <img v-if="userAvatar" :src="userAvatar" class="rounded image--cover profileImage" alt />
          </div>
          <p class="text-uppercase fz-12" :class="$route.path.includes('/profile-page') ? 'activeLink' : ''">
            {{ $t('common.navbar.profile_link') }}
          </p>
        </router-link>
      </b-nav-item>
      <b-nav-item class="bottomLink mr-1">
        <router-link v-if="getAuthInfo.isAuthorized" to="/sign-in">
          <font-awesome-icon
            :icon="['fas', 'sign-out-alt']"
            :class="$route.meta.layout === 'auth' ? `icon-login-active mr-0-5` : `icon-login mr-0-5`"
          ></font-awesome-icon>
          <p
            class="text-uppercase"
            :class="$route.meta.layout === 'auth' ? 'activeLink fz-12' : 'fz-12'"
            @click="manualLogout()"
          >
            {{ $t('common.navbar.sign_out') }}
          </p>
        </router-link>
        <router-link v-if="!getAuthInfo.isAuthorized" to="/sign-in">
          <font-awesome-icon
            :icon="['fas', 'sign-in-alt']"
            :class="$route.meta.layout === 'auth' ? `icon-login-active mr-0-5` : `icon-login mr-0-5`"
          ></font-awesome-icon>
          <p class="text-uppercase" :class="$route.meta.layout === 'auth' ? 'activeLink fz-12' : 'fz-12'">
            {{ $t('common.navbar.sign_in_link') }}
          </p>
        </router-link>
      </b-nav-item>
    </b-navbar-nav>
    <b-modal
      id="language-modal"
      ref="languageModal"
      header-class="modal-header pt-0 px-0 pb-0 border-bottom-0 mt-1-5 mt-md-0"
      body-class="px-1-5 pb-2"
      centered
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block d-block d-md-none w-100">
          <div class="d-flex justify-content-between">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="closeWithoutSave"
            ></font-awesome-icon>

            <h4>Language</h4>
            <div></div>
          </div>
        </div>
      </template>
      <div class="h-100 mt-2">
        <button
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          class="btn btn-outline-secondary-custom collapsed btn-block py-1 mb-1-5"
          :class="{ active: choosenLang === lang }"
          @click="setLangValueToData(lang)"
        >
          <span class="text-uppercase align-middle">{{ lang }}</span>
        </button>
      </div>
      <template v-slot:modal-footer="">
        <button class="btn btn-lg btn-primary w-100 mt-auto mb-2" @click="setLanguage(choosenLang)">
          <p class="h6 font-weight-medium mb-0 text-white">
            Save changes
          </p>
        </button>
      </template>
    </b-modal>
  </b-navbar>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      langs: ['DEUTSCH', 'ENGLISH'],
      choosenLang: null,
      isDropDownActive: false
    }
  },
  computed: {
    ...mapGetters({
      userAvatar: 'user/getUserAvatar',
      getAuthInfo: 'user/getAuthInfo'
    })
  },
  mounted() {
    this.choosenLang = this.fullChoosenLanguage()
  },
  methods: {
    ...mapActions('user', {
      manualLogout: 'logout'
    }),
    ...mapMutations({
      setSiteLang: 'user/SET_SITE_LANG'
    }),
    setLanguage(val) {
      this.$bvModal.hide('language-modal')
      this.setDropDownFlag()
      this.setSiteLang(val.slice(0, 2).toLowerCase())
    },
    setLangValueToData(lang) {
      this.choosenLang = lang
    },
    setDropDownFlag() {
      this.isDropDownActive = !this.isDropDownActive
    },
    fullChoosenLanguage() {
      for (let i = 0; i < this.langs.length; i++) {
        if (this.langs[i].includes(this.$root.$i18n.locale.toUpperCase())) {
          return this.langs[i]
        }
      }
    },
    closeWithoutSave() {
      this.$bvModal.hide('language-modal')
      this.setDropDownFlag()
    }
  }
}
</script>

<style scoped>
.bottomLink {
  font-size: 12px !important;
  font-family: Roboto;
  line-height: 1.17;
  letter-spacing: normal;
  font-weight: 500;
  width: 24%;
  height: 3.4rem;
  text-align: center;
  vertical-align: middle;
}
.bottomLink a {
  text-decoration: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.bottom-mobile-nav {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -3px 6px 0 rgba(155, 159, 162, 0.16);
  z-index: 999;
}
.profileImage {
  width: 18px;
  height: 18px;
}
.activeLink {
  color: #111517 !important;
}
.b-dropdown {
  position: fixed;
  background: black;
  left: 0;
  display: flex;
  bottom: 50px;
  border: 1px solid black;
  width: 100%;
  justify-content: space-around;
}
</style>
