<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  data() {
    return {
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      }
    }
  },
  computed: {
    ...mapGetters({
      mainTemplate: 'user/getMainTemplate',
      isRedirectedFromBooking: 'user/getRedirectedAuthFlag',
      clientBooking: 'payment/getClientBooking',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      getFormattedHaircutData: 'haircutParams/getFormattedHaircutData',
      getFormatedReservedProductsBySalonId: 'payment/getFormatedReservedProductsBySalonId'
    })
  },
  methods: {
    ...mapActions({
      createTemplate: 'user/createTemplate',
      createClientBooking: 'payment/createClientBooking',
      setError: 'userMessages/setError'
    }),
    authorizationRedirect() {
      if (this.isRedirectedFromBooking === true) {
        let bookingPayload = this.clientBooking

        if (this.getFormatedReservedProductsBySalonId(this.clientBooking.salon_id).products.length) {
          bookingPayload = {
            ...bookingPayload,
            ...this.getFormatedReservedProductsBySalonId(this.clientBooking.salon_id)
          }
        }

        if (!this.mainTemplate) {
          const { getFormattedHaircutData } = this

          let bodyParams = {
            main_template: '1'
          }
          if (this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1) {
            bodyParams.values = {
              gender: getFormattedHaircutData.parameters[0],
              length: getFormattedHaircutData.parameters[1]
            }
          } else {
            bodyParams.values = {
              gender: getFormattedHaircutData.parameters[0],
              length: null
            }
          }

          if (!isEmpty(this.allSelectedHaircutParams.age)) {
            bodyParams.age = getFormattedHaircutData[getFormattedHaircutData.length - 1]
          }

          this.createTemplate(bodyParams)
            .then(data => {
              bookingPayload.client_id = data.id

              this.createClientBooking(bookingPayload)
                .then(result => {
                  this.$router.push(`/payment/${result.id}`)
                  this.setRedirectAuthFlag(false)
                })
                .catch(errors => {
                  if (errors.code === 425) {
                    this.setError({
                      type: 'error',
                      messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                    })
                  } else {
                    if (errors.message && errors.message === 'Your email address is not verified.') {
                      this.$bvModal.show('verification-modal')
                    } else {
                      this.setError(this.createBookingErrorMessage)
                    }
                  }
                })
            })
            .catch(errors => {
              if (errors.message && errors.message === 'Your email address is not verified.') {
                this.$bvModal.show('verification-modal')
              } else {
                this.setError(this.createBookingErrorMessage)
              }
            })
        } else {
          bookingPayload.client_id = this.mainTemplate.id

          this.createClientBooking(bookingPayload)
            .then(result => {
              this.$router.push(`/payment/${result.id}`)
              this.setRedirectAuthFlag(false)
            })
            .catch(errors => {
              if (errors.code === 425) {
                this.setError({
                  type: 'error',
                  messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                })
              } else {
                if (errors.message && errors.message === 'Your email address is not verified.') {
                  this.$bvModal.show('verification-modal')
                } else {
                  this.setError(this.createBookingErrorMessage)
                }
              }
            })
        }
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>
