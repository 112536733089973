<template>
  <b-navbar
    id="top-navbar"
    class="navbar navbar-expand-sm navbar-light fix pt-0 pb-0 px-md-0 px-lg-1"
    :class="{
      'border-bottom': hasBorder,
      'pb-0-5': this.$route.path !== '/salons' || this.$route.path.includes('payment-page'),
      'bg-inherit':
        this.$route.path == '/' ||
        this.$route.path == '/contact-us' ||
        (this.$route.path.includes('/profile-page') && isTabletWIdth) ||
        (this.$route.path.includes('/marketplace') && isTabletWIdth),
      'bg-white':
        this.$route.path !== '/' &&
        this.$route.path !== '/contact-us' &&
        !(this.$route.path.includes('/profile-page') && isTabletWIdth) &&
        !(this.$route.path.includes('/marketplace') && isTabletWIdth),
      hideNavbar: hideNavbar
    }"
    :style="this.$route.path === '/salons' ? 'height: 4rem !important' : ''"
  >
    <div :class="`${hasFullWidth ? 'container-fluid px-3-5' : 'container'}`">
      <b-navbar-brand href="#">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/logo_quiff.svg" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-nav class="navbar-nav ml-auto mr-0 align-items-center">
        <b-nav-item :href="salonAppUrl" link-classes="btn btn-link text-primary px-0" target="_blank">
          <u>{{ $t('common.navbar.owner_link') }}</u>
        </b-nav-item>

        <b-nav-item v-show="!authInfo.isAuthorized">
          <a class="btn btn-primary text-white border-0 btn-lg" @click="$router.push('/sign-in')">
            {{ $t('common.navbar.sign_in') }}
          </a>
        </b-nav-item>
        <b-nav-item v-show="authInfo.isAuthorized">
          <b-nav-item-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none d-flex align-items-center px-0"
            no-caret
          >
            <template v-slot:button-content>
              <button
                class="nav-item btn btn-link nav-link px-0"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img v-if="userAvatar" :src="userAvatar" class="user-image rounded mr-0-5 image--cover" alt />
                <span class="text-truncate user-name" :title="accountInfo.first_name">{{
                  accountInfo.first_name
                }}</span>
              </button>
            </template>
            <b-dropdown-item to="/profile-page/templates">
              {{ $t('common.navbar.profile_link') }}
            </b-dropdown-item>
            <b-dropdown-item to="/sign-in" @click="manualLogout()">
              {{ $t('common.navbar.logout_link') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav-item>
      </b-navbar-nav>
      <LanguageDropdown></LanguageDropdown>
    </div>
  </b-navbar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LanguageDropdown from '@/components/LanguageDropdown.vue'
import config from 'config'

export default {
  name: 'TopNavbar',
  components: {
    LanguageDropdown
  },
  props: {
    hasBorder: Boolean,
    hasFullWidth: Boolean
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters('user', {
      authInfo: 'getAuthInfo',
      accountInfo: 'getAccountInfo',
      userAvatar: 'getUserAvatar'
    }),
    hideNavbar() {
      return this.$route.name === 'not-found'
    },
    salonAppUrl() {
      return config.salonAppUrl
    },
    isTabletWIdth() {
      return this.windowWidth < 992 && this.windowWidth > 767
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    this.$nextTick(function() {
      window.addEventListener('scroll', function() {
        let navbar = document.getElementById('top-navbar')
        if (navbar) {
          let nav_classes = navbar.classList
          if (window.scrollY >= 15) {
            if (nav_classes.contains('squeezed') === false) {
              nav_classes.toggle('squeezed')
              nav_classes.toggle('py-0-5')
              if (nav_classes.contains('bg-inherit') === true) nav_classes.toggle('bg-inherit')
            }
          } else {
            if (nav_classes.contains('squeezed') === true) {
              nav_classes.toggle('squeezed')
              nav_classes.toggle('py-0-5')
              if (nav_classes.contains('bg-inherit') === false) nav_classes.toggle('bg-inherit')
            }
          }
        }
      })
    })
  },
  methods: {
    ...mapActions('user', {
      setAuthStatus: 'setAuthStatus',
      manualLogout: 'logout'
    })
  }
}
</script>
<style lang="scss" scoped>
.ProductView {
  margin-top: 10rem;
}
.user-image {
  width: 45px;
  height: 45px;
}
.user-name {
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
}
#top-navbar {
  background-color: white;
  transition: all 0.1s;
  &.squeezed {
    z-index: 997 !important;
    background-color: white !important;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  }
}
.dropdown-toggle::after {
  border-top: 0.3em solid #8d9fa7 !important;
}
.fix {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
}
.bg-inherit {
  background-color: inherit !important;
}
.bg-white {
  background-color: white !important;
}
.hideNavbar {
  display: none !important;
}
</style>
