const getDefaultState = () => {
  return {
    errorInfo: {
      errorType: '',
      statusCode: 0,
      errorExists: false,
      errorMessage: '',
      errorMessageI18Key: ''
    },
    successInfo: {
      successCode: 0,
      successExists: false,
      successMessage: '',
      successMessageI18Key: ''
    }
  }
}

const state = getDefaultState

const getters = {
  getErrorInfo: state => state.errorInfo,
  getSuccessInfo: state => state.successInfo
}
const mutations = {
  setError(state, payload) {
    state.errorInfo.errorType = payload.type
    state.errorInfo.statusCode = payload.code || 0
    state.errorInfo.errorExists = true

    if (payload.messageI18Key !== undefined) {
      state.errorInfo.errorMessageI18Key = payload.messageI18Key
    } else {
      state.errorInfo.errorMessage = payload.message
    }
  },
  clearError(state) {
    state.errorInfo.errorType = ''
    state.errorInfo.statusCode = 0
    state.errorInfo.errorExists = false
    state.errorInfo.errorMessage = ''
    state.errorInfo.errorMessageI18Key = ''
  },
  setSuccessMessage(state, payload) {
    state.successInfo.successCode = payload.code || 0
    state.successInfo.successExists = true

    if (payload.messageI18Key !== undefined) {
      state.successInfo.successMessageI18Key = payload.messageI18Key
    } else {
      state.successInfo.successMessage = payload.message
    }
  },
  clearSuccessMessage(state) {
    state.successInfo.successCode = 0
    state.successInfo.successExists = false
    state.successInfo.successMessageI18Key = ''
    state.successInfo.successMessage = ''
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  }
}
const actions = {
  setError({ commit }, payload) {
    commit('setError', payload)
  },
  clearError({ commit }) {
    commit('clearError')
  },
  setSuccess({ commit }, payload) {
    commit('setSuccessMessage', payload)
  },
  clearSuccess({ commit }) {
    commit('clearSuccessMessage')
  }
}

export const userMessages = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
