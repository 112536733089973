<template>
  <section v-if="loaded" class="navbar-offset position-relative">
    <div class="w-100 d-block d-md-none border-bottom">
      <i @click="$router.go(-1)">
        <font-awesome-icon :icon="['far', 'chevron-left']" class="left-chevron mb-1 mt-1-5 ml-1-5"></font-awesome-icon>
      </i>
    </div>
    <div v-if="successPaymentFlag" class="col-md-12 col mt-md-5 pr-md-4 pl-md-0 px-1">
      <div class="px-md-3 px-0-8 mt-md-0 mt-1-5">
        <div class="d-flex justify-content-center flex-wrap">
          <img src="@/assets/images/examples/pay-in-salon.png" class="thanksPageImage" width="490px" height="240px" />
          <h1 class="mt-3 w-100 text-center">{{ $t('common.forms.label.payment.thanks') }}</h1>
          <p class="mt-1 w-100 text-center">
            {{ $t('common.forms.label.payment.your_salon') }}
          </p>

          <p class="mt-1 w-100 text-center" v-html="$t('common.forms.label.payment.you_can')"></p>

          <p class="mt-1 w-100 text-center">
            {{ $t('common.forms.label.payment.want_to_be') }}
          </p>
        </div>
        <div class="w-100 mt-1 d-flex justify-content-center">
          <a href="https://www.linkedin.com/company/quiff-ch" target="_blank" class="mx-1">
            <div class="linkedin-icon">
              <font-awesome-icon :icon="['fab', 'linkedin-in']" class="icon-twitter"></font-awesome-icon>
            </div>
          </a>
          <a href="https://www.pinterest.ch/quiff_ch/" target="_blank" class="mx-1">
            <div class="pinterest-icon">
              <font-awesome-icon :icon="['fab', 'pinterest-p']" class="icon-twitter"></font-awesome-icon>
            </div>
          </a>
          <a href="https://www.instagram.com/quiff.ch/" target="_blank" class="mx-1">
            <div class="icon-inst">
              <font-awesome-icon :icon="['fab', 'instagram']" class="icon-instagram"></font-awesome-icon>
            </div>
          </a>

          <a href="https://www.facebook.com/quiff.ch" target="_blank" class="mx-1">
            <div class="facebook-icon">
              <font-awesome-icon :icon="['fab', 'facebook-f']" class="icon-facebook"></font-awesome-icon>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-if="!successPaymentFlag && clientBooking" class="container pt-md-4 px-md-0 pt-md-1 pt-0 px-0 mb-8 mb-md-0">
      <div class="col d-none d-md-block d-lg-none px-0">
        <div class="d-flex flex-wrap justify-content-lg-between col-12 px-0">
          <h1 class="mb-md-5 mb-2 mt-md-0 mt-1 col-md-7 col-12 px-0 paymentTitle">
            {{ $t('common.forms.label.payment.payment_method') }}
          </h1>
          <div ref="btnBlock" class="d-flex mb-md-1 mb-2 col-md col-12 px-0">
            <button
              ref="card"
              style="font-size:14px;"
              class="col btn btn-primary collapsed right-btn"
              @click="payByCard"
            >
              {{ $t('common.forms.label.payment.credit_card') }}
            </button>
            <button
              ref="salon"
              style="font-size:14px;"
              class="col btn btn-outline-secondary-custom collapsed left-btn"
              @click="payInTheSalon"
            >
              {{ $t('common.forms.label.payment.pay_in_salon') }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="!paymentStatus.isSending && !paymentStatus.responseStatus" class="row h-100">
        <div v-if="!successPaymentFlag && clientBooking" class="col-md-7 col px-md-0 px-1">
          <div class="px-md-0-7 px-0-8 mt-md-0 mt-1-5">
            <div class="d-block d-md-none d-lg-block">
              <div class="d-flex flex-wrap justify-content-lg-between col-12 px-0">
                <h1 class="mb-md-6 mb-2 mt-md-0 mt-1 col-md-7 col-12 px-0 paymentTitle">
                  {{ $t('common.forms.label.payment.payment_method') }}
                </h1>
                <div ref="btnBlock" class="d-flex mb-md-1 mb-2 col-md col-12 px-0">
                  <button
                    ref="cardTablet"
                    style="font-size:14px;"
                    class="col btn btn-primary collapsed right-btn"
                    @click="payByCard"
                  >
                    {{ $t('common.forms.label.payment.credit_card') }}
                  </button>
                  <button
                    ref="salonTablet"
                    style="font-size:14px;"
                    class="col btn btn-outline-secondary-custom collapsed left-btn"
                    @click="payInTheSalon"
                  >
                    {{ $t('common.forms.label.payment.pay_in_salon') }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="paidByMethodFlad && clientBooking">
              <form v-if="chargesEnabled && payoutsEnabled">
                <div class>
                  <label for="creditNumber" class="poppins-font text-black mb-1 payment-text font-weight-medium">
                    {{ $t('common.forms.label.payment.card') }}
                  </label>
                  <div class="input-group mb-1 d-flex flex-column">
                    <input
                      id="creditNumber"
                      v-model="paymentData.creditCardNumber"
                      v-validate="'required|numeric|min:16|max:16'"
                      name="creditCardNumber"
                      type="text"
                      class="payment-text form-control text-black input-round-borders mb-1 py-1 px-2"
                      :placeholder="$t('common.forms.placeholder.payment_creadit_card')"
                      :data-vv-as="$t('common.forms.label.payment.card_error_attribute_name')"
                    />
                    <span class="text-danger">{{ errors.first('creditCardNumber') }}</span>
                  </div>
                </div>
                <div class>
                  <label for="yourEmail" class="poppins-font payment-text text-black mb-1 font-weight-medium">
                    {{ $t('common.forms.label.payment.email') }}
                  </label>
                  <div class="input-group mb-1 d-flex flex-column">
                    <input
                      id="yourEmail"
                      v-model="paymentData.email"
                      v-validate="'email|min:5'"
                      name="email"
                      type="text"
                      class="form-control payment-text text-black input-round-borders mb-1 py-1 px-2"
                      :placeholder="$t('common.forms.placeholder.payment_email')"
                      :data-vv-as="$t('common.forms.label.payment.email_error_attribute_name')"
                    />
                    <span class="text-danger">{{ errors.first('email') }}</span>
                  </div>
                </div>
                <div class>
                  <label for="creditName" class="poppins-font payment-text text-black mb-1 font-weight-medium">
                    {{ $t('common.forms.label.payment.cardholder_name') }}
                  </label>
                  <div class="input-group mb-1 d-flex flex-column">
                    <input
                      id="creditName"
                      v-model="paymentData.cardHolderName"
                      v-validate="'required|alpha_spaces'"
                      name="cardHolderName"
                      type="text"
                      class="form-control payment-text text-black input-round-borders mb-1 py-1 px-2"
                      :placeholder="$t('common.forms.placeholder.payment_cardholder_name')"
                      :data-vv-as="$t('common.forms.label.payment.cardholder_name_error_attribute_name')"
                    />
                    <span class="text-danger">{{ errors.first('cardHolderName') }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 mb-1">
                    <label for="expireDate" class="poppins-font payment-text text-black mb-1 font-weight-medium">
                      {{ $t('common.forms.label.payment.expiry_date') }}
                    </label>
                    <div class="row">
                      <div class="col-6 pl-0-3 pl-md-1">
                        <select
                          id="expireDateMonth"
                          v-model="paymentData.expiryDate.month"
                          v-validate="'required'"
                          :full-width="true"
                          name="expiryDate"
                          class="form-control w-100 mx-auto py-1"
                          style="font-size:14px;"
                        >
                          <option style="display: none;" selected="selected" value="">
                            {{ $t('common.forms.placeholder.payment_mounth') }}
                          </option>
                          <option v-for="(option, key) in months" :key="`top-${key}`" :value="option">
                            {{ option }}
                          </option>
                        </select>
                      </div>
                      <div class="col-6">
                        <div class="input-group d-flex flex-column">
                          <select
                            id="expireDateYear"
                            v-model="paymentData.expiryDate.year"
                            v-validate="'required'"
                            :full-width="true"
                            name="expiryDate"
                            class="form-control w-100 mx-auto py-0-8"
                            style="font-size:14px;"
                          >
                            <option style="display: none;" selected="selected" value="">
                              {{ $t('common.forms.placeholder.payment_year') }}
                            </option>
                            <option v-for="(option, key) in years" :key="`top-${key}`" :value="option">
                              {{ option }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <span class="text-danger">{{ errors.first('expiryDate') }}</span>
                  </div>
                  <div class="col-12 col-md-6 pr-0-5 pr-md-1">
                    <label for="cvvCode" class="poppins-font payment-text text-black mb-1 font-weight-medium">
                      {{ $t('common.forms.label.payment.cvv_code') }}
                    </label>
                    <div class="input-group mb-1 d-flex flex-column">
                      <input
                        id="cvvCode"
                        v-model="paymentData.cvcCode"
                        v-validate="'required|numeric|min:3'"
                        name="cvcCode"
                        type="text"
                        class="form-control payment-text text-black input-round-borders text-center mb-1 py-1 px-md-2 px-1"
                        :placeholder="$t('common.forms.placeholder.payment_cvc')"
                        :data-vv-as="$t('common.forms.label.payment.cvv_code')"
                        maxlength="4"
                      />
                      <span class="text-danger">{{ errors.first('cvcCode') }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-2-5 px-0 form-check">
                  <div class="checkbox">
                    <label class="mb-0">
                      <input type="checkbox" @change="paymentData.userAgreement = !paymentData.userAgreement" />
                      <i class="input-helper"></i>
                      <p class="text-dark mb-0 roboto-font">
                        {{ $t('common.forms.label.payment.agree_start') }}
                        <router-link to="/agb" target="_blank" :title="$t('common.forms.label.payment.term_link')">
                          {{ $t('common.forms.label.payment.term_link') }}
                        </router-link>
                        {{ $t('common.forms.label.payment.and') }}
                        <router-link
                          to="/privacy-policy"
                          target="_blank"
                          :title="$t('common.forms.label.payment.privacy_link')"
                        >
                          {{ $t('common.forms.label.payment.privacy_link') }} </router-link
                        >{{ $t('common.forms.label.payment.privacy_link_second') }}
                      </p>
                    </label>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-lg btn-primary w-100 d-none d-md-block"
                  :disabled="!paymentData.userAgreement"
                  @click="handlePaymentAndBookingCreation"
                >
                  <p class="h6 font-weight-medium mb-0 text-white">
                    {{ $t('common.forms.label.payment.confirm') }} {{ totalPrice }} CHF
                  </p>
                </button>
              </form>
              <div v-else>
                <p class="mt-2-5 text-danger text-center">
                  {{ $t('payment.disabled_online_payment_title') }}
                </p>
              </div>
            </div>
            <div v-else>
              <p class="">{{ $t('common.forms.label.payment.attent') }}</p>
              <div class="notation px-2 py-2 mt-2 mb-5">
                <h3 class="mb-2 align-content-start">
                  {{ $t('common.forms.label.payment.pleaseBookBesponsibly') }}
                </h3>
                <p class="noticeText">
                  {{ $t('common.forms.label.payment.attention') }}
                </p>
              </div>

              <button
                type="button"
                class="btn btn-lg btn-block btn-primary w-100 d-none d-md-block"
                @click="isPayWillBeInTheSalon"
              >
                <p class="h6 font-weight-medium mb-0 text-white">
                  {{ $t('common.forms.label.payment.pay_in_salon') }}
                  {{ totalPrice }} CHF
                </p>
              </button>
            </div>
          </div>
        </div>
        <div v-if="!successPaymentFlag && clientBooking" class="col-5 pl-3 payment-salon-info d-none d-md-block">
          <div class="col-12 col-xl-8 px-2 pt-2 pb-0-5 payment-salon-info-block float-right">
            <img
              :src="salon.images.length ? salon.images[0].path : `@/assets/images/default/default-salon-img.png`"
              height="180px"
              alt=""
              class="w-100 mb-2 salon-payment-img"
            />
            <h3>{{ salon.title }}</h3>
            <p class="mb-1 text-black font-weight-medium payment-text">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon-marker"></font-awesome-icon>
              {{ salonAddress }}
            </p>
            <hr class="mt-1-5 mb-2 infoBlockLine" />

            <div
              v-for="(param, key) in haircutBookingParams"
              :key="`payment-label-${key}`"
              class="d-flex justify-content-between"
            >
              <p class="text-black font-weight-medium payment-text poppins-font">
                {{ $t(param.i18KeyLabel) }}
              </p>
              <p class="text-right">{{ param.value }}</p>
            </div>
            <hr class="mt-1-5 mb-2 infoBlockLine" />

            <ReservedProducts :id="salon.id"></ReservedProducts>

            <div class="d-flex justify-content-between roboto-font">
              <p class="text-black font-weight-medium">
                {{ $t('common.forms.label.payment.subtotal') }}
              </p>
              <p class="text-right">{{ clientBooking.default_price }}</p>
            </div>
            <div class="d-flex justify-content-between roboto-font">
              <p class="text-black font-weight-medium">
                {{ $t('common.forms.label.payment.tax') }}
              </p>
              <p class="text-right">{{ clientBookingTax }}</p>
            </div>
            <hr class="mt-1-5 mb-2 infoBlockLine" />
            <div class="d-flex justify-content-between poppins-font font-weight-semibold">
              <p class="text-black">
                {{ $t('common.forms.label.payment.total') }}
              </p>
              <p class="h5 text-primary">{{ totalPrice }} CHF</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <Spinner v-if="paymentStatus.isSending" />
        <SuccessPayment v-if="isPaymentSuccessfull" />
        <FailedPayment v-if="paymentFailed" @closeFailedPage="closeFailedPage" />
      </div>
    </div>
    <div v-if="!successPaymentFlag && clientBooking" class="mobileBar d-md-none d-block border-top">
      <div class="row d-flex flex-nowrap px-1">
        <div class="col text-center">
          <p v-b-modal.paymentInfoModal class="clearBtn d-inline-block mt-2">
            {{ $t('common.forms.label.payment.view_details') }}
          </p>
        </div>
        <div class="mt-1-5 confirmAndPayButton" :class="isDeutchLang ? 'col-10' : 'col-8'">
          <button
            v-if="paidByMethodFlad"
            type="button"
            class="btn btn-primary py-1-5 px-0 w-100 text-nowrap"
            :disabled="!paymentData.userAgreement"
            @click="handlePaymentAndBookingCreation"
          >
            <p class="h6 font-weight-medium mb-0 text-white">
              {{ $t('common.forms.label.payment.confirm') }}
              {{ totalPrice }} CHF
            </p>
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary py-1-5 px-0 w-100 text-nowrap"
            @click="isPayWillBeInTheSalon"
          >
            <p class="h6 font-weight-medium mb-0 text-white">
              {{ $t('common.forms.label.payment.pay_in_salon') }}
              {{ totalPrice }} CHF
            </p>
          </button>
        </div>
      </div>
    </div>
    <b-modal
      id="paymentInfoModal"
      ref="paymentInfoModal"
      header-class="modal-header px-0 pb-0 pr-0 border-bottom-0"
      body-class=""
      centered
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block d-block d-md-none">
          <div class="d-flex justify-content-between">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="close()"
            ></font-awesome-icon>

            <h4 class="topText">
              {{ $t('common.forms.label.payment.booking-details') }}
            </h4>
            <div></div>
          </div>
        </div>
        <button type="button" class="close outline-none d-none d-md-block " aria-label="Close" @click="close()">
          <span aria-hidden="true" class="close-icon align-top"></span>
        </button>
      </template>
      <div v-if="clientBooking" class="col pt-2 px-1-5">
        <img
          :src="salon.images.length ? salon.images[0].path : `@/assets/images/default/default-salon-img.png`"
          height="180px"
          alt=""
          class="w-100 mb-2 salon-payment-img"
        />
        <h3>{{ salon.title }}</h3>
        <p class="mb-1 text-black font-weight-medium payment-text">
          <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="icon-marker"></font-awesome-icon>
          {{ salonAddress }}
        </p>
        <hr class="mt-1-5 mb-2 infoBlockLine" />
        <div
          v-for="(param, key) in haircutBookingParams"
          :key="`payment-label-${key}`"
          class="d-flex justify-content-between"
        >
          <p class="text-black font-weight-medium payment-text poppins-font">
            {{ $t(param.i18KeyLabel) }}
          </p>
          <p class="text-right">{{ param.value }}</p>
        </div>
        <hr class="mt-1-5 mb-2 infoBlockLine" />

        <ReservedProducts :id="salon.id"></ReservedProducts>

        <div class="d-flex justify-content-between roboto-font">
          <p class="text-black font-weight-medium">
            {{ $t('common.forms.label.payment.subtotal') }}
          </p>
          <p class="text-right">{{ clientBooking.default_price }}</p>
        </div>
        <div class="d-flex justify-content-between roboto-font">
          <p class="text-black font-weight-medium">
            {{ $t('common.forms.label.payment.tax') }}
          </p>
          <p class="text-right">{{ clientBookingTax }}</p>
        </div>
        <hr class="mt-1-5 mb-2 infoBlockLine" />
        <div class="d-flex justify-content-between poppins-font font-weight-semibold">
          <p class="text-black">
            {{ $t('common.forms.label.payment.total') }}
          </p>
          <p class="h5 text-primary">{{ totalPrice }} CHF</p>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { SalonsRepository } from '../../api/salons.repository'
import Spinner from '../../views/Spinner'
import SuccessPayment from './SuccessPayment'
import FailedPayment from './FailedPayment'
import ReservedProducts from '@/components/ReservedProducts'
import moment from 'moment'
import config from 'config'

/** @typedef {object} PaymentData
 *  @property {string} email
 *  @property {number|null} creditCardNumber
 *  @property {number|null} cardHolderName
 *  @property {number|null} cvvCode
 *  @property {number|null} expiryDate
 *  @property {boolean} userAgreement
 */
export default {
  name: 'PaymentPage',
  components: { Spinner, SuccessPayment, FailedPayment, ReservedProducts },
  data() {
    return {
      loaded: false,
      haircutParamsLabels: ['For whom', 'Hair length', 'Service', 'Day', 'Specialist', 'Time'],
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      /** @type {PaymentData} */
      paymentData: {
        email: '',
        creditCardNumber: null,
        cardHolderName: null,
        cvcCode: null,
        expiryDate: {
          month: '',
          year: ''
        },
        userAgreement: false
      },
      paymentStatus: {
        isSending: false,
        responseStatus: ''
      },
      stripe: null,
      successPaymentFlag: false,
      paidByMethodFlad: true,
      time_passed: false,
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      },
      chargesEnabled: false,
      payoutsEnabled: false
    }
  },
  computed: {
    ...mapGetters({
      commonHaircutParams: 'haircutParams/getCommonHaircutParams',
      clientBooking: 'payment/getClientBooking',
      salon: 'salons/getSalon',
      getReservedProducts: 'payment/getReservedProducts'
    }),
    bookingId() {
      return this.$route.params.id
    },
    years() {
      const currentYear = String(new Date().getFullYear()).substring(2)
      let finalArr = []
      for (let i = Number(currentYear); i <= Number(currentYear) + 20; i++) {
        finalArr.push(String(i))
      }
      return finalArr
    },
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    },
    isPaymentSuccessfull() {
      return !this.paymentStatus.isSending && this.paymentStatus.responseStatus === 'success'
    },
    salonAddress() {
      return this.salon.address.full_address
    },
    paymentFailed() {
      return !this.paymentStatus.isSending && this.paymentStatus.responseStatus === 'failed'
    },

    haircutBookingParams() {
      /** An object that contains data for booking infomation
       * label field containts the name of the booking data
       * value field contains the name of the booking data value
       * @typedef {object} HaircutBookingParam
       * @property {string} label
       * @property {string} value
       */
      const formattedHourNumber = parseInt(moment(this.clientBooking.start_time).format('H:mm'))
      let finalTimeValue = ''
      if (formattedHourNumber < 12)
        finalTimeValue = `${this.$t('common.forms.label.payment.morning')}, ${moment(this.clientBooking.start_time)
          .locale(this.$root.$i18n.locale)
          .format('H:mm')}`
      else if (formattedHourNumber >= 12 && formattedHourNumber < 16) {
        finalTimeValue = `${this.$t('common.forms.label.payment.afternoon')}, ${moment(this.clientBooking.start_time)
          .locale(this.$root.$i18n.locale)
          .format('H:mm')}`
      } else if (formattedHourNumber >= 16) {
        finalTimeValue = `${this.$t('common.forms.label.payment.evening')}, ${moment(this.clientBooking.start_time)
          .locale(this.$root.$i18n.locale)
          .format('H:mm')}`
      }
      const { start_time } = this.clientBooking
      const {
        gender: { value: genderValue }
      } = this.commonHaircutParams
      let lengthValue = null
      this.clientBooking.parameter_values.forEach(el => {
        if (el.id > 20) lengthValue = el.value
      })
      const choosenStaffName = this.clientBooking ? `${this.clientBooking.staff_fullName}` : '-'
      let services = ''
      this.clientBooking.services.forEach(el => (services += el.service_title + ' '))
      const choosenServicesTitles = services
      /** @type {HaircutBookingParam[]} */
      return [
        { i18KeyLabel: 'common.forms.label.payment.whom', value: this.$t(`common.template_gender_key.${genderValue}`) },
        {
          i18KeyLabel: 'common.forms.label.payment.length',
          value: lengthValue ? this.$t(`common.length_key.${lengthValue}`) : '-'
        },
        {
          i18KeyLabel: 'common.forms.label.payment.service',
          value: choosenServicesTitles
        },
        {
          i18KeyLabel: 'common.forms.label.payment.day',
          value: moment(start_time)
            .locale(this.$root.$i18n.locale)
            .format('MMMM, DD YYYY')
        },
        { i18KeyLabel: 'common.forms.label.payment.specialist', value: choosenStaffName },
        { i18KeyLabel: 'common.forms.label.payment.time', value: finalTimeValue ? finalTimeValue : '' }
      ]
    },
    clientBookingTax() {
      if (this.clientBooking) {
        const price = parseFloat(this.clientBooking.app_fee).toFixed(2)
        return price ? price : 0
      } else return 0
    },
    totalPrice() {
      let price = 0

      price += Number(this.clientBooking.default_price)
      price += Number(this.clientBookingTax)
      return price ? price : 0
    },
    creditCardTokenPayload() {
      const { creditCardNumber, expiryDate, cvcCode, cardHolderName } = this.paymentData
      const paymentData = {
        number: creditCardNumber,
        exp_month: expiryDate.month,
        exp_year: expiryDate.year,
        cvc: cvcCode,
        name: cardHolderName
      }
      let bodyPayload = ''
      for (const key in paymentData) {
        bodyPayload += `card[${key}]=${paymentData[key]}&`
      }
      return bodyPayload
    }
  },
  created() {
    window.addEventListener('beforeunload', this.clearBookignProcess)
  },
  mounted() {
    if (this.bookingId) {
      let payload = {
        book_id: this.bookingId
      }

      this.loaded = false

      this.fetchBookingById(payload)
        .then(result => {
          this.chargesEnabled = result.charges_enabled && result.payable
          this.payoutsEnabled = result.payouts_enabled && result.payable
          this.loaded = true
          if (result.paid_by) {
            this.$router.push('/')
          }
        })
        .catch(error => this.$router.push('/'))
    } else {
      this.$router.push('/')
    }

    if (window.innerWidth < 767) {
      let menu = document.querySelector('.bottomNavBar')
      menu.classList.remove('d-block')
      menu.classList.add('d-none')
    }
  },
  beforeDestroy() {
    if (window.innerWidth < 767) {
      let menu = document.querySelector('.bottomNavBar')
      menu.classList.add('d-block')
      menu.classList.remove('d-none')
    }

    window.removeEventListener('beforeunload', this.clearBookignProcess)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!from.name && to.name === 'payment' && !vm.clientBooking.paid_by) {
        vm.deleteClientBooking({ booking_id: vm.clientBooking.id })
          .then(() => {
            vm.$router.push('/')
          })
          .catch(() => {
            vm.resetPaymentState()
            vm.resetShopState()
            vm.$router.push('/')
          })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.clientBooking && !this.time_passed) {
      if (!this.clientBooking.paid_by) {
        this.leaveConfirmMessage().then(value => {
          if (value) {
            this.clearReservedProducts()
            this.deleteClientBooking({ booking_id: this.clientBooking.id })
              .then(() => {
                next()
              })
              .catch(() => {
                this.resetPaymentState()
                this.resetShopState()
                this.$router.push('/')
              })
          }
        })
      } else {
        next()
      }
    } else {
      if (this.clientBooking) {
        if (!this.clientBooking.paid_by) {
          this.clearReservedProducts()
          this.deleteClientBooking({ booking_id: this.clientBooking.id })
            .then(() => {
              next()
            })
            .catch(() => {
              this.resetPaymentState()
              this.resetShopState()
              this.$router.push('/')
            })
        } else {
          next()
        }
      } else {
        this.clearReservedProducts()
      }
      next()
    }
  },
  methods: {
    ...mapActions({
      createClientBookingSalon: 'payment/createClientBookingSalon',
      createBookingPayment: 'payment/createBookingPayment',
      clearClientBooking: 'payment/clearClientBooking',
      deleteClientBooking: 'payment/deleteClientBooking',
      fetchBookingById: 'payment/getBookingById',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    ...mapMutations({
      resetPaymentState: 'payment/RESET_STATE',
      clearReservedProducts: 'payment/CLEAR_RESERVED_PRODUCT',
      resetShopState: 'shop/RESET_STATE'
    }),
    createStripePayment() {
      const stripeKey = config.stripePK

      return SalonsRepository.createStripeToken(this.creditCardTokenPayload, stripeKey)
    },
    handlePaymentAndBookingCreation() {
      if (!this.paymentData.userAgreement) return
      this.$validator.validateAll().then(result => {
        if (result) {
          this.paymentStatus.isSending = true
          this.createStripePayment()
            .then(({ data: tokenData }) => {
              const { client_id, id } = this.clientBooking
              this.createBookingPayment({ client_id, booking_id: id, token: tokenData.id, currency: 'chf' })
                .then(() => {
                  this.paymentStatus.isSending = false
                  this.setSuccess({
                    type: 'payment',
                    messageI18Key: 'payment.payment_success_message'
                  })
                  this.successPaymentFlag = true
                  setTimeout(() => {
                    this.clearReservedProducts()
                    this.clearClientBooking()
                  }, 1500)
                })
                .catch(errors => {
                  this.paymentStatus.isSending = false

                  if (errors.code === 425) {
                    this.setError({
                      type: 'error',
                      messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                    })

                    this.time_passed = true

                    window.removeEventListener('beforeunload', this.clearBookignProcess)
                  } else {
                    this.setError({
                      type: 'payment_error',
                      messageI18Key: 'payment.payment_error_message'
                    })
                  }
                })
            })
            .catch(error => {
              this.setError({
                type: 'payment',
                message: error.response.data.error.message
              })
              this.paymentStatus.isSending = false
              this.closeFailedPage()
            })
        }
      })
    },
    closeFailedPage() {
      this.paymentStatus.responseStatus = ''
      this.paymentData.userAgreement = false
    },
    isPayWillBeInTheSalon() {
      let payloadData = { paid_by: 'Salon', booking_id: this.clientBooking.id, client_id: this.clientBooking.client_id }
      this.$refs.paymentInfoModal.isVisible = false

      this.createClientBookingSalon(payloadData)
        .then(() => {
          this.successPaymentFlag = true
          setTimeout(() => {
            this.clearReservedProducts()
            this.clearClientBooking()
          }, 1500)
        })
        .catch(errors => {
          if (errors.code === 425) {
            this.setError({
              type: 'error',
              messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
            })

            this.time_passed = true

            window.removeEventListener('beforeunload', this.clearBookignProcess)
          } else {
            this.setError(this.createBookingErrorMessage)
          }
        })
    },
    clearBookignProcess(e) {
      e.preventDefault()
      e.returnValue = ''
    },
    leaveConfirmMessage() {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', {
        domProps: { innerHTML: 'Title from <i>HTML<i> string' }
      })
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [this.$t('common.confirm.title')]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5'] }, [this.$t('common.confirm.leave_payment_description')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-4 px-4',
          cancelVariant: 'light',
          cancelTitle: this.$t('common.confirm.cancel_btn')
        })
        .then(value => {
          return value
        })
        .catch(error => {
          return error
        })
    },
    payByCard() {
      this.classWork(this.$refs.card, this.$refs.salon)
      this.classWork(this.$refs.cardTablet, this.$refs.salonTablet)
      this.paidByMethodFlad = true
    },
    payInTheSalon() {
      this.classWork(this.$refs.salon, this.$refs.card)
      this.classWork(this.$refs.salonTablet, this.$refs.cardTablet)
      this.paidByMethodFlad = false
    },
    classWork(btnToAdd, btnToRemove) {
      btnToRemove.classList.add('btn-outline-secondary-custom', 'collapsed')
      btnToRemove.classList.remove('btn-primary')
      btnToAdd.classList.add('btn-primary')
      btnToAdd.classList.remove('btn-outline-secondary-custom', 'collapsed')
    }
  }
}
</script>
<style scoped>
.mobileBar {
  width: 100%;
  bottom: 0;
  height: 85px;
  position: fixed;
  z-index: 100;
  background-color: #fff;
  overflow: auto;
}
.clearBtn {
  color: #8d9fa7;
  font-size: 14px;
  border-bottom: 1px solid #8d9fa7;
  cursor: pointer;
}
.homepageBtn {
  width: 50%;
}
.right-btn {
  border-radius: 4px 0 0 4px !important;
  white-space: nowrap;
  height: 48px;
}
select {
  text-align: center;
  text-align-last: center;
  /* webkit*/
}
select:active,
select:focus {
  color: #111517;
}
.left-btn {
  border-radius: 0 4px 4px 0 !important;

  height: 48px;
  white-space: nowrap;
}
.notation {
  background-color: rgba(255, 107, 9, 0.03);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}
.noticeText {
  color: black;
  font-family: Roboto;
  font-size: 16px;
}
.payment-salon-info-block {
  border: 1px dashed #e0e0e0;
  border-radius: 7px;
}

.icon-inst {
  background-image: linear-gradient(45deg, #ffdc55, #fe543f 51%, #c937aa);
}
.icon-inst,
.linkedin-icon,
.pinterest-icon,
.facebook-icon {
  width: 48px;
  height: 48px;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
}
.linkedin-icon {
  background-color: #0074b0;
}
.pinterest-icon {
  background-color: #e60023;
}
.facebook-icon {
  background-color: #3b5998;
}
.paymentTitle {
  font-size: 42px;
}
.payment-close-icon {
  display: inline-block;
  font-size: 15px;
  color: #111517;
}
.product-image {
  border-radius: 7px;
  border: 1px solid #e0e0e0;
}
@media screen and (max-width: 330px) {
  .confirmAndPayButton {
    -ms-flex: 0 0 70.66667%;
    flex: 0 0 70.66667%;
    max-width: 70.66667%;
  }
}
select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
</style>
