<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-column">
        <div class="calendar-pager row align-items-center justify-content-between mb-1">
          <div class="col-auto d-flex align-items-center">
            <span
              :class="{ 'cursor-pointer': !isFirstWeek() }"
              class="px-0-5"
              @click="manageWeekDates(monthDaysContext, 'previousWeek', $event)"
            >
              <font-awesome-icon
                class="next-icon"
                :class="{ inactive: isFirstWeek() }"
                :icon="['far', 'angle-left']"
              ></font-awesome-icon>
            </span>
            <span class="font-weight-medium pt-0-3">{{ calendarMonth() }} {{ calendarYear() }}</span>
            <span
              :class="{ 'cursor-pointer': !isInactiveNextWeekSwitch }"
              class="px-0-5"
              @click="manageWeekDates(monthDaysContext, 'nextWeek', $event)"
            >
              <font-awesome-icon
                :class="{ inactive: isInactiveNextWeekSwitch }"
                class="next-icon"
                :icon="['far', 'angle-right']"
              ></font-awesome-icon>
            </span>
          </div>
          <div :class="{ 'col-7': this.$route.path.includes('profile-page') }">
            <vc-date-picker
              ref="calendar"
              v-model="choosenDate"
              :popover="{ placement: 'bottom', visibility: 'click' }"
              :attributes="calendarAttrs"
              color="orange"
              :min-date="today.toDate()"
              :max-date="lastDayOfMaxMonth.toDate()"
              :rows="1"
              :columns="this.$route.name === 'salon-page' ? 1 : 2"
              :nav-visibility="'hidden'"
              :pane-width="270"
              is-expanded
              :locale="{ id: isDeutchLang ? 'de' : 'en', masks: { weekdays: 'WW' }, firstDayOfWeek: 2 }"
            >
              <span slot="header-title" slot-scope="{ monthLabel, yearLabel }"> {{ monthLabel }} {{ yearLabel }} </span>

              <div
                class="col-auto show-full-calendar d-none d-md-block"
                :class="{ 'text-right': this.$route.path.includes('profile-page') }"
              >
                <small class="mr-0-5">
                  {{ $t('common.forms.label.full_calendar') }}
                </small>
                <span class="text-primary">+</span>
              </div>
            </vc-date-picker>
          </div>
        </div>
        <div class="days-name-row row text-center mb-1">
          <div v-for="day in weekDays" :key="`${day}-week`" class="col">
            <span>{{ day }}</span>
          </div>
        </div>
        <div class="days-row row text-center">
          <div
            v-for="day in days"
            :key="`${day.date()}-weekday`"
            class="col px-0-5 available-date"
            :class="{
              'current-day': isCurrentDay(day),
              'choosen-day': isChoosenDate(day),
              'inactive-day': isInactiveDay(day) && !isChoosenDate(day),
              'future-day': isFutureDay(day)
            }"
            @click="chooseDate(day)"
          >
            <span>{{ day.date() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Calendar',
  data() {
    return {
      today: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      monday: moment(this.getMonday(new Date())).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      days: [],
      monthDaysContext: moment(this.getMonday(new Date())).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      calendarAttrs: [
        {
          dates: new Date()
        }
      ],
      isInactiveNextWeekSwitch: false,
      weekDaysContext: moment(),
      choosenDate: null
    }
  },
  computed: {
    ...mapGetters({
      getChoosenDate: 'haircutParams/getChoosenDate',
      getSiteLang: 'user/getSiteLang'
    }),
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    },
    // TODO: dont repeat yourself
    weekDays() {
      const formatteEnglishdWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      const formattedDeutchWeekDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
      return this.isDeutchLang ? formattedDeutchWeekDays : formatteEnglishdWeekDays
    },
    minMonth() {
      return this.today.format('MMMM')
    },
    maxMonth() {
      return this.today
        .clone()
        .add(1, 'month')
        .format('MMMM')
    },
    lastDayOfMaxMonth() {
      return this.today
        .clone()
        .set({ hour: 1, minute: 0, second: 0, millisecond: 100 })
        .add(1, 'months')
        .endOf('month')
    }
  },
  watch: {
    choosenDate(newVal) {
      this.manageWeekDatesFullCal(newVal)
    },
    getChoosenDate(newVal) {
      this.choosenDate = newVal
    }
  },
  created() {
    this.choosenDate = this.getChoosenDate
    //  this.manageWeekDates(this.today, 'currentWeek')

    this.manageWeekDates(this.monday, 'currentWeek')
    if (this.getChoosenDate) {
      let choosen = moment(this.getChoosenDate)
      if (this.today.diff(choosen, 'days') > 0) {
        this.clearChoosenDate()
        this.clearDate()
      }
    }
  },
  methods: {
    ...mapActions('haircutParams', {
      setCommonHaircutParams: 'setCommonHaircutParams'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapMutations('haircutParams', {
      setDate: 'SET_DATE',
      clearChoosenDate: 'CLEAR_CHOOSEN_DATE',
      clearDate: 'CLEAR_DATE'
    }),
    calendarMonth() {
      let date = this.monthDaysContext.locale(this.getSiteLang)
      return moment(date)
        .clone()
        .add(3, 'days')
        .format('MMMM')
    },
    calendarYear() {
      return this.monthDaysContext.format('Y')
    },
    isFirstWeek() {
      const { today, monthDaysContext } = this
      const previousWeek = monthDaysContext.clone().subtract(0, 'week')
      const previousWeekDifferenceFromToday = previousWeek.diff(today, 'days')
      if (previousWeekDifferenceFromToday <= 0) {
        return true
      } else {
        return false
      }
    },
    isChoosenDate(day) {
      return day.isSame(this.choosenDate, 'day')
    },
    isFutureDay(day) {
      return this.today.diff(day, 'days') < 0 ? true : false
    },
    isDisabledDay(day) {
      let current = this.today.clone().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      let maxDate = this.lastDayOfMaxMonth.clone().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })

      let date = day.clone().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })

      let previews = current.diff(date, 'minutes') > 0
      let next = maxDate.diff(date, 'minutes') < 0

      return (!previews || next) && (previews || !next)
    },
    isCurrentDay(day) {
      return this.today.diff(day, 'days') === 0
    },
    isInactiveDay(day) {
      if (day.diff(this.lastDayOfMaxMonth, 'minutes') >= 0) {
        this.isInactiveNextWeekSwitch = true
        return true
      }

      return false
    },
    manageWeekDates(dateContext, actionType, amountOfWeeks) {
      if (amountOfWeeks && Object.keys(amountOfWeeks).length) {
        if (String(amountOfWeeks.toElement.classList).includes('inactive')) {
          return
        }
      }
      let dates = []
      let firstWeekDay = null
      const nextWeek = dateContext.clone().add(1, 'week')
      switch (actionType) {
        case 'nextWeek':
          if (this.lastDayOfMaxMonth.diff(nextWeek, 'minutes') >= 0) {
            firstWeekDay = dateContext.add(1, 'week')
          } else {
            return
          }
          break
        case 'previousWeek':
          if (!this.monday.isSame(dateContext, 'day')) {
            firstWeekDay = dateContext.subtract(1, 'week')
            this.isInactiveNextWeekSwitch = false
          } else {
            return
          }
          break
        case 'currentWeek':
          firstWeekDay = dateContext
          break
        case 'severalWeeksForward':
          firstWeekDay = dateContext.add(amountOfWeeks, 'weeks')
          break
        case 'severalWeeksBack':
          firstWeekDay = dateContext.subtract(amountOfWeeks, 'weeks')
      }
      while (dates.length !== 7) {
        dates.push(firstWeekDay.clone())
        firstWeekDay.add(1, 'd')
      }
      firstWeekDay.subtract(1, 'week')
      this.days = dates
    },
    manageWeekDatesFullCal(dateValue) {
      const datesDifference = moment(dateValue).diff(this.monthDaysContext, 'days')
      if (moment(dateValue).diff(this.today, 'days') <= 0) return
      if (datesDifference > 6) {
        const weeksToAdd = Math.floor(datesDifference / 7)
        this.manageWeekDates(this.monthDaysContext, 'severalWeeksForward', weeksToAdd)
      } else if (datesDifference < 0) {
        let weeksToSubtract = Math.floor(Math.abs(datesDifference) / 7)
        const daysLeft = Math.abs(datesDifference) % 7
        weeksToSubtract = daysLeft >= 1 ? weeksToSubtract + 1 : weeksToSubtract
        this.manageWeekDates(this.monthDaysContext, 'severalWeeksBack', weeksToSubtract)
      }
      const dateParam = { paramName: 'date', value: this.choosenDate }
      this.setCommonHaircutParams(dateParam)
    },
    chooseDate(value) {
      if (!this.isDisabledDay(value)) return
      else {
        this.choosenDate = value._d
        const dateParam = { paramName: 'date', value: value.toDate() }
        this.setCommonHaircutParams(dateParam)
        this.setDate(this.choosenDate)
      }
    },
    getMonday(d) {
      d = new Date(d)
      let day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    }
  }
}
</script>
<style lang="scss">
.future-day {
  color: black;
}
@media screen and (min-width: 768px) {
  .vc-popover-content-wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
</style>
