<template>
  <div class="col-12 col-lg px-0" :class="{ 'col-md': $route.name === 'salon-page' }">
    <b-button-group class="nav nav-tabs justify-content-center">
      <b-button
        variant="link"
        :class="['nav-link d-flex justify-content-center', parameters.includes(1) ? 'active' : '']"
        @click.prevent="setSelectedParameters([1])"
      >
        <i class="lady-icon mr-md-1 mr-0-5"></i>
        <span class="tab-text">{{ $t('common.tab.ladies') }}</span>
      </b-button>
      <b-button
        variant="link"
        :class="['nav-link d-flex justify-content-center', parameters.includes(2) ? 'active' : '']"
        @click.prevent="setSelectedParameters([2])"
      >
        <i class="gent-icon mr-md-1 mr-0-5"></i>
        <span class="tab-text">{{ $t('common.tab.gents') }}</span>
      </b-button>
      <b-button
        variant="link"
        :class="['nav-link d-flex justify-content-center', parameters.includes(3) ? 'active' : '']"
        @click.prevent="setSelectedParameters([3, 4])"
      >
        <i class="child-icon mr-md-1 mr-0-5"></i>
        <span class="tab-text">{{ $t('common.tab.child') }}</span>
      </b-button>
    </b-button-group>
    <div v-if="reviewsList.data.length > 0" class="reviews-list mt-3">
      <Reviews :get-reviews-data="reviewsList.data" :review-size-value="'col-12'" />
      <div class="row mt-3">
        <pagination :data="reviewsList" :show-disabled="true" @pagination-change-page="getReviews">
          <span slot="prev-nav">
            <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
          </span>
          <span slot="next-nav">
            <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
          </span>
        </pagination>
      </div>
    </div>
    <div v-else class="mt-5">
      <i class="rating-icon mt-2"></i>
      <h5 class="mt-2 pt-1 pb-2 ml-50 text-center">{{ $t('common.card.empty_service_text') }}</h5>
    </div>
  </div>
</template>
<script>
import Reviews from '@/components/salon/Reviews'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReviewsTabs',
  components: { Reviews },
  props: ['type', 'staffId', 'salonId'],
  data() {
    return {
      parameters: [1]
    }
  },
  computed: {
    ...mapGetters({
      salonReviews: 'salons/getSalonReviews',
      staffReviews: 'salons/getStaffReviews',
      salon: 'salons/getSalon'
    }),
    reviewsList() {
      return this.type === 'staff' ? this.staffReviews : this.salonReviews
    }
  },
  watch: {
    staffId: {
      handler(newVal) {
        this.setSelectedParameters([1])
      }
    }
  },
  mounted() {
    this.setSelectedParameters([1])
  },
  methods: {
    ...mapActions({
      fetchSalonReviews: 'salons/getSalonReviews',
      fetchStaffReviews: 'salons/getStaffReviews'
    }),
    setSelectedParameters(parameters) {
      this.parameters = parameters
      this.getReviews()
    },
    getReviews(page = 1) {
      let params = {
        id: this.salonId,
        parameters: this.parameters,
        page: page
      }

      if (this.staffId) {
        params.staff_id = this.staffId
        params.id = this.staffId

        this.fetchStaffReviews(params)
      } else {
        this.fetchSalonReviews(params)
      }
    }
  }
}
</script>
<style scoped></style>
