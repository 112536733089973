<template>
  <div :class="serviceCategoryHaircutParams ? 'col-3' : 'col-6'" class="px-0">
    <slot v-if="serviceCategoryHaircutParams">
      <OptionsWithLength :kids-combination="kidsCombination" />
    </slot>
    <slot v-else>
      <OptionsWithoutLength :kids-combination="kidsCombination" />
    </slot>
  </div>
</template>
<script>
import OptionsWithLength from './OptionsWithLength'
import OptionsWithoutLength from './OptionsWithoutLength'
export default {
  components: {
    OptionsWithLength,
    OptionsWithoutLength
  },
  props: {
    kidsCombination: {
      type: Array,
      default: () => []
    },
    serviceCategoryHaircutParams: {
      type: Number,
      default: null
    }
  },
  computed: {}
}
</script>
