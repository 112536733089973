<template>
  <main class="profile-main-block">
    <div class="profile-page navbar-offset">
      <div class="container">
        <div class="row profile-main-info">
          <div class="col-12 px-0 pt-3 d-none d-md-block d-lg-none">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <div class="profile-img profileTabletImageBlock mb-2 col-auto p-0">
                  <img :src="userAvatar" class="image--cover w-100 h-100" alt />
                </div>
                <div class="profile-block col px-lg-0 ml-1">
                  <div>
                    <h3 class="name text-truncate mb-0-5" :title="fullName">{{ fullName }}</h3>
                    <p class=" text-truncate mb-0-5" :title="accountInfo.email">{{ accountInfo.email }}</p>
                  </div>
                  <div class="d-flex">
                    <router-link :to="'/profile-page/edit'" class="btn btn-outline-secondary-custom btn-settings">
                      <font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-4 mr-0-2 mt-3">
                <button
                  v-b-modal.quick-booking-modal
                  class="btn btn-lg btn-primary w-100 mt-auto py-1-5 mb-0 quick-btn"
                >
                  <p class="h6 font-weight-medium mb-0 text-white text-nowrap">
                    {{ $t('common.forms.button.quick_book') }}
                  </p>
                </button>
              </div>
            </div>
          </div>
          <div
            id="v-pills-tab"
            class="d-none d-md-block d-lg-none col-3 nav flex-column nav-pills pb-3 mx-n1 mx-md-n0"
            role="tablist"
            aria-orientation="vertical"
          >
            <div class="pages mt-0-5 mb-0-5 justify-content-around justify-content-md-center">
              <router-link
                v-for="(page, key) in userProfilePages"
                :key="`${key}-page`"
                :to="'/profile-page/' + page.path"
                :class="'nav-link py-1-5 ml-0 ml-md-1'"
                active-class="linkActive pl-1"
              >
                <div class="d-flex align-items-center">
                  {{ $t(page.i18KeyName) }}
                </div>
              </router-link>
            </div>
          </div>
          <div class="d-block d-md-none d-lg-block col-lg-3 col-md-2 offset-xl-1 pt-md-7 pt-2">
            <div class="col text-block px-0">
              <div>
                <div class="profile-info-block">
                  <div class="profile-img mb-2 col-auto p-0">
                    <img :src="userAvatar" class="image--cover w-100 h-100" alt />
                  </div>
                  <div class="profile-block col px-lg-0">
                    <div>
                      <h3 class="name text-truncate mb-0-5" :title="fullName">{{ fullName }}</h3>
                      <p class="mb-2 text-truncate" :title="accountInfo.email">{{ accountInfo.email }}</p>
                    </div>
                    <div class="d-flex mb-3 mb-md-6">
                      <div class="btn btn-primary mr-md-1-5 mr-0-8 d-none">
                        <font-awesome-icon :icon="['fas', 'coins']" class="mr-1"></font-awesome-icon>
                        $0.00
                      </div>

                      <router-link :to="'/profile-page/edit'" class="btn btn-outline-secondary-custom btn-settings">
                        <font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon>
                      </router-link>
                    </div>
                  </div>
                </div>
                <button
                  v-b-modal.quick-booking-modal
                  class="d-block d-md-none btn btn-lg btn-primary w-100 mt-auto mb-0 quick-btn"
                >
                  <p class="h6 font-weight-medium mb-0 text-white">
                    {{ $t('common.forms.button.quick_book') }}
                  </p>
                </button>
                <div
                  id="v-pills-tab"
                  class="nav flex-column nav-pills pb-3 mx-n1 mx-md-n0"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div class="pages mt-0-5 mb-0-5 justify-content-around justify-content-md-center">
                    <router-link
                      v-for="(page, key) in userProfilePages"
                      :key="`${key}-page`"
                      :to="'/profile-page/' + page.path"
                      :class="'nav-link py-1-5 ml-0 ml-md-1'"
                      active-class="linkActive pl-1"
                    >
                      <div class="d-flex align-items-center">
                        {{ $t(page.i18KeyName) }}
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <button
              v-b-modal.quick-booking-modal
              class="d-none d-md-block d-xl-block btn btn-lg btn-primary w-100 mt-auto mb-0 quick-btn"
            >
              <p class="h6 font-weight-medium mb-0 text-white">
                {{ $t('common.forms.button.quick_book') }}
              </p>
            </button>
          </div>
          <div
            class="profile-tabs d-flex col-xxl-6 col-xl-8 col-lg-9 col-md mx-auto py-lg-6 py-1 pl-1 pl-md-2 pl-lg-1 main-tab-data custom-scroll"
          >
            <router-view></router-view>
          </div>
        </div>
      </div>
      <b-modal
        id="quick-booking-modal"
        header-class="modal-header pt-md-3-5 pt-0 px-md-3 px-0 pb-0 mt-md-1 mt-1-5 border-bottom-0"
        body-class="px-md-3 px-1 pb-0 booking-modal pt-0"
        centered
      >
        <template v-slot:modal-header="{ close }">
          <div class="top-block d-block d-md-none w-100">
            <div class="d-flex justify-content-between">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-mobile ml-1-5"
                @click="close()"
              ></font-awesome-icon>

              <h4 class="pl-2">
                {{ $t('common.forms.button.quick_book') }}
              </h4>

              <p class="collapsed change-pass-btn mr-1-5" @click="clearChoosenData">
                {{ $t('common.forms.button.clear_all') }}
              </p>
            </div>
          </div>
          <button type="button" class="close position-absolute d-none d-md-block" @click="close()">
            <span aria-hidden="true" class="close-icon"></span>
          </button>
        </template>
        <div class="w-100 mb-2 d-none d-md-block">
          <h5 id="createTemplateLabel" class="font-weight-medium centered-block">
            {{ $t('common.forms.button.quick_book') }}
          </h5>

          <p class="w-100 d-none d-md-block text-center">
            {{ $t('common.modal.book_subtitle') }}
          </p>
        </div>

        <div class="row mt-1">
          <div class="col-12 mb-1-5">
            <GenderOptions
              v-if="storageReady && areParamsLoaded"
              :adult-options="adultGenderOptions"
              :child-options="childGenderOptions"
              :haircut-params="haircutParams"
              :hide-child-label="true"
              :centered-icons="true"
            />

            <p class="group-label font-weight-medium mb-1">
              {{ $t('common.forms.label.select_service') }}
            </p>
            <Services :services-display-limit="1"> </Services>
            <HairLengthSelect v-if="isHairLengthVisible" :options="hairLengthOptions" :params="haircutParams" />
          </div>
          <div class="col-12 mb-1-5">
            <p class="group-label font-weight-medium mb-1">
              {{ $t('common.forms.label.select_day') }}
            </p>
            <Calendar></Calendar>
          </div>
        </div>
        <div class="mb-3 mb-md-5 mt-1">
          <div class="d-flex">
            <p class="ml-md-0 ml-0-3 mb-0-8 pt-0-2 inputFont">{{ $t('common.forms.label.choose_location') }}</p>
            <p v-b-modal.avaliable-salons-modal class="mb-0 pt-0-2" @click="setIconActiveClass">
              <font-awesome-icon
                class="fa-w-16 ml-0-5 tooltipInfoIcon icon-tooltip"
                style="font-size:16px;"
                data-toggle="tooltip"
                :icon="['fas', 'info-circle']"
              ></font-awesome-icon>
            </p>
          </div>
          <div id="multiselect" tabindex="-1" class="multiselect has-icon map">
            <div class="multiselect__actions d-flex flex-wrap align-items-center">
              <font-awesome-icon :icon="['far', 'search']" class="icon-search"></font-awesome-icon>
              <span class="icon-sep mx-1"></span>
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close"
                @click="clearChoosenPlace"
              ></font-awesome-icon>
            </div>
            <div class="multiselect__tags locationInput" style="padding-top: 5px">
              <div class="multiselect__tags-wrap" style="display: none;"></div>
              <gmap-autocomplete
                ref="gmap-autocomplete"
                class="g-autocomplete"
                style="font-size:16px;"
                :options="gmapAutoCompleteOptions"
                @keypress.enter="$event.preventDefault()"
                @place_changed="setPlace"
              >
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div class="row col-12 pr-0 mb-2-5 mt-2 mt-md-0">
          <div class="col-12 text-left pl-0 mb-1">
            <p class="group-label font-weight-medium mb-1">
              {{ choosenPlace ? $t('common.forms.label.select_radius') : $t('common.forms.label.choose_radius') }}
            </p>
          </div>
          <div class="col-12 col-md-5 d-flex rangeValueBlock px-0">
            <button href="#" :disabled="!choosenPlace" class="changeRadiusValueButton" @click="takeAwayRadiusValue">
              -
            </button>

            <span :v-model="radius" class="valueSpan">{{ radius }} km</span>
            <button class="changeRadiusValueButton" :disabled="!choosenPlace" @click.prevent="addRadiusValue">
              +
            </button>
          </div>

          <div class="col d-flex align-items-center pr-0 pl-md-2 px-0 pr-md-0 mt-2 mt-md-0 collapsed">
            <span class="text-small text-gray pr-1 pr-md-0">0</span>
            <VueSlider
              v-model.number="radius"
              :disabled="!choosenPlace"
              v-bind="options"
              class="col px-1-5"
            ></VueSlider>

            <span class="text-small text-gray">20</span>
          </div>
        </div>
        <template v-slot:modal-footer="">
          <button
            class="btn btn-lg btn-primary w-100 mt-auto mb-2"
            :disabled="!choosenPlace || radius === 0"
            @click="searchForSalons"
          >
            <p class="h6 font-weight-medium mb-0 text-white">
              {{ $t('common.forms.button.start_search_short') }}
            </p>
          </button>
        </template>
      </b-modal>
      <AvaliableSalons />
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AvaliableSalons from '@/components/AvaliableSalons'
import Services from '@/components/Services'
import Calendar from '@/components/Calendar'
import GenderOptions from '@/components/GenderOptions'
import HairLengthSelect from '@/components/HairLengthSelect'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import moment from 'moment'

export default {
  name: 'UserProfilePage',
  components: {
    Services,
    Calendar,
    VueSlider,
    GenderOptions,
    HairLengthSelect,
    AvaliableSalons
  },
  data() {
    return {
      userProfilePages: [
        {
          i18KeyName: 'common.profile_menu.link.profile_templates',
          path: 'templates'
        },
        {
          i18KeyName: 'common.profile_menu.link.history',
          path: 'history'
        },
        {
          i18KeyName: 'common.profile_menu.link.profile_favorites',
          path: 'favorites'
        }
      ],
      gmapAutoCompleteOptions: {
        fields: ['geometry', 'formatted_address', 'name'],
        componentRestrictions: {
          country: 'ch'
        }
      },
      choosenPlace: null,
      radius: 0,
      options: {
        min: 0,
        max: 20,
        interval: 1,
        height: 6,
        tooltip: 'none'
      },
      haircutParams: {
        gender: {},
        date: null,
        length: {},
        age: {}
      },
      areParamsLoaded: false,
      fullName: null
    }
  },
  computed: {
    ...mapGetters('user', {
      accountInfo: 'getAccountInfo',
      userAvatar: 'getUserAvatar',
      mainTemplate: 'getMainTemplate',
      userPosition: 'getUserPosition'
    }),
    ...mapGetters({
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      formattedHaircutData: 'haircutParams/getFormattedHaircutData',
      getChoosenServices: 'haircutParams/getChoosenServices',
      getParametersKeysNameByApiKey: 'haircutParams/getParametersKeysNameByApiKey',
      parametersValues: 'haircutParams/getParametersValues',
      getChoosenDate: 'haircutParams/getChoosenDate'
    }),
    storageReady() {
      return this.adultGenderOptions.length && this.childGenderOptions.length && this.hairLengthOptions.length
    },
    adultGenderOptions() {
      return [this.parametersValues.genders.values[0], this.parametersValues.genders.values[1]]
    },
    childGenderOptions() {
      return [this.parametersValues.genders.values[2], this.parametersValues.genders.values[3]]
    },
    hairLengthOptions() {
      return this.parametersValues.hairLength.values
    },
    isHairLengthVisible() {
      return this.getChoosenServices.length && this.getChoosenServicesHaircutParam() === 1 ? true : false
    }
  },
  watch: {
    mainTemplate: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          const formattedData = []

          if (newVal.gender_parameter) {
            formattedData.push(newVal.gender_parameter)
          }

          if (newVal.hair_length_parameter) {
            formattedData.push(newVal.hair_length_parameter)
          }

          if (newVal.age_parameter) {
            formattedData.push(newVal.age_parameter)
          }

          this.setMainTemplateParameters(formattedData)
          this.getParamsValues().then(data => {
            let params = this.collectParams(data)
            if (params) {
              Object.assign(this.haircutParams, params)
            }
          })
        } else this.setMainTemplateParameters(new Array())
      }
    },
    accountInfo: {
      deep: true,
      handler(newVal) {
        this.fullName = newVal.first_name + ' ' + newVal.last_name
      }
    }
  },
  mounted() {
    this.fullName = this.accountInfo.first_name + ' ' + this.accountInfo.last_name
    if (window.innerWidth < 767) {
      this.options.height = 9
      let dot = document.querySelectorAll('.vue-slider-dot')
      dot.forEach(el => {
        el.style.width = '24px'
        el.style.height = '24px'
      })
    }
    this.getParamsValues().then(data => {
      this.areParamsLoaded = true
      let params = this.collectParams(data)
      if (params) {
        Object.assign(this.haircutParams, params)
        if ((!params.age && params.age === undefined) || params.age === null) {
          this.setAllCommonHaicutParams(params)
        } else {
          this.setAllCommonHaicutParams(params)
          this.setChildHaircutParams(params.age)
        }
      } else this.haircutParams.gender = this.allSelectedHaircutParams.gender
      this.haircutParams.length = this.allSelectedHaircutParams.length
      if (this.allSelectedHaircutParams.age) this.haircutParams.age = this.allSelectedHaircutParams.age
    })
  },
  methods: {
    ...mapActions('salons', {
      getSalons: 'getSalons',
      clearCurrentPlace: 'clearCurrentPlace',
      setSearchRadius: 'setSearchRadius',
      setCurrentPlace: 'setCurrentPlace'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapActions('haircutParams', {
      clearChoosenDate: 'clearChoosenDate',
      getParamsValues: 'getParamsValues'
    }),
    ...mapMutations({
      setMainTemplateParameters: 'haircutParams/SET_MAIN_TEMPLATE_PARAMETERS',
      clearChoosenServices: 'haircutParams/CLEAR_CHOOSEN_SERVICES',
      setAllCommonHaicutParams: 'haircutParams/SET_ALL_COMMON_HAIRCUT_PARAMS',
      setChildHaircutParams: 'haircutParams/SET_CHILD_HAIRCUT_PARAMS'
    }),
    isHaircutParamsEmpty(obj) {
      for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key].length === 0) {
          return true
        }
      }
      return false
    },
    setIconActiveClass() {
      this.$bvModal.show('avaliable-salons-modal')
      const icon = document.querySelector('.icon-tooltip')
      if (icon) icon.classList.add('tooltipInfoIconActive')
    },
    searchForSalons() {
      let errorInfo = {
        type: this.$t('common.forms.alert.empty_fields_type'),
        messageI18Key: 'common.forms.alert.empty_fields_message'
      }
      const coords = {
        latitude: this.userPosition.lat,
        longitude: this.userPosition.lng,
        radius: 5000
      }
      let formattedBookDate = moment(this.getChoosenDate).format('Y-MM-DD')

      this.setSearchRadius(+this.radius)
      this.setCurrentPlace(this.choosenPlace)
      const searchSalonsData = Object.assign({}, coords, this.formattedHaircutData, {
        book_date: formattedBookDate
      })
      if (this.isHaircutParamsEmpty(searchSalonsData) === false) {
        this.$router.push('/salons')
      } else {
        this.$router.push('/')
        this.setError(errorInfo)
      }
    },
    clearChoosenData() {
      this.clearChoosenServices()
      this.clearChoosenDate()
    },
    clearChoosenPlace() {
      this.clearCurrentPlace()
      this.choosenPlace = null
      this.radius = 1
      this.$refs['gmap-autocomplete'].$el.value = ''
    },
    setPlace(place) {
      this.choosenPlace = place
    },
    takeAwayRadiusValue() {
      if (this.radius !== 1) this.radius = this.radius - 0.5
    },
    addRadiusValue() {
      if (this.radius < 20) this.radius = this.radius + 0.5
    },
    collectParams(params) {
      if (this.mainTemplate === undefined) return

      const { gender_parameter, hair_length_parameter, age_parameter } = this.mainTemplate
      const mainTemplateParams = Array.from([gender_parameter, hair_length_parameter, age_parameter])
      let commonParamsData = {}
      for (let key in params) {
        let i = Object.keys(params).indexOf(key)
        let paramsLength = params.hairLength.values.find(param => param.id === mainTemplateParams[i])
        let paramsAge = params.age.values.find(param => param.id === mainTemplateParams[i])
        let paramsData = params[key].values.find(param => param.id === mainTemplateParams[i])
        if (paramsData !== undefined) {
          commonParamsData[this.getParametersKeysNameByApiKey(key)] = paramsData
        } else if (paramsLength !== undefined) commonParamsData.length = paramsLength
        else if (paramsAge) commonParamsData.age = paramsAge
      }
      return commonParamsData
    },
    getChoosenServicesHaircutParam() {
      if (!this.getChoosenServices.length) return
      else {
        return this.getChoosenServices[0].service_category
          ? this.getChoosenServices[0].service_category.haircut_params
          : this.getChoosenServices[0].haircut_params
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn-settings {
  position: absolute;
}
.btn-primary {
  box-shadow: 0 5px 15px 0 rgba(255, 107, 9, 0.3);
}

.profile-main-info {
  .name {
    font-size: 24px;
  }
}
.centered-block {
  margin-left: 33% !important;
}
.pages a {
  padding: 0.5rem 0;
}
.nav-pills a.nav-link.active {
  padding-top: 1rem;
  padding-bottom: 100px;
  color: orange;
}
.top-block {
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 2px #e0e0e0;
}
.linkActive {
  border-left: 2px solid#ff6b09;
  border-radius: 0rem !important;
  color: black !important;
}
.rangeValueBlock {
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
}
.changeRadiusValueButton {
  outline: none !important;
  text-decoration: none;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: none;
  border-right: solid 1px #e9efed;
  background-color: #fbfbfb;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #8d9fa7;
  padding-left: 20px;
  padding-top: 1px;
  padding-right: 30px;
}
.changeRadiusValueButton:last-child {
  padding-left: 18px;
  border-right: none !important;
  border-left: solid 1px #e9efed;
}
.valueSpan {
  width: 4rem;
  padding-top: 0.7rem;
  text-align: center;
  font-family: Roboto;
}
.rangeValueBlock {
  justify-content: space-between;
}
.locationInput {
  padding-top: 3px !important;
  height: 42px !important;
}
.changeRadiusValueButton {
  width: 42px !important;
  height: 42px !important;
}
.profileTabletImageBlock {
  height: 100px !important;
  width: 100px !important;
}
</style>
