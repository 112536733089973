<template>
  <div v-if="getReviewsData.length">
    <div
      v-for="(person, key) in getReviewsData"
      :key="`person${key}`"
      class="review row pt-2 border-bottom-md underLine"
    >
      <div class="col-12 px-0-3">
        <div class="card border-0">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <img
                class="staff-image img-cover"
                :src="clientAvatar(person)"
                :alt="`${person.client_first_name} ${person.client_last_name}`"
              />
            </div>
            <div class="col pl-1-5">
              <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
                <div class="d-block">
                  <h6 class="card-title mb-0-5 wordWrap font-weight-medium">
                    {{ person.client_first_name }}
                    {{ person.client_last_name }}
                  </h6>
                  <p class="mb-0">
                    {{ person.salon_staff_first_name }}
                    {{ person.salon_staff_last_name }}
                  </p>
                </div>

                <div class="d-flex d-md-block">
                  <p class="card-text text-gray mb-0 mb-md-0-5 order-md-first order-last salonCardDate text-right">
                    <small class="ml-0-5">{{ person.created_at | moment }}</small>
                  </p>
                  <span class="rating-block order-md-last order-first">
                    <FaRating
                      :glyph="iconStar"
                      :border-width="0"
                      :active-color="'#ff6b09'"
                      :inactive-color="'#FFE1CD'"
                      :show-rating="false"
                      :item-size="starSizeValue"
                      :read-only="true"
                      :increment="0.01"
                      :rating="calculateClientReviewRate(person)"
                      :max-rating="5"
                      :spacing="3"
                    ></FaRating>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="reviewSizeValue" class="mt-1-5">
        <TextLoader :data-descr="person.description" />
      </div>
    </div>
  </div>
</template>
<script>
import TextLoader from './TextLoader'
import { FaRating } from 'vue-rate-it'
import Star from 'vue-rate-it/glyphs/star'
import moment from 'moment'

export default {
  components: {
    TextLoader,
    FaRating
  },
  filters: {
    moment: function(date) {
      return moment(date).format('ll')
    }
  },
  props: {
    getReviewsData: {
      type: Array,
      default: () => []
    },
    starSizeValue: {
      type: Number,
      default: 18
    },
    reviewSizeValue: {
      type: String,
      default: () => 'col-md col-12 mt-0 mt-md-1'
    }
  },
  data() {
    return {
      iconStar: ''
    }
  },
  created() {
    this.iconStar = Star
  },
  methods: {
    clientAvatar(person) {
      return person.client_avatar ? person.client_avatar : require('@/assets/images/default/default_avatar.png')
    },
    calculateClientReviewRate(person) {
      if (person.evaluation_ratings.length === 0) {
        return 0
      }
      return person.evaluation_ratings[0].value
    }
  }
}
</script>
<style scoped>
.wordWrap {
  white-space: nowrap;
}
.cardStyle {
  bottom: 1rem;
  left: 0rem;
}
.cardHeight {
  height: 5.2rem;
}
.underLine {
  border-bottom: 2px solid #f1f1f1;
}
.review:last-child {
  border-bottom: none !important;
}
.reviewImage {
  width: 74px;
  height: 74px;
}
</style>
