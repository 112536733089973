<template>
  <div class="signup-page">
    <div class="row no-gutters vh-100-lg desktop-sign-in-bg">
      <AuthSlider class="sale-offset" />

      <div class="col-md-12 col-lg col-xxl-6">
        <div class="row no-gutters h-100 px-1-5">
          <div
            class="col-12 mb-3 md-lg-0 mt-3 d-lg-flex flex-wrap align-items-center justify-content-between align-self-start px-lg-3 d-none d-lg-block sale-offset"
          >
            <a :href="salonLoginLink" :title="$t('signin.content.salon_login_link')">
              {{ $t('signin.content.salon_login_link') }}
            </a>
            <p class="mb-0 text-center">
              <span class="text-secondary">{{ $t('signin.content.signup_link_text') }}</span>
              <router-link to="/sign-up" class="ml-1" :title="$t('signin.content.signup_link')">
                {{ $t('signin.content.signup_link') }}
              </router-link>
              <LanguageDropdown :class="'pl-1-5'"></LanguageDropdown>
            </p>
          </div>

          <div class="col-12 col-md-8 col-lg-12 signup-form-bg col-xl-8 mx-auto py-3">
            <div class="my-lg-auto my-lg-2-5 mx-lg-0 mx-auto w-100">
              <div class="d-lg-none mb-1-5">
                <a :href="salonLoginLink" :title="$t('signin.content.salon_login_link')">
                  {{ $t('signin.content.salon_login_link') }}
                </a>
              </div>

              <div class="text-center mb-2 mb-lg-3">
                <h1 class="h2 mb-2">
                  {{ $t('signin.content.title') }}
                </h1>
                <p>{{ $t('signin.content.subtitle') }}</p>

                <p class="mb-0 text-center d-block d-lg-none">
                  <span class="text-secondary">{{ $t('signin.content.signup_link_text') }}</span>
                  <router-link to="/sign-up" class="ml-1" :title="$t('signin.content.signup_link')">
                    {{ $t('signin.content.signup_link') }}
                  </router-link>
                  <LanguageDropdown :class="'ml-0-5'" :btn-class="'py-1 px-0-5'"></LanguageDropdown>
                </p>
              </div>

              <div class="row mt-2-5">
                <div class="col-6">
                  <button class="btn--google font-weight-medium w-100" type="button" @click="signIn('google')">
                    <i class="google_icon mr-1"></i>
                    {{ $t('common.forms.button.google') }}
                  </button>
                </div>

                <div class="col-6">
                  <fb-signin-button
                    class="fb-signin-button font-weight-medium"
                    :params="fbSignInParams"
                    @success="onFacebookAuthSuccess"
                    @error="onFacebookAuthError"
                  >
                    <font-awesome-icon :icon="['fab', 'facebook-f']" class="mr-1"></font-awesome-icon>
                    {{ $t('common.forms.button.facebook') }}
                  </fb-signin-button>
                </div>
              </div>

              <div class="mt-2-5 d-flex mb-2-5">
                <div class="border-line col mb-1"></div>
                <span class="mx-3 text-gray"> {{ $t('common.forms.label.or') }}</span>
                <div class="border-line col mb-1"></div>
              </div>

              <form class="form-row no-gutters" @submit.prevent="signIn('manual')">
                <div class="col-12">
                  <label for="email" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(emailField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="email"
                      v-model="emailField.value"
                      v-validate="emailField.validateRules"
                      :data-vv-as="$t(emailField.i18KeyLabel)"
                      :type="emailField.type"
                      :name="emailField.name"
                      class="form-control w-100 px-1-5"
                      :placeholder="$t(emailField.i18KeyPlaceholder)"
                    />
                    <span v-if="errors.has(emailField.name) && getSiteLang === 'en'" class="error-message mt-1">{{
                      errors.first(emailField.name)
                    }}</span>
                    <span v-if="errors.has(emailField.name) && getSiteLang === 'de'" class="error-message mt-1">
                      Email ist ungültig
                    </span>
                  </div>
                </div>

                <div class="col-12">
                  <label for="password" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(passwordField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="password"
                      v-model="passwordField.value"
                      v-validate="passwordField.validateRules"
                      :data-vv-as="$t(passwordField.i18KeyLabel)"
                      :name="passwordField.name"
                      :type="passwordField.type"
                      class="form-control px-1-5 border-right-0"
                      :placeholder="$t(passwordField.i18KeyPlaceholder)"
                    />
                    <div class="input-group-append" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-1 border-left-0 cursor-pointer">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                    <span v-if="errors.has(passwordField.name)" class="error-message mt-1">{{
                      errors.first(passwordField.name)
                    }}</span>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group mb-2-5 px-0 form-check">
                    <div class="checkbox">
                      <label class="mb-0">
                        <input type="checkbox" />
                        <i class="input-helper"></i>
                        <div class="text-black text-nowrap">
                          {{ $t('signin.content.rememmber_me') }}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col text-right">
                  <router-link to="/reset-password-request">
                    {{ $t('signin.content.forgot_password_link') }}
                  </router-link>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block btn-lg">
                    {{ $t('signin.content.sign_in_link') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <VerificationPopup />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import AuthSlider from '../../components/AuthSlider'
import { mapValues, keyBy, values } from 'lodash'
import LanguageDropdown from '@/components/LanguageDropdown'
import AuthorizationRedirect from '@/_mixins/AuthorizationRedirect'
import VerificationPopup from '@/views/verification/VerificationPopup'
import config from 'config'

export default {
  name: 'AuthPage',
  components: {
    AuthSlider,
    LanguageDropdown,
    VerificationPopup
  },
  mixins: [AuthorizationRedirect],
  data() {
    return {
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      emailField: {
        i18KeyLabel: 'common.forms.label.auth.email',
        name: 'email',
        type: 'email',
        value: '',
        validateRules: 'required|email|min:5',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.email'
      },
      passwordField: {
        i18KeyLabel: 'common.forms.label.auth.password',
        name: 'password',
        type: 'password',
        value: '',
        validateRules: 'required|min:8|max:16',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.password'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo',
      getSiteLang: 'user/getSiteLang'
    }),
    signInRequiredFields() {
      return [this.emailField, this.passwordField]
    },
    passwordIcon() {
      return this.passwordField.type === 'password' ? 'eye' : 'eye-slash'
    },
    salonAppUrl() {
      return config.salonAppUrl
    },
    salonLoginLink() {
      return `${this.salonAppUrl}/sign-in`
    }
  },
  mounted() {
    if (!this.isRedirectedFromBooking) {
      this.resetUserState()
      this.resetUserMessageState()
      this.resetShopState()
      this.resetHaircutrState()
    }
  },
  methods: {
    ...mapActions('user', {
      register: 'register',
      login: 'login',
      getAccountInfo: 'getAccountInfo',
      socialLogin: 'socialLogin',
      setRedirectAuthFlag: 'setRedirectAuthFlag',
      setAuthStatus: 'setAuthStatus'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
      resetUserMessageState: 'userMessages/RESET_STATE',
      resetShopState: 'shop/RESET_STATE',
      resetSalonsState: 'salons/RESET_STATE',
      resetHaircutrState: 'haircutParams/RESET_STATE'
    }),
    signIn(type) {
      if (type === 'manual') {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.manualSignIn()
          }
        })
      } else if (type === 'google') {
        this.googleSignIn()
      }
    },
    onFacebookAuthSuccess() {
      // eslint-disable-next-line no-undef
      FB.api('/me?fields=id,name,email', res => {
        const authPayload = {
          email: res.email,
          source: 'facebook',
          source_id: res.id
        }

        if (authPayload.email !== undefined && authPayload.email !== null) {
          this.socialLogin(authPayload)
            .then(() => {
              this.getAccountInfo()
                .then(() => {
                  this.setAuthStatus({ isAuthorized: true, socialAuthType: 'facebook' })
                  this.authorizationRedirect()
                })
                .catch(errors => {
                  if (errors.success === false) {
                    this.$validator.errors.add({
                      field: 'email',
                      msg: this.$t('signup.user-messages.wrong_email_or_password')
                    })
                  }
                })
            })
            .catch(error => {
              let messageI18Key = ''

              if (error.data === null) {
                messageI18Key = 'signin.user-messages.social_auth_email_busy_error'
                // The email has already been taken. Please use manual sign in.
              } else if (error.data.message === 'The selected email is invalid.') {
                messageI18Key = 'signin.user-messages.social_auth_email_not_found_error'
              } else {
                messageI18Key = 'signin.user-messages.social_auth_error'
              }

              this.setError({
                type: 'social_sign_in',
                messageI18Key: messageI18Key
              })
            })
        } else {
          this.setError({
            type: 'social_sign_in',
            messageI18Key: 'signin.user-messages.social_auth_mobile_error'
          })
        }
      })
    },
    onFacebookAuthError() {
      this.setError({
        type: 'social_auth',
        messageI18Key: 'signin.user-messages.manual_auth_error'
      })
    },
    manualSignIn() {
      const loginData = mapValues(keyBy(this.signInRequiredFields, 'name'), 'value')
      this.login(loginData)
        .then(data => {
          if (data !== 'client') {
            this.$validator.errors.add({ field: 'email', msg: this.$t('signup.user-messages.wrong_email_or_password') })
            return false
          }

          this.getAccountInfo()
            .then(() => {
              this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
              this.authorizationRedirect()
            })
            .catch(errors => {
              if (errors.success === false) {
                this.$validator.errors.add({
                  field: 'email',
                  msg: this.$t('signup.user-messages.wrong_email_or_password')
                })
              }
            })
        })
        .catch(errors => {
          if (errors.success === false) {
            this.$validator.errors.add({ field: 'email', msg: this.$t('signup.user-messages.wrong_email_or_password') })
          }
        })
    },
    googleSignIn() {
      this.$gAuth
        .signIn()
        .then(userData => {
          const clientProfileData = userData.getBasicProfile() // get object with profile data (is Google response object changing???)
          const [source_id, full_name, first_name, last_name, profile_url, email] = Object.values(clientProfileData)
          const signInPayload = {
            source: 'google',
            source_id,
            email
          }
          this.socialLogin(signInPayload)
            .then(() => {
              this.getAccountInfo().then(() => {
                this.setAuthStatus({ isAuthorized: true, socialAuthType: 'google' })
                this.authorizationRedirect()
              })
            })
            .catch(error => {
              let messageI18Key = ''

              if (error.data === null) {
                messageI18Key = 'signin.user-messages.social_auth_email_busy_error'
                // The email has already been taken. Please use manual sign in.
              } else if (error.data.message === 'The selected email is invalid.') {
                messageI18Key = 'signin.user-messages.social_auth_email_not_found_error'
              } else {
                messageI18Key = 'signin.user-messages.social_auth_error'
              }

              this.setError({
                type: 'social_sign_in',
                messageI18Key: messageI18Key
              })
            })
        })
        .catch(() => {
          this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
          this.setError({
            type: 'social_sign_in',
            messageI18Key: 'signin.user-messages.social_auth_error'
          })
        })
    },
    changeType() {
      this.passwordField.type === 'password'
        ? (this.passwordField.type = 'text')
        : (this.passwordField.type = 'password')
    }
  }
}
</script>
