<template>
  <section class>
    <div class="container pt-lg-14 pt-7">
      <div class="row">
        <div class="text-center mb-lg-8-5 mb-2 mb-lg-4 col-12">
          <h2 class="mb-lg-2-5">{{ $t('main.another-sections.section_2.title') }}</h2>
        </div>
      </div>
      <div class="row card-list justify-content-center">
        <div class="col-lg-4">
          <div class="card flex-md-row flex-lg-column">
            <div class="text-center px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-5">
              <h3 class="mb-2-5">{{ $t('main.another-sections.section_2.card_1.title') }}</h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.another-sections.section_2.card_1.description') }}
              </p>
            </div>
            <img src="@/assets/images/client-lp/list-1.png" alt class="mw-100 h-auto" />
          </div>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0">
          <div class="card flex-md-row flex-lg-column">
            <div class="text-center px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-5">
              <h3 class="mb-2-5">{{ $t('main.another-sections.section_2.card_2.title') }}</h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.another-sections.section_2.card_2.description') }}
              </p>
            </div>
            <img src="@/assets/images/client-lp/list-2.png" alt class="mw-100 h-auto" />
          </div>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0">
          <div class="card flex-md-row flex-lg-column">
            <div class="text-center px-lg-2-5 px-md-4 px-2-5 pt-lg-6 pt-5">
              <h3 class="mb-2-5">{{ $t('main.another-sections.section_2.card_3.title') }}</h3>
              <p class="mb-3 mx-lg-1">
                {{ $t('main.another-sections.section_2.card_3.description') }}
              </p>
            </div>
            <img src="@/assets/images/client-lp/list-3.png" alt class="mw-100 h-auto" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'SecondSection'
}
</script>
