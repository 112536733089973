<template>
  <div>
    <!-- Need added dault salon image -->
    <div v-if="images.length" class="salon-images row no-gutters overflow-hidden">
      <div v-if="images.length > 0" class="col-lg border-right h-100">
        <img class="w-100 h-100" :src="images[0].path" alt />
      </div>
      <div v-if="images.length > 1" class="col border-right h-100">
        <img class="w-100 h-100" :src="images[1].path" alt />
      </div>
      <div v-if="images.length > 2" :class="[images.length == 3 ? 'col-lg-4' : 'col-lg-3']">
        <img v-if="images.length == 3" class="border-bottom w-100 h-100" :src="images[2].path" />
        <img v-if="images.length > 3" class="border-bottom w-100" :src="images[2].path" />
        <img v-if="images.length > 3" class="w-100" :src="images[3].path" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="scss" scoped>
.salon-images {
  height: 500px;
}
.last {
  overflow: hidden;
}
</style>
