<template>
  <section class="light-gradient-bg">
    <div class="container py-7 py-md-8 pt-lg-14 pb-lg-275">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-7">
          <h3 class="mb-lg-6 mb-4">{{ $t('main.another-sections.section_4.title') }}</h3>

          <router-link
            :to="`/sign-up`"
            class="btn btn-primary btn-lg py-2 px-lg-6-5 px-5-5 mr-md-0 mb-4 mb-md-0 with-shadow text-nowrap"
          >
            {{ $t('main.another-sections.section_4.button_link') }}
          </router-link>
        </div>
        <div class="col-md-5 offset-lg-1 position-relative">
          <img src="@/assets/images/client-lp/client-lp-2.png" alt class="mw-100 img-top" />
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-7 d-md-none d-block px-0">
      <a
        :href="salonAppUrl"
        target="_blank"
        class="btn text-nowrap btn-primary btn-lg py-2 px-lg-6-5 px-7 mb-4 mb-md-0 with-shadow"
      >
        {{ $t('common.navbar.owner_landing_link') }}
      </a>
    </div>
    <div class="col-12 mt-4 mt-md-2 mb-md-4 mb-2 px-0 d-flex flex-wrap justify-content-center">
      <h4 class="mr-2 mt-0 mt-md-1 text-center">{{ $t('main.another-sections.section_4.read-more-title') }}</h4>
      <div v-b-toggle="`accordion-1`" class="col-lg-2 col-md-4 col-12 mt-1 mt-md-0 px-0" block>
        <button class="btn btn-block btn-primary py-1">
          <span>{{ $t('main.another-sections.section_4.read-more') }}</span>
        </button>
      </div>
    </div>
    <b-collapse :id="`accordion-1`" accordion="my-root-accordion" role="tabpanel">
      <div class="row mb-3">
        <div class="container px-0 text-center">
          <h2 class="mb-2">
            Mit Quiff schnell und bequem Deinen Coiffeur online buchen!
          </h2>
          <p class="mb-2">
            Du möchtest was Neues ausprobieren, oder einfach nur mal wieder die Haare schneiden lassen? Von der
            Alltagsfriseur und dem Standard Haarschnitt hin zu fresh und stylish? Oder brauchst Du eine neue Farbe?
            Kurzum: Du bist auf der Suche nach einem Coiffeur Salon in Deiner Nähe, der Deine Wünsche erfüllt. Ausserdem
            möchtest Du vergleichen können und den für Dich geeignetsten Coiffeur online buchen. Einfach, schnell und
            praktisch. Herzlichen Glückwunsch – dann bist Du bei quiff.ch genau richtig.
          </p>
          <h2 class="mb-2">
            Das sind die Vorteile Deiner Coiffeur online Reservation:
          </h2>
          <p class="mb-2">
            Gehörst Du zu den spontanen Kunden, die einen tollen
            <a href="https://blog.quiff.ch/post/Coiffeur-in-der-Naehe" target="_blank" title="Coiffeur in der Nähe">
              Coiffeur in der Nähe
            </a>
            suchen? Oder ist Dir die Entfernung egal, denn Du suchst den absoluten Star Coiffeur? Du magst es praktisch
            und möchtest deshalb Deinen Coiffeur online buchen? Du möchtest die Salons vergleichen nach Preis, Angebot
            und Qualifikationen? Hier leiten Dich unsere Kundenbewertungen! Und das nicht nur pauschal, sondern Du
            kannst sehen, welcher Mitarbeiter bei den Bewertungen super abschneidet. Probier`s doch mal aus! Klicke Dich
            durch die Salons Deiner Wahl und buche was oder wer Dir gefällt.
          </p>
          <h3 class="mb-2">
            Den Lieblings-Coiffeur entdecken und merken
          </h3>
          <p class="mb-2">
            Erst wenn Du verbindlich einen Coiffeur online buchen möchtest, musst Du Dich einmal registrieren und ein
            Profil für Dich anlegen. Hier kannst Du auch problemlos weitere Personen, beispielsweise Deine Kinder oder
            andere Familienmitglieder, hinzufügen. Du kannst Deine vergangenen Termine und Buchungen jederzeit alle
            einsehen. Praktisch, oder? Die Registrierung ist selbstverständlich kostenlos. Du kannst nach dem Besuch bei
            Deinem Lieblingscoiffeur eine Bewertung hinterlassen, sowohl für den Salon als auch Deinen persönlichen
            Favoriten. Was Dir gefällt, gefällt auch sicher anderen!
          </p>
          <h3 class="mb-2">
            Coiffeur online buchen, dabei Treuepunkte sammeln und Produkte shoppen
          </h3>
          <p class="mb-4">
            Shoppst Du gerne Pflege- und Styling Produkte, Make-up und mehr? Und das zum Vorteilspreis? Sofern Du den
            Coiffeur Termin online gebucht hast, erhältst Du sogenannte „Qredits“. Dabei handelt es sich um ein
            Treuepunktesystem. Das bedeutet: Du kannst Deine „Qredits“ gegen Rabatte bei all unseren Partneranbietern
            online einlösen. Wenn Du in Quiff eingeloggt bist, hast Du automatisch Zugang zu dem Partner Onlineshops.
            Hier findest Du alles was Dein Shopping Herz begehrt. Styling- und Beautyprodukte, klassische Pflegeprodukte
            aber auch solche, die gerade hip sind! Natürlich umfasst das Sortiment nicht nur Schönes für die Damenwelt.
            Die Herren finden ebenfalls Ihre Pflegeprodukte. Für das Haarstyling, die Kopfhautpflege aber auch für die
            Bartpflege. Konsequent wird der Shop erweitert, so dass sich auch entsprechendes Zubehör wie etwa der
            Bartschneider, diverse Aufsätze, die Nagelschere oder der Nasenhaar-Rasierer darin wiederfinden.
          </p>
          <h3 class="mb-2">
            Die Produkte Deines Lieblings Coiffeurs reservieren
          </h3>
          <p class="mb-2">
            Übrigens, die Pflegesortimente der bei uns gelisteten Coiffeure gibt es selbstverständlich auch online! Du
            möchtest gerne den empfohlenen Produkten Deines Coiffeurs treu bleiben und willst Dir schon den Nachschub
            für Deinen nächsten Besuch sichern? Das geht klar! Du kannst Dir ganz in Ruhe die gesamte Produktpalette
            online ansehen und Deine Wunschartikel zusammen mit Deinem Termin online reservieren! Dieser besondere
            Service bietet sich besonders dann an, wenn Dich bereits ein verwendetes Produkt so überzeugt hat, dass Du
            es auch zu Hause in Deine Pflegeserie aufnehmen möchtest. Aber auch Neukunden können bereits im Vorfeld
            stöbern und sich interessante Produkte vormerken.
          </p>
          <h3 class="mb-2">Coiffeur Termin Reservation mit einem Klick!</h3>
          <p class="mb-2">
            Sobald die Wahl auf den passenden Termin, den Coiffeur in der Nähe oder den besten bewerteten Salon gefallen
            ist, kannst Du mit einem Mausklick ganz einfach buchen beziehungsweise verbindlich reservieren. Wir senden
            Dir unmittelbar danach eine kurze Bestätigung per E-Mail, damit Du auch wirklich sicher sein kannst, dass
            alles funktioniert hat. Und damit Du Deinen Termin auch garantiert nicht vergisst, erhältst Du drei Stunden
            vorher noch einmal eine kurze Erinnerungsmail. Und dann heisst es: geniessen und Dich verwöhnen lassen.
          </p>
          <p class="mb-2">
            Anschliessend solltest Du die Möglichkeit nutzen, Deinen Coiffeur-Besuch zu bewerten – und so auch andere
            Nutzer unserer Plattform an Deinen Erfahrungen teilhaben und sie davon profitieren zu lassen. Bewerte sowohl
            den Salon als auch den für Dich verantwortlichen Mitarbeiters. Und wenn Du zufrieden warst, bietet es sich
            an, gleich den nächsten Termin bei diesem Coiffeur online zu buchen. Oder Du möchtest mal etwas Neues
            ausprobieren? Dann steht Dir eine grosse Auswahl an weiteren Salons in Deiner Nähe zur Verfügung.
            Durchstöbere die Plattform, lasse Dich inspirieren und triff ganz in Ruhe die richtige Entscheidung. Selbst
            ein Coiffeur ohne Voranmeldung ist bei uns gelistet und bietet Dir die Möglichkeit, einen Termin online zu
            reservieren und so Planungssicherheit zu haben. Denn auch bei einem
            <a
              href="https://blog.quiff.ch/post/Coiffeur-ohne-Voranmeldung"
              target="_blank"
              title="Coiffeur ohne Voranmeldung"
            >
              Coiffeur ohne Voranmeldung
            </a>
            gibt es Stosszeiten und unter Umständen Warteschlangen!
          </p>
          <h2 class="mb-2">
            Coiffeur online buchen im Umkreis von Bern, St Gallen, Aarau und Basel!
          </h2>
          <p class="mb-2">
            Das sind unsere Hotspots! Vom Coiffeur in Bern, über St Gallen, Aarau bis hin zu Basel drängeln sich die
            Salons dicht an dicht. Hier wirst Du fündig, wenn es um den nächsten Haarschnitt geht. Du findest nahezu
            alle Dienstleistungen eines Coiffeurs auf Quiff. Zum Beispiel sind das ein Damenhaarschnitt oder
            Herrenhaarschnitt, färben, stylen, die Hochzeitsfrisur, eine Haarverlängerung oder auch Perücken Anpassung,
            eine Dauerwelle, die Bartpflege, Augenbrauen zupfen, Wimpern färben bis hin zum Make-up und
            Gesichtsbehandlungen. Profitiere vom vielfältigen Angebot.
          </p>
          <p class="mb-2">
            Nach und nach folgen weitere Coiffeur Salons, die Dich inspirieren und die Du vielleicht einmal ausprobieren
            möchtest. Wähle nach Deinen ganz persönlichen Entscheidungskriterien. Diese sind natürlich sehr
            unterschiedlich. Dir ist es zum Beispiel wichtig, einen günstigen Coiffeur zu finden? Mit Quiff kannst Du
            die Preise vergleichen, Dir ausserdem einen Eindruck des Salons und deren Angestellten verschaffen. Du
            siehst ausserdem die Qualifikationen der Mitarbeiter. Vielleicht legst Du auch besonderen Wert auf die
            Entfernung, denn Du möchtest mal eben schnell in Deiner Mittagspause zum Coiffeur. Was auch Deine Intension
            ist, Quiff hat an alles gedacht. Probiere es am besten gleich mal aus!
          </p>
          <h2 class="mb-2">
            Quiff.ch – auch für Coiffeure die richtige Wahl
          </h2>

          <p class="mb-2">
            Wir bringen zusammen, was zusammen gehört – und das auf vertrauensvolle, unkomplizierte Weise, zudem absolut
            kostenlos! Auch für Dich als Saloninhaber sind wir der richtige Ansprechpartner und die richtige Plattform.
            Denn wir nehmen Dir die gesamte organisatorische Arbeit ab, sodass Du Dich voll und ganz auf das Wesentliche
            konzentrieren kannst: Deine Kunden und deren Zufriedenheit. Immer mehr Kunden möchten Ihren Coiffeur online
            buchen. Sei dabei, wechsle jetzt zum
            <a class="" :href="salonAppUrl" title="Salon-Login" target="_blank">
              Salon-Login
            </a>
            und erfahre mehr!
          </p>
        </div>
      </div>
    </b-collapse>
  </section>
</template>
<script>
import config from 'config'

export default {
  name: 'FourthSection',
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  }
}
</script>
