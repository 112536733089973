<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mt-5">
          Coiffeur in Bern und Umgebung: suchen, finden, buchen
        </h1>
        <p class="mb-2">
          Du bist auf der Suche nach einem Coiffeur in Bern und Umgebung? Du suchst einen Salon, indem du dich
          wohlfühlst und bei dem du den für dich perfekten Haarschnitt verpasst bekommst? Deine Haare wachsen zu langsam
          und du suchst zum Beispiel den Coiffeur-Experten für eine Haarverlängerung in Bern? Du wohnst in der Stadt
          oder planst einen Besuch und möchtest diesen mit einer Wohlfühlzeit bei einem Coiffeur verbinden? Dann bist du
          bei Quiff genau richtig, denn wir halten nicht nur eine Liste mit zahlreichen Coiffeuren für dich bereit.
          Nein, wir bieten dir auch die Möglichkeit, deinen Termin beim Coiffeur bzw. Friseur in Bern gleich online zu
          buchen.
        </p>
        <p class="mb-2">
          Wir zeigen dir beispielsweise, welcher Coiffeur am Bahnhof Bern zu finden ist, sodass du die Wartezeit
          zwischen zwei Zugfahrten als kleine Auszeit für Körper und Seele gestalten kannst. Wir präsentieren dir eine
          Liste der Friseure in deiner Nähe oder in der Stadt deiner Wahl. In diesem Fall alle Coiffeure in Bern und
          Umgebung. Blättere durch unsere grosse Auswahl, schau dir die einzelnen Salons in Ruhe an – und entscheide
          dich für den Coiffeur in Bern, der die Dienstleistungen anbietet, die du suchst.
        </p>
        <h2 class="mb-2">
          Unkompliziert online buchen – dein Termin beim Coiffeur in Bern
        </h2>
        <p class="mb-2">
          Haarverlängerung, Kurzhaarschnitt für Männer, neue Haarfarbe oder Dauerwelle – egal, was du mit deinen Haaren
          planst, wir haben garantiert das richtige Angebot für dich und deine Wünsche. Wenn du deine Vorauswahl für die
          gewünschte Dienstleistung getroffen hast, zeigen wir dir die Auswahl an Salons, die diese anbieten. Und dann
          liegt es an dir: Wähle du den Coiffeur in Bern, der beispielsweise ganz in deiner Nähe ist oder der die besten
          Referenzen hat oder bei dem dein Bauchgefühl „Ja“ sagt.
        </p>
        <p class="mb-2">
          Sobald du dich entschieden hast, kannst du bei dem von dir ausgewählten Coiffeur in Bern online Termin
          eintragen – und schon bist du fertig. Anschliessend erhältst du noch eine Bestätigung per Mail und eine
          Erinnerung kurz vor deinem gebuchten Termin. Und das Beste: Mit jedem Besuch bei deinem Coiffeur Bern sammelst
          du Treuepunkte. Diese kannst du in unserem Onlineshop bei einem Produkt-Shopping einlösen.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Coiffeur in Bern: Quiff hilft dir dabei, die richtige Wahl zu treffen. Schnell, unkompliziert und inklusive einem attraktiven Treuepunktesystem.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  }
}
</script>
