<template>
  <main class="navbar-offset profile-page">
    <div class="container">
      <div class="row privacy-main-info">
        <div class="d-flex flex-wrap">
          <div class="col-lg-4 col-md-2 navigationBlock pt-md-6 pt-2">
            <div :class="getSiteLang === 'de' ? 'mb-md-5-5 mb-2' : 'mb-0'">
              <h1 class="mb-1">{{ $t('terms.agb_for_partners_page-title') }}</h1>
              <font-awesome-icon :icon="['far', 'clock']" class="text-primary"> </font-awesome-icon>
              <span class="text-black pl-0-5">{{ $t('terms.updated') }} {{ getFormattedData }}</span>
            </div>
            <PrivacyNavigationTabs class="mt-0" />
          </div>
          <div class="col-xxl-6 col-xl-8 col-lg-9 col-md-8 mx-auto py-md-7 py-1 mt-md-11 mt-0">
            <h1 class="mb-3 mt-md-0 mt-3">{{ $t('terms.agb-for-patners-page.title-1') }}</h1>
            <p class="mb-2">
              {{ $t('terms.agb-for-patners-page.title-2') }}
            </p>
            <h6 class="mb-2">
              {{ $t('terms.agb-for-patners-page.title-3') }}
            </h6>
            <span>{{ $t('terms.agb-for-patners-page.title-4') }}</span>
            <p class="my-2">
              {{ $t('terms.agb-for-patners-page.title-5') }}
            </p>
            <ul>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-6') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-7') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-8') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-9') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-10') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-11') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-12') }}</h3>
                <ul>
                  <li class="mb-2">
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-13') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-14') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-15') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-16') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-17') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-18') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-19') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-20') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-21') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-22') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-23') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-24') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-25') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-26') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-27') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-28') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-29') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-30') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-31') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-32') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-33') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-34') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-35') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-36') }}
                    </p>
                  </li>

                  <li class="mb-0-5">
                    <p>
                      {{ $t('terms.agb-for-patners-page.title-37') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-38') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-39') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-40') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-41') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-42') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-43') }}</h3>
                <ul>
                  <li>
                    <p class="mb-2">
                      {{ $t('terms.agb-for-patners-page.title-44') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-45') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-46') }}
                          <br />
                          {{ $t('terms.agb-for-patners-page.title-47') }}
                          <br />
                          {{ $t('terms.agb-for-patners-page.title-48') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-49') }}
                </h3>
                <ul>
                  <li class="mb-2">
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-50') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-51') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-52') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-53') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-54') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-55') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-56') }}
                    </p>
                  </li>
                  <li class="mb-2">
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-57') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-58') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-59') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-60') }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-61') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-62') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-2">
                      {{ $t('terms.agb-for-patners-page.title-63') }}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-0-5">
                          {{ $t('terms.agb-for-patners-page.title-64') }}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-65') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-66') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-67') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-68') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">1{{ $t('terms.agb-for-patners-page.title-69') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-70') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-71') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-72') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-73') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">{{ $t('terms.agb-for-patners-page.title-74') }}</h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-75') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-76') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-77') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-78') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-79') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-80') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-81') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-82') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-83') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-84') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-85') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-86') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-87') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-88') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-89') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-90') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-91') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-92') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-93') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-94') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-95') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-96') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-97') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-98') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-99') }}
                    </p>
                  </li>
                  <li>
                    <p class="mv-0-5">
                      {{ $t('terms.agb-for-patners-page.title-100') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-101') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-102') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-103') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-104') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-105') }}
                    </p>
                  </li>
                  <li class="mb-0-5">
                    <p class="mb-2">
                      {{ $t('terms.agb-for-patners-page.title-106') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-107') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-108') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-109') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-110') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-111') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-112') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-113') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-114') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-115') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-116') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-117') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-118') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-119') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-120') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-121') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-122') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-123') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-124') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-125') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-126') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-127') }}
                    </p>
                  </li>
                  <ul>
                    <li>
                      <p class="mb-0-5">
                        {{ $t('terms.agb-for-patners-page.title-128') }}
                      </p>
                    </li>
                  </ul>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-129') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-130') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-131') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-132') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-133') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-134') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-135') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-136') }}
                    </p>
                  </li>
                </ul>
              </li>
              <li class="mb-2">
                <h3 class="mb-2">
                  {{ $t('terms.agb-for-patners-page.title-137') }}
                </h3>
                <ul>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-138') }}
                    </p>
                  </li>
                  <li>
                    <p class="mb-0-5">
                      {{ $t('terms.agb-for-patners-page.title-139') }}
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            <p>{{ $t('terms.agb-for-patners-page.title-140') }}</p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PrivacyNavigationTabs from './PrivacyNavigationTabs'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    PrivacyNavigationTabs
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getSiteLang: 'user/getSiteLang'
    }),
    getFormattedData() {
      return moment('2020-04-11 09:00:00')
        .locale(this.getSiteLang)
        .format('MMMM, DD YYYY')
    }
  }
}
</script>
