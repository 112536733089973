<template>
  <div class="col mt-md-0 mt-2">
    <div class="row">
      <div class="col-12 text-left">
        <span class="text-small text-grey"> {{ age }} {{ $t('common.forms.label.year') }} </span>
      </div>
      <div class="col-12 d-flex">
        <small class="text-gray">
          {{ options.min }}
        </small>
        <VueSlider v-model="age" v-bind="options" class="col px-1-5" />
        <small class="text-gray">
          {{ options.max }}
        </small>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'AgeSlider',
  components: {
    VueSlider
  },
  props: {
    ageData: {
      type: Object,
      default: () => ({})
    },
    gender: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      age: 1,
      options: {
        min: 1,
        max: 16,
        interval: 1,
        height: 6,
        tooltip: 'none'
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues',
      childHaircutParams: 'haircutParams/getChildHaircutParams'
    }),
    existingAges() {
      return this.parametersValues.age.values
    },
    isModileWIdth() {
      return window.innerWidth < 767 ? true : false
    }
  },
  watch: {
    ageData: {
      deep: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal)) {
          this.age = newVal.value
        }
      }
    },
    age: {
      deep: true,
      handler(newVal) {
        const childAge = this.existingAges.find(age => age.value === String(newVal))
        this.setChildHaircutParams({ age: childAge })
      }
    },
    childHaircutParams: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.age && Object.keys(newVal.age).length) {
          this.age = newVal.age.value
        }
      }
    }
  },
  mounted() {
    if (this.isModileWIdth) {
      this.options.height = 9
      let dot = document.querySelector('.vue-slider-dot')
      dot.style.width = '24px'
      dot.style.height = '24px'
    }
    if (this.ageData && this.gender) {
      if (this.ageData.value !== undefined) {
        this.age = this.ageData.value
      } else {
        this.age = this.existingAges[0].value
      }
    }
    if (this.childHaircutParams.age && this.gender) {
      if (this.childHaircutParams.age.value) {
        this.age = this.childHaircutParams.age.value
      } else if (!this.childHaircutParams.age.value && this.childHaircutParams.age.length) {
        this.age = this.childHaircutParams.age
      }

      this.setChildHaircutParams({ age: this.age })
    }
  },
  methods: {
    ...mapActions('haircutParams', {
      setChildHaircutParams: 'setChildHaircutParams'
    })
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #ff6b09;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>
