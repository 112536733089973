<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">Damen Coiffeur – für alle Ansprüche das richtige Angebot</h1>
        <p class="mb-2">
          Wenn es um das äussere Erscheinungsbild geht, sind Frauen besonders anspruchsvoll. Das spiegelt sich auch bei
          der Wahl des richtigen Damen Coiffeur wider. Schliesslich möchte Frau sich nicht nur während ihres Aufenthalts
          im Salon wohlfühlen, sie möchte auch mit dem Ergebnis vollends zufrieden sein. Für dieses (zurecht)
          anspruchsvolle Klientel halten wir viele Coiffeure bereit – sodass jeder den Damen Coiffeur findet, den er
          sucht.
        </p>
        <p class="mb-3">
          Während Männer oft das Schnelle und Unkomplizierte lieben, sind die Anforderungen, die Frauen an ihren Schnitt
          und auch an den Coiffeurbesuch stellen, deutlich vielschichtiger. Genau wie die Frisuren deutlich
          vielschichtiger sind. Die einen suchen einen Coiffeur, der ihnen einen frechen Kurzhaarschnitt verpasst. Die
          anderen benötigen einen Friseur, der lange Haare perfekt pflegen und schneiden kann oder der die Haare so
          färben kann, dass sie natürlich und dennoch trendy aussehen. Und wieder andere möchten sich für den
          wichtigsten Tag ihres Lebens aufhübschen lassen und suchen einen Coiffeur, der viel Erfahrung im Umgang mit
          Brautfrisuren hat. Zu guter Letzt gibt es noch diejenigen, die preisbewusst sind und die Coiffeur Preise in
          ihre Auswahl mit einbeziehen.
        </p>
        <h2 class="mb-2">
          Der richtige Coiffeur für den perfekten Look
        </h2>
        <p class="mb-2">
          Coiffeursalons gibt es praktisch wie Sand am Meer.
          <a :href="`${salonAppUrl}/Coiffeur-Zürich`" title="Coiffeur Zürich" target="_blank">Coiffeur Zürich</a>,
          <router-link to="/Coiffeur-Bern" title="Coiffeur Bern"> Coiffeur Bern</router-link>,
          <router-link to="/Coiffeur-Basel" title="Coiffeur Basel">Coiffeur Basel</router-link> oder, oder, oder. Wer
          diese Schlagworte eingibt, der wird unzählige Ergebnisse finden – und gleichzeitig von der unsortierten
          Auswahl überfordert sein. Quiff schafft hier Abhilfe und erleichtert die Suche nach dem passenden Damen
          <a :href="`${salonAppUrl}/Coiffeur-in-der-Nähe`" title="Coiffeur in der Nähe" target="_blank"
            >Coiffeur in der Nähe</a
          >
          respektive Deiner Stadt.
        </p>
        <p class="mb-2">
          Wir bieten Dir ebenfalls eine grosse Auswahl, aber wir bieten Dir auch die Möglichkeit zu filtern. Nach dem
          Ort, nach den angebotenen Dienstleistungen und nach Spezialisierungen. Zudem hast Du die Möglichkeit, von den
          Erfahrungen anderer Frauen zu profitieren. Denn jeder, der einen Salon besucht hat, hat im Anschluss die
          Option, diesen zu bewerten. Und das ist gold wert! Viele besuchen einen Salon aufgrund der Empfehlung einer
          guten Kollegin. Bei Quiff ist das nicht anders.
        </p>
        <p class="mb-2">
          Und wir bieten Dir ein weiteres, attraktives Gimmick: Mit jedem Besuch beim Damen Coiffeur, der über unser
          Portal gebucht wurde, sammelst Du automatisch Treuepunkte. Diese kannst Du anschliessend im Online Shop für
          <router-link to="/Haarprodukte" title="Haarprodukte">Haarprodukte</router-link>, Pflegeprodukte oder
          Kosmetikartikel einlösen. Doch jetzt heisst es erst einmal: Stöbern, Dich inspirieren lassen und dann ganz
          bequem einen Coiffeur online buchen. Du bist nur noch einen Klick von Deiner neuen Frisur entfernt.
        </p>
        <div class="my-5 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Du suchst einen Damen Coiffeur, bei dem Du Dich wohlfühlst und von dem man nur Gutes hört? Quiff hilft Dir dabei, den passenden Salon zu finden.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
