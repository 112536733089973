<template>
  <div class="col-12 px-0 border-right d-none d-md-block">
    <div class="col-12 p-0 d-flex justify-content-left">
      <div class="col-2 py-1 h-100vh d-flex justify-content-center align-items-center border-right px-0">
        <span class="child-icon"></span>
      </div>

      <div class="col-10 px-0 d-flex flex-wrap align-items-center">
        <div
          v-for="(option, idx) in kidsCombination"
          :key="idx"
          class="col-12 d-flex justify-content-left py-1 py-0 text-nowrap text-truncate table-row-height"
          :class="idx !== kidsCombination.length - 1 ? 'border-bottom' : ''"
        >
          <span class="pr-0-5 text-nowrap text-left text-truncate">
            {{ $t(`servicesRedesign.${option.gender}`) }}
            <span class="text-gray"> {{ `(${option.ageFrom} - ${option.ageTo} year)` }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    kidsCombination: {
      type: Array,
      default: () => []
    }
  }
}
</script>
