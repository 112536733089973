<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mb-2 mt-5">
          Haarprodukte bequem online kaufen
        </h1>
        <p class="mb-2">
          Dein Coiffeur hat bei Deinem letzten Besuch Haarprodukte verwendet, die Du gerne auch zuhause nutzen möchtest?
          Deine Haare bedürfen einer intensiven Pflege und Du möchtest dafür spezielle Coiffeur Produkte kaufen? Oder Du
          möchtest einfach mal in einem Haarshop stöbern und die angebotenen Haarprodukte online bestellen? Egal, was
          Deine Intention ist: Wir von Quiff haben garantiert das richtige Angebot für Dich.
        </p>
        <p class="mb-2">
          Denn in unserem Portal präsentieren alle gelisteten Salons Ihre Produktpalette. Hier kannst Du zwischen einer
          Vielzahl unterschiedlichster Pflege- und Kosmetikprodukte wählen. Und zwar ausnahmslos zwischen den Produkten,
          die auch die Coiffeure in ihren Salons verwenden und anbieten. Damit hast Du die Gewissheit, dass diese von
          Experten getestet und für gut befunden wurden.
        </p>
        <p class="mb-2">
          Entweder Du lässt Dich von den Produktbeschreibungen und den Artikelbildern inspirieren oder Du suchst ganz
          gezielt nach einem Coiffeur Produkt. Das ist beispielsweise dann sinnvoll, wenn Dir ein Shampoo, ein
          Conditioner oder der Haarschaum bei deinem letzten Coiffeurbesuch so gut gefallen hat, dass Du das Produkt
          unbedingt auch regelmässig zuhause verwenden möchtest.
        </p>
        <h2 class="mb-2">
          Coiffeur online buchen & Haarprodukte shoppen
        </h2>
        <p class="mb-2">
          Bei Quiff kann man nicht nur den
          <router-link to="/Coiffeur-online-buchen" title="Coiffeur Termin online buchen">
            Coiffeur Termin online buchen</router-link
          >, sondern auch auf Shopping Tour gehen. Du shoppst entweder von zuhause aus oder Du überbrückst idealerweise
          die Wartezeiten zwischen Deinen Coiffeur Anwendungen. Du profitierst nämlich bei jedem gebuchten Onlinetermin
          von unserem attraktiven Treuepunktesystem. Und das funktioniert so: Bei jedem gebuchten Termin schreiben wir
          Dir Bonuspunkte gut. Diese kannst Du anschliessend im Haarshop beim Kauf der Haarprodukte Deiner Wahl
          einlösen. Du legst die Produkte hierzu in Deinen virtuellen Einkaufskorb – und Dein Coiffeur hält sie bei
          Deinem nächsten Besuch für Dich bereit.
        </p>
        <p class="mb-2">
          Damit Du auch das richtige Lieblingsprodukt wählst, kannst Du Dir die Bestellnummer bei Deinem Coiffeur
          notieren. Oder, wie gesagt, Du stöberst einfach nur und lässt Dich inspirieren. Hier hast Du die
          Möglichkeiten, nach unterschiedlichen Produkten zu selektieren. Du benötigst etwas für trockenes Haar? Deine
          Haare haben mal wieder eine Kur verdient? Dann suche gezielt nach diesen Haarprodukten und reserviere sie
          bequem online.
        </p>
        <div class="my-5 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Bestelle Deine Haarprodukte Deines Coiffeurs bequem online und profitiere dabei von unserem attraktiven Treuepunktesystem. Online buchen, Punkte sammeln!'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  },
  beforeCreate: function() {
    window.scrollTo(0, -200)
  }
}
</script>
