<template>
  <main class="navbar-offset profile-page">
    <div class="container">
      <div class="row privacy-main-info">
        <div class="d-flex flex-wrap w-100">
          <div class="col-lg-4 col-md-2 navigationBlock pt-md-6 pt-2">
            <div class="mb-md-5 mb-2">
              <h1 class="mb-1">{{ $t('terms.privacy_page-title') }}</h1>
              <font-awesome-icon :icon="['far', 'clock']" class="text-primary"> </font-awesome-icon>
              <span class="text-black pl-0-5">{{ $t('terms.updated') }} {{ getFormattedData }}</span>
            </div>
            <PrivacyNavigationTabs class="mt-0-5" />
          </div>
          <div class="col-xxl-6 col-xl-8 col-lg-9 col-md-8 mx-auto py-md-7 py-1 mt-md-11 mt-0 ">
            <h1 class="mb-2">{{ $t('terms.privacy-page.title-1') }}</h1>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-2') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-4') }}
            </p>
            <p class="mb-2">{{ $t('terms.privacy-page.title-5') }}</p>
            <p class="mb-2">{{ $t('terms.privacy-page.title-6') }}</p>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-7') }}
            </h3>
            <ul>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-8') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-9') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-10') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-11') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-12') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-13') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-14') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-15') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-16') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-17') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-18') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-19') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-20') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-21') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-22') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-23') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-24') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-25') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-26') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-27') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-28') }}</p>
              </li>
              <li class="mb-0-3">
                <p>{{ $t('terms.privacy-page.title-29') }}</p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-30') }}</h3>

            <span>Quiff AG</span>
            <br />
            <span>Steinenstrasse 7</span>
            <br />
            <span>6048 Horw</span>
            <br />
            <span class="mb-3">{{ $t('terms.privacy-page.title-31') }}</span>
            <br />
            <div class="d-flex my-2">
              <h3 class="mb-2">
                {{ $t('terms.privacy-page.title-32') }}
              </h3>
              <p class="pl-1 pt-0-3"><a href="mailto:info@quiff.ch" title="info@quiff.ch">info@quiff.ch</a></p>
            </div>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-33') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-34') }}
            </p>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-35') }}</h3>
            <ul class="mb-2">
              <li>
                <p>{{ $t('terms.privacy-page.title-36') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-37') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-38') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-39') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-40') }}</p>
              </li>
              <li>
                <p>
                  {{ $t('terms.privacy-page.title-41') }}
                </p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-42') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-43') }}</p>
              </li>
              <li>
                <p>{{ $t('terms.privacy-page.title-44') }}</p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-45') }}
            </h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-46') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-47') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-48') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-49') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-50') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-51') }}</p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-52') }}</h3>
            <ul>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-53') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-54') }}.</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-55') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-56') }}</p>
              </li>
              <li>
                <p class="mb-0-5">
                  {{ $t('terms.privacy-page.title-57') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-58') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-59') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-60') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-61') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-62') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-63') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-64') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-65') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-66') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-67') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-68') }}</p>
              </li>
              <li>
                <p class="mb-0-5">{{ $t('terms.privacy-page.title-69') }}</p>
              </li>
              <li>
                <p class="mb-0-5">
                  {{ $t('terms.privacy-page.title-70') }}<br />{{ $t('terms.privacy-page.title-71') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-72') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-73') }}
            </p>
            <ul class="mb-2">
              <li>
                <p>
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-74') }}</span>
                  {{ $t('terms.privacy-page.title-75') }}
                </p>
              </li>
              <li>
                <p>
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-76') }}</span>
                  {{ $t('terms.privacy-page.title-77') }}
                </p>
              </li>

              <li>
                <p>
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-78') }} </span>
                  {{ $t('terms.privacy-page.title-79') }}
                </p>
              </li>
              <li>
                <p>
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-80') }} </span>
                  {{ $t('terms.privacy-page.title-81') }}
                </p>
              </li>
              <li>
                <p>
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-82') }}</span>
                  {{ $t('terms.privacy-page.title-83') }}
                </p>
              </li>
            </ul>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-84') }}</span>
              {{ $t('terms.privacy-page.title-85') }}
            </p>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-86') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-87') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-88') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-89') }}</span>
              {{ $t('terms.privacy-page.title-90') }}
            </p>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-91') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-92') }}
            </p>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-93') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-94') }}
            </p>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-95') }}
            </h3>
            <ul class="mb-2">
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-96') }}
                  </span>
                  {{ $t('terms.privacy-page.title-97') }}
                </p>
              </li>
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-98') }}
                  </span>
                  {{ $t('terms.privacy-page.title-99') }}
                </p>
              </li>
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-100') }}
                  </span>
                  {{ $t('terms.privacy-page.title-101') }}
                </p>
              </li>
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-102') }}
                  </span>
                  {{ $t('terms.privacy-page.title-103') }}
                </p>
              </li>
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-104') }}
                  </span>
                  {{ $t('terms.privacy-page.title-105') }}
                </p>
              </li>
              <li class="mb-0-5">
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-106') }}
                  </span>
                  {{ $t('terms.privacy-page.title-107') }}
                </p>
              </li>
            </ul>
            <p class="mb-2">
              <span class="text-black font-weight-bold">
                {{ $t('terms.privacy-page.title-108') }}
              </span>
              {{ $t('terms.privacy-page.title-109') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">
                {{ $t('terms.privacy-page.title-110') }}
              </span>
              {{ $t('terms.privacy-page.title-111') }}
              <a href="https://optout.aboutads.info" title="https://optout.aboutads.info"
                >https://optout.aboutads.info</a
              >
              {{ $t('terms.privacy-page.title-112') }}
              <a href="https://www.youronlinechoices.com/" title="https://www.youronlinechoices.com/"
                >https://www.youronlinechoices.com/</a
              >
              {{ $t('terms.privacy-page.title-113') }}
            </p>
            <p>
              <span class="text-black font-weight-bold">
                {{ $t('terms.privacy-page.title-114') }}
              </span>
              {{ $t('terms.privacy-page.title-115') }}
            </p>
            <ul class="mb-2">
              <li>
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-116') }}
                  </span>
                  {{ $t('terms.privacy-page.title-117') }}
                </p>
              </li>
              <li>
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-118') }}
                  </span>
                  {{ $t('terms.privacy-page.title-119') }}
                </p>
              </li>
              <li>
                <p>
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-120') }}
                  </span>
                  {{ $t('terms.privacy-page.title-121') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-0-5">
              {{ $t('terms.privacy-page.title-122') }}
            </h3>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-123') }}
            </p>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-124') }}
            </p>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-125') }}
            </p>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-126') }}
            </p>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-127') }}
            </p>
            <p class="mb-0-5">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-128') }} </span>
              {{ $t('terms.privacy-page.title-129') }}
            </p>
            <p class="mb-0-5">
              {{ $t('terms.privacy-page.title-130') }}
            </p>
            <p class="mb-0-5">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-131') }} </span>
              {{ $t('terms.privacy-page.title-132') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-133') }} </span>
                  {{ $t('terms.privacy-page.title-134') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-135') }}</span>
                  {{ $t('terms.privacy-page.title-136') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-137') }} </span>
                  {{ $t('terms.privacy-page.title-138') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-139') }} </span>
                  {{ $t('terms.privacy-page.title-140') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-141') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-142') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-143') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-145') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-146') }}</span>
                  {{ $t('terms.privacy-page.title-147') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-148') }} </span>
                  {{ $t('terms.privacy-page.title-149') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-150') }}</span>
                  {{ $t('terms.privacy-page.title-151') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-152') }} </span>
                  {{ $t('terms.privacy-page.title-153') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-154') }}
            </h3>
            <ul>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Stripe: </span>
                  {{ $t('terms.privacy-page.title-155') }}
                  <a href="https://stripe.com/de" title="https://stripe.com/de">https://stripe.com/de</a
                  >{{ $t('terms.privacy-page.title-156')
                  }}<a href="https://stripe.com/de/privacy" title="https://stripe.com/de/privacy">
                    https://stripe.com/de/privacy</a
                  >.
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-157') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-158') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-159') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-160') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-161') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-162') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-163') }} </span>
                  {{ $t('terms.privacy-page.title-164') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-165') }} </span>
                  {{ $t('terms.privacy-page.title-166') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-167') }} </span>
                  {{ $t('terms.privacy-page.title-168') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-169') }}</span>
                  {{ $t('terms.privacy-page.title-170') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-171') }}
            </h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Facebook Single-Sign-On: </span>
                  {{ $t('terms.privacy-page.title-172') }}
                  <a href="https://www.facebook.com" title="https://www.facebook.com">https://www.facebook.com</a>
                  {{ $t('terms.privacy-page.title-173')
                  }}<a href="https://www.facebook.com" title="https://www.facebook.com"> https://www.facebook.com</a>
                  {{ $t('terms.privacy-page.title-174') }}
                  <a href="https://www.facebook.com/about/privacy" title="https://www.facebook.com/about/privacy"
                    >https://www.facebook.com/about/privacy</a
                  >
                  {{ $t('terms.privacy-page.title-175') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a
                  >
                  {{ $t('terms.privacy-page.title-176') }}
                  <a href="https://www.facebook.com/settings?tab=ads" title="https://www.facebook.com/settings?tab=ads"
                    >https://www.facebook.com/settings?tab=ads</a
                  >.
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Google Single-Sign-On: </span>
                  {{ $t('terms.privacy-page.title-178') }}
                  <a href="https://www.google.de" title="https://www.google.de">https://www.google.de</a>
                  {{ $t('terms.privacy-page.title-179') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy</a
                  >
                  {{ $t('terms.privacy-page.title-180') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a
                  >{{ $t('terms.privacy-page.title-181') }}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    title="https://adssettings.google.com/authenticated"
                    >https://adssettings.google.com/authenticated</a
                  >.
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-182') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-183') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-184') }} </span>
              {{ $t('terms.privacy-page.title-185') }}
            </p>
            <p class="mb-">
              {{ $t('terms.privacy-page.title-186') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-187') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-188') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-189') }}</span>
                  {{ $t('terms.privacy-page.title-190') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-191') }}</span>
                  {{ $t('terms.privacy-page.title-192') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-194') }}</span>
                  {{ $t('terms.privacy-page.title-195') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-196') }} </span>
                  {{ $t('terms.privacy-page.title-197') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-198') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-199') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-200') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-201') }} </span>
                  {{ $t('terms.privacy-page.title-202') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-203') }} </span>
                  {{ $t('terms.privacy-page.title-204') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-205') }}</span>
                  {{ $t('terms.privacy-page.title-206') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-207') }}</span>
                  {{ $t('terms.privacy-page.title-208') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-209') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-210') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-211') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-212') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-213') }} </span>
              {{ $t('terms.privacy-page.title-214') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-215') }} </span>
                  {{ $t('terms.privacy-page.title-216') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-217') }} </span>
                  {{ $t('terms.privacy-page.title-218') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-219') }} </span>
                  {{ $t('terms.privacy-page.title-220') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-221') }} </span>
                  {{ $t('terms.privacy-page.title-222') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-223') }}
            </h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-224') }}</span>
                  {{ $t('terms.privacy-page.title-225') }}
                  <a href="https://cloud.google.com/" title="https://cloud.google.com/;">https://cloud.google.com/;</a>
                  {{ $t('terms.privacy-page.title-226') }}
                  <a href="https://www.google.com/policies/privacy" title="https://www.google.com/policies/privacy,"
                    >https://www.google.com/policies/privacy,</a
                  >
                  {{ $t('terms.privacy-page.title-227') }}
                  <a href="https://cloud.google.com/security/privacy" title="https://cloud.google.com/security/privacy;"
                    >https://cloud.google.com/security/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-228') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive;"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&status=Aktive;</a
                  >
                  {{ $t('terms.privacy-page.title-229') }}
                  <a
                    href="https://cloud.google.com/terms/data-processing-terms"
                    title="https://cloud.google.com/terms/data-processing-terms;"
                    >https://cloud.google.com/terms/data-processing-terms;</a
                  >
                  {{ $t('terms.privacy-page.title-230') }}
                  <a
                    href="https://cloud.google.com/terms/data-processing-terms"
                    title="https://cloud.google.com/terms/data-processing-terms"
                    >https://cloud.google.com/terms/data-processing-terms.</a
                  >
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-231') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-232') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-233') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-234') }}</span>
              {{ $t('terms.privacy-page.title-235') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-236') }}</span>
              {{ $t('terms.privacy-page.title-237') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-238') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-239') }}</span>
              {{ $t('terms.privacy-page.title-240') }}
            </p>
            <p class="mb-0-5">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-241') }} </span>
              {{ $t('terms.privacy-page.title-242') }}
            </p>
            <p class="mb-0-5">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-243') }} </span>
              {{ $t('terms.privacy-page.title-244') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-245') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-246') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-247') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-248') }} </span>
                  {{ $t('terms.privacy-page.title-249') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-250') }} </span>
                  {{ $t('terms.privacy-page.title-251') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-252') }}</span>
                  {{ $t('terms.privacy-page.title-253') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-254') }} </span>
                  {{ $t('terms.privacy-page.title-255') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-256') }} </span>
                  {{ $t('terms.privacy-page.title-257') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-258') }}</h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Mailgun: </span>
                  {{ $t('terms.privacy-page.title-260') }}
                  <a href="https://www.mailgun.com" title="https://www.mailgun.com">https://www.mailgun.com</a>;
                  {{ $t('terms.privacy-page.title-261') }}
                  <a href="https://www.mailgun.com/privacy-policy" title="https://www.mailgun.com/privacy-policy"
                    >https://www.mailgun.com/privacy-policy</a
                  >;
                  {{ $t('terms.privacy-page.title-262') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000PCbmAAG&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000PCbmAAG&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000PCbmAAG&status=Active</a
                  >.
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-263') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-264') }}
            </p>
            <p class="md-2">
              {{ $t('terms.privacy-page.title-265') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-266') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-267') }} </span>
                  {{ $t('terms.privacy-page.title-268') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-269') }} </span>
                  {{ $t('terms.privacy-page.title-270') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold"> {{ $t('terms.privacy-page.title-271') }}</span>
                  {{ $t('terms.privacy-page.title-272') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold"> {{ $t('terms.privacy-page.title-273') }} </span>
                  {{ $t('terms.privacy-page.title-274') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">Onlinemarketing</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-276') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-277') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-278') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-279') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-280') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-281') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-282') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold"> {{ $t('terms.privacy-page.title-283') }} </span>
              {{ $t('terms.privacy-page.title-284') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-285') }}</span>
              {{ $t('terms.privacy-page.title-286') }}
            </p>

            <p class="mb-2">
              <span class="text-black font-weight-bold">Facebook-Pixel: </span>
              {{ $t('terms.privacy-page.title-288') }}
              <a href="https://www.facebook.com/audiencenetwork/" title="https://www.facebook.com/audiencenetwork/"
                >https://www.facebook.com/audiencenetwork/</a
              >
              {{ $t('terms.privacy-page.title-289') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-290') }} </span>
                  {{ $t('terms.privacy-page.title-291') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-292') }} </span>
                  {{ $t('terms.privacy-page.title-293') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-294') }}</span>
                  {{ $t('terms.privacy-page.title-295') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-296') }} </span>
                  {{ $t('terms.privacy-page.title-297') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-298') }} </span>
                  {{ $t('terms.privacy-page.title-299') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-300') }}</span>
                  {{ $t('terms.privacy-page.title-301') }}
                  <a href="https://www.youronlinechoices.eu" title="https://www.youronlinechoices.eu"
                    >https://www.youronlinechoices.eu.</a
                  >
                  {{ $t('terms.privacy-page.title-302') }}
                  <a href="https://www.youradchoices.ca/choices" title="https://www.youradchoices.ca/choices"
                    >https://www.youradchoices.ca/choices.</a
                  >
                  {{ $t('terms.privacy-page.title-303') }}
                  <a href="https://www.aboutads.info/choices" title="https://www.aboutads.info/choices"
                    >https://www.aboutads.info/choices.</a
                  >
                  {{ $t('terms.privacy-page.title-304') }}
                  <a href="https://optout.aboutads.info" title="https://optout.aboutads.info"
                    >https://optout.aboutads.info.</a
                  >
                </p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-305') }}</h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Google Tag Manager: </span>
                  {{ $t('terms.privacy-page.title-307') }}
                  <a href="https://marketingplatform.google.com" title="https://marketingplatform.google.com"
                    >https://marketingplatform.google.com;</a
                  >
                  {{ $t('terms.privacy-page.title-308') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-309') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.</a
                  >
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Google Analytics: </span>
                  {{ $t('terms.privacy-page.title-311') }}
                  <a
                    href="https://marketingplatform.google.com/intl/de/about/analytics/"
                    title="https://marketingplatform.google.com/intl/de/about/analytics/"
                    >https://marketingplatform.google.com/intl/de/about/analytics/;</a
                  >
                  {{ $t('terms.privacy-page.title-312') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-313') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active;</a
                  >
                  {{ $t('terms.privacy-page.title-314') }}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de,"
                    title="https://tools.google.com/dlpage/gaoptout?hl=de"
                    >https://tools.google.com/dlpage/gaoptout?hl=de,</a
                  >
                  {{ $t('terms.privacy-page.title-315') }}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    title="https://adssettings.google.com/authenticated"
                    >https://adssettings.google.com/authenticated.</a
                  >
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-316') }} </span>
                  {{ $t('terms.privacy-page.title-317') }}
                  <a href="https://marketingplatform.google.com" title="https://marketingplatform.google.com">
                    https://marketingplatform.google.com;</a
                  >
                  {{ $t('terms.privacy-page.title-318') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-319') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.</a
                  >
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Facebook-Pixel: </span>
                  {{ $t('terms.privacy-page.title-320') }}
                  {{ $t('terms.privacy-page.title-321') }}
                  <a href="https://www.facebook.com" title="https://www.facebook.com">https://www.facebook.com;</a>
                  {{ $t('terms.privacy-page.title-322') }}
                  <a href="https://www.facebook.com/about/privacy" title="https://www.facebook.com/about/privacy"
                    >https://www.facebook.com/about/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-323') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                  >
                    https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active;</a
                  >
                  {{ $t('terms.privacy-page.title-324')
                  }}<a
                    href="https://www.facebook.com/settings?tab=ads"
                    title="https://www.facebook.com/settings?tab=ads"
                  >
                    https://www.facebook.com/settings?tab=ads.</a
                  >
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-325') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-326') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-327') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-328') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-329') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-330') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-331') }} </span>
                  {{ $t('terms.privacy-page.title-332') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-333') }} </span>
                  {{ $t('terms.privacy-page.title-334') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-335') }}</span>
                  {{ $t('terms.privacy-page.title-336') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-337') }} </span>
                  {{ $t('terms.privacy-page.title-338') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-339') }}</h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Instagram : </span>
                  {{ $t('terms.privacy-page.title-340') }}
                  <a href="https://www.instagram.com" title="https://www.instagram.com">https://www.instagram.com</a>;
                  {{ $t('terms.privacy-page.title-341') }}
                  <a href="https://instagram.com/about/legal/privacy" title="https://instagram.com/about/legal/privacy"
                    >https://instagram.com/about/legal/privacy</a
                  >.
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Facebook: </span>
                  {{ $t('terms.privacy-page.title-342') }}
                  <a href="https://www.facebook.com" title="https://www.facebook.com">https://www.facebook.com</a>;
                  {{ $t('terms.privacy-page.title-343') }}
                  <a href="https://www.facebook.com/about/privacy" title="https://www.facebook.com/about/privacy"
                    >https://www.facebook.com/about/privacy</a
                  >;
                  {{ $t('terms.privacy-page.title-344') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a
                  >; {{ $t('terms.privacy-page.title-345') }}
                  <a href="https://www.facebook.com/settings?tab=ads" title="https://www.facebook.com/settings?tab=ads"
                    >https://www.facebook.com/settings?tab=ads</a
                  >;
                  {{ $t('terms.privacy-page.title-346') }}
                  <a
                    href="https://www.facebook.com/legal/terms/page_controller_addendum"
                    title="https://www.facebook.com/legal/terms/page_controller_addendum"
                    >https://www.facebook.com/legal/terms/page_controller_addendum</a
                  >, {{ $t('terms.privacy-page.title-347') }}
                  <a
                    href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                    title="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                    >https://www.facebook.com/legal/terms/information_about_page_insights_data.</a
                  >
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">LinkedIn: </span>
                  {{ $t('terms.privacy-page.title-348') }}
                  <a href="https://www.linkedin.com" title="https://www.linkedin.com">https://www.linkedin.com;</a>
                  {{ $t('terms.privacy-page.title-349') }}
                  <a
                    href="https://www.linkedin.com/legal/privacy-policy;"
                    title="https://www.linkedin.com/legal/privacy-policy"
                    >https://www.linkedin.com/legal/privacy-policy;</a
                  >
                  {{ $t('terms.privacy-page.title-350') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active;"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active;</a
                  >
                  {{ $t('terms.privacy-page.title-351') }}
                  <a
                    href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                    title="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                    >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.</a
                  >
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">YouTube: </span>
                  {{ $t('terms.privacy-page.title-352') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy;</a
                  >
                  {{ $t('terms.privacy-page.title-353') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a
                  >; {{ $t('terms.privacy-page.title-354') }}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    title="https://adssettings.google.com/authenticated"
                    >https://adssettings.google.com/authenticated.</a
                  >
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-355') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-356') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-357') }}
            </p>
            <p class="mb-2">
              <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-358') }} </span>
              {{ $t('terms.privacy-page.title-359') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-360') }} </span>
                  {{ $t('terms.privacy-page.title-361') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-362') }}</span>
                  {{ $t('terms.privacy-page.title-363') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-364') }} </span>
                  {{ $t('terms.privacy-page.title-365') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-366') }}</span>
                  {{ $t('terms.privacy-page.title-367') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-368') }}</h3>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Google Maps: </span>
                  {{ $t('terms.privacy-page.title-370') }}
                  <a href="https://cloud.google.com/maps-platform" title="https://cloud.google.com/maps-platform"
                    >https://cloud.google.com/maps-platform</a
                  >;
                  {{ $t('terms.privacy-page.title-371') }}
                  <a href="https://policies.google.com/privacy" title="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy</a
                  >;
                  {{ $t('terms.privacy-page.title-372') }}
                  <a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active"
                    title="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active"
                    >https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active</a
                  >; {{ $t('terms.privacy-page.title-373') }}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de"
                    title="https://tools.google.com/dlpage/gaoptout?hl=de"
                    >https://tools.google.com/dlpage/gaoptout?hl=de</a
                  >, {{ $t('terms.privacy-page.title-374') }}
                  <a
                    href="https://adssettings.google.com/authenticated"
                    title="https://adssettings.google.com/authenticated"
                    >https://adssettings.google.com/authenticated</a
                  >.
                </p>
              </li>
            </ul>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-375') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-376') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-377') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-378') }}
            </p>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-379') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-380') }}
            </p>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-381') }}
            </p>
            <h3 class="mb-2">{{ $t('terms.privacy-page.title-382') }}</h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-383') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">
                    {{ $t('terms.privacy-page.title-384') }}
                  </span>
                  {{ $t('terms.privacy-page.title-385') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold"> {{ $t('terms.privacy-page.title-386') }}</span>
                  {{ $t('terms.privacy-page.title-387') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-388') }} </span>
                  {{ $t('terms.privacy-page.title-389') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-390') }} </span>
                  {{ $t('terms.privacy-page.title-391') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-392') }} </span>
                  {{ $t('terms.privacy-page.title-393') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-394') }}</span>
                  {{ $t('terms.privacy-page.title-395') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-396') }} </span>
                  {{ $t('terms.privacy-page.title-397') }}
                </p>
              </li>
            </ul>
            <h3 class="mb-2">
              {{ $t('terms.privacy-page.title-398') }}
            </h3>
            <p class="mb-2">
              {{ $t('terms.privacy-page.title-399') }}
            </p>
            <ul class="mb-2">
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-400') }} </span>
                  {{ $t('terms.privacy-page.title-401') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">Cross-Device Tracking: </span>
                  {{ $t('terms.privacy-page.title-403') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">IP-Masking: </span>
                  {{ $t('terms.privacy-page.title-405') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-406') }} </span>
                  {{ $t('terms.privacy-page.title-407') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-408') }} </span>
                  {{ $t('terms.privacy-page.title-409') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-410') }} </span>
                  {{ $t('terms.privacy-page.title-411') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-412') }}</span>
                  {{ $t('terms.privacy-page.title-413') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-414') }}</span>
                  {{ $t('terms.privacy-page.title-415') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-416') }} </span>
                  {{ $t('terms.privacy-page.title-417') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-418') }}</span>
                  {{ $t('terms.privacy-page.title-419') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-420') }} </span>
                  {{ $t('terms.privacy-page.title-421') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-422') }} </span>
                  {{ $t('terms.privacy-page.title-423') }}
                </p>
              </li>
              <li>
                <p class="mb-0-5">
                  <span class="text-black font-weight-bold">{{ $t('terms.privacy-page.title-424') }} </span>
                  {{ $t('terms.privacy-page.title-425') }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PrivacyNavigationTabs from './PrivacyNavigationTabs'
import moment from 'moment'

export default {
  components: {
    PrivacyNavigationTabs
  },
  data() {
    return {}
  },
  computed: {
    getFormattedData() {
      return moment('2020-03-27 09:00:00')
        .locale(this.$root.$i18n.locale)
        .format('MMMM, DD YYYY')
    }
  }
}
</script>
