const getClientBooking = state => state.clientBooking
const getReservedProducts = state => state.reservedProducts
const getReservedProductsBySalonId = state => id => state.reservedProducts.filter(item => item.salon_id === id)
const getFormatedReservedProductsBySalonId = state => id => {
  let products = []

  state.reservedProducts.forEach(item => {
    if (item.salon_id === id && !item.reservedFlag) {
      let productData = { product_id: item.id }

      if (item.variation) {
        productData = {
          ...productData,
          ...{ variation_id: item.variation.id }
        }
      }

      products.push(productData)
    }
  })

  let reservedProducts = []

  state.reservedProducts.forEach(item => {
    if (item.reservedFlag) {
      console.log(item)
      reservedProducts.push(item.deleteId)
    }
  })

  let payload = null

  payload = {
    products: products
  }

  if (reservedProducts.length) {
    payload = {
      ...payload,
      ...{ reserved_products: reservedProducts }
    }
  }

  return payload
}

export default {
  getClientBooking,
  getReservedProducts,
  getReservedProductsBySalonId,
  getFormatedReservedProductsBySalonId
}
