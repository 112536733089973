<template>
  <!-- services tabs -->
  <!-- Nav tabs -->
  <div>
    <b-tabs v-if="salonServices" content-class="mt-0-5" nav-class="servicesTabsNav">
      <b-tab v-if="salonServices.Lady" :active="true">
        <template v-slot:title>
          <span class="lady-icon"></span>
          <span class="pl-md-1 pl-0-5 pr-0-5 tab-text">
            {{ $t('common.tab.ladies') }}
          </span>
        </template>
        <div v-if="salonServices" class="d-flex mt-2 flex-wrap">
          <div v-for="(service, key) in salonServices.Lady" :key="`services-${key}`">
            <span class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5 bRadius">{{
              service.title
            }}</span>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="salonServices.Gentleman" :active="true">
        <template v-slot:title>
          <span class="gent-icon"></span>
          <span class="pl-md-1 pl-0-5 pr-0-5 tab-text">
            {{ $t('common.tab.gents') }}
          </span>
        </template>
        <div v-if="salonServices" class="d-flex mt-2 flex-wrap">
          <div v-for="(service, key) in salonServices.Gentleman" :key="`services-${key}`">
            <span
              class="d-inline-block bg-secondary-light text-small text-gray border-radius py-0-5 px-1 mr-0-5 mb-1-5 bRadius"
            >
              {{ service.title }}
            </span>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="salonServices.Boy || salonServices.Girl" class="mr-2">
        <template v-slot:title>
          <span class="child-icon"></span>
          <span class="pl-md-1 pl-0-5 pr-0-5 tab-text">
            {{ $t('common.tab.child') }}
          </span>
        </template>
        <div v-if="salonServices.Boy" class="d-flex mt-2 flex-wrap">
          <div v-for="(service, key) in salonServices.Boy" :key="`services-${key}`">
            <span class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5 bRadius">
              {{ service.title }}
            </span>
          </div>
        </div>
        <div v-if="salonServices.Girl" class="d-flex mt-2 flex-wrap">
          <div v-for="(service, key) in salonServices.Girl" :key="`services-${key}`">
            <span class="d-inline-block bg-secondary-light text-small text-gray py-0-5 px-1 mr-0-5 mb-1-5 bRadius">
              {{ service.title }}
            </span>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
export default {
  props: {
    salonServices: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
