<template>
  <b-dropdown
    :toggle-class="`p-0 custom-toggle bg-transparent ${btnClass}`"
    menu-class="menu--w-auto py-0"
    variant="link"
    :no-caret="true"
  >
    <template v-slot:button-content>
      <span class="text-black text-uppercase align-middle">
        <font-awesome-icon :icon="['far', 'globe']" class="text-gray ml-0-5"></font-awesome-icon>
        {{ $root.$i18n.locale }}
      </span>
    </template>
    <b-dropdown-item
      v-for="(lang, i) in langs"
      :key="`Lang${i}`"
      class="text-center"
      :class="{ selected: $root.$i18n.locale === lang }"
      @click="setLanguage(lang)"
    >
      <span class="text-black text-uppercase align-middle">{{ lang }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'LanguageDropdown',
  props: {
    btnClass: {
      type: String,
      default: 'py-0-8'
    }
  },
  data() {
    return {
      langs: ['de', 'en']
    }
  },
  methods: {
    ...mapMutations({
      setSiteLang: 'user/SET_SITE_LANG'
    }),
    setLanguage(val) {
      this.setSiteLang(val)
    }
  }
}
</script>

<style lang="scss">
.custom-toggle {
  background-color: #fff !important;
  line-height: 1rem !important;
  &:after {
    content: none !important;
  }
}
.menu--w-auto {
  li {
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: 0;
    }
  }
  min-width: auto !important;
  .dropdown-item {
    padding: 10px;
    &:hover,
    &:focus {
      background-color: #fbfbfb;
    }
  }
  .selected {
    .dropdown-item {
      background-color: rgba(255, 107, 9, 0.07);
    }
  }
}
.dropdown-menu {
  width: 100% !important;
}
</style>
