import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import shop from './modules/shop'

import haircutParams from './modules/haircut-params'
import { userMessages } from './modules/user-messages'
import salons from './modules/salons'
import user from './modules/user'
import payment from './modules/payment'

Vue.use(Vuex)
Vue.component('pagination', require('laravel-vue-pagination'))

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    haircutParams,
    userMessages,
    salons,
    payment,
    user,
    shop
  }
})

export default store
