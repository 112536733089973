var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"calendar-pager row align-items-center justify-content-between mb-1"},[_c('div',{staticClass:"col-auto d-flex align-items-center"},[_c('span',{staticClass:"px-0-5",class:{ 'cursor-pointer': !_vm.isFirstWeek() },on:{"click":function($event){return _vm.manageWeekDates(_vm.monthDaysContext, 'previousWeek', $event)}}},[_c('font-awesome-icon',{staticClass:"next-icon",class:{ inactive: _vm.isFirstWeek() },attrs:{"icon":['far', 'angle-left']}})],1),_c('span',{staticClass:"font-weight-medium pt-0-3"},[_vm._v(_vm._s(_vm.calendarMonth())+" "+_vm._s(_vm.calendarYear()))]),_c('span',{staticClass:"px-0-5",class:{ 'cursor-pointer': !_vm.isInactiveNextWeekSwitch },on:{"click":function($event){return _vm.manageWeekDates(_vm.monthDaysContext, 'nextWeek', $event)}}},[_c('font-awesome-icon',{staticClass:"next-icon",class:{ inactive: _vm.isInactiveNextWeekSwitch },attrs:{"icon":['far', 'angle-right']}})],1)]),_c('div',{class:{ 'col-7': this.$route.path.includes('profile-page') }},[_c('vc-date-picker',{ref:"calendar",attrs:{"popover":{ placement: 'bottom', visibility: 'click' },"attributes":_vm.calendarAttrs,"color":"orange","min-date":_vm.today.toDate(),"max-date":_vm.lastDayOfMaxMonth.toDate(),"rows":1,"columns":this.$route.name === 'salon-page' ? 1 : 2,"nav-visibility":'hidden',"pane-width":270,"is-expanded":"","locale":{ id: _vm.isDeutchLang ? 'de' : 'en', masks: { weekdays: 'WW' }, firstDayOfWeek: 2 }},scopedSlots:_vm._u([{key:"header-title",fn:function(ref){
var monthLabel = ref.monthLabel;
var yearLabel = ref.yearLabel;
return _c('span',{},[_vm._v(" "+_vm._s(monthLabel)+" "+_vm._s(yearLabel)+" ")])}}]),model:{value:(_vm.choosenDate),callback:function ($$v) {_vm.choosenDate=$$v},expression:"choosenDate"}},[_c('div',{staticClass:"col-auto show-full-calendar d-none d-md-block",class:{ 'text-right': this.$route.path.includes('profile-page') }},[_c('small',{staticClass:"mr-0-5"},[_vm._v("\n                "+_vm._s(_vm.$t('common.forms.label.full_calendar'))+"\n              ")]),_c('span',{staticClass:"text-primary"},[_vm._v("+")])])])],1)]),_c('div',{staticClass:"days-name-row row text-center mb-1"},_vm._l((_vm.weekDays),function(day){return _c('div',{key:(day + "-week"),staticClass:"col"},[_c('span',[_vm._v(_vm._s(day))])])}),0),_c('div',{staticClass:"days-row row text-center"},_vm._l((_vm.days),function(day){return _c('div',{key:((day.date()) + "-weekday"),staticClass:"col px-0-5 available-date",class:{
            'current-day': _vm.isCurrentDay(day),
            'choosen-day': _vm.isChoosenDate(day),
            'inactive-day': _vm.isInactiveDay(day) && !_vm.isChoosenDate(day),
            'future-day': _vm.isFutureDay(day)
          },on:{"click":function($event){return _vm.chooseDate(day)}}},[_c('span',[_vm._v(_vm._s(day.date()))])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }