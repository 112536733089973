<template>
  <div id="v-pills-tab" class="nav flex-column nav-pills mx-n1 mx-md-n0" role="tablist" aria-orientation="vertical">
    <div class="pages mb-0-5 justify-content-around justify-content-md-center">
      <router-link
        v-for="(page, key) in userPrivacyPages"
        :key="`${key}-page`"
        :to="'/' + page.path"
        :class="'nav-link py-1-5 ml-0 ml-md-1 text-nowrap'"
        active-class="linkActive pl-1"
      >
        <div class="d-flex align-items-center">
          {{ $t(page.i18KeyName) }}
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userPrivacyPages: [
        {
          i18KeyName: 'terms.agb_page-title',
          path: 'agb'
        },
        {
          i18KeyName: 'terms.agb_for_partners_page-title',
          path: 'agb-for-partners'
        },
        {
          i18KeyName: 'terms.privacy_page-title',
          path: 'privacy-policy'
        },
        {
          i18KeyName: 'terms.impressum_page-title',
          path: 'impressum'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.pages a {
  padding: 0.5rem 0;
  color: #8d9fa7;
}
.nav-pills a.nav-link.active {
  padding-top: 1rem;
  padding-bottom: 100px;
  color: orange;
}
.linkActive {
  border-left: 2px solid#ff6b09;
  border-radius: 0rem !important;
  color: black !important;
}
</style>
