<template>
  <vc-date-picker
    ref="calendarWide"
    v-model="choosenDate"
    class="calendarForSalonsFilter"
    :popover="{
      labelClass: 'btn btn-outline-secondary-custom collapsed py-0-8',
      placement: 'bottom-left',
      visibility: 'click'
    }"
    :attributes="calendarAttrs"
    color="orange"
    :min-date="today.toDate()"
    :max-date="lastDayOfMaxMonth.toDate()"
    :rows="1"
    :columns="2"
    :locale="{ id: getSiteLang, firstDayOfWeek: 2 }"
    :first-day-of-week="2"
  >
    <button
      v-if="!choosenDate"
      ref="date"
      :class="`btn btn-outline-secondary-custom collapsed py-0-8 mr-1 ${choosenDate ? 'active' : ''}`"
      style="font-size:14px;"
    >
      {{ $t('common.forms.label.date') }}
    </button>
    <button
      v-else
      ref="date"
      :class="`btn btn-outline-secondary-custom collapsed py-0-8 mr-1 ${choosenDate ? 'active' : ''}`"
      style="font-size:14px;"
    >
      {{ viewChoosenDate }}
    </button>
  </vc-date-picker>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      choosenDate: null,
      calendarAttrs: [
        {
          dates: new Date()
        }
      ],
      today: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      monthDaysContext: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }),
      viewChoosenDate: null
    }
  },
  computed: {
    lastDayOfMaxMonth() {
      return this.today
        .clone()
        .set({ hour: 1, minute: 0, second: 0, millisecond: 100 })
        .add(1, 'months')
        .endOf('month')
    },
    ...mapGetters({
      getCommonHaircutParams: 'haircutParams/getCommonHaircutParams',
      getSiteLang: 'user/getSiteLang'
    })
  },
  watch: {
    choosenDate(newVal) {
      moment.locale(this.getSiteLang)
      this.manageWeekDatesFullCal(newVal)
      this.formattedViewDate(newVal)
      this.setDate(newVal)
    },
    getSiteLang(newVal) {
      moment.locale(newVal)
      this.manageWeekDatesFullCal(this.choosenDate)
      this.formattedViewDate(this.choosenDate)
    }
  },
  mounted() {
    this.setChoosenDate()
    if (this.choosenDate) {
      let choosen = moment(this.choosenDate)
      if (this.today.diff(choosen, 'days') > 0) {
        this.clearChoosenDate()
        this.clearDate()
      }
    }
  },
  methods: {
    ...mapActions('haircutParams', {
      setCommonHaircutParams: 'setCommonHaircutParams'
    }),
    ...mapMutations('haircutParams', {
      setDate: 'SET_DATE',
      clearChoosenDate: 'CLEAR_CHOOSEN_DATE',
      clearDate: 'CLEAR_DATE'
    }),
    manageWeekDatesFullCal(dateValue) {
      const dateParam = { paramName: 'date', value: dateValue }
      this.setCommonHaircutParams(dateParam)
    },
    setChoosenDate() {
      this.choosenDate = this.getCommonHaircutParams.date
      this.formattedViewDate(this.choosenDate)
    },
    formattedViewDate(val) {
      this.viewChoosenDate = moment(val).format('MMMM DD, Y')
    }
  }
}
</script>

<style scoped>
.calendarForSalonsFilter {
  z-index: 11;
}
</style>
