<template>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12 col px-0-5">
        <MultiSelect
          v-if="existingServices"
          v-model="choosenServices"
          :options="isBookingPage === true ? bookingOptions : existingServices"
          :searchable="true"
          :multiple="this.$route.name === 'salon-page' ? true : false"
          :group-select="true"
          :close-on-select="true"
          :allow-empty="this.$route.name === 'salon-page' ? true : false"
          :show-labels="false"
          :open-direction="'bottom'"
          track-by="title"
          label="title"
          :placeholder="$root.$i18n.locale === 'de' ? 'Beginne zu tippen...' : 'Start typing...'"
          :limit="servicesDisplayLimit"
          @remove="removeTab"
          @search-change="searchForServices"
          @select="onSelect"
        >
          <template slot="caret" slot-scope="{ toggle }">
            <div
              class="multiselect__select d-flex align-items-center justify-content-center"
              @mousedown.prevent.stop="toggle()"
            >
              <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MultiSelect from 'vue-multiselect'
import { isEmpty } from 'lodash'

export default {
  name: 'Services',
  components: { MultiSelect },
  props: {
    servicesDisplayLimit: {
      type: Number,
      default: 3
    },
    salonId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      choosenServices: [],
      services: [],
      bookingOptions: [],
      formData: {},
      parameters: [],
      bookingSalonId: null
    }
  },
  computed: {
    ...mapGetters({
      existingServices: 'haircutParams/getExistingServices',
      commonHaircutParams: 'haircutParams/getCommonHaircutParams',
      storeChoosenServices: 'haircutParams/getChoosenServices',
      bookingServices: 'salons/getSalonServices',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams'
    }),
    isBookingPage() {
      return this.$route.path.includes('/salon-page/') ? true : false
    }
  },
  watch: {
    storeChoosenServices: {
      deep: true,
      handler(newVal) {
        this.choosenServices = newVal
      }
    },
    allSelectedHaircutParams: {
      deep: true,
      handler(newVal) {
        if (this.isBookingPage && this.bookingSalonId && this.bookingServices) {
          this.bookingOptions = []
          if (newVal.gender) {
            if (this.bookingServices[newVal.gender.value]) {
              this.bookingOptions = this.bookingServices[newVal.gender.value]
            } else this.bookingOptions = []
          }
        }
      }
    }
  },
  mounted() {
    if (!this.isBookingPage) {
      this.getServices()
      this.restoreChoosenServices()
      this.choosenServices = this.storeChoosenServices
    } else {
      this.bookingSalonId = parseInt(this.$route.path.match(/\d+/))
      this.clearChoosenServices()
      if (Object.keys(this.bookingServices).length && !isEmpty(this.allSelectedHaircutParams.gender)) {
        if (this.bookingServices[this.allSelectedHaircutParams.gender.value]) {
          this.bookingOptions = []
          this.bookongOptions = this.bookingServices[this.allSelectedHaircutParams.gender.value]
        } else {
          this.bookingOptions = []
        }
      }
    }
  },
  beforeDestroy() {
    if (this.$route.name.includes('salon-page')) this.clearChoosenServices()
  },
  // TODO: 1 method for all clear-data methods
  methods: {
    ...mapActions('haircutParams', {
      setCommonHaircutParams: 'setCommonHaircutParams',
      addServices: 'addServices',
      servicesSearch: 'getServicesByName',
      clearChoosenServices: 'clearChoosenServices',
      getServices: 'getApiServices',
      addExistingServiceBack: 'addExistingServiceBack',
      removeExistingService: 'removeExistingService'
    }),
    ...mapActions('salons', {
      getSalonServices: 'getSalonServices'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapMutations('haircutParams', {
      removeChoosenService: 'REMOVE_CHOOSEN_SERVICE',
      addChoosenService: 'ADD_SERVICE',
      saveAllSalonsChoosenServices: 'SAVE_CHOOSEN_SERVICES',
      addServiceForBooking: 'ADD_SERVICE_FOR_SALON_PAGE',
      removeServiceForBooking: 'REMOVE_SERVICE_FOR_SALON_PAGE',
      restoreChoosenServices: 'RESTORE_CHOOSEN_SERVICES'
    }),
    handleChoosenServiceDelete(service) {
      this.addExistingServiceBack(service)
    },
    removeTab(option) {
      if (this.$route.name === 'salon-page') this.removeServiceForBooking(option)
      else this.removeChoosenService()
    },
    onSelect(option) {
      if (!this.isBookingPage) {
        this.addChoosenService(option)
        this.saveAllSalonsChoosenServices()
        this.getServices()
      } else {
        this.addServiceForBooking(option)
      }
    },
    removeService(option) {
      if (this.$route.name === 'salon-page') this.removeServiceForBooking
    },
    searchForServices(service) {
      this.parameters = []

      if (isEmpty(this.allSelectedHaircutParams.gender)) {
        const errorInfo = {
          type: 'Not enough data for search',
          messageI18Key: 'common.forms.alert.another.params_empty'
        }
        this.setError(errorInfo)
      } else {
        if (!isEmpty(this.allSelectedHaircutParams.gender)) {
          this.parameters.push(this.allSelectedHaircutParams.gender.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.length)) {
          this.parameters.push(this.allSelectedHaircutParams.length.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          this.parameters.push(this.allSelectedHaircutParams.age.id)
        }

        Object.assign(this.formData, { service }, { parameters: this.parameters })

        if (service.length >= 3 && this.parameters.length) {
          this.servicesSearch(this.formData)
        }
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect__strong {
  color: #8d9fa7;
  font-weight: 400;
}
.multiselect__select {
  height: 48px;
}
.multiselect__select:before {
  content: none;
}
.multiselect__tag {
  margin-bottom: -8px !important;
}
</style>
