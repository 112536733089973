import Repository from './repository'

const loginUrl = '/login'
const meUrl = '/me'
const logoutUrl = '/logout'
const registerUrl = '/client/register'
const socialAuthUrl = '/client/auth'
const socialLoginUrl = '/client/soc-login'
const updateProfileUrl = '/user'
const changePasswordUrl = '/change-password'
const templateUrl = '/template'
const resetPasswordRequestUrl = '/reset-password-request'
const setNewPasswordUrl = '/reset-password-set'
const addreviewUrl = '/review'
const contactFormClient = '/contact-from-client'
const emailVerification = 'email/verify/'
const emailResend = 'email/resend/'

export const UserRepository = {
  register(registerPayload) {
    return Repository.post(registerUrl, registerPayload).then(data => data.data)
  },
  login(loginPayload) {
    return Repository.post(loginUrl, loginPayload).then(({ data }) => data)
  },
  getAccountInfo() {
    return Repository.get(meUrl).then(({ data }) => data)
  },
  socialAuth(registerPayload) {
    return Repository.post(socialAuthUrl, registerPayload).then(({ data }) => data)
  },
  socialLogin(loginPayload) {
    return Repository.post(socialLoginUrl, loginPayload).then(({ data }) => data)
  },
  updatePassword(passwordPayload) {
    return Repository.post(changePasswordUrl, passwordPayload).then(({ data }) => data)
  },
  updateProfile(profilePayload) {
    return Repository.post(updateProfileUrl, profilePayload).then(({ data }) => data)
  },
  createTemplate(payload) {
    return Repository.post(`${templateUrl}-create`, payload).then(({ data }) => data)
  },
  deleteTemplate(payload) {
    return Repository.delete(`${templateUrl}-delete/${payload}`).then(({ data }) => data)
  },
  resetPasswordRequest(email) {
    return Repository.post(resetPasswordRequestUrl, { email })
  },
  setNewPassword(payload) {
    return Repository.post(setNewPasswordUrl, payload).then(({ data }) => data)
  },
  updateTemplate(payload) {
    return Repository.post(`${templateUrl}-update`, payload).then(({ data }) => data)
  },
  addReviews(payload) {
    return Repository.post(addreviewUrl, payload).then(({ data }) => data)
  },
  logout() {
    return Repository.post(logoutUrl)
  },
  contact(payload) {
    return Repository.post(contactFormClient, payload).then(({ data }) => data)
  },
  emailVerify(payload) {
    return Repository.get(
      `${emailVerification}${payload.id}?expires=${payload.expires}&signature=${payload.signature}`
    ).then(({ data }) => data)
  },
  resendEmail() {
    return Repository.get(emailResend).then(({ data }) => data)
  }
}
