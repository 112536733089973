<template>
  <div class="px-1 px-md-3 pb-3">
    <div class="text-center mb-3 mt-3 mt-md-0">
      <h3>{{ $t('common.modal.salon_request.form.title') }}</h3>
      <p>{{ $t('common.modal.salon_request.form.description') }}</p>
    </div>
    <form>
      <div class="form-group mb-3">
        <label class="control-label font-weight-medium text-black" for="feedback">
          {{ $t('common.forms.label.email') }}
        </label>
        <input
          id="email"
          v-model="email"
          v-validate="'required|min:5|max:255'"
          name="email"
          class="form-control"
          rows="10"
          :class="{ 'mb-1': errors.has('email') }"
        />
        <span v-if="errors.has('email')" class="text-danger mt-1">{{ errors.first('email') }}</span>
      </div>

      <div class="form-group">
        <button class="btn btn-primary btn-lg btn-block" @click.prevent="submitForm()">
          {{ $t('common.modal.salon_request.form.button') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SalonsRequestForm',
  props: {
    disabledSubmutBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      email: null,
      dataSent: false
    }
  },
  computed: {
    ...mapGetters({
      requestNewSalonsData: 'salons/getRequestNewSalonsData'
    }),
    disabledSubmit() {
      return this.dataSent
    }
  },
  methods: {
    ...mapActions({
      сreateSalonsRequestUrl: 'salons/сreateSalonsRequestUrl',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    hideModal() {
      this.$bvModal.hide('request-salons-modal')
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.addSalonRequest()
        }
      })
    },
    addSalonRequest() {
      let payloadData = this.requestNewSalonsData
      if (this.email) {
        payloadData = {
          ...payloadData,
          ...{ email: this.email }
        }
      }

      this.$gmapApiPromiseLazy().then(() => {
        let geocoder = new google.maps.Geocoder()
        let location = new google.maps.LatLng(this.requestNewSalonsData.latitude, this.requestNewSalonsData.longitude)

        geocoder.geocode({ latLng: location }, (results, status) => {
          if (status == 'OK') {
            payloadData.city = results[0].address_components[2].long_name

            this.createRequest(payloadData)
          } else {
            this.createRequest(payloadData)
          }
        })
      })
    },
    createRequest(payloadData) {
      this.сreateSalonsRequestUrl(payloadData)
        .then(data => {
          this.dataSent = true
          this.hideModal()

          this.setSuccess({
            type: 'common.forms.alert.request_salon.success',
            messageI18Key: 'common.forms.alert.request_salon.success'
          })
        })
        .catch(error => {
          this.setError({
            type: 'Error',
            messageI18Key: 'common.forms.alert.another.global_error'
          })
        })
    }
  }
}
</script>
<style scoped>
.feeback-area {
  min-height: 175px;
  resize: none;
}
.btn-rate {
  width: 48px;
  height: 48px;
}
</style>
