import Vue from 'vue'
import Router from 'vue-router'
import HaircutParamsPage from './views/HaircutParamsPage'
import SalonsPage from './views/SalonsPage'
import SalonPage from './views/SalonPage'

import UserProfilePage from './views/profile/UserProfilePage'
import UserTemplates from './views/profile/UserTemplates'
import UserHistory from './views/profile/UserHistory'
import UserFavorites from './views/profile/UserFavorites'
import UserCreditCard from './views/profile/UserCreditCard'
import UserProfileEditor from './views/profile/UserProfileEditor'
import PaymentPage from './views/payment/PaymentPage'
import Product from './views/shop/Product'
import Marketplace from './views/shop/Marketplace'
import SignUp from './views/auth/SignUp'
import SignIn from './views/auth/SignIn'
import ResetPassword from './views/auth/ResetPassword'
import NotFound from './views/404'
import Agb from './views/privacy/Agb'
import AgbForPartners from './views/privacy/AgbForPartners'
import PrivacyPolicy from './views/privacy/PrivacyPolicy'
import Impressum from './views/privacy/Impressum'
import ContactUs from './views/contact/ContactUs'
import CoiffeurOnlineBuchen from '@/components/CoiffeurOnlineBuchen'
import CoiffeurAarau from './views/seo-pages/CoiffeurAarau'
import CoiffeurBern from './views/seo-pages/CoiffeurBern'
import CoiffeurBasel from './views/seo-pages/CoiffeurBasel'
import HerrenCoiffeur from './views/seo-pages/Herren-Coiffeur'
import KinderCoiffeur from './views/seo-pages/Kinder-Coiffeur'
import DamenCoiffeur from './views/seo-pages/DamenCoiffeur'
import CoiffeurZürich from './views/seo-pages/CoiffeurZürich'
import CoiffeurStGallen from './views/seo-pages/CoiffeurStGallen'
import Haarprodukte from './views/seo-pages/Haarprodukte'
import EmailVerification from './views/verification/EmailVerification'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        i18PageTitle: '',
        layout: 'default'
      },
      component: HaircutParamsPage
    },
    {
      path: '/salons',
      name: 'salons-page',
      meta: {
        i18PageTitle: 'salons.page-title',
        layout: 'max-width-container'
      },
      component: SalonsPage
    },
    {
      path: '/salon-page/:id',
      meta: {
        i18PageTitle: '',
        layout: 'max-width-container'
      },
      name: 'salon-page',
      component: SalonPage
    },
    {
      path: '/Coiffeur-online-buchen',
      redirect: '/',
      name: 'coiffeur-online-buchen',
      meta: {
        i18PageTitle: 'Deinen Coiffeur online buchen und viele Vorteile sichern | QUIFF',
        layout: 'header-bordered'
      },
      component: CoiffeurOnlineBuchen
    },
    {
      path: '/Coiffeur-Aarau',
      name: 'coiffeur-aarau',
      meta: {
        i18PageTitle: 'Finde deinen Coiffeur in Aarau – grosse Auswahl mit Vorteilen für Dich | Quiff',
        layout: 'header-bordered'
      },
      component: CoiffeurAarau
    },
    {
      path: '/Coiffeur-Zürich',
      name: 'Coiffeur-Zürich',
      meta: {
        i18PageTitle: 'Bester Coiffeur in Zürich? Wähle Deinen Favoriten und buche einen Termin | Quiff',
        layout: 'header-bordered'
      },
      component: CoiffeurZürich
    },
    {
      path: '/Coiffeur-St-Gallen',
      name: 'Coiffeur St Gallen',
      meta: {
        i18PageTitle: 'Coiffeur in St. Gallen – finde Deinen Favoriten und buche online | Quiff',
        layout: 'seo'
      },
      component: CoiffeurStGallen
    },
    {
      path: '/Coiffeur-Bern',
      name: 'coiffeur-bern',
      meta: {
        i18PageTitle: 'Dein Coiffeur in Bern und Umgebung – suchen, finden, buchen | Quiff',
        layout: 'header-bordered'
      },
      component: CoiffeurBern
    },
    {
      path: '/Coiffeur-Basel',
      name: 'coiffeur-basel',
      meta: {
        i18PageTitle: 'Deinen neuen Coiffeur in Basel und Umgebung finden | Quiff',
        layout: 'header-bordered'
      },
      component: CoiffeurBasel
    },
    {
      path: '/Herren-Coiffeur',
      name: 'herren-coiffeur',
      meta: {
        i18PageTitle: 'Hier findest Du ihn – den Herren Coiffeur für Deinen Stil | Quiff',
        layout: 'header-bordered'
      },
      component: HerrenCoiffeur
    },
    {
      path: '/Kinder-Coiffeur',
      name: 'kinder-coiffeur',
      meta: {
        i18PageTitle: 'Kinder Coiffeur – finde den passenden Coiffeur für die Kleinen | Quiff',
        layout: 'header-bordered'
      },
      component: KinderCoiffeur
    },
    {
      path: '/Damen-Coiffeur',
      name: 'damen-coiffeur',
      meta: {
        i18PageTitle: 'Finde den passenden und best bewerteten Damen Coiffeur | Quiff',
        layout: 'seo'
      },
      component: DamenCoiffeur
    },
    {
      path: '/Haarprodukte',
      name: 'haarprodukte-coiffeur',
      meta: {
        i18PageTitle: 'Haarprodukte online kaufen, Coiffeur Treuepunkte einlösen | Q',
        layout: 'seo'
      },
      component: Haarprodukte
    },
    {
      path: '/marketplace/:id',
      name: 'marketplace',
      meta: {
        i18PageTitle: 'Marketplace',
        layout: 'header-bordered'
      },
      component: Marketplace,
      children: [
        {
          path: 'product',
          name: 'product-detail-page',
          meta: {
            i18PageTitle: 'Product',
            layout: 'header-bordered'
          },
          component: Product
        }
      ]
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      meta: {
        i18PageTitle: 'signup.page-title',
        layout: 'auth'
      },
      component: SignUp
    },
    {
      path: '/payment/:id',
      name: 'payment',
      meta: {
        i18PageTitle: 'payment.page-title',
        layout: 'default'
      },
      component: PaymentPage
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      meta: {
        i18PageTitle: 'signin.page-title',
        layout: 'auth'
      },
      component: SignIn
    },
    {
      path: '/reset-password-request',
      name: 'reset-password-request',
      meta: {
        i18PageTitle: 'forgot_password.page-title',
        layout: 'auth'
      },
      component: ResetPassword
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      meta: {
        i18PageTitle: 'contact-us.title',
        layout: 'default'
      },
      component: ContactUs
    },
    {
      path: '/agb',
      name: 'agb',
      meta: {
        i18PageTitle: 'terms.agb_page-title',
        layout: 'header-bordered'
      },
      component: Agb
    },
    {
      path: '/agb-for-partners',
      name: 'agb-for-partners',
      meta: {
        i18PageTitle: 'terms.agb_for_partners_page-title',
        layout: 'header-bordered'
      },
      component: AgbForPartners
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      meta: {
        i18PageTitle: 'terms.privacy_page-title',
        layout: 'header-bordered'
      },
      component: PrivacyPolicy
    },
    {
      path: '/impressum',
      name: 'impressum',
      meta: {
        i18PageTitle: 'terms.impressum_page-title',
        layout: 'header-bordered'
      },
      component: Impressum
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password-new',
      meta: {
        i18PageTitle: 'forgot_password.page-title',
        layout: 'auth'
      },
      component: ResetPassword
    },
    {
      path: '/profile-page',
      name: 'profile-page',
      meta: {
        i18PageTitle: 'profile.page-title',
        layout: 'header-bordered'
      },
      component: UserProfilePage,
      children: [
        {
          path: 'edit',
          name: 'user-profile-editor',
          meta: {
            i18PageTitle: 'profile_editor.page-title',
            layout: 'header-bordered'
          },
          component: UserProfileEditor
        },
        {
          path: 'templates',
          name: 'user-templates',
          meta: {
            i18PageTitle: 'profile_templates.page-title',
            layout: 'header-bordered'
          },
          component: UserTemplates
        },
        {
          path: 'history',
          name: 'user-history',
          meta: {
            i18PageTitle: 'profile_history.page-title',
            layout: 'header-bordered'
          },
          component: UserHistory
        },
        {
          path: 'favorites',
          name: 'user-favorites',
          meta: {
            i18PageTitle: 'profile_favorites.page-title',
            layout: 'header-bordered'
          },
          component: UserFavorites
        },
        {
          path: 'credit-card',
          name: 'user-credit-card',
          meta: {
            i18PageTitle: 'profile_credit-card.page-title',
            layout: 'header-bordered'
          },
          component: UserCreditCard
        }
      ]
    },
    {
      path: '/verification/:id/:expires/:signature',
      name: 'verification',
      meta: {
        i18PageTitle: 'profile_credit-card.page-title',
        layout: 'auth'
      },
      component: EmailVerification
    },
    {
      path: '/not-found',
      name: 'not-found',
      meta: {
        i18PageTitle: 'error.page-title',
        layout: 'default'
      },
      component: NotFound
    }
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    'sign-in',
    'sign-up',
    'salons-page',
    'salon-page',
    'contact-us',
    'home',
    'not-found',
    'reset-password-request',
    'reset-password-new',
    'agb',
    'agb-for-partners',
    'privacy-policy',
    'impressum',
    'marketplace',
    'product-detail-page',
    'coiffeur-online-buchen',
    'coiffeur-aarau',
    'coiffeur-bern',
    'coiffeur-basel',
    'kinder-coiffeur',
    'herren-coiffeur',
    'damen-Coiffeur',
    'haarprodukte-coiffeur',
    'verification'
  ]
  const isPublic = publicPages.includes(to.name)
  const authUser = localStorage.getItem('access_token')

  if (!to.matched.length) {
    if (!to.path.includes('verification')) next('/not-found')
  } else {
    if (!isPublic && !authUser) {
      return next('/sign-in')
    }

    next()
  }
})

export default router
