<template>
  <div class="col-12 px-0">
    <div class="row text-center">
      <div
        v-for="genderOption in adultOptions"
        :key="genderOption.id"
        class="col pl-md-0-5 pr-md-1 pr-md-1 common-icon"
      >
        <label
          class="w-100 responsiveIcon position-relative btn-params"
          :class="{
            active: gender && gender.value === genderOption.value && !isChildOption
          }"
        >
          <i
            :class="{
              'btn-params--lady icon iconGenders': genderOption.value === 'Lady',
              'btn-params--gents icon iconGenders': genderOption.value === 'Gentleman'
            }"
          ></i>
          <input
            v-model="gender"
            type="radio"
            name="radio-1"
            class="btn-checkbox"
            autocomplete="off"
            :value="genderOption"
          />
          <span
            :class="{
              'active-background': gender && gender.value === genderOption.value && !isChildOption
            }"
            class="btn btn-checkbox btn-block"
          >
          </span>
        </label>
      </div>
      <div v-if="kidsCombination.length" class="col pl-md-0-5 pr-md-0-5">
        <label
          class="w-100 position-relative btn-params childIconElement"
          :class="{ active: isChildOption }"
          @click="openChildOptions"
        >
          <i class="btn-params--child icon iconGenders"></i>
          <span class="btn btn-checkbox btn-block px-3" :class="{ 'active-background': isChildOption }"> </span>
        </label>
      </div>
    </div>
    <div v-if="isChildOption" class="form-group">
      <div class="row text-center">
        <div class="col-12">
          <MultiSelect
            v-model="choosenCombination"
            :options="returnSortedCombinationsArr"
            :searchable="false"
            :allow-empty="false"
            :close-on-select="true"
            :show-labels="true"
            :open-direction="'bottom'"
            label="title"
            :placeholder="'Choose the option'"
          >
            <template slot="caret" slot-scope="{ toggle }">
              <div
                class="multiselect__select d-flex align-items-center justify-content-center"
                @mousedown.prevent.stop="toggle()"
              >
                <font-awesome-icon :icon="['far', 'angle-down']" :class="`text-gray`"></font-awesome-icon>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="option" class="bookingSelect">
              <div class="option__desc d-flex w-100 multiselect-text-color multiselect-choosen-position">
                <span class="pr-0-5 text-nowrap text-center text-truncate " v-html="option.option.multiselectString">
                </span>
              </div>
            </template>

            <template slot="option" slot-scope="option">
              <div class="option__desc multiselect-text-color">
                <span class="pr-0-5 text-nowrap text-center text-truncate " v-html="option.option.multiselectString">
                </span>
              </div>
            </template>
          </MultiSelect>
        </div>
      </div>
    </div>
    <div v-if="isChildOption && choosenCombination" class="col-12 px-0">
      <slot v-if="serviceCategoryHaircutParams" name="rowWithLength">
        <div class="col p-0 d-flex flex-wrap border border-bottom-0">
          <TableHeader />
          <ColumnWithLength
            :name="choosenCombination.gender"
            :combinations="[choosenCombination]"
            @set-choosen-combination="setChoosenCombination"
          />
        </div>
      </slot>
      <slot v-else name="rowWithoutLength">
        <div class="col-12 px-0 border">
          <ColumnWithoutLength
            :combinations="[choosenCombination]"
            :is-mobile-width="true"
            @set-choosen-combination="setChoosenCombination"
          />
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'vue-multiselect'
import ColumnWithLength from './KidsColumn/ColumnWithLength'
import ColumnWithoutLength from './KidsColumn/ColumnWithoutLength'
import TableHeader from './TableHeader'
export default {
  components: {
    MultiSelect,
    ColumnWithLength,
    ColumnWithoutLength,
    TableHeader
  },
  props: {
    adultOptions: {
      type: Array,
      default: () => []
    },
    kidsCombination: {
      type: Array,
      default: () => []
    },
    serviceCategoryHaircutParams: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isChildOption: false,
      gender: null,
      choosenCombination: null
    }
  },
  computed: {
    returnSortedCombinationsArr() {
      let arr = []
      // Boy (1 - 4 year)
      this.kidsCombination.forEach(combi => {
        let obj = {
          gender: null,
          ageFrom: null,
          ageTo: null,
          multiselectString: null,
          combination: []
        }
        obj.gender = combi.parameter_values[0].value
        obj.ageTo = combi.parameter_values[combi.parameter_values.length - 1].value
        obj.combination.push(combi)

        if (combi.parameter_values[1].id > 20) {
          obj.ageFrom = combi.parameter_values[2].value
        } else {
          obj.ageFrom = combi.parameter_values[1].value
        }
        obj.multiselectString = `${
          obj.gender === 'Boy' ? this.$t('servicesRedesign.boys') : this.$t('servicesRedesign.girls')
        } <span class="text-gray">(${obj.ageFrom} - ${obj.ageTo} ${this.$t('servicesRedesign.year')})</span>`
        arr.forEach(el => {
          if (el.gender === obj.gender && el.ageTo === obj.ageTo && el.ageFrom === obj.ageFrom) {
            el.combination.push(combi)
          }
        })
        arr.push(obj)
      })

      let uniqueArray = arr.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(t => t.gender === thing.gender && t.ageTo === thing.ageTo && t.ageFrom === thing.ageFrom)
      )

      return uniqueArray
    }
  },
  watch: {
    gender: {
      deep: true,
      handler(newVal) {
        this.handleGenderOptionClick(newVal)
      }
    }
  },
  methods: {
    openChildOptions() {
      this.isChildOption = true
      this.gender = null
      this.choosenCombination = null
    },
    handleGenderOptionClick(gender) {
      const genderOption = gender ? gender.value : null
      if (genderOption === 'Lady' || genderOption === 'Gentleman') {
        this.isChildOption = false
        this.choosenChild = null
      } else if (genderOption === null) {
        this.isChildOption = true
        this.gender = { id: 3, value: 'Kids' }
      }
      this.$emit('choosen-gender-value', this.gender)
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
