<template>
  <div class="content mb-md-4-5 mb-3">
    <h3 class="mb-1-5 mb-xxl-3">
      {{ $t('common.card.services') }}
    </h3>
    <div class="row flex-wrap justify-content-start flex-wrap">
      <div
        v-for="(item, key) in categories"
        :key="`category-${key}`"
        :class="`mb-1 ml-md-1 ml-0 col-12 p-0 service-accordion-card root-accordion-card-${item.id}`"
      >
        <div
          v-b-toggle="`accordion-${item.id}`"
          class="col py-md-2-5 py-1-5 px-0 d-flex justify-content-between custom-root-accordion category-bg tabs"
          block
        >
          <div class="pl-2 font-weight-medium poppins-font">
            <span :class="`category-title-${item.id}`">{{ item.title }}</span>
            <span class="d-block d-md-none text-gray mt-0-3 mr-1"
              >{{ $t('salon.section.content.from') }} {{ minCategoryPrice(categoryServices(item.id)) }} CHF</span
            >
          </div>
          <div class="pr-2 d-flex flex-nowrap">
            <span :class="`d-none d-md-block text-gray mr-1 text-nowrap category-min-price category-price-${item.id}`"
              >{{ $t('salon.section.content.from') }} {{ minCategoryPrice(categoryServices(item.id)) }} CHF</span
            >
            <font-awesome-icon
              :icon="['far', 'angle-down']"
              style="transition: all ease 0.2s;"
              :class="`text-gray root-angle`"
            ></font-awesome-icon>
          </div>
        </div>
        <b-collapse
          :id="`accordion-${item.id}`"
          accordion="my-root-accordion"
          role="tabpanel"
          @shown="openCard(true, item.id)"
          @hidden="openCard(false, item.id)"
        >
          <div
            v-for="service in categoryServices(item.id)"
            :key="`service-${service.id}`"
            :class="`service-tab service-${service.id}`"
          >
            <div
              v-b-toggle="`accordion-${service.id}-service`"
              class="custom-child-accordion col border-top py-2 px-0"
              block
            >
              <div class="col-12 px-0 d-flex justify-content-between">
                <span class="pl-2 font-weight-medium poppins-font">{{ service.title }}</span>
                <div class="pr-2">
                  <span class="text-gray mr-1 service-min-price"
                    >{{ $t('salon.section.content.from') }} {{ servicePrice(service) }} CHF</span
                  >
                  <font-awesome-icon
                    :icon="['far', 'angle-down']"
                    :class="`text-gray child-angle`"
                    style="transition: all ease 0.2s;"
                  ></font-awesome-icon>
                </div>
              </div>
              <div v-if="service.description" class="col-12 pl-2 px-0 mt-0-3">
                <span class="text-gray">{{ service.description }}</span>
              </div>
            </div>
            <b-collapse :id="`accordion-${service.id}-service`" accordion="my-accordion" role="tabpanel">
              <div class="pb-2 px-2">
                <ServicesRow
                  :service-id="service.id"
                  :service-category-haircut-params="service.service_category_haircut_params"
                  :combinations="service.combinations_without_trashed"
                  :choosen-service="choosenService"
                  @set-choosen-combination="setChoosenCombination"
                />
              </div>
            </b-collapse>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ServicesRow from './ServiceRow'
export default {
  components: {
    ServicesRow
  },
  props: {
    salon: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      categories: [],
      choosenService: null
    }
  },
  computed: {
    ...mapGetters({
      existingServices: 'haircutParams/getExistingServices'
    })
  },
  mounted() {
    this.getServices().then(() => {
      this.salon.services.forEach(service => {
        this.existingServices.forEach(category => {
          if (
            service.service_category_id === category.id &&
            !JSON.stringify(this.categories).includes(JSON.stringify(category))
          ) {
            this.categories.push(category)
          }
        })
      })
    })
  },
  methods: {
    ...mapActions({
      getServices: 'haircutParams/getApiServices'
    }),
    minCategoryPrice(services) {
      let prices = services.map(service => {
        if (service.combination) {
          return service.combination.min_price ? service.combination.min_price : service.combination.default_price
        } else return service.price_min
      })
      return Math.min.apply(null, prices)
    },
    categoryServices(id) {
      return this.salon.services.filter(service => service.service_category_id === id)
    },
    servicePrice(service) {
      if (service.combination !== undefined) {
        return service.combination.min_price ? service.combination.min_price : service.combination.default_price
      } else return service.price_min
    },
    getMinPrice(service) {
      return service.min_price ? service.min_price : service.default_price
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    },
    openCard(val, id) {
      let accordion = document.querySelector(`.root-accordion-card-${id}`)
      if (val) {
        accordion.classList.add('active-tab')
      } else accordion.classList.remove('active-tab')
    }
  }
}
</script>
