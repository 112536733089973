<template>
  <main>
    <div
      v-if="salon"
      class="navbar-offset marketplace-main-info"
      :class="{ 'mobile-marketplace-bg': this.$route.name === 'marketplace' }"
    >
      <div v-if="this.$route.name === 'product-detail-page'" class="w-100 d-block d-md-none border-bottom">
        <i @click="$router.go(-1)">
          <font-awesome-icon
            :icon="['far', 'chevron-left']"
            class="left-chevron mb-1 mt-1-5 ml-1-5"
          ></font-awesome-icon>
        </i>
      </div>
      <div class="container pt-lg-7 pt-md-3 pt-0 px-md-0" :class="this.$route.name === 'marketplace' ? 'px-1' : 'px-0'">
        <div class="row">
          <div v-if="this.$route.name === 'marketplace'" class="col d-block d-md-none">
            <h3 class="mt-1-5 mb-0">
              {{ salon.title }}
            </h3>
          </div>
          <div class="col-12 px-0 pt-3 d-none d-md-block d-lg-none">
            <div class="d-flex justify-content-between">
              <div class="col-6 px-0 d-flex">
                <img
                  :src="salon.images.length ? salon.images[0].path : `@/assets/images/default/default-salon-img.png`"
                  height="80px"
                  width="160px"
                  alt=""
                  class="mb-2 ml-1 salon-payment-img marketplace-img"
                />
                <h3 class="my-1 ml-2">
                  {{ salon.title }}
                </h3>
              </div>
              <div class="mr-1">
                <button
                  v-if="getClientBooking"
                  class="btn btn-lg btn-block btn-primary mb-2 w-100"
                  @click="getAuthInfo.isAuthorized ? onBooking() : authConfirmMessage()"
                >
                  <span>{{ $t('market.continue-booking') }}</span>
                </button>
                <button v-else class="btn btn-lg btn-block btn-primary mb-2 w-100" @click="goToSalon">
                  <span>{{ $t('market.continue-booking') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3 d-none d-md-block px-0">
            <div class="d-block d-md-none d-lg-block">
              <img
                :src="salon.images.length ? salon.images[0].path : `@/assets/images/default/default-salon-img.png`"
                height="150px"
                alt=""
                class="w-75 mb-2 salon-payment-img marketplace-img d-none d-lg-block"
              />
              <h3 class="my-2">
                {{ salon.title }}
              </h3>
              <button
                v-if="getClientBooking"
                class="btn btn-lg btn-block btn-primary mb-3 w-75"
                @click="getAuthInfo.isAuthorized ? onBooking() : authConfirmMessage()"
              >
                <span>{{ $t('market.continue-booking') }}</span>
              </button>
              <button v-else class="btn btn-lg btn-block btn-primary mb-3 w-75" @click="goToSalon">
                <span>{{ $t('market.continue-booking') }}</span>
              </button>
            </div>
            <div
              id="v-pills-tab"
              class="nav flex-column d-none d-md-block nav-pills pb-3 mx-n1 mx-md-0"
              role="tablist"
              aria-orientation="vertical"
            >
              <div class="pages mt-0-5 mb-0-5 justify-content-around justify-content-md-center">
                <a
                  v-for="(page, key) in salonProductCategoriesFilter"
                  :key="`${key}-page`"
                  :href="`${page.path}`"
                  :class="
                    page.id === choosenCategoryId
                      ? 'nav-link py-1-5 ml-0 ml-md-1 linkActive pl-1'
                      : 'nav-link py-1-5 ml-0 ml-md-1'
                  "
                  @click="sortByCategory($event, page.id)"
                >
                  <div class="d-flex align-items-center">
                    {{ page.name }}
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div v-if="isProductPageRoute && dataIsLoaded" class="col-12 col-md-9 col-lg ml-lg-5 px-0 ml-0">
            <ReservedProducts :id="salon.id"></ReservedProducts>

            <div class="col px-0 mb-lg-2 mb-md-1 mb-0 d-flex marketFilters">
              <div class="d-none d-md-block col-12 col-md-6 col-lg-4 pl-0-6 pr-1">
                <MultiSelect
                  v-model="choosenDefaultPriceSortedValue"
                  :options="sortedData"
                  :searchable="false"
                  :allow-empty="false"
                  :close-on-select="true"
                  :show-labels="true"
                  :open-direction="'bottom'"
                  label="title"
                  :placeholder="this.$t('market.sort-by')"
                  @select="onCategorySelect"
                >
                  <template slot="caret" slot-scope="{ toggle }">
                    <div
                      class="multiselect__select d-flex align-items-center justify-content-center"
                      @mousedown.prevent.stop="toggle()"
                    >
                      <i class="market-multiselect-icon"></i>
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                    <div class="option__desc d-flex w-100 multiselect-text-color multiselect-choosen-position">
                      <p class="mb-0 text-nowrap d-none d-lg-block">{{ $t('market.sort-by') }}</p>
                      <span class="pl-1 pt-0-2 text-nowrap text-truncate">{{ option.option }}</span>
                    </div>
                  </template>

                  <template slot="option" slot-scope="option">
                    <div class="option__desc multiselect-text-color">
                      <span>{{ option.option }}</span>
                    </div>
                  </template>
                </MultiSelect>
              </div>
              <div class="col-lg-8 d-flex col-md-6 col-12 pl-0-2 mt-1 mt-md-0 mb-0-5 mb-md-0 ml-0-5 pr-0 pr-md-1-5">
                <div id="multiselect" tabindex="-1" class="multiselect quiff">
                  <div class="form-group mb-0 mb-md-1-5 has-search d-flex">
                    <input
                      type="text"
                      class="form-control mr-1-3 mr-md-0"
                      :placeholder="$t('market.search')"
                      @input="searchForProducts($event)"
                    />
                    <font-awesome-icon
                      :icon="['far', 'search']"
                      class="icon-search form-control-feedback"
                    ></font-awesome-icon>
                  </div>
                </div>
                <i v-b-modal.filters-modal class="filter-icon d-md-none mt-1-5 mr-1-5 filterIcon"></i>
              </div>
              <div class="col d-none d-md-block"></div>
            </div>
            <div class="d-block d-md-none col">
              <button
                v-if="getClientBooking"
                class="btn btn-lg btn-block btn-primary mt-1 mb-0 w-100"
                @click="getAuthInfo.isAuthorized ? onBooking() : authConfirmMessage()"
              >
                <span>{{ $t('market.continue-booking') }}</span>
              </button>
              <button v-else class="btn btn-lg btn-block btn-primary mt-1 mb-0 w-100" @click="goToSalon">
                <span>{{ $t('market.continue-booking') }}</span>
              </button>
            </div>
            <div
              id="v-pills-tab"
              class="nav flex-column nav-pills pb-1 mx-n1 mx-md-n0 d-block d-md-none"
              role="tablist"
              aria-orientation="vertical"
            >
              <div class="pages marketMobileMenu pl-2 pr-2 mb-0-5 d-flex flex-nowrap overflow-auto">
                <a
                  v-for="(page, key) in salonProductCategoriesFilter"
                  :key="`${key}-page`"
                  :href="`${page.path}`"
                  :class="{
                    'nav-link pb-1-5 mr-3 linkActive pb-1 text-nowrap': page.id === choosenCategoryId,
                    'nav-link pb-1-5 mr-3 text-nowrap pb-1': page.id !== choosenCategoryId,
                    'pr-1-5': key === salonProductCategoriesFilter.length - 1
                  }"
                  @click="sortByCategory($event, page.id)"
                >
                  <div class="d-flex align-items-center">
                    {{ page.name }}
                  </div>
                </a>
              </div>
            </div>
            <div class="col px-0 d-flex flex-wrap">
              <div
                v-for="item in getProducts"
                :id="`product-${item.id}`"
                :key="item.id"
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    :src="item.image ? item.image : require('@/assets/images/default/default-product.png')"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />
                  <span
                    :class="{ 'mt-2 mt-lg-0': item.short_description.length < 40 }"
                    class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate"
                  >
                    {{ item.title }}
                  </span>
                  <p class="mb-1 d-none d-md-block mt-1">{{ item.short_description }}</p>
                  <p class="h5 my-1 my-md-0 text-primary mb-0">{{ item.price }} CHF</p>
                  <button
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                    @click="goToProductPage(item.id)"
                  >
                    <span class="text-nowrap">{{ $t('market.view-more') }}</span>
                  </button>
                </div>
              </div>
              <div class="row w-100 mt-2 mb-2 mb-md-0">
                <pagination
                  :data="getSalonProducts"
                  :show-disabled="true"
                  class="ml-1-5"
                  @pagination-change-page="getProductsPagination"
                >
                  <span slot="prev-nav">
                    <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
                  </span>
                  <span slot="next-nav">
                    <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
                  </span>
                </pagination>
              </div>
            </div>
          </div>
          <div
            v-if="!isProductPageRoute"
            class="col-12 col-md ml-md-5 ml-0"
            :class="this.$route.name === 'marketplace' ? 'px-1' : 'px-0'"
          >
            <ProductPage :salon-id="salon.id" :product-id="choosenProductId" @go-back="goToMarket" />
          </div>
          <div v-if="!dataIsLoaded" class="col">
            <Spinner />
          </div>
        </div>
      </div>
      <b-modal
        id="filters-modal"
        ref="filtersModal"
        header-class="modal-header pt-0 px-0 pb-0 border-bottom-0 mt-1-5 mt-md-0"
        body-class="px-1-5 pb-2 booking-modal"
        centered
        hide-footer
      >
        <template v-slot:modal-header="{ close }">
          <div class="top-block d-block d-md-none w-100">
            <div class="d-flex">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-mobile ml-1-5"
                @click="close()"
              ></font-awesome-icon>
              <div class="w-100 d-flex justify-content-center">
                <h4>{{ $t('market.filters') }}</h4>
              </div>
            </div>
          </div>
        </template>
        <div class="mt-2">
          <span class="text-nowrap">{{ $t('market.sort-by') }}</span>
        </div>
        <div class="h-100 mt-1">
          <button
            v-for="(page, i) in salonProductCategoriesFilter"
            :key="`category${i}`"
            :class="{ active: choosenCategoryId === page.id }"
            class="btn btn-outline-secondary-custom collapsed btn-block py-1 mb-1-5"
            @click="sortByCategory($event, page.id)"
          >
            <span class="text-uppercase align-middle">{{ page.name }}</span>
          </button>
        </div>
      </b-modal>
      <VerificationPopup />
    </div>
  </main>
</template>
<script>
import ReservedProducts from '@/components/ReservedProducts'
import MultiSelect from 'vue-multiselect'
import ProductPage from './Product'
import Spinner from '@/views/Spinner'
import VerificationPopup from '@/views/verification/VerificationPopup'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  components: {
    ReservedProducts,
    MultiSelect,
    ProductPage,
    Spinner,
    VerificationPopup
  },
  data() {
    return {
      salonProductCategoriesFilter: [
        {
          name: this.$t('market.all-products'),
          path: this.$route.path,
          id: 0
        }
      ],
      allProductsListPayload: {
        salon_id: null,
        sort: 'price'
      },
      searchValue: '',
      choosenDefaultPriceSortedValue: [],
      sortedData: [this.$t('market.low-to-high'), this.$t('market.high-to-low')],
      amountOfFoundedSalons: [],
      productsPagination: {
        page: 1
      },
      choosenCategoryId: 0,
      choosenProductId: 0,
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      },
      dataIsLoaded: true
    }
  },
  computed: {
    ...mapGetters({
      salon: 'salons/getSalon',
      getSalonProducts: 'salons/getSalonProducts',
      getsalonProductCategories: 'salons/getsalonProductCategories',
      getMainTemplate: 'user/getMainTemplate',
      getFormattedHaircutData: 'haircutParams/getFormattedHaircutData',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      getClientBooking: 'payment/getClientBooking',
      getAuthInfo: 'user/getAuthInfo',
      getSiteLang: 'user/getSiteLang',
      getFormatedReservedProductsBySalonId: 'payment/getFormatedReservedProductsBySalonId'
    }),
    getProducts() {
      return this.getSalonProducts ? this.getSalonProducts.data : []
    },
    isProductPageRoute() {
      return this.$route.path.includes('product') ? false : true
    },
    isBookingFilled() {
      return (
        this.clientBookingData.combinations &&
        this.clientBookingData.salon_staff_id &&
        this.clientBookingData.salon_id &&
        this.clientBookingData.start_time &&
        this.clientBookingData.time
      )
    }
  },
  watch: {
    getClientBooking: {
      deep: true,
      handler(newVal) {
        this.clientBookingData = newVal
      }
    },
    getSiteLang(newVal) {
      this.salonProductCategoriesFilter.shift()
      this.salonProductCategoriesFilter.unshift({ name: this.$t('market.all-products'), path: this.$route.path, id: 0 })
      this.sortedData = [this.$t('market.low-to-high'), this.$t('market.high-to-low')]
      this.choosenDefaultPriceSortedValue = this.sortedData[0]
    }
  },
  mounted() {
    if (this.salon) {
      this.choosenDefaultPriceSortedValue = [this.sortedData[0]]
      this.allProductsListPayload.salon_id = this.salon.id
      this.dataIsLoaded = false
      this.fetchSalonProducts(this.allProductsListPayload)
        .then(() => {
          this.dataIsLoaded = true
        })
        .catch(() => this.$router.push('/not-found'))
      this.fetchSalonCategories({ salon_id: this.salon.id }).then(() => this.addCategoriesForFilters())
      this.clientBookingData = this.getClientBooking
    } else this.$router.push('/not-found')
  },
  methods: {
    ...mapActions({
      fetchSalonProducts: 'salons/getSalonProducts',
      fetchSalonCategories: 'salons/getSalonCategories',
      createTemplate: 'user/createTemplate',
      createClientBooking: 'payment/createClientBooking',
      setRedirectAuthFlag: 'user/setRedirectAuthFlag',
      setClientBooking: 'payment/setClientBooking'
    }),
    ...mapActions('userMessages', {
      setError: 'setError',
      setSuccess: 'setSuccess'
    }),
    mouseOver(event) {
      if (event.target.className.includes('productCard')) {
        event.target.classList.add('cardIsActive')
      }
    },
    onDeactive(event) {
      if (event.target.className.includes('productCard')) {
        event.target.classList.remove('cardIsActive')
      }
    },
    onCategorySelect(val) {
      if (val === this.sortedData[0]) this.allProductsListPayload.sort = 'price'
      else if (val === this.sortedData[1]) this.allProductsListPayload.sort = '-price'
      else this.allProductsListPayload.sort = '-price'
      this.fetchSalonProducts(this.allProductsListPayload)
    },
    searchForProducts(evt) {
      if (evt.target.value.length > 2) {
        this.fetchSalonProducts(Object.assign(this.allProductsListPayload, { title: evt.target.value }))
      } else {
        delete this.allProductsListPayload.title
        this.fetchSalonProducts(this.allProductsListPayload)
      }
    },
    getProductsPagination(page = 1) {
      this.productsPagination.page = page
      this.fetchSalonProducts(Object.assign(this.allProductsListPayload, this.productsPagination))
    },
    sortByCategory(evt, id) {
      if (evt) {
        if (this.$route.path !== `/marketplace/${this.salon.id}`) this.$router.push(`/marketplace/${this.salon.id}`)
        evt.preventDefault()
        this.choosenCategoryId = id
        this.getsalonProductCategories.forEach(el => {
          if (el.id === this.choosenCategoryId) {
            this.fetchSalonProducts(Object.assign(this.allProductsListPayload, { category_id: el.id }))
          } else if (!id) {
            delete this.allProductsListPayload.category_id
            this.fetchSalonProducts(this.allProductsListPayload)
          }
        })
        this.$bvModal.hide('filters-modal')
      }
    },
    clearChoosenData() {
      delete this.allProductsListPayload.category_id
      this.fetchSalonProducts(this.allProductsListPayload)
    },
    addCategoriesForFilters() {
      if (this.getsalonProductCategories.length) {
        this.getsalonProductCategories.forEach(el => {
          this.salonProductCategoriesFilter.push({
            name: el.title,
            path: '',
            id: el.id
          })
        })
      }
    },
    goToProductPage(id) {
      this.choosenProductId = id
      this.$router.push(`${this.$route.path}/product`)
    },
    goToMarket(val) {
      this.isProductPage = val
    },
    onBooking() {
      if (!this.isBookingFilled) return

      let bookingPayload = this.clientBookingData

      if (this.getFormatedReservedProductsBySalonId(this.salon.id).products.length) {
        bookingPayload = {
          ...bookingPayload,
          ...this.getFormatedReservedProductsBySalonId(this.salon.id)
        }
      }

      if (!this.getMainTemplate) {
        const { getFormattedHaircutData } = this
        let bodyParams = {
          main_template: '1'
        }
        if (this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1) {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: getFormattedHaircutData.parameters[1]
          }
        } else {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: null
          }
        }
        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          bodyParams.age = getFormattedHaircutData[getFormattedHaircutData.length - 1]
        }
        this.createTemplate(bodyParams)
          .then(data => {
            bookingPayload.client_id = data.id

            this.createClientBooking(bookingPayload)
              .then(data => {
                this.$router.push(`/payment/${data.id}`)
              })
              .catch(errors => {
                if (errors.code === 425) {
                  this.setError({
                    type: 'error',
                    messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                  })
                } else {
                  if (errors.message && errors.message === 'Your email address is not verified.') {
                    this.$bvModal.show('verification-modal')
                    this.setClientBooking(this.clientBookingData)
                  } else {
                    this.setError(this.createBookingErrorMessage)
                  }
                }
              })
          })
          .catch(errors => {
            if (errors.message && errors.message === 'Your email address is not verified.') {
              this.$bvModal.show('verification-modal')
              this.setClientBooking(this.clientBookingData)
            } else {
              this.setError(this.createBookingErrorMessage)
            }
          })
      } else {
        bookingPayload.client_id = this.getMainTemplate.id

        this.createClientBooking(bookingPayload)
          .then(data => {
            this.$router.push(`/payment/${data.id}`)
          })
          .catch(errors => {
            if (errors.message && errors.message === 'Your email address is not verified.') {
              this.$bvModal.show('verification-modal')
              this.setClientBooking(this.clientBookingData)
            } else {
              this.setError(this.createBookingErrorMessage)
            }
          })
      }
    },
    isHairLengthVisible() {
      if (this.allSelectedHaircutParams.choosenServices.length) {
        if (this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1) return true
        else return false
      } else return false
    },
    redirectToAuthPage() {
      if (!this.isBookingFilled) return
      this.setRedirectAuthFlag(true)
      this.setClientBooking(this.clientBookingData)
      this.$router.push('/sign-in')
    },
    authConfirmMessage() {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', {
        domProps: { innerHTML: 'Title from <i>HTML<i> string' }
      })
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [this.$t('common.confirm.auth_title')]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5'] }, [this.$t('common.confirm.auth_sub_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4',
          bodyClass: ' pb-1-5 px-4',
          cancelVariant: 'light flex-auto mr-1-5',
          okVariant: 'primary flex-auto',
          okTitle: this.$t('common.confirm.to_login_btn'),
          cancelTitle: this.$t('common.confirm.cancel_btn')
        })
        .then(value => {
          if (value) {
            this.redirectToAuthPage()
          }
        })
        .catch(error => {
          return error
        })
    },
    goToSalon() {
      this.$router.push(`/salon-page/${this.salon.id}`)
    }
  }
}
</script>
