<template>
  <b-modal
    id="avaliable-salons-modal"
    ref="avaliableSalons"
    header-class="modal-header border-bottom-0 staff-modal pb-0 d-flex justify-content-end"
    footer-class="modal-footer px-2-5 pt-0"
    centered
    @hidden="hideModal"
  >
    <template v-slot:modal-header="{ close }">
      <font-awesome-icon
        :icon="['fal', 'times']"
        class="icon-close cursor-pointer"
        @click="hideModal"
      ></font-awesome-icon>
    </template>
    <div class="w-100">
      <div class="d-flex justify-content-center">
        <span class="mb-0 poppins-font text-center font-weight-medium">
          {{ $t('avaliable-salons-modal.avaliable-salons') }}
        </span>
      </div>
      <div>
        <div
          v-if="avaliableSalons.length"
          :class="avaliableSalons.length > 6 ? 'pr-2-5' : 'pr-0'"
          class="mx-3 mt-3 mb-1 custom-avaliable-scroll overflow-auto height-auto"
        >
          <ul class="pl-0 list-unstyled mb-0" style="height:330px !important;">
            <li v-for="item in avaliableSalons" :key="item.id" class="border p-1-5">
              <div class="d-flex justify-content-between">
                <p class="text-black poppins-font mb-0">{{ item.title }}</p>
                <p class="roboto-font mb-0">({{ item.count }})</p>
              </div>
            </li>
          </ul>
        </div>
        <h4 v-else class="my-2 text-center">
          {{ $t('avaliable-salons-modal.no-avaliable') }}
        </h4>
      </div>
    </div>
    <template v-slot:modal-footer="{ close }">
      <button type="button" class="btn btn-outline-secondary btn-block close-btn" @click="hideModal">
        {{ $t('avaliable-salons-modal.close') }}
      </button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      avaliableSalons: []
    }
  },
  computed: {
    ...mapGetters({
      getAvaliableSalons: 'salons/getAvaliableSalons'
    })
  },
  mounted() {
    this.fetchAvaliableSalons().then(() => {
      this.formattedAvaliableSalonsData(this.getAvaliableSalons)
    })
  },
  methods: {
    ...mapActions({
      fetchAvaliableSalons: 'salons/getAvaliableSalons'
    }),
    setIconActiveClass() {
      const icon = document.querySelector('.icon-tooltip')
      if (icon) icon.classList.add('tooltipInfoIconActive')
    },
    hideModal() {
      this.$bvModal.hide('avaliable-salons-modal')
      const icon = document.querySelector('.icon-tooltip')
      if (icon) icon.classList.remove('tooltipInfoIconActive')
    },
    formattedAvaliableSalonsData(data) {
      this.avaliableSalons = Object.entries(data).map(([title, count], id) => ({ title, count, id }))
    }
  }
}
</script>
<style scoped>
.tooltipInfoIconActive {
  color: #ff6b09 !important;
}
.tooltipInfoIcon:hover {
  color: #ff6b09 !important;
}
.tooltipInfoIcon {
  color: #e5e5e5;
  font-size: 16px;
}
</style>
