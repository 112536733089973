<template>
  <div
    class="text-truncate w-100 h-100 pt-1-5 combi"
    :class="{
      disabledService: !getMinPrice && !getTime,
      'cursor-pointer': getMinPrice && getTime
    }"
    @click="setChoosenCombination"
  >
    <span v-if="getMinPrice && getTime" class="pr-0-5 combinationText"
      >{{ getMinPrice }} CHF <span class="text-gray">{{ `(0${getTime})` }}</span></span
    >
    <span v-else class="pr-0-5">-</span>
    <span style="display: none; background:#ff6b09" class="text-nowrap text-white">
      {{ $t('servicesRedesign.book') }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    combination: {
      type: Object,
      default: () => {}
    },
    isCombinationWithBorder: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getMinPrice() {
      if (this.combination) {
        return this.combination.min_price ? this.combination.min_price : this.combination.default_price
      } else return null
    },
    getTime() {
      if (this.combination) {
        return this.combination.duration
          ? ((this.combination.duration / 60) | `00`) +
              ' : ' +
              (this.combination.duration % 60 ? this.combination.duration % 60 : `00`)
          : null
      } else return null
    }
  },
  methods: {
    setChoosenCombination() {
      this.$emit('set-choosen-combination', this.combination)
    }
  }
}
</script>
