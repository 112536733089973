export default {
    DATA: {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: null,
        last_page: 1,
        last_page_url: "",
        next_page_url: null,
        path: "",
        per_page: 10,
        prev_page_url: null,
        to: null,
        total: 0
    }
}