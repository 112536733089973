<template>
  <main class="navbar-offset profile-page">
    <div class="container">
      <div class="row privacy-main-info">
        <div class="d-flex flex-wrap">
          <div class="col-lg-4 col-md-2 navigationBlock pt-md-6 pt-2">
            <div class="mb-md-5 mb-1">
              <h1 class="mb-1">{{ $t('terms.impressum_page-title') }}</h1>
              <font-awesome-icon :icon="['far', 'clock']" class="text-primary"> </font-awesome-icon>
              <span class="text-black pl-0-5">{{ $t('terms.updated') }} {{ getFormattedData }}</span>
            </div>
            <PrivacyNavigationTabs class="mt-0-5" />
          </div>
          <div class="col-xxl-6 col-xl-8 col-lg-9 col-md-8 mx-auto py-md-7 py-1 mt-md-11 mt-0">
            <h1 class="mb-2">{{ $t('terms.impressum-page.title') }}</h1>
            <h3>{{ $t('terms.impressum-page.address.address-title') }}</h3>
            <p>{{ $t('terms.impressum-page.address.address-name') }}</p>
            <p>{{ $t('terms.impressum-page.address.address-first') }}</p>
            <p>{{ $t('terms.impressum-page.address.address-second') }}</p>
            <p class="mb-2">{{ $t('terms.impressum-page.address.address-third') }}</p>
            <div class="d-flex mb-2">
              <h3>Email:</h3>
              <p class="pt-0-3 pl-0-8"><a href="mailto:info@quiff.ch" title="info@quiff.ch">info@quiff.ch</a></p>
            </div>
            <h3>{{ $t('terms.impressum-page.title-1') }}</h3>
            <p>{{ $t('terms.impressum-page.title-2') }}</p>
            <p class="mb-2">{{ $t('terms.impressum-page.title-3') }}</p>
            <h3>{{ $t('terms.impressum-page.title-4') }}</h3>
            <p class="mb-2">{{ $t('terms.impressum-page.title-5') }}</p>
            <p class="mb-2">
              {{ $t('terms.impressum-page.title-6') }}
            </p>
            <h3>{{ $t('terms.impressum-page.title-7') }}</h3>
            <p class="mb-2">{{ $t('terms.impressum-page.title-8') }}</p>
            <h3>{{ $t('terms.impressum-page.title-9') }}</h3>
            <p class="mb-2">
              {{ $t('terms.impressum-page.title-10.text-1') }}
              <span class="text-black font-weight-bold">{{ $t('terms.impressum-page.title-10.text-bold') }}</span>
              {{ $t('terms.impressum-page.title-10.text-2') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PrivacyNavigationTabs from './PrivacyNavigationTabs'
import moment from 'moment'

export default {
  components: {
    PrivacyNavigationTabs
  },
  data() {
    return {}
  },
  computed: {
    getFormattedData() {
      return moment('2020-05-25 09:00:00')
        .locale(this.$root.$i18n.locale)
        .format('MMMM, DD YYYY')
    }
  }
}
</script>
