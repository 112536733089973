<template>
  <div>
    <div class="row mt-2 mt-md-0 mb-2">
      <div class="col-12 mb-md-2 mb-1">
        <div class="d-flex">
          <p class="ml-md-0 ml-0-3 mb-0-8 pt-0-2 inputFont">{{ $t('common.forms.label.choose_location') }}</p>
          <p v-b-modal.avaliable-salons-modal class="mb-0 pt-0-2" @click="setIconActiveClass">
            <font-awesome-icon
              class="fa-w-16 ml-0-5 tooltipInfoIcon icon-tooltip"
              style="font-size:16px;"
              data-toggle="tooltip"
              :icon="['fas', 'info-circle']"
            ></font-awesome-icon>
          </p>
        </div>
        <div id="multiselect" tabindex="-1" class="multiselect has-icon map">
          <div class="multiselect__actions d-flex flex-wrap align-items-right justify-content-end">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close mr-0-5 mt-0-2"
              @click="clearChoosenPlace"
            ></font-awesome-icon>
          </div>
          <div class="multiselect__tags locationInput" style="padding-top: 5px">
            <div class="multiselect__tags-wrap" style="display: none;"></div>
            <gmap-autocomplete
              ref="gmap-autocomplete"
              class="g-autocomplete"
              style="font-size:16px;"
              :options="gmapAutoCompleteOptions"
              @keypress.enter="$event.preventDefault()"
              @place_changed="setPlace"
            >
            </gmap-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <AvaliableSalons />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AvaliableSalons from '@/components/AvaliableSalons'
import { gmapApi } from 'vue2-google-maps'
import 'vue-slider-component/theme/default.css'
import moment from 'moment'

export default {
  name: 'LocationModalBody',
  components: {
    AvaliableSalons
  },
  props: {
    isHaircutParamsEmpty: Boolean,
    apply: Boolean
  },
  data() {
    return {
      radius: 5,
      avaliableSalons: [],
      choosenPlace: null,
      options: {
        min: 0,
        max: 20,
        interval: 1,
        height: 6,
        tooltip: 'none'
      },
      gmapAutoCompleteOptions: {
        fields: ['geometry', 'formatted_address', 'name'],
        componentRestrictions: {
          country: 'ch'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      storedRadius: 'salons/getSearchRadius',
      currentPlace: 'salons/getCurrentPlace',
      userPosition: 'user/getUserPosition',
      formattedHaircutData: 'haircutParams/getFormattedHaircutData',
      getChoosenDate: 'haircutParams/getChoosenDate',
      getAvaliableSalons: 'salons/getAvaliableSalons'
    })
  },
  watch: {
    apply(newVal) {
      if (newVal) this.applyAndSearch()
      this.$emit('applyAndSearchFlag', false)
    }
  },
  mounted() {
    if (this.$route.name === 'home') {
      this.setUserLocationAndFormattedAddress()
    }
  },
  // TODO: 1 method for all clear-data methods
  methods: {
    ...mapActions('salons', {
      setSearchRadius: 'setSearchRadius',
      setCurrentPlace: 'setCurrentPlace',
      setNewSearchDataFlag: 'setNewSearchDataFlag',
      clearCurrentPlace: 'clearCurrentPlace',
      getSalons: 'getSalons',
      fetchAvaliableSalons: 'getAvaliableSalons'
    }),
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    ...mapMutations('salons', {
      setSavedDataFlag: 'SET_LOCATION_MODAL_DATA_FLAG'
    }),
    ...mapMutations('user', {
      setUserPosition: 'SET_USER_POSITION'
    }),
    setIconActiveClass() {
      this.$bvModal.show('avaliable-salons-modal')
      const icon = document.querySelector('.icon-tooltip')
      if (icon) icon.classList.add('tooltipInfoIconActive')
    },
    clearChoosenPlace() {
      this.clearCurrentPlace()
      this.choosenPlace = null
      this.radius = 1
      this.$refs['gmap-autocomplete'].$el.value = ''
    },
    setPlace(place) {
      this.choosenPlace = place
    },
    applyAndSearch() {
      let errorInfo = {}

      if (!this.choosenPlace) {
        errorInfo = {
          type: this.$t('common.forms.alert.empty_fields_type'),
          messageI18Key: 'common.forms.alert.empty_fields_message'
        }

        this.setError(errorInfo)
      } else {
        if (this.radius) {
          this.setSearchRadius(+this.radius)
          this.setCurrentPlace(this.choosenPlace)
          this.setSavedDataFlag(true)
          this.searchForSalons()
        }
      }
    },
    searchForSalons() {
      this.$router.push('/salons')
    },
    setUserLocationAndFormattedAddress() {
      if (this.$route.name === 'home' && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const coords = position.coords
            // let coordsMutation = this.setUserFormattedAddress
            this.setUserPosition({ lat: position.coords.latitude, lng: position.coords.longitude })

            let el = this
            this.$gmapApiPromiseLazy().then(() => {
              var geocoder = new google.maps.Geocoder()
              var location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)

              geocoder.geocode({ latLng: location }, (results, status) => {
                if (status == 'OK') {
                  let add = results[0].formatted_address

                  el.choosenPlace = results[0]

                  let locationInput = document.querySelector('.g-autocomplete')
                  locationInput.value = add
                }
              })
            })
          },
          e => {
            // eslint-disable-next-line
          console.log(e)
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
        )
      }
    }
  }
}
</script>
<style lang="scss">
.tooltipInfoIconActive {
  color: #ff6b09 !important;
}
.tooltipInfoIcon:hover {
  color: #ff6b09 !important;
}
.tooltipInfoIcon {
  color: #e5e5e5;
  font-size: 16px;
}
.modal-container {
  box-shadow: 0 20px 40px 0 rgba(155, 159, 162, 0.35);
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.number-input__input:focus {
  border-color: #ff6b09 !important;
  outline: 0;
}
.v--modal {
  box-shadow: 0 20px 40px 0 rgba(155, 159, 162, 0.35) !important;
  z-index: 1050 !important;
}
.changeRadiusValueButton {
  outline: none !important;
  text-decoration: none;
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: none;
  border-right: solid 1px #e9efed;
  background-color: #fbfbfb;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #8d9fa7;
  padding-left: 20px;
  padding-top: 1px;
  padding-right: 30px;
}
.changeRadiusValueButton:last-child {
  padding-left: 18px;
  border-right: none !important;
  border-left: solid 1px #e9efed;
}
.valueSpan {
  width: 4rem;
  padding-top: 0.7rem;
  text-align: center;
  font-family: Roboto;
}
.rangeValueBlock {
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
}
.inputFont {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  color: #111517;
}
.locationInput {
  padding-top: 2px;
  height: 48px;
  font-family: Roboto;
}
@media screen and (min-width: 650px) and (max-width: 1000px) {
  .locationModalBodyContainer .modal-header {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .vue-slider-dot {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
