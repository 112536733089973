// TODO: implement proper payment when api is ready
import { SalonsRepository } from '@/api/salons.repository'
const createBookingPayment = ({ commit }, payload) => {
  return SalonsRepository.createClientPayment(payload)
}
const createClientBooking = ({ commit, rootState }, payload) => {
  return SalonsRepository.createClientBooking(payload)
    .then(({ data }) => {
      commit('SET_CLIENT_BOOKING', data)

      if (data.reserved_products.length) {
        rootState.payment.reservedProducts = data.reserved_products.map(item => {
          const { id, title, price, image, salon_id } = item.product

          let product = {
            id: id,
            deleteId: id,
            title: title,
            price: price,
            salon_id: salon_id,
            bookingFlag: true,
            images: [
              {
                path: image
              }
            ]
          }

          if (item.variation) {
            product.variation = item.variation
          }

          return product
        })
      } else {
        rootState.payment.reservedProducts = []
      }

      return data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}

const createClientBookingSalon = ({ commit }, payload) => {
  return SalonsRepository.createClientBookingSalon(payload).then(({ data }) => {
    return data
  })
}

const deleteClientBooking = ({ commit }, payload) => {
  return SalonsRepository.deleteClientBooking(payload).then(({ data }) => {
    commit('CLEAR_CLIENT_BOOKING')
  })
}

const setClientBooking = ({ commit }, payload) => {
  commit('SET_CLIENT_BOOKING', payload)
}
const clearClientBooking = ({ commit }) => {
  commit('CLEAR_CLIENT_BOOKING')
  return true
}

const getBookingById = ({ commit }, payload) => {
  return SalonsRepository.getBookingById(payload)
    .then(({ data }) => {
      commit('SET_CLIENT_BOOKING', data.data)
      return data.data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}

const removeReservedProduct = ({ commit }, payload) => {
  return SalonsRepository.removeReservedProduct(payload.requestData)
    .then(data => {
      commit('REMOVE_RESERVED_PRODUCT', payload.productId)
      return data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}

const removeBookingProduct = ({ commit }, payload) => {
  return SalonsRepository.removeBookingProduct(payload.requestData)
    .then(data => {
      commit('REMOVE_RESERVED_PRODUCT', payload.productId)
      return data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}

export default {
  createBookingPayment,
  setClientBooking,
  createClientBooking,
  clearClientBooking,
  deleteClientBooking,
  createClientBookingSalon,
  getBookingById,
  removeReservedProduct,
  removeBookingProduct
}
