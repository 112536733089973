<template>
  <main>
    <div class="content mb-md-4-5 mb-3">
      <!-- vue modal component -->
      <h3 class="mb-1-5 mb-xxl-3">
        {{ $t('salon.section.content.specialists_title') }}
      </h3>
      <div v-if="salon.staff" class="row specialists">
        <div
          v-for="(staff, key) in salon.staff"
          :key="`staff-${key}`"
          class="col-12 col-md-6 col-xxl-4 px-0-5 sp px-md-1"
        >
          <div v-b-modal.tab-modal class="card mb-2 p-0-8" @click="fetchStaffToSelectEmit(staff.id)">
            <div class="row flex-nowrap no-gutters">
              <img :src="specialistAvatarPath(staff)" class="card-img position-absolute" alt="Avatar image" />
              <div class="col  pr-0 StaffInfoBLock">
                <div class="card-body h-100 w-100 d-flex flex-column justify-content-center pl-1">
                  <div class="card-title mb-0 d-flex justify-content-between align-items-center">
                    <h6 class="mb-0 text-nowrap text-truncate">
                      {{ staffName(staff.user.first_name, staff.user.last_name) }}
                    </h6>
                    <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                  </div>
                  <p class="card-text my-0-5 text-black w-75 h-auto text-truncate">
                    {{ staff.specialization }}
                  </p>
                  <p class="card-text text-gray">
                    <i class="barbershop-icon"></i> {{ getFormattedStaffString(staff) }}
                  </p>
                </div>
              </div>
              <a href="#link_to_modal" class="stretched-link"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  filters: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text
    }
  },
  props: {
    salon: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    fetchStaffToSelectEmit(id) {
      this.$emit('fetchStaffToSelect', id)
    },
    specialistAvatarPath(object) {
      return object.avatar ? object.avatar.path : require('@/assets/images/default/default_avatar.png')
    },
    formatParametersString(obj) {
      if (obj) {
        if (obj.length) {
          const parametersString = Object.keys(obj).reduce((acc, curr) => acc + `/${curr} `)
          const formattedParametersString = parametersString.substring(0, parametersString.length - 1)
          return formattedParametersString
        }
      }
    },
    staffName(firstName, lastName) {
      return `${firstName} ${lastName}`
    },
    getFormattedStaffString(newVal) {
      let str = ''
      let genderServicesParameter = {
        Lady: [],
        Gentleman: [],
        Boy: [],
        Girl: []
      }
      if (newVal && newVal.services) {
        let genderObjectKeys = Object.keys(genderServicesParameter)
        newVal.services.forEach(el => {
          for (let i = 0; i < genderObjectKeys.length; i++) {
            if (el.parameter_value.value === genderObjectKeys[i]) {
              genderServicesParameter[genderObjectKeys[i]].push(el.service.title)
              continue
            }
          }
        })
        genderObjectKeys.forEach(el => {
          if (genderServicesParameter[el].length) {
            if (!str.includes(this.$t(`common.tab_for_staff.Kids`))) {
              if (el === 'Boy' || el === 'Girl') {
                str += `${this.$t(`common.tab_for_staff.Kids`)}/`
              } else str += `${this.$t(`common.tab_for_staff.${el}`)}/`
            }
          }
        })
        return str.substring(0, str.length - 1)
      } else return ''
    }
  }
}
</script>
<style scoped>
.card-body {
  padding: 0;
}
.card {
  width: auto;
}
.specialists .card .row img {
  width: 4.75rem;
  height: 4.75rem;
}
.row .no-gutters {
  height: 80px;
}
.StaffInfoBLock {
  padding-left: 76px !important;
}
@media screen and (max-width: 767px) {
  .StaffInfoBLock {
    padding-left: 65px !important;
  }
  .card {
    height: 85px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1465px) {
  .specialists .card .row img {
    width: 65px;
    height: 65px;
  }
  .row .no-gutters {
    height: 65px;
  }
}
</style>
