import { isEmpty } from 'lodash'

const getCommonHaircutParams = state => state.commonHaircutParams
const getChoosenDate = state => state.choosenDate
const getChoosenServices = state => {
  if (Array.isArray(state.commonHaircutParams.choosenServices)) {
    return state.commonHaircutParams.choosenServices
  } else {
    return [state.commonHaircutParams.choosenServices]
  }
}
const getExistingServices = state => state.services
const getParametersValues = state => state.params
const getMainTemplateParameters = state => state.parametersFromMainTemplate
const getChildHaircutParams = state => state.childHaircutParams

const getParametersKeysNameByApiKey = () => key => {
  const map = [{ genders: 'gender' }, { age: 'age' }, { hairLength: 'length' }]
  return map.find(param => param[key])[key]
}

const getFormattedHaircutData = (state, getters) => {
  const { gender, length, choosenServices, age } = getters['getAllSelectedHaircutParams']

  let services
  let haircutParam
  let ObjectKey = 'categories'
  let finalObject = {
    parameters: []
  }

  if (Array.isArray(choosenServices) && choosenServices.length) {
    services = choosenServices.map(service => service.id.toString())
  } else if (!Array.isArray(choosenServices) && Object.keys(choosenServices).length) {
    services = [choosenServices.id.toString()]
  } else services = []

  if (choosenServices.length > 0) {
    const service = choosenServices[0]

    if (service.service_category_haircut_params !== undefined) {
      haircutParam = service.service_category_haircut_params
      ObjectKey = 'services'
    } else if (service.service_category !== undefined) {
      haircutParam = service.service_category.haircut_params
      ObjectKey = 'services'
    } else {
      haircutParam = service.haircut_params
      ObjectKey = 'categories'
    }
  }

  if (!isEmpty(gender)) {
    finalObject.parameters.push(gender.id)
  }

  if (!isEmpty(length)) {
    finalObject.parameters.push(length.id)
  }

  if (!isEmpty(age)) {
    finalObject.parameters.push(age.id)
  }

  finalObject[ObjectKey] = services

  return finalObject
}

const getAllSelectedHaircutParams = state => {
  let params = {}
  if (!isEmpty(state.commonHaircutParams.gender)) {
    params.gender = state.commonHaircutParams.gender
  }

  if (!isEmpty(state.childHaircutParams.age)) {
    params.age = state.childHaircutParams.age
  }
  params.date = state.commonHaircutParams.date

  if (state.commonHaircutParams.choosenServices.length > 0) {
    let services = state.commonHaircutParams.choosenServices
    let hairParams = 0
    services.forEach(el => {
      if (el.service_category_haircut_params !== undefined) {
        hairParams += el.service_category_haircut_params
      } else {
        hairParams += el.service_category ? el.service_category.haircut_params : el.haircut_params
      }
    })
    if (hairParams === 1) {
      if (!isEmpty(state.commonHaircutParams.length)) {
        params.length = state.commonHaircutParams.length
      } else {
        params.length = {}
      }
    }

    params.choosenServices = state.commonHaircutParams.choosenServices
  } else {
    params.choosenServices = []
  }
  return params
}

export default {
  getCommonHaircutParams,
  getChoosenServices,
  getExistingServices,
  getParametersValues,
  getFormattedHaircutData,
  getAllSelectedHaircutParams,
  getParametersKeysNameByApiKey,
  getMainTemplateParameters,
  getChildHaircutParams,
  getChoosenDate
}
