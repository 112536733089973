const LANG_KEY = 'language'
const COOKIE_KEY = 'cookie'

const initLang = (() => {
  let siteLang = window.localStorage.getItem(LANG_KEY)
  return siteLang || 'de'
})()

const acceptCookiesAlert = (() => {
  let cookie = window.localStorage.getItem('cookie')

  return cookie
})()

const getDefaultState = () => {
  return {
    accountInfo: {
      firstName: '',
      lastName: '',
      email: '',
      client_id: null,
      image: null,
      clients: []
    },
    isAuthorized: false,
    isRedirectedForAuth: false,
    socialAuthType: '',
    userPosition: {
      lat: 46.948,
      lng: 7.4474
    },
    formattedAddress: '',
    resetEmail: '',
    siteLang: initLang,
    hideAcceptCookiesAlert: acceptCookiesAlert
  }
}

const SET_TOKEN = (state, token) => {
  localStorage.setItem('access_token', token)
}
const UPDATE_USER_PROFILE_IMAGE = (state, image) => {
  state.accountInfo.image = image
}

const SET_USER_ACCOUNT_INFO = (state, payload) => {
  Object.assign(state.accountInfo, payload)

  state.accountInfo.image = payload.avatar ? payload.avatar[0] && payload.avatar[0].path : null
  state.accountInfo.client_id = payload.id || null
}

const SET_AUTH_STATUS = (state, authData) => {
  state.isAuthorized = authData.isAuthorized
  state.socialAuthType = authData.socialAuthType
}

const SET_RESET_EMAIL = (state, email) => {
  state.resetEmail = email
}

const ADD_TEMPLATE = (state, data) => {
  state.accountInfo.clients.push(data)
}
const UPDATE_TEMPLATE = (state, data) => {
  let clientIndex = state.accountInfo.clients.findIndex(template => template.id === data.id)

  if (data.main_template === 1) {
    state.accountInfo.clients.splice(clientIndex, 1)

    state.accountInfo.clients.forEach((template, key) => {
      if (template.main_template === 1) {
        state.accountInfo.clients[key].main_template = 0
      }
    })

    state.accountInfo.clients.unshift(data)
  } else {
    state.accountInfo.clients[clientIndex] = data
  }
}
const DELETE_TEMPLATE = (state, id) => {
  let clientIndex = state.accountInfo.clients.findIndex(client => client.id === id)

  if (clientIndex > -1) {
    state.accountInfo.clients.splice(clientIndex, 1)
  }
}
const SET_USER_POSITION = (state, { lat, lng }) => {
  state.userPosition.lat = lat
  state.userPosition.lng = lng
}

const LOGOUT = state => {
  if (state.authInfo === 'google') {
    this.$gAuth.signOut().then(() => {
      this.setAuthStatus({ isAuthorized: false, socialAuthType: '' })
      this.$router.push('/sign-in')
    })
  }

  if (state.authInfo === 'facebook') {
    FB.logout(() => {
      this.setAuthStatus({ isAuthorized: false, socialAuthType: '' })
      this.$router.push('/sign-in')
      localStorage.removeItem('facebook_auth')
    })
  }

  let cachedLanguage = window.localStorage.getItem(LANG_KEY)
  let cachedCookie = window.localStorage.getItem(COOKIE_KEY)

  Object.assign(state, { ...getDefaultState(), resetEmail: state.resetEmail })
  localStorage.clear()

  if (cachedLanguage) {
    state.siteLang = cachedLanguage
    window.localStorage.setItem(LANG_KEY, cachedLanguage)
  }

  if (cachedCookie) {
    window.localStorage.setItem(COOKIE_KEY, cachedCookie)
  }
}

const RESET_STATE = state => {
  Object.assign(state, { ...getDefaultState(), resetEmail: state.resetEmail })
}

const SET_SITE_LANG = (state, payload) => {
  state.siteLang = payload
  window.localStorage.setItem(LANG_KEY, payload)
}

const SET_REDIRECTED_AUTH = (state, value) => {
  state.isRedirectedForAuth = value
}

const RESET_FEEDBACK_REQUEST = state => {
  state.accountInfo.booking = null
}

const TOGGLE_COOKIE_ALERT = (state, payload) => {
  window.localStorage.setItem('cookie', payload)
  state.hideAcceptCookiesAlert = payload
}

export default {
  getDefaultState,
  SET_TOKEN,
  UPDATE_USER_PROFILE_IMAGE,
  SET_USER_ACCOUNT_INFO,
  SET_AUTH_STATUS,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  LOGOUT,
  UPDATE_TEMPLATE,
  SET_RESET_EMAIL,
  SET_USER_POSITION,
  SET_REDIRECTED_AUTH,
  SET_SITE_LANG,
  RESET_FEEDBACK_REQUEST,
  TOGGLE_COOKIE_ALERT,
  RESET_STATE
}
