<template>
  <div class="col ml-lg-4 ml-xxl-7 px-0-5">
    <h3 class="mb-0-5 d-md-block d-none">
      {{ $t('salon.section.booking.title') }}
    </h3>
    <div class="booking-container d-flex flex-column mb-3">
      <div class="px-1-5 pt-1"></div>
      <div class="booking-section p-0" :class="{ active: activeGenderBlock }">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between">
            <p :class="`${!activeGenderBlock ? 'text-gray' : 'text-black'}`" class="card-title mb-1 font-weight-medium">
              {{ $t('common.forms.label.step', { number: 1 }) }}
              <span :class="`${!activeGenderBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.whom') }}
              </span>
            </p>
            <font-awesome-icon
              v-if="!activeGenderBlock"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>
          <div v-if="storageReady && areParamsLoaded">
            <GenderOptions
              :adult-options="adultGenderOptions"
              :child-options="childGenderOptions"
              :haircut-params="haircutParams"
              :clear-data-flag="clearDataFlag"
              :loading-combination-data="loadingCombinationData"
              :hide-sub-title="true"
              :centered-icons="true"
            />
          </div>
        </div>
      </div>
      <div class="booking-section p-0" :class="{ active: activeServiceBlock, 'd-none': disabledServiceBlock }">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between">
            <p
              :class="`${!activeServiceBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 2 }) }}
              <span :class="`${!activeServiceBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.select_service') }}
              </span>
            </p>
            <font-awesome-icon
              v-if="!activeServiceBlock && !disabledServiceBlock"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>
          <div class="row">
            <div class="col-12 mb-1-5 mb-md-1">
              <Services :salon-id="salon.id" />
            </div>
          </div>
        </div>
      </div>
      <div class="booking-section p-0" :class="{ active: activeLengthBlock, 'd-none': disabledLengthBlock }">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start flex-nowrap">
              <p
                :class="`${!activeLengthBlock ? 'text-gray' : 'text-black'}`"
                class="card-title mb-1 font-weight-medium"
              >
                {{ $t('common.forms.label.step', { number: 3 }) }}
                <span :class="`${!activeLengthBlock ? 'text-black' : 'text-orange'}`">
                  {{ $t('common.forms.label.length') }}
                </span>
              </p>
              <div
                class="salonDetailPageCustomTooltip d-flex"
                @blur="hide"
                @focus="show"
                @mouseover="show"
                @mouseleave="hide"
              >
                <p id="service-info-1" class="pr-1 mb-0">
                  <font-awesome-icon
                    class="fa-w-16 ml-0-5 tooltipInfoIcon"
                    style="font-size:16px;"
                    data-toggle="tooltip"
                    :icon="['fas', 'info-circle']"
                  ></font-awesome-icon>
                </p>
                <div ref="tooltip" class="tooltipBlock">
                  <div class="infoHairLengthBlock d-flex flex-wrap show">
                    <div class="px-0 infoUnderBlock mt-0">
                      <img src="@/assets/images/icons/short-hair.svg" width="83px" height="75px" />
                      <div class="hairtext-block text-left">
                        <span class="infoText roboto-font fz-14 text-black mt-0-5 mb-0">{{
                          $t('common.forms.label.length_info.chin_length')
                        }}</span>
                        <div class="bottomInfoTextBlock py-0-3 mt-3 text-center">
                          <span>{{ $t('common.forms.label.length_info.short') }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="px-0 infoUnderBlock my-1">
                      <img src="@/assets/images/icons/medium-length.svg" width="83px" height="75px" />
                      <div class="hairtext-block text-left">
                        <span class="roboto-font fz-14 infoText text-black mt-0-5 mb-0">{{
                          $t('common.forms.label.length_info.shoulder_length')
                        }}</span>
                        <div class="bottomInfoTextBlock py-0-3 mt-3 text-center">
                          <span>{{ $t('common.forms.label.length_info.medium') }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="px-0 infoUnderBlock mt-0-2">
                      <img src="@/assets/images/icons/long-length.svg" width="83px" height="75px" />
                      <div class="hairtext-block text-left">
                        <span class="infoText roboto-font fz-14  text-black mt-0-5 mb-0">{{
                          $t('common.forms.label.length_info.longer_than_shoulder_length')
                        }}</span>
                        <div class="bottomInfoTextBlock py-0-3 mt-0-8 text-center">
                          <span>{{ $t('common.forms.label.length_info.long') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <font-awesome-icon
              v-if="!activeLengthBlock && !disabledServiceBlock"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>
          <HairLengthSelect :hide-sub-title="true" :options="hairLengthOptions" :params="haircutParams" />
        </div>
      </div>
      <div class="booking-section p-0 " :class="{ active: activeBookDateBlock, 'd-none': disabledBookDateBlock }">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p
              v-if="isHairLengthVisible()"
              :class="`${!activeBookDateBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 4 }) }}
              <span :class="`${!activeBookDateBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.select_day') }}
              </span>
            </p>
            <p
              v-else
              :class="`${!activeBookDateBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 3 }) }}
              <span :class="`${!activeBookDateBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.select_day') }}
              </span>
            </p>
            <font-awesome-icon
              v-if="!activeBookDateBlock && !disabledBookDateBlock"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>
          <Calendar />
        </div>
      </div>
      <div class="booking-section p-0 " :class="{ active: activeStaffBlock, 'd-none': disabledStaffBlock }">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p
              v-if="isHairLengthVisible()"
              :class="`${!activeStaffBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 5 }) }}
              <span :class="`${!activeStaffBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.choose_specialist') }}
              </span>
            </p>
            <p
              v-else
              :class="`${!activeStaffBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 4 }) }}
              <span :class="`${!activeStaffBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.choose_specialist') }}
              </span>
            </p>
            <font-awesome-icon
              v-if="!activeStaffBlock && !disabledStaffBlock"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>
          <div class="row">
            <div class="col-12 mb-1-5 mb-md-0">
              <p
                v-if="allSelectedHaircutParams.choosenServices.length > 1 && !staffMultiselectData.length"
                class="mb-1"
              >
                {{ $t('salon.section.booking.warn') }}
              </p>
              <Multiselect
                v-model="choosenMultiselectValue"
                :options="staffMultiselectData"
                :value="value"
                :searchable="false"
                :select-label="''"
                placeholder="Stylist"
                class="bookingSelect"
              >
                <template slot="caret" slot-scope="{ option, toggle }">
                  <div
                    class="multiselect__select d-flex align-items-center justify-content-center"
                    @mousedown.prevent.stop="toggle()"
                  >
                    <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                  <div class="option__desc multiselect-text-color multiselect-choosen-position">
                    <img :src="staffAvatar(option)" alt="" class="staff-avatar-size" />
                    <span class="pl-1-5 pt-1"
                      >{{ option.option.user.first_name }} {{ option.option.user.last_name }}</span
                    >
                  </div>
                </template>

                <template slot="option" slot-scope="option">
                  <div class="option__desc multiselect-text-color">
                    <img :src="staffAvatar(option)" alt="" class="staff-avatar-size" />
                    <span class="pl-1-5">{{ option.option.user.first_name }} {{ option.option.user.last_name }} </span>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="timeIsRequested && choosenMultiselectValue"
        class="booking-section p-0 mb-1-5 mb-md-0"
        :class="{ active: activeTimeBlock, 'd-none': disabledTimeBlock }"
      >
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p
              v-if="isHairLengthVisible()"
              :class="`${!activeTimeBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 6 }) }}
              <span :class="`${!activeTimeBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.select_time') }}
              </span>
            </p>
            <p
              v-else
              :class="`${!activeTimeBlock ? 'text-gray' : 'text-black'}`"
              class="card-title mb-1 font-weight-medium"
            >
              {{ $t('common.forms.label.step', { number: 5 }) }}
              <span :class="`${!activeTimeBlock ? 'text-black' : 'text-orange'}`">
                {{ $t('common.forms.label.select_time') }}
              </span>
            </p>
            <font-awesome-icon
              v-if="!disabledTimeBlock && clientBookingData.start_time"
              :icon="['fas', 'check-circle']"
              class="text-primary"
            ></font-awesome-icon>
          </div>

          <div v-if="timeDataObject.morning.length">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.morning') }}
            </small>
            <div class="row row-sm text-center mb-1-5">
              <div v-for="(time, key) in timeDataObject.morning" :key="`time${key}`" class="col-3">
                <label class="w-100 mb-1">
                  <input
                    v-model="selectedTime"
                    type="radio"
                    name="radio-7"
                    class="btn-checkbox"
                    autocomplete="off"
                    :value="time"
                    @click="extractTimeChooseData(time)"
                  />
                  <span class="btn btn-checkbox btn-block">{{ time }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="timeIsRequested && !timeDataObject.morning.length && !isFullTimeBooked">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.morning') }}
            </small>
            <small class="d-block text-black mb-md-0-5">
              {{ $t('salon.section.booking.morning_empty') }}
            </small>
          </div>
          <div v-if="timeDataObject.afternoon.length">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.afternoon') }}
            </small>
            <div class="row row-sm text-center mb-0">
              <div v-for="(time, key) in timeDataObject.afternoon" :key="`time${key}`" class="col-3 mb-1">
                <label class="w-100 mb-1">
                  <input
                    v-model="selectedTime"
                    type="radio"
                    name="radio-7"
                    class="btn-checkbox"
                    autocomplete="off"
                    :value="time"
                    @click="extractTimeChooseData(time)"
                  />
                  <span class="btn btn-checkbox btn-block">{{ time }}</span>
                </label>
              </div>
            </div>
          </div>

          <div v-else-if="timeIsRequested && !timeDataObject.afternoon.length && !isFullTimeBooked">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.afternoon') }}
            </small>
            <small class="d-block text-black mb-md-0-5">
              {{ $t('salon.section.booking.afternoon_empty') }}
            </small>
          </div>
          <div v-if="timeDataObject.evening.length">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.evening') }}
            </small>
            <div class="row row-sm text-center mb-0">
              <div v-for="(time, key) in timeDataObject.evening" :key="`time${key}`" class="col-3">
                <label class="w-100 mb-1">
                  <input
                    v-model="selectedTime"
                    type="radio"
                    name="radio-7"
                    class="btn-checkbox"
                    autocomplete="off"
                    :value="time"
                    @click="extractTimeChooseData(time)"
                  />
                  <span class="btn btn-checkbox btn-block">{{ time }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="timeIsRequested && !timeDataObject.evening.length && !isFullTimeBooked">
            <small class="d-block text-gray timeText mb-md-0-5">
              {{ $t('salon.section.booking.evening') }}
            </small>
            <small class="d-block text-black mb-md-0-5">
              {{ $t('salon.section.booking.evening_empty') }}
            </small>
          </div>
          <div v-if="isFullTimeBooked || isStaffDataNotValid">
            <small class="d-block text-black">
              Sorry, all time for this day is already busy
            </small>
          </div>
        </div>
      </div>
      <div class="row px-md-1 px-0" :class="!bookBtnIsDisabled && choosenMultiselectValue ? 'py-1-5' : 'pb-2-5'">
        <div class="col-12 px-0 pt-1 border-top">
          <ReservedProducts :id="salon.id"></ReservedProducts>

          <p v-if="!bookBtnIsDisabled && choosenMultiselectValue" class="h5 mb-1 px-1-5 mt-0-8">
            {{ $t('common.forms.label.price') }}
            <span class="text-dark"> {{ actualCombinationPrice }} CHF</span>
          </p>
          <div v-if="!bookBtnIsDisabled && choosenMultiselectValue" class="px-1-5">
            <button
              :class="{ 'mt-2-5': !bookBtnIsDisabled }"
              type="submit"
              class="btn btn-primary btn-block mb-2 mb-md-0 py-1"
              @click="
                salon.hasProducts ? marketplaceMessage() : authInfo.isAuthorized ? onBooking() : authConfirmMessage()
              "
            >
              {{ $t('common.forms.button.book_now') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <VerificationPopup />
  </div>
</template>
<script>
import GenderOptions from './../GenderOptions'
import ReservedProducts from '@/components/ReservedProducts'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import HairLengthSelect from './../HairLengthSelect'
import Multiselect from 'vue-multiselect'
import Services from './../Services'
import Calendar from './../Calendar'
import moment from 'moment'
import VerificationPopup from '@/views/verification/VerificationPopup'

import { isEqual, pick, isEmpty } from 'lodash'

export default {
  components: {
    GenderOptions,
    HairLengthSelect,
    Multiselect,
    Services,
    Calendar,
    ReservedProducts,
    VerificationPopup
  },
  props: {
    salon: {
      type: Object,
      default: () => ({})
    },
    clearDataFlag: {
      type: Boolean,
      default: false
    },
    serviceCombinationData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      areParamsLoaded: false,
      loadingTime: false,
      staffSelectInterval: null,
      haircutParams: {
        gender: {},
        date: null,
        length: {},
        age: {}
      },
      dataForSalonFilter: {
        services: [],
        parameters: [],
        salon_id: null,
        book_date: null
      },
      responseSalonFilterArray: [],
      timeDataObject: {
        morning: [],
        afternoon: [],
        evening: []
      },
      isStaffDataNotValid: false,
      timeIsRequested: false,
      timeChooseObject: {},
      dataStaff: [],
      value: '',
      multiselectIsDisabled: true,
      staffMultiselectData: [],
      choosenMultiselectValue: null,
      loadingCombinationData: false,
      clientBookingData: {
        description: null,
        combinations: null,
        client_id: null,
        salon_staff_id: null,
        salon_id: null,
        start_time: null,
        time: null,
        price: null,
        paid_by: null,
        default_price: 0,
        app_fee: 0,
        stripe_app_fee: 0,
        stripe_owner_fee: 0,
        status: 'Reserved',
        parameters: [],
        products: []
      },
      bookBtnIsDisabled: true,
      actualCombinationPrice: 0,
      combinationData: {
        parameters: null,
        salon_id: null
      },
      procedureDuration: null,
      selectedTime: false,
      isClearedDate: false,
      isActiveFetchSalonStaffRequest: false,
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      }
    }
  },
  computed: {
    ...mapGetters({
      parametersValues: 'haircutParams/getParametersValues',
      staffFilter: 'salons/getSalonsStaffFilter',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      childHaircutParams: 'haircutParams/getChildHaircutParams',
      getChoosenServices: 'haircutParams/getChoosenServices',
      getChoosenDate: 'haircutParams/getChoosenDate',
      getClientBooking: 'payment/getClientBooking',
      authInfo: 'user/getAuthInfo',
      getMainTemplate: 'user/getMainTemplate',
      formattedHaircutData: 'haircutParams/getFormattedHaircutData',
      getFormattedHaircutData: 'haircutParams/getFormattedHaircutData',
      salonCombination: 'salons/getSalonCombination',
      getFormatedReservedProductsBySalonId: 'payment/getFormatedReservedProductsBySalonId',
      accountInfo: 'user/getAccountInfo',
      existingServices: 'haircutParams/getExistingServices',
      bookingServices: 'salons/getSalonServices'
    }),
    storageReady() {
      return this.adultGenderOptions.length && this.childGenderOptions.length && this.hairLengthOptions.length
    },
    hairLengthOptions() {
      return this.parametersValues.hairLength.values
    },
    adultGenderOptions() {
      return [this.parametersValues.genders.values[0], this.parametersValues.genders.values[1]]
    },
    childGenderOptions() {
      return [this.parametersValues.genders.values[2], this.parametersValues.genders.values[3]]
    },
    salonId() {
      return Number(this.$route.params.id)
    },
    activeGenderBlock() {
      return this.allSelectedHaircutParams.gender
        ? Object.keys(this.allSelectedHaircutParams.gender).length === 0
        : false
    },
    activeLengthBlock() {
      return (
        this.activeGenderBlock === false &&
        this.allSelectedHaircutParams.choosenServices.length &&
        this.isHairLengthVisible() === true &&
        isEmpty(this.allSelectedHaircutParams.length) &&
        this.allSelectedHaircutParams.length &&
        !Object.keys(this.allSelectedHaircutParams.length).length
      )
    },
    disabledLengthBlock() {
      return (
        this.isHairLengthVisible() === false ||
        this.activeGenderBlock ||
        !this.allSelectedHaircutParams.choosenServices.length
      )
    },
    activeServiceBlock() {
      return !this.activeGenderBlock && this.allSelectedHaircutParams.choosenServices.length === 0
    },
    disabledServiceBlock() {
      return (
        (!this.isHairLengthVisible() && !this.allSelectedHaircutParams.gender) ||
        Object.keys(this.allSelectedHaircutParams.gender).length === 0
      )
    },
    activeBookDateBlock() {
      return (
        this.activeGenderBlock === false &&
        this.activeServiceBlock === false &&
        this.activeLengthBlock === false &&
        this.disabledStaffBlock === true
      )
    },
    disabledBookDateBlock() {
      return (
        this.activeServiceBlock === true ||
        this.activeLengthBlock === true ||
        this.activeGenderBlock === true ||
        (this.isHairLengthVisible() === true && Object.keys(this.haircutParams.length).length === 0)
      )
    },
    activeStaffBlock() {
      return (
        !this.activeBookDateBlock &&
        !this.activeGenderBlock &&
        !this.activeLengthBlock &&
        !this.activeServiceBlock &&
        this.clientBookingData.salon_staff_id == null
      )
    },
    isBookingFilled() {
      return (
        this.clientBookingData.combinations &&
        this.clientBookingData.salon_staff_id &&
        this.clientBookingData.salon_id &&
        this.clientBookingData.start_time &&
        this.clientBookingData.time
      )
    },
    disabledStaffBlock() {
      return this.allSelectedHaircutParams.date.length === 0 || this.disabledBookDateBlock
    },
    activeTimeBlock() {
      return (
        !this.activeBookDateBlock &&
        !this.activeGenderBlock &&
        !this.activeLengthBlock &&
        !this.activeServiceBlock &&
        !this.activeStaffBlock &&
        this.clientBookingData.start_time == null
      )
    },
    disabledTimeBlock() {
      return this.clientBookingData.salon_staff_id == null
    },
    isFullTimeBooked() {
      return (
        this.timeIsRequested &&
        !this.timeDataObject.morning.length &&
        !this.timeDataObject.afternoon.length &&
        !this.timeDataObject.evening.length
      )
    },
    isChildParametersNotEmpty() {
      return this.childHaircutParams.age && Object.keys(this.childHaircutParams.age).length ? true : false
    }
  },
  watch: {
    serviceCombinationData: {
      deep: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) this.setChoosenCombinationData(newVal)
      }
    },
    allSelectedHaircutParams: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.loadingCombinationData) return
        const { gender, length, choosenServices, date, age } = newVal

        const oldGroupFieldsForCombinationRequest = pick(oldVal, ['gender', 'choosenServices', 'length', 'age', 'date'])
        const newGroupFieldsForCombinationRequest = pick(newVal, ['gender', 'choosenServices', 'length', 'age', 'date'])
        const oldGroupFieldsForStaffRequest = pick(oldVal, ['gender', 'choosenServices', 'length', 'age', 'date'])
        const newGroupFieldsForStaffRequest = pick(newVal, ['gender', 'choosenServices', 'length', 'age', 'date'])

        if (!isEqual(oldGroupFieldsForStaffRequest.gender, newGroupFieldsForCombinationRequest.gender)) {
          this.choosenMultiselectValue = null
          this.clearChoosenServices()
          this.clearChoosenDate()
          this.dataForSalonFilter.book_date = null
          this.isClearedDate = true
        }
        if (
          !isEqual(oldGroupFieldsForStaffRequest.choosenServices, newGroupFieldsForCombinationRequest.choosenServices)
        ) {
          this.choosenMultiselectValue = null
          this.haircutParams.length = {}
          this.clearChoosenDate()
          this.clearChoosenLength()
          this.isClearedDate = true
        }
        if (gender && choosenServices.length && date && !this.isClearedDate) {
          this.choosenMultiselectValue = null
          this.dataForSalonFilter.book_date = moment(date).format('Y-MM-DD')
          this.dataForSalonFilter.services = choosenServices.map(el => el.id)

          this.dataForSalonFilter.parameters = []
          if (!isEmpty(gender)) {
            this.dataForSalonFilter.parameters.push(gender.id)
          }

          if (!isEmpty(length)) {
            this.dataForSalonFilter.parameters.push(length.id)
          }
          if (!isEmpty(age)) {
            this.dataForSalonFilter.parameters.push(age.id)
          }
          if (!this.isActiveFetchSalonStaffRequest) this.fetchSalonSalonStaffs(this.dataForSalonFilter)
        }

        if (gender && length && choosenServices.length && date && this.choosenMultiselectValue) {
          this.combinationData.parameters = []

          if (this.isHairLengthVisible() === true && Object.keys(newVal.length).length) {
            this.combinationData.parameters = [newVal.gender.id, newVal.length.id]
          } else this.combinationData.parameters = [newVal.gender.id]
          this.combinationData.services = newVal.choosenServices.map(el => el.id)
          this.combinationData.book_date = moment(date).format('Y-MM-DD')
          this.combinationData.staff_id = this.choosenMultiselectValue.id

          if (newVal.age.id !== undefined) {
            this.combinationData.parameters.push(newVal.age.id)
          }

          this.fetchCombination(this.combinationData).then(data => {
            this.clientBookingData.combinations = data.map(el => el.id)
            this.clientBookingData.price = parseFloat(data.price.replace(/\,/g, ''))
            this.clientBookingData.default_price = data.discount_price
              ? parseFloat(data.discount_price.replace(/\,/g, ''))
              : parseFloat(data.default_price.replace(/\,/g, ''))
            this.clientBookingData.app_fee = data.app_fee
            this.clientBookingData.stripe_app_fee = data.stripe_app_fee
            this.clientBookingData.stripe_owner_fee = data.stripe_owner_fee
            this.setChoosenMultiselectWorkingHours(this.choosenMultiselectValue)
          })
        } else {
          this.combinationData.parameters = []

          if (this.salonCombination && Object.keys(this.salonCombination).length) {
            this.clientBookingData.combinations = null
            this.clientBookingData.price = parseFloat(this.salonCombination.price.replace(/\,/g, ''))
            if (newVal.min_price !== undefined) {
              newVal.discount_price
                ? (this.actualCombinationPrice = newVal.discount_price)
                : (this.actualCombinationPrice = newVal.min_price)
            } else if (newVal.default_price !== undefined && newVal.min_price === undefined) {
              newVal.discount_price
                ? (this.actualCombinationPrice = newVal.discount_price)
                : (this.actualCombinationPrice = newVal.default_price)
            }
          }
        }
        this.isClearedDate = false
      }
    },
    staffFilter: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          newVal.forEach(el => this.staffMultiselectData.push(el))
          if (this.staffMultiselectData.length > newVal.length) {
            this.staffMultiselectData.splice(newVal.length)
          }
          this.multiselectIsDisabled = false
        } else {
          this.choosenMultiselectValue = null
        }
      }
    },
    getChoosenServices: {
      deep: true,
      handler(newVal) {
        this.setServices(newVal)
      }
    },
    getChoosenDate(newVal) {
      this.dataForSalonFilter.book_date = moment(newVal).format('Y-MM-DD')
    },
    choosenMultiselectValue(newVal) {
      if (newVal) {
        this.bookBtnIsDisabled = true
        this.timeDataObject = {
          morning: [],
          afternoon: [],
          evening: []
        }
        this.combinationData.parameters = []
        if (
          isEmpty(this.allSelectedHaircutParams.gender) ||
          !this.allSelectedHaircutParams.choosenServices.length ||
          !this.getChoosenDate
        ) {
          this.clientBookingData.combinations = null
          this.clientBookingData.price = parseFloat(this.salonCombination.price.replace(/\,/g, ''))
        } else if (
          !isEmpty(this.allSelectedHaircutParams.gender) &&
          this.allSelectedHaircutParams.choosenServices.length > 0 &&
          this.getChoosenDate
        ) {
          if (this.isHairLengthVisible() && !isEmpty(this.allSelectedHaircutParams.length)) {
            this.combinationData.parameters = [
              this.allSelectedHaircutParams.gender.id,
              this.allSelectedHaircutParams.length.id
            ]
          } else this.combinationData.parameters = [this.allSelectedHaircutParams.gender.id]
          this.combinationData.services = this.allSelectedHaircutParams.choosenServices.map(el => el.id)
          this.combinationData.book_date = moment(this.getChoosenDate).format('Y-MM-DD')
          this.combinationData.staff_id = newVal.id
          if (!isEmpty(this.allSelectedHaircutParams.age)) {
            this.combinationData.parameters.push(this.allSelectedHaircutParams.age.id)
          }

          this.fetchCombination(this.combinationData).then(data => {
            this.clientBookingData.combinations = data.combinations.map(el => el.id)
            this.clientBookingData.price = parseFloat(data.price.replace(/\,/g, ''))
            this.clientBookingData.default_price = data.discount_price
              ? parseFloat(data.discount_price.replace(/\,/g, ''))
              : parseFloat(data.default_price.replace(/\,/g, ''))
            this.clientBookingData.app_fee = data.app_fee
            this.clientBookingData.stripe_app_fee = data.stripe_app_fee
            this.clientBookingData.stripe_owner_fee = data.stripe_owner_fee
            this.timeIsRequested = true
            this.setChoosenMultiselectWorkingHours(newVal)
          })
        }
      } else {
        this.resetTime()

        this.timeIsRequested = false
        this.clientBookingData.salon_staff_id = null
        this.actualCombinationPrice = 0

        this.resetSelectedTime()
      }
    },
    clientBookingData: {
      deep: true,
      handler(newVal) {
        if (newVal.combinations && newVal.salon_staff_id && newVal.salon_id && newVal.start_time) {
          this.bookBtnIsDisabled = false
        } else {
          this.bookBtnIsDisabled = true
        }
      }
    },
    salonCombination: {
      deep: true,
      handler(newVal) {
        this.clientBookingData.combinations = [newVal.id]
        if (newVal.min_price !== undefined) {
          newVal.discount_price
            ? (this.actualCombinationPrice = newVal.discount_price)
            : (this.actualCombinationPrice = newVal.min_price)
        } else if (newVal.default_price !== undefined && newVal.min_price === undefined) {
          newVal.discount_price
            ? (this.actualCombinationPrice = newVal.discount_price)
            : (this.actualCombinationPrice = newVal.default_price)
        }

        this.procedureDuration = newVal.duration
      }
    },
    clearDataFlag(newVal) {
      if (newVal) {
        return new Promise(r => {
          this.haircutParams = {
            gender: {},
            date: null,
            length: {},
            age: {}
          }
          this.clearCommonParams()
          this.clearChildHaircutParams()
          this.clearChoosenDate()
          this.choosenMultiselectValue = null
          r()
        }).then(() => {
          this.disabledClearAllFlag()
        })
      }
    }
  },
  created() {
    this.registerStaffSelectInterval()
  },
  mounted() {
    this.getParamsValues().then(() => {
      this.areParamsLoaded = true
    })
    this.salonSelectData()
    this.setSalonId()
    this.clientBookingData.salon_id = this.salonId
  },
  beforeDestroy() {
    clearInterval(this.staffSelectInterval)
  },
  methods: {
    ...mapActions({
      getParamsValues: 'haircutParams/getParamsValues',
      fetchSalonsStaffFilter: 'salons/getSalonsStaffFilter',
      setClientBooking: 'payment/setClientBooking',
      createTemplate: 'user/createTemplate',
      setRedirectAuthFlag: 'user/setRedirectAuthFlag',
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError',
      fetchCombination: 'salons/getSalonCombination',
      setCommonHaircutParams: 'haircutParams/setCommonHaircutParams',
      clearChoosenDate: 'haircutParams/clearChoosenDate',
      clearChildHaircutParams: 'haircutParams/clearChildHaircutParams',
      setChildHaircutParams: 'haircutParams/setChildHaircutParams',
      createClientBooking: 'payment/createClientBooking'
    }),
    ...mapMutations({
      clearChoosenServices: 'haircutParams/CLEAR_CHOOSEN_SERVICES',
      clearCommonParams: 'haircutParams/RESET_COMMON_PARAMS',
      clearChoosenLength: 'haircutParams/CLEAR_CHOOSEN_LENGTH',
      addServiceForBooking: 'haircutParams/ADD_SERVICE_FOR_SALON_PAGE'
    }),
    staffAvatar(staff) {
      return staff.option.avatar ? staff.option.avatar.path : require('@/assets/images/default/default_avatar.png')
    },
    setServices(services) {
      this.dataForSalonFilter.services = []

      services.forEach(el => {
        //TODO: use .map instead of .forEach
        this.dataForSalonFilter.services.push(el.id)
      })
      if (this.dataForSalonFilter.services.length === 0) {
        this.choosenMultiselectValue = null
      }
    },
    fetchSalonSalonStaffs(params) {
      this.staffMultiselectData = []
      this.isActiveFetchSalonStaffRequest = true
      this.fetchSalonsStaffFilter(params).then(() => {
        this.isActiveFetchSalonStaffRequest = false
        if (this.staffFilter) {
          this.staffFilter.forEach(el => this.staffMultiselectData.push(el))
          if (this.staffMultiselectData.length > this.staffFilter.length) {
            this.staffMultiselectData.splice(this.staffFilter.length)
          }
          this.multiselectIsDisabled = false
        }
        if (this.choosenMultiselectValue && this.staffFilter.length) {
          this.staffFilter.forEach(el => {
            if (el.id === this.choosenMultiselectValue.id) this.choosenMultiselectValue = el
          })
          this.setChoosenMultiselectWorkingHours(this.choosenMultiselectValue)
        } else this.choosenMultiselectValue = null
      })
    },
    setCombinationData() {
      if (this.salon.combination) {
        this.clientBookingData.combinations = [this.salon.combination.id]
        this.clientBookingData.price = parseFloat(this.salon.combination.price.replace(/\,/g, ''))

        if (this.salon.combination.default_price !== undefined) {
          this.actualCombinationPrice = this.salon.combination.default_price
        }
      }
    },
    extractTimeChooseData(time) {
      let currentDate = moment(this.dataForSalonFilter.book_date).format('Y-MM-DD')
      const timeInHours = moment(`${currentDate} ${time}`).format('HH')
      const partOfTheDay =
        timeInHours < 12
          ? this.$t('salon.section.booking.morning')
          : timeInHours < 16
          ? this.$t('salon.section.booking.afternoon')
          : this.$t('salon.section.booking.evening')
      this.clientBookingData.time = `${partOfTheDay}, ${time}`
      this.clientBookingData.start_time = `${currentDate} ${time}`
    },
    salonSelectData() {
      this.dataStaff = this.salon.staff.map(el => ({
        avatar: el.avatar ? el.avatar.path : '',
        name: `${el.user.first_name} ${el.user.last_name}`
      }))
    },
    marketplaceMessage() {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', {
        domProps: { innerHTML: 'Title from <i>HTML<i> string' }
      })
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [this.$t('market.modal.title')]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5 mb-1'] }, [this.$t('market.modal.title-description')]),
        h('img', {
          attrs: { src: require('@/assets/images/examples/marketplace-img.png') },
          class: ['markentplaceImage']
        })
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4',
          bodyClass: ' pb-1-5',
          cancelVariant: 'light flex-auto mr-1-5',
          okVariant: 'primary flex-auto',
          okTitle: this.$t('market.modal.ok'),
          cancelTitle: this.$t('market.modal.no')
        })
        .then(value => {
          if (value) {
            //  authInfo.isAuthorized ? onBooking() : authConfirmMessage()
            this.goToStore()
          } else this.authInfo.isAuthorized ? this.onBooking() : this.authConfirmMessage()
        })
        .catch(error => {
          return error
        })
    },
    authConfirmMessage() {
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', {
        domProps: { innerHTML: 'Title from <i>HTML<i> string' }
      })
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h3 text-center mb-0 text-black pt-4 pb-1-5'] }, [this.$t('common.confirm.auth_title')]),
        h('p', { class: ['text-center pb-0 mb-0 lh-1-5'] }, [this.$t('common.confirm.auth_sub_title')])
      ])

      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4',
          bodyClass: ' pb-1-5 px-4',
          cancelVariant: 'light flex-auto mr-1-5',
          okVariant: 'primary flex-auto',
          okTitle: this.$t('common.confirm.to_login_btn'),
          cancelTitle: this.$t('common.confirm.cancel_btn')
        })
        .then(value => {
          if (value) {
            this.redirectToAuthPage()
          }
        })
        .catch(error => {
          return error
        })
    },
    goToStore() {
      this.clientBookingData.parameters = this.combinationData.parameters
      this.setClientBooking(this.clientBookingData)
      this.$router.push(`/marketplace/${this.salon.id}`)
    },
    redirectToAuthPage() {
      if (!this.isBookingFilled) return
      this.setRedirectAuthFlag(true)
      this.clientBookingData.parameters = this.combinationData.parameters
      this.setClientBooking(this.clientBookingData)
      this.$router.push('/sign-in')
    },
    onBooking() {
      if (!this.isBookingFilled) return
      this.clientBookingData.parameters = this.combinationData.parameters

      let bookingPayload = this.clientBookingData

      if (this.getFormatedReservedProductsBySalonId(this.salonId).products.length) {
        bookingPayload = {
          ...bookingPayload,
          ...this.getFormatedReservedProductsBySalonId(this.salonId)
        }
      }
      if (!this.getMainTemplate) {
        const { getFormattedHaircutData } = this
        let bodyParams = {
          main_template: '1'
        }
        if (this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1) {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: getFormattedHaircutData.parameters[1]
          }
        } else {
          bodyParams.values = {
            gender: getFormattedHaircutData.parameters[0],
            length: null
          }
        }
        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          bodyParams.age = getFormattedHaircutData[getFormattedHaircutData.length - 1]
        }
        this.createTemplate(bodyParams)
          .then(data => {
            bookingPayload.client_id = data.id

            this.createClientBooking(bookingPayload)
              .then(data => {
                this.$router.push(`/payment/${data.id}`)
              })
              .catch(errors => {
                if (errors.code === 425) {
                  this.setError({
                    type: 'error',
                    messageI18Key: 'common.forms.alert.booking.time_is_selected_error'
                  })
                } else {
                  if (errors.message && errors.message === 'Your email address is not verified.') {
                    this.$bvModal.show('verification-modal')
                    this.setClientBooking(this.clientBookingData)
                  } else {
                    this.setError(this.createBookingErrorMessage)
                  }
                }
              })
          })
          .catch(errors => {
            if (errors.message && errors.message === 'Your email address is not verified.') {
              this.$bvModal.show('verification-modal')
              this.setClientBooking(this.clientBookingData)
            } else {
              this.setError(this.createBookingErrorMessage)
            }
          })
      } else {
        bookingPayload.client_id = this.getMainTemplate.id

        this.createClientBooking(bookingPayload)
          .then(data => {
            this.$router.push(`/payment/${data.id}`)
          })
          .catch(() => {
            if (this.accountInfo && !this.accountInfo.email_verified_at) {
              this.$bvModal.show('verification-modal')
              this.setClientBooking(this.clientBookingData)
            }
            this.setError(this.createBookingErrorMessage)
          })
      }
    },
    setWorkingHours(elem) {
      elem.forEach(el => {
        if (parseInt(el) < 12) {
          this.timeDataObject.morning.push(el)
        } else if (parseInt(el) < 16) {
          this.timeDataObject.afternoon.push(el)
        } else if (parseInt(el) >= 16) {
          this.timeDataObject.evening.push(el)
        }
      })
      this.timeIsRequested = true
    },
    setSalonId() {
      this.dataForSalonFilter.salon_id = parseInt(this.$route.params.id)
      this.combinationData.salon_id = this.$route.params.id
    },
    handleLengthOptionClick(value) {
      const hairLengthParam = { paramName: 'length', value }
      this.setCommonHaircutParams(hairLengthParam)
    },
    handleGenderOptionClick(value) {
      const genderParam = { paramName: 'gender', value }
      this.setCommonHaircutParams(genderParam)
    },
    activatedClearAllFlag() {
      this.$emit('activated-clear-all-flag')
    },
    disabledClearAllFlag() {
      this.$emit('disabled-clear-all-flag')
    },
    setChoosenMultiselectWorkingHours(el) {
      this.resetTime()
      if (el !== null && el.workingHours) {
        this.clientBookingData.salon_staff_id = el.id
        if (el.workingHours !== null) {
          if (Array.isArray(el.workingHours)) {
            this.setWorkingHours(el.workingHours)
          } else {
            this.setWorkingHours(Object.values(el.workingHours))
          }
        } else this.isStaffDataNotValid = true
      } else {
        this.clientBookingData.salon_staff_id = null
        this.clientBookingData.start_time = null
      }
    },
    resetTime() {
      this.timeDataObject.morning = []
      this.timeDataObject.afternoon = []
      this.timeDataObject.evening = []
    },
    resetSelectedTime() {
      this.selectedTime = false
      this.clientBookingData.time = null
      this.clientBookingData.start_time = null
      this.bookBtnIsDisabled = true
    },
    isHairLengthVisible() {
      if (this.allSelectedHaircutParams.choosenServices.length) {
        if (
          this.allSelectedHaircutParams.choosenServices.length === 1 &&
          this.allSelectedHaircutParams.choosenServices[0].service_category_haircut_params === 1
        ) {
          return true
        } else if (this.allSelectedHaircutParams.choosenServices.length > 1) {
          let sum = 0
          this.allSelectedHaircutParams.choosenServices.forEach(el => {
            sum += el.service_category_haircut_params
          })
          if (sum) return true
          else return false
        } else return false
      } else return false
    },
    registerStaffSelectInterval() {
      this.staffSelectInterval = setInterval(() => {
        if (!this.loadingTime && this.choosenMultiselectValue) {
          let requestData = {
            ...this.dataForSalonFilter,
            ...{ staff_id: this.choosenMultiselectValue.id }
          }

          this.loadingTime = true

          this.fetchSalonsStaffFilter(requestData)
            .then(result => {
              let items = result.data.items
              this.resetTime()
              if (items.length > 0) {
                let staff = {}
                items.forEach(el => {
                  if (el.workingHours) staff = el
                })
                this.setChoosenMultiselectWorkingHours(staff)

                if (!staff.workingHours.includes(this.selectedTime)) {
                  this.resetSelectedTime()
                }
              } else {
                this.resetSelectedTime()
              }

              this.loadingTime = false
            })
            .catch(() => {})
        }
      }, 1500)
    },
    show() {
      this.$refs.tooltip.style.display = 'block'
    },
    hide() {
      this.$refs.tooltip.style.display = 'none'
    },
    setChoosenCombinationData(data) {
      if (!data) return
      this.choosenMultiselectValue = null
      this.loadingCombinationData = true

      this.clearChoosenServices()
      this.clearChildHaircutParams()
      if (data.service_id) {
        this.bookingServices[data.parameter_values[0].value].forEach(el => {
          if (el.id === data.service_id && !this.allSelectedHaircutParams.choosenServices.length) {
            this.addServiceForBooking(el)
          }
        })
      }
      this.haircutParams.gender = data.parameter_values[0]
      this.handleGenderOptionClick(data.parameter_values[0])
      if ((data.parameter_values.length > 1 && data.name.includes('Boy')) || data.name.includes('Girl')) {
        this.haircutParams.age = data.parameter_values[data.parameter_values.length - 1]
        this.setChildHaircutParams({ age: data.parameter_values[data.parameter_values.length - 1] })
      }
      if ((data.parameter_values.length > 1 && data.parameter_values[1].id > 20) || data.lengthParameters) {
        this.haircutParams.length = data.lengthParameters || data.parameter_values[1]
        this.handleLengthOptionClick(data.lengthParameters || data.parameter_values[1])
      }
      this.dataForSalonFilter.parameters = []
      if (!isEmpty(this.haircutParams.gender)) {
        this.dataForSalonFilter.parameters.push(this.haircutParams.gender.id)
      }

      if (!isEmpty(this.haircutParams.length)) {
        this.dataForSalonFilter.parameters.push(this.haircutParams.length.id)
      }
      if (!isEmpty(this.haircutParams.age)) {
        this.dataForSalonFilter.parameters.push(this.haircutParams.age.id)
      }

      if (
        this.dataForSalonFilter.book_date &&
        this.dataForSalonFilter.book_date !== 'Invalid date' &&
        this.dataForSalonFilter.parameters.length &&
        this.dataForSalonFilter.services.length
      ) {
        this.fetchSalonSalonStaffs(this.dataForSalonFilter)
      }
      this.$nextTick(() => (this.loadingCombinationData = false))
    }
  }
}
</script>
<style lang="scss" scoped>
// remove if .modal-body { display: none } is removed
.modal .modal-body {
  display: block !important;
}
.booking-section {
  &.active {
    border-left: 2px solid #ff6b09;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.multiselect__single {
  right: 0.7rem !important;
}
.multiselect-text-color {
  color: #111517;
}
.multiselect-choosen-position {
  margin-top: -5px !important;
}
.text-orange {
  color: #ff6b09;
}
.timeText {
  font-family: Roboto;
  font-size: 14px;
}

.markentplaceImage {
  width: 100%;
  height: 220px;
}
.tooltipInfoIcon:hover {
  color: #ff6b09 !important;
}
.tooltipInfoIcon {
  color: #e5e5e5;
  font-size: 16px;
}
.infoHairLengthBlock {
  width: 273px;
  height: 375px;
  padding: 15px;
  padding-bottom: 13px !important;
  background: #fff;
  border-radius: 7px;
}
.infoUnderBlock {
  height: 105px;
  display: flex;
  width: 100%;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #fbfbfb;
}
.infoUnderBlock img {
  margin: 15px;
  margin-bottom: 0 !important;
}
.bottomInfoTextBlock {
  background: #8d9fa7;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.hairtext-block {
  width: inherit;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}
.bottomInfoTextBlock {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-family: Roboto;
}
</style>
