<template>
  <div class="col-12 px-0 justify-content-center d-flex align-items-center" style="height: 50px">
    <div class="col-6 px-0 h-100 border-right d-none d-md-block">
      <div class="col p-0 d-flex">
        <div class="col-2 py-1 px-0 border-right text-left d-flex justify-content-center">
          <span v-if="name === 'Lady'" class="ml-0-8 lady-icon"></span>
          <span v-else class="ml-0-8 gent-icon"></span>
        </div>
        <div class="col-10 py-1 d-flex justify-content-left">
          <span v-if="name === 'Lady'">{{ $t('servicesRedesign.lady') }}</span>
          <span v-else>{{ $t('servicesRedesign.gentleman') }}</span>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-12 p-0 justify-content-center d-flex h-100 combination-column">
      <ColumnText :combination="returnCombination()" @set-choosen-combination="setChoosenCombination" />
    </div>
  </div>
</template>
<script>
import ColumnText from './../ColumnText'
export default {
  components: {
    ColumnText
  },
  props: {
    name: {
      type: String,
      default: null
    },
    combinations: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    returnCombination() {
      let combi = null
      this.combinations.forEach(combination => {
        if (combination.name.includes(this.name)) {
          combi = combination
        }
      })
      return combi
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
