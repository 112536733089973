<template>
  <main>
    <div class="location-bar col-auto left-side-width d-flex align-items-center pl-lg-5 pl-3-5 top-bar">
      <b-dropdown
        ref="dropdownServices"
        block
        variant="outline-secondary-custom"
        class="p-0 mr-1"
        menu-class="row w-auto"
        :toggle-class="`collapsed py-0-5 px-1 dropdown--default ${choosenServicesAmountForDropdownTab ? 'active' : ''}`"
        no-caret
        :text="
          `${
            !choosenServicesAmountForDropdownTab ? $t('common.card.services') : `${choosenServicesAmountForDropdownTab}`
          }`
        "
        @hide="setSavedFlag"
      >
        <div class="inside">
          <div class="col-12 text-left">
            <h6 class="mt-2 ml-0-5">
              {{ $t('common.forms.label.select_services') }}
            </h6>
            <ServicesForSalonsPage
              ref="servicesForSalonsPage"
              :is-toggled="isDropdownToggled"
              :is-saved="isServiceDropdownSaved"
              class="ml-0"
              @choosenServicesLength="setChoosenServicesLength"
              @isDataSet="toggleFlagChangeValue"
              @isDataSaved="savedFlagChangeValue"
            >
            </ServicesForSalonsPage>
          </div>
          <hr style="box-shadow: 0 5px 15px 0 rgba(17, 21, 23, 0.07);" class="mt-3" width="100%" />
          <div class="col-12 text-left mb-0-5 d-flex justify-content-end">
            <button
              ref="servicesSaveBtn"
              :disabled="!choosenServiceFromChild.length"
              class="btn btn-primary px-2 py-0-5"
              @click="fetchSalonsByParametersData"
            >
              {{ $t('common.forms.button.save') }}
            </button>
          </div>
        </div>
      </b-dropdown>
      <WideCalendar />

      <b-dropdown
        ref="dropdownLocation"
        block
        variant="outline-secondary-custom"
        class="p-0 mr-1"
        :toggle-class="`collapsed py-0-5 px-1 dropdown--default ${savedLocationForDropdownTab ? 'active' : ''}`"
        no-caret
        menu-class="w-auto"
        :text="
          `${savedLocationForDropdownTab ? `${truncatedFormattedAddressData()}` : $t('common.forms.label.location')}`
        "
        @hide="setSavedLocationValue"
      >
        <div class="inside">
          <div class="col-12 text-left">
            <h6 class="mt-2 ml-0-5">
              {{ $t('common.forms.label.select_location') }}
            </h6>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div id="multiselect" tabindex="-1" class="multiselect">
                    <div class="multiselect__actions d-flex flex-wrap align-items-center justify-content-end">
                      <i class="close-icon" @click="clearChoosenPlace"></i>
                    </div>
                    <div class="multiselect__tags d-flex align-items-center px-1-5 pt-0">
                      <div class="multiselect__tags-wrap" style="display: none;"></div>
                      <gmap-autocomplete
                        ref="gmap-autocomplete"
                        class="g-autocomplete pr-7"
                        :options="gmapAutoCompleteOptions"
                        @keypress.enter="$event.preventDefault()"
                        @place_changed="setPlace"
                      >
                      </gmap-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style="box-shadow: 0 5px 15px 0 rgba(17, 21, 23, 0.07);" class="mt-3" width="100%" />
          <div class="col-12 text-left mb-0-5 d-flex justify-content-end">
            <button
              ref="locSaveBtn"
              :disabled="!savedLocationForDropdownTab || !currentPlace || !currentLocation"
              class="btn btn-primary px-2 py-0-5"
              @click="setNewSearchData"
            >
              {{ $t('common.forms.button.save') }}
            </button>
          </div>
        </div>
      </b-dropdown>

      <b-dropdown
        ref="dropdownDistance"
        block
        variant="outline-secondary-custom"
        class="p-0 mr-1"
        menu-class="row w-auto"
        :toggle-class="`collapsed py-0-5 px-1 dropdown--default ${choosenRadiusValueForDropdownTab ? 'active' : ''}`"
        no-caret
        :text="
          `${$t('common.forms.label.distance')} ${choosenRadiusValueForDropdownTab}${$t(
            'common.forms.label.distance_value'
          )}`
        "
        @hide="setChoosenRadiusValue"
      >
        <div class="inside">
          <div class="col-12 text-left">
            <h6 class="mt-2 ml-0-5">
              {{ $t('common.forms.label.select_radius') }}
            </h6>

            <number-input v-model.number="radius" :min="0" :max="20" :center="true" class="col-12 text-center mb-1-5">
            </number-input>

            <div class="col-12 d-flex align-items-center mb-1-5">
              <span class="text-small text-gray">0</span>
              <input
                id="customRange3"
                v-model.number="radius"
                type="range"
                class="custom-range mx-2"
                min="0"
                max="20"
              />
              <span class="text-small text-gray">20</span>
            </div>
          </div>

          <hr style="box-shadow: 0 5px 15px 0 rgba(17, 21, 23, 0.07);" class="mt-3" width="100%" />
          <div class="col-12 mb-0-5 d-flex justify-content-end">
            <button class="btn btn-primary px-2 py-0-5" :disabled="radius === 0" @click="setNewSearchData">
              {{ $t('common.forms.button.save') }}
            </button>
          </div>
        </div>
      </b-dropdown>
    </div>
    <b-modal
      id="filter-modal"
      ref="filterTop"
      header-class="modal-header p-0 border-bottom-0"
      body-class="filterModalBody px-1-5 pb-1-5"
      centered
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block d-block d-md-none w-100">
          <div class="d-flex pt-1-5 justify-content-between">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="close()"
            ></font-awesome-icon>

            <h4 class="pl-1-5">
              {{ $t('common.forms.label.filters') }}
            </h4>

            <p class="collapsed change-pass-btn mr-1-5" @click="clearAllData">
              {{ $t('common.forms.button.clear_all') }}
            </p>
          </div>
        </div>
      </template>

      <div>
        <h6 class="mt-1 mb-1-5 responseModalText">
          {{ $t('common.forms.label.select_services') }}
        </h6>
        <Services :services-display-limit="2" class="ml-0 px-0"> </Services>
        <h6 class="my-1-5 responseModalText">
          {{ $t('common.forms.label.select_day') }}
        </h6>
        <Calendar />
        <div class="my-1-5">
          <h6 class="mb-1-5 responseModalText">
            {{ $t('common.forms.label.your_location') }}
          </h6>
          <div id="multiselect" tabindex="-1" class="multiselect has-icon map">
            <div class="multiselect__actions d-flex flex-wrap align-items-center">
              <font-awesome-icon
                :icon="['far', 'search']"
                class="icon-search"
                @click="setNewSearchData"
              ></font-awesome-icon>
              <span class="icon-sep mx-1"></span>
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close"
                @click="clearChoosenPlace"
              ></font-awesome-icon>
            </div>
            <div class="multiselect__tags" style="padding-top: 5px; height: 48px;">
              <div class="multiselect__tags-wrap" style="display: none;"></div>
              <gmap-autocomplete
                ref="gmap-autocomplete"
                class="g-autocomplete pr-3"
                style="font-size:16px;"
                :options="gmapAutoCompleteOptions"
                @keypress.enter="$event.preventDefault()"
                @place_changed="setPlace"
              >
              </gmap-autocomplete>
            </div>
          </div>
        </div>
        <div class="row col-12 mb-2-5 mt-2 mx-0 px-0">
          <div class="col-12 text-left pl-0 mb-1">
            <p class="text-small text-grey inputFont">
              {{ currentLocation ? $t('common.forms.label.select_radius') : $t('common.forms.label.choose_radius') }}
            </p>
          </div>
          <div class="col-12 col-md-3 d-flex rangeValueBlock px-0">
            <button href="#" :disabled="!currentLocation" class="changeRadiusValueButton" @click="takeAwayRadiusValue">
              -
            </button>

            <span :v-model="radius" class="valueSpan">{{ radius }} km</span>
            <button class="changeRadiusValueButton" :disabled="!currentLocation" @click.prevent="addRadiusValue">
              +
            </button>
          </div>

          <div class="col d-flex align-items-center pr-0 pl-md-2 px-0 pr-md-0 mt-2 mt-md-0 collapsed">
            <span class="text-small text-gray pr-1 pr-md-0">1</span>
            <VueSlider
              v-model.number="radius"
              :disabled="!currentLocation"
              v-bind="options"
              class="col px-1-5"
            ></VueSlider>

            <span class="text-small text-gray">20</span>
          </div>
        </div>
        <div>
          <div ref="btnBlock" class="d-flex mb-1">
            <button
              ref="rate"
              style="font-size:14px; padding: 12px 14px"
              class="col btn btn-outline-secondary-custom collapsed left-btn"
              @click="getSalonsByRating"
            >
              {{ $t('common.forms.button.total_rating') }}
            </button>
            <button
              ref="actual"
              style="font-size:14px; padding: 12px 14px;"
              class="col btn-outline-secondary-custom collapsed right-btn"
              @click="getSalonsByActual"
            >
              {{ $t('common.forms.button.actual_rating') }}
            </button>
          </div>
        </div>
        <button type="submit" class="btn btn-lg btn-primary btn-block mt-3" @click="hideFilterModal">
          <p class="h6 font-weight-medium mb-0 text-white">
            {{ $t('common.forms.button.save_changes') }}
          </p>
        </button>
      </div>
    </b-modal>
  </main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import ServicesForSalonsPage from './../components/ServicesForSalonsPage'
import Services from './../components/Services'
import WideCalendar from './WideCalendar'
import Calendar from './Calendar'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'UserLocationBar',
  components: {
    'number-input': VueNumberInput,
    Services,
    ServicesForSalonsPage,
    WideCalendar,
    Calendar,
    VueSlider
  },
  props: {
    searchSalonsData: {
      type: Object,
      default: () => ({})
    },
    mountedRequestFlag: {
      type: Boolean
    }
  },
  data() {
    return {
      radius: 20,
      currentLocation: null,
      savedLocationForDropdownTab: null,
      choosenServicesAmountForDropdownTab: null,
      choosenRadiusValueForDropdownTab: null,
      isSearchDisabled: false,
      choosenServicesIsEmpty: false,
      chosenServicesLength: null,
      choosenServicesTitle: [],
      choosenPlace: null,
      isDropdownToggled: false,
      isServiceDropdownSaved: false,
      choosenServiceFromChild: [],
      savedLocationInputValue: null,
      repeatedClickOnPrimaryButton: false,
      options: {
        min: 1,
        max: 20,
        interval: 0.5,
        height: 9,
        tooltip: 'none'
      },
      gmapAutoCompleteOptions: {
        fields: ['geometry', 'formatted_address', 'name'],
        componentRestrictions: {
          country: 'ch'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      storedRadius: 'salons/getSearchRadius',
      currentPlace: 'salons/getCurrentPlace',
      getChoosenServices: 'haircutParams/getChoosenServices',
      getCommonHaircutParams: 'haircutParams/getCommonHaircutParams',
      getChoosenDate: 'haircutParams/getChoosenDate',
      getLocationModalDataSavedFlag: 'salons/getLocationModalDataSavedFlag'
    })
  },
  watch: {
    currentLocation: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          if (typeof newVal === 'string') this.$refs['gmap-autocomplete'].$refs.input.value = newVal
          else this.$refs['gmap-autocomplete'].$refs.input.value = newVal.formatted_address
          this.$refs.locSaveBtn.disabled = false
          this.isSearchDisabled = false
        }
      },
      getLocationModalDataSavedFlag(newVal) {
        if (newVal) {
          this.savedLocationForDropdownTab = this.currentPlace
          this.choosenRadiusValueForDropdownTab = this.storedRadius
        }
        this.setSavedDataFlag(false)
      },
      storedRadius(newVal) {
        this.radius = newVal
      },
      currentPlace: {
        deep: true,
        handler(newVal) {
          if (newVal) {
            if (typeof newVal === 'string') {
              this.$refs['gmap-autocomplete'].$refs.input.value = newVal
            } else {
              this.$refs['gmap-autocomplete'].$refs.input.value = newVal.formatted_address
            }
            this.currentLocation = newVal
          }
        }
      }
    },
    getChoosenServices: {
      deep: true,
      handler(newVal) {
        this.checkChoosenServicesLength(newVal)
      }
    },
    getChoosenDate(newVal) {
      if (this.mountedRequestFlag) {
        this.getSalons(this.searchSalonsData)
      }
    }
  },
  mounted() {
    this.checkChoosenServicesLength(this.getChoosenServices)
    if (this.getChoosenServices.length) this.choosenServicesAmountForDropdownTab = this.getChoosenServices[0].title

    this.radius = this.storedRadius
    this.choosenRadiusValueForDropdownTab = this.radius
    if (this.currentPlace) {
      this.currentLocation = this.currentPlace
      this.savedLocationForDropdownTab = this.currentLocation
      let formattedLocationString =
        typeof this.currentPlace === 'object' && this.currentPlace
          ? this.currentPlace.formatted_address
          : this.currentPlace
      this.$refs['gmap-autocomplete'].$refs.input.value = formattedLocationString

      this.setPlace(this.currentPlace)
    }
    let isIos = navigator.platform.includes('iPhone') ? true : false
    if (isIos && window.innerWidth < 500) this.$refs.filterTop.bodyClasses.push('mt-0-8')
    else if (isIos && window.innerWidth > 500) this.$refs.filterTop.bodyClasses.push('mt-3')
  },
  beforeDestroy() {
    this.saveCHoosenServices()
  },
  // TODO: 1 method for all clear-data methods
  methods: {
    ...mapActions('salons', {
      setCurrentPlace: 'setCurrentPlace',
      setSearchRadius: 'setSearchRadius',
      setNewSearchDataFlag: 'setNewSearchDataFlag',
      getSalons: 'getSalons',
      clearCurrentPlace: 'clearCurrentPlace'
    }),
    ...mapActions({
      clearChoosenServices: 'haircutParams/clearChoosenServices',
      clearChoosenDate: 'haircutParams/clearChoosenDate',
      addServices: 'haircutParams/addServices',
      getServices: 'haircutParams/getApiServices'
    }),
    ...mapMutations({
      resetChoosenServices: 'haircutParams/RESET_CHOOSEN_SERVICES',
      addChoosenService: 'haircutParams/ADD_SERVICE',
      saveCHoosenServices: 'haircutParams/SAVE_CHOOSEN_SERVICES',
      clearCurrentPlace: 'salons/CLEAR_CURRENT_PLACE',
      clearSalons: 'salons/CLEAR_SALONS',
      setSavedDataFlag: 'SET_LOCATION_MODAL_DATA_FLAG'
    }),
    setPlace(place) {
      this.currentLocation = place
    },
    clearChoosenPlace() {
      this.savedLocationInputValue = this.currentPlace
      this.$emit('clearPlaceEmitFlag', true)
      this.currentLocation = null
      this.$refs['gmap-autocomplete'].$el.value = ''
      this.setNewSearchDataFlag({
        component: 'LocationBar',
        isNewDataSet: true
      })
    },
    setNewSearchData() {
      this.setSearchRadius(this.radius)
      this.choosenRadiusValueForDropdownTab = this.radius
      if (this.currentLocation) {
        this.savedLocationForDropdownTab = this.currentLocation
        this.setCurrentPlace(this.currentLocation)
      }

      this.setNewSearchDataFlag({
        component: 'LocationBar',
        isNewDataSet: true
      })
      this.$refs.dropdownLocation.hide(true)
      this.$refs.dropdownDistance.hide(true)
    },
    async fetchSalonsByParametersData() {
      this.isServiceDropdownSaved = true
      await this.$refs.servicesForSalonsPage.setService()

      this.getSalons(this.searchSalonsData)
      this.getServices()
      this.$refs.dropdownServices.hide(true)
      this.isServiceDropdownSaved = true
    },
    resetChoosenServicesMethod() {
      this.choosenServicesAmountForDropdownTab = null
      this.clearChoosenServices()
      this.clearSalons()
    },
    checkChoosenServicesLength(val) {
      !val.length ? (this.choosenServicesIsEmpty = true) : (this.choosenServicesIsEmpty = false)
    },
    hideFilterModal() {
      this.setNewSearchData()
      this.getSalons(this.searchSalonsData)
      this.$bvModal.hide('filter-modal')
    },
    applyAndSearch() {
      this.setSearchRadius(+this.radius)
      this.setCurrentPlace(this.choosenPlace)
      this.setNewSearchDataFlag({
        component: 'Modal',
        isNewDataSet: true
      })
    },
    getSalonsByRating() {
      this.repeatedClickOnPrimaryButton = false
      this.$refs.rate.classList.forEach(el => {
        if (el === 'btn-primary') this.repeatedClickOnPrimaryButton = true
      })
      delete this.searchSalonsData.distance_sort
      if (this.repeatedClickOnPrimaryButton) {
        delete this.searchSalonsData.rating_total_sort
        delete this.searchSalonsData.rating_actual_sort
      } else Object.assign(this.searchSalonsData, { rating_total_sort: 0, rating_actual_sort: 1 })
      this.classWork(this.$refs.rate, this.$refs.actual)
    },
    getSalonsByActual() {
      this.repeatedClickOnPrimaryButton = false
      this.$refs.actual.classList.forEach(el => {
        if (el === 'btn-primary') this.repeatedClickOnPrimaryButton = true
      })
      if (this.repeatedClickOnPrimaryButton) {
        delete this.searchSalonsData.distance_sort
      } else Object.assign(this.searchSalonsData, { distance_sort: 1 })
      delete this.searchSalonsData.rating_total_sort
      delete this.searchSalonsData.rating_actual_sort
      this.classWork(this.$refs.actual, this.$refs.rate)
    },
    classWork(btnToAdd, btnToRemove) {
      btnToRemove.classList.add('btn-outline-secondary-custom', 'collapsed')
      btnToRemove.classList.remove('btn-primary')
      if (!this.repeatedClickOnPrimaryButton) {
        btnToAdd.classList.add('btn-primary')
        btnToAdd.classList.remove('btn-outline-secondary-custom', 'collapsed')
      } else {
        btnToAdd.classList.remove('btn-primary')
        btnToAdd.classList.add('btn-outline-secondary-custom', 'collapsed')
      }
    },
    clearAllData() {
      this.clearChoosenPlace()
      this.resetChoosenServicesMethod()
      this.clearChoosenDate()
    },
    setSavedLocationValue() {
      let locationInput = document.querySelector('.g-autocomplete')
      if (this.currentPlace) {
        locationInput.value = this.currentPlace.formatted_address
        this.setPlace(this.currentPlace)
        this.savedLocationForDropdownTab = this.currentPlace.formatted_address
      } else {
        this.savedLocationInputValue
          ? (locationInput.value = this.savedLocationInputValue.formatted_address)
          : locationInput.value
        this.savedLocationForDropdownTab = this.savedLocationInputValue
        this.$emit('setPendingFlag', true)
        this.setCurrentPlace(this.savedLocationInputValue).then(() => this.getSalons(this.searchSalonsData))
      }
    },
    setChoosenRadiusValue() {
      this.radius = this.choosenRadiusValueForDropdownTab
    },
    setSavedFlag() {
      this.isDropdownToggled = true
    },
    toggleFlagChangeValue(val) {
      this.isDropdownToggled = val
    },
    savedFlagChangeValue(val) {
      this.isServiceDropdownSaved = val
      if (this.getChoosenServices.length) this.choosenServicesAmountForDropdownTab = this.getChoosenServices[0].title
    },
    setChoosenServicesLength(val) {
      if (Array.isArray(val)) this.choosenServiceFromChild = val
      else this.choosenServiceFromChild = Array(val)
    },
    takeAwayRadiusValue() {
      if (this.radius !== 1) this.radius = this.radius - 0.5
    },
    addRadiusValue() {
      if (this.radius < 20) this.radius = this.radius + 0.5
    },
    truncatedFormattedAddressData() {
      if (
        this.savedLocationForDropdownTab &&
        typeof this.savedLocationForDropdownTab === 'object' &&
        Object.keys(this.savedLocationForDropdownTab).length
      )
        return this.savedLocationForDropdownTab.formatted_address.substring(0, 45) + '...'
      else if (this.savedLocationForDropdownTab && typeof this.savedLocationForDropdownTab === 'string')
        return this.savedLocationForDropdownTab.substring(0, 45) + '...'
      else if (!this.savedLocationForDropdownTab) return ''
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  .dropdown--default {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: solid 1px #e0e0e0;
    font-size: 14px;
    &:after {
      content: none;
    }
  }
}

.icon {
  display: inline-block;
}
.radius-icon {
  width: 18px;
  height: 18px;
}
.inside {
  width: 439px;
  height: auto;
  background-color: #ffffff;
}
.multiselect__select {
  display: none !important;
}
.multiselect__actions {
  top: 2px !important;
  padding-top: 0.4rem !important;
}
.multiselect__input {
  margin-top: 5px !important;
}
.clearBtn {
  color: #8d9fa7;
  font-size: 14px;
  border-bottom: 1px solid white;
  cursor: pointer;
}
.clearBtn:hover {
  border-bottom: 1px solid #8d9fa7;
}
* {
  outline-style: none !important;
}
.pac-container {
  z-index: 1050 !important;
}
.right-btn {
  border-radius: 0 4px 4px 0 !important;
}
.left-btn {
  border-radius: 4px 0 0 4px !important;
}
.top-block {
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 2px #e0e0e0;
}

@media screen and (max-width: 767px) {
  .vue-slider-dot {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
