const getAccountInfo = state => state.accountInfo
const getFeedbackRequest = state => state.accountInfo.booking
const getAuthInfo = state => ({
  isAuthorized: state.isAuthorized,
  socialAuthType: state.socialAuthType
})

const getFavorites = state => state.favorites
const getTemplates = state => state.accountInfo.clients
const getRedirectedAuthFlag = state => state.isRedirectedForAuth
const getResetEmail = state => state.resetEmail
const getUserPosition = state => state.userPosition
const getAuthorizedFlag = state => state.isAuthorized
const getMainTemplate = state => {
  return state.accountInfo.clients.find(template => template.main_template === 1)
}

const getUserAvatar = (state, getters) => {
  const mainTemplate = getters['getMainTemplate']

  return mainTemplate !== undefined && mainTemplate.avatar !== undefined && mainTemplate.avatar !== null
    ? mainTemplate.avatar.path
    : require('@/assets/images/default/default_avatar.png')
}

const getSiteLang = state => state.siteLang
const getAcceptCookiesAlert = state => state.hideAcceptCookiesAlert

export default {
  getAccountInfo,
  getAuthInfo,
  getFavorites,
  getTemplates,
  getUserPosition,
  getResetEmail,
  getRedirectedAuthFlag,
  getMainTemplate,
  getAuthorizedFlag,
  getUserAvatar,
  getSiteLang,
  getFeedbackRequest,
  getAcceptCookiesAlert
}
