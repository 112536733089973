<template>
  <div v-if="girls.length || boys.length" class="col-12 d-flex px-0 text-center">
    <KidsParameters
      :kids-combination="returnSortedCombinationsArr([...girls, ...boys])"
      :service-category-haircut-params="serviceCategoryHaircutParams"
    />
    <slot v-if="serviceCategoryHaircutParams" name="rowWithLength">
      <div class="col-9 p-0 d-flex flex-wrap">
        <ColumnWithLength
          :is-other-parameter-empty="returnSortedCombinationsArr(boys).length ? false : true"
          :name="'Girl'"
          :combinations="returnSortedCombinationsArr(girls)"
          @set-choosen-combination="setChoosenCombination"
        />
        <ColumnWithLength
          :is-other-parameter-empty="returnSortedCombinationsArr(girls).length ? false : true"
          :name="'Boy'"
          :combinations="returnSortedCombinationsArr(boys)"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
    </slot>
    <slot v-else name="rowWithoutLength">
      <ColumnWithoutLength
        :combinations="returnSortedCombinationsArr([...girls, ...boys])"
        @set-choosen-combination="setChoosenCombination"
      />
    </slot>
  </div>
</template>
<script>
import KidsParameters from './KidsColumn/KIdsParameters'
import ColumnWithLength from './KidsColumn/ColumnWithLength'
import ColumnWithoutLength from './KidsColumn/ColumnWithoutLength'
export default {
  components: {
    KidsParameters,
    ColumnWithLength,
    ColumnWithoutLength
  },
  props: {
    kidsCombination: {
      type: Array,
      default: () => []
    },
    girls: {
      type: Array,
      default: () => []
    },
    boys: {
      type: Array,
      default: () => []
    },
    serviceCategoryHaircutParams: {
      type: Number,
      default: null
    }
  },
  methods: {
    returnSortedCombinationsArr(combinationsArray) {
      let arr = []

      combinationsArray.forEach(combi => {
        let obj = {
          gender: null,
          ageFrom: null,
          ageTo: null,
          combination: []
        }
        obj.gender = combi.parameter_values[0].value
        obj.ageTo = combi.parameter_values[combi.parameter_values.length - 1].value
        obj.combination.push(combi)
        if (combi.parameter_values[1].id > 20) {
          obj.ageFrom = combi.parameter_values[2].value
        } else {
          obj.ageFrom = combi.parameter_values[1].value
        }
        arr.forEach(el => {
          if (el.gender === obj.gender && el.ageTo === obj.ageTo && el.ageFrom === obj.ageFrom) {
            el.combination.push(combi)
          }
        })
        arr.push(obj)
      })

      let uniqueArray = arr.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(t => t.gender === thing.gender && t.ageTo === thing.ageTo && t.ageFrom === thing.ageFrom)
      )

      return uniqueArray
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
