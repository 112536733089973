<template>
  <div>
    <div class="form-group">
      <div class="d-flex align-items-center mt-2 mb-0-5">
        <div v-if="!hideSubTitle" class="d-flex flex-nowrap">
          <p class="group-label font-weight-medium mb-0 mt-0-3 ml-md-0 ml-0-3 responseModalText">
            {{ $t('common.forms.label.choose_length') }}
          </p>
          <infoPopup />
        </div>
      </div>
      <div class="row text-center">
        <div v-for="option in options" :key="option.id" class="col px-md-0-8">
          <label class="w-100">
            <input
              :id="`hairlenght-${option.id}`"
              v-model="params.length"
              type="radio"
              name="radio-3"
              class="btn-checkbox"
              autocomplete="off"
              :value="option"
            />
            <span class="btn btn-checkbox btn-block">{{ $t(`common.length_key.${option.value}`) }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import infoPopup from '@/components/infoPopup'
export default {
  components: {
    infoPopup
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    params: {
      type: Object,
      default: () => ({})
    },
    hideSubTitle: Boolean
  },
  data() {
    return {}
  },
  watch: {
    'params.length': {
      handler(val) {
        this.handleLengthOptionClick(val)
      }
    }
  },
  methods: {
    ...mapActions('haircutParams', {
      setCommonHaircutParams: 'setCommonHaircutParams'
    }),
    handleLengthOptionClick(value) {
      const hairLengthParam = { paramName: 'length', value }
      this.setCommonHaircutParams(hairLengthParam)
    }
  }
}
</script>
