<template>
  <div v-if="isLoaded" class="tab-content h-100 w-100" :class="{ 'm-auto': getBookingHistoryData.length === 0 }">
    <div role="tabpanel" aria-labelledby="v-pills-info-tab" class="d-flex flex-column justify-content-start h-100">
      <div v-if="getBookingHistoryData.length === 0" class="text-center m-auto">
        <i class="template-icon mb-2"></i>
        <h1 class="h3 mb-1-5">{{ $t('common.profile_menu.link.history') }}</h1>
        <p class="my-2-5 font-weight-medium poppins-font text-black text-center">
          {{ $t('profile_history.empty_content_text') }}
        </p>
      </div>

      <div class="mb-md-5">
        <h1 class="h3 mb-1-5">{{ $t('common.profile_menu.link.history') }}</h1>
        <div
          v-for="(item, key) in getBookingHistoryData"
          :key="key"
          class="card d-flex justify-content-between flex-row p-1-5 mb-1-5 align-items-center"
        >
          <div class="media align-items-center">
            <img
              :src="specialistAvatarPath(item.salon_staff)"
              class="mr-1-5"
              width="45px"
              height="45px"
              style="border-radius: 3px"
              alt
            />
            <div class="media-body">
              <p class="h6 mb-0-8 text-black font-weight-medium">{{ item.salon_staff.specialization }}</p>
              <span class="text-gray"
                >{{ item.salon_staff.user.first_name }} {{ item.salon_staff.user.last_name }}</span
              >
            </div>
          </div>
          <div class="pl-0-8">
            <p class="text-primary font-weight-medium mb-0-8 text-right text-nowrap">{{ item.price }} CHF</p>
            <p class="text-gray mb-0 text-right text-nowrap">{{ item.start_time | filterData }}</p>
          </div>
        </div>
      </div>
      <div class="row mt-3 ml-3">
        <pagination :data="getBookingHistory" :show-disabled="true" @pagination-change-page="getHistoryPagination">
          <span slot="prev-nav">
            <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
          </span>
          <span slot="next-nav">
            <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
          </span>
        </pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'UserHistory',
  filters: {
    filterData(value) {
      return moment(value).format('DD MMMM, Y')
    }
  },
  data() {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      getBookingHistory: 'salons/getBookingHistory'
    }),
    getBookingHistoryData() {
      return this.getBookingHistory.data
    }
  },
  mounted() {
    this.fetchBookingHistory().then(() => {
      this.isLoaded = true
    })
    this.getHistoryPagination()
  },
  methods: {
    ...mapActions({
      fetchBookingHistory: 'salons/getBookingHistory'
    }),
    getHistoryPagination(page = 1) {
      this.fetchBookingHistory({ page })
    },
    specialistAvatarPath(staff) {
      return staff.avatar ? staff.avatar.path : require('@/assets/images/default/default_avatar.png')
    }
  }
}
</script>
