<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mt-5 mb-2">
          Kinder Coiffeur – der Coiffeur für das besondere Klientel
        </h1>
        <p class="mb-2">
          Vermutlich sind die Anforderung an keinen Coiffeur so hoch wie an den Kinder Coiffeur. Das liegt weniger
          daran, dass dieses Klientel besonders anspruchsvoll im Hinblick auf die Frisuren ist. Mama und Papa sind es
          vielleicht schon, aber den kleinen Kunden geht es um andere Dinge. Darum, dass sie sich wohl fühlen und keine
          Angst zu haben brauchen. Darum, dass sie während ihres Coiffeur-Besuchs auch Kind bleiben dürfen und nicht
          stillsitzen müssen wie eine Wachsfigur. Darum, dass der Friseur Geduld zeigt und Verständnis für ihre Unruhe
          und ihre Neugier mitbringt. Nicht ohne Grund gibt es Coiffeure, die Kinder nur ungern oder gar nicht annehmen.
        </p>
        <p class="mb-2">
          Doch dass es ihn gibt, den idealen Kinder Coiffeur, den Coiffeur, dem der Umgang mit Deinem Kind grosse Freude
          bereitet, das zeigen wir Dir. Denn in unserem Quiff Salon-Portfolio findest Du auch zahlreiche Coiffeure, die
          Deiner Prinzessin oder Deinem Superhelden den passenden Kinderhaarschnitt verpassen. Den Kinderhaarschnitt für
          Mädchen oder den frechen Haarschnitt für Jungen. Mit ganz viel Geduld, mit ganz viel Einfühlungsvermögen und
          im Idealfall in einem kindgerechten Salon. Vielleicht hast Du Glück und ein
          <a :href="coiffeurNane" target="_blank" title="Coiffeur in der Nähe">Coiffeur in der Nähe</a> punktet sogar in
          Sachen kindertauglich. Das entnimmst Du am besten den Bewertungen anderer Kunden. Diese unterstützen bzw.
          beeinflussen Dich sicher auch bei Deiner Suche nach einem
          <router-link to="/" title="Damen Coiffeur">Damen Coiffeur</router-link> oder
          <router-link to="/Herren-Coiffeur" title="Herren Coiffeur">Herren Coiffeur.</router-link>
        </p>
        <h2 class="mb-2">
          Triff die für Dich richtige Wahl für Dein Kind – nicht nur der Kinderhaarschnitt sollte perfekt sein
        </h2>
        <p class="mb-2">
          Wir liefern Dir hier eine Auswahl, die Du Dir in Ruhe – vielleicht sogar gemeinsam mit Deinem Kind – anschauen
          kannst. Zahlreiche Impressionen, auch in Form von Fotos, und Erfahrungsberichte anderer Eltern helfen Dir
          sicherlich dabei, die richtige Entscheidung zu treffen. Du als Elternteil musst ein gutes Gefühl dabei haben,
          aber auch Dein Kind muss sich gut aufgehoben fühlen.
        </p>
        <p class="mb-2">
          Wenn Du die Wahl für den Kinder Coiffeur getroffen hast, kannst Du einen Termin über Dein eigenes Profil
          buchen. Denn hier hast Du die Möglichkeit, auch Familienmitglieder anzulegen und für diese ebenfalls einen
          Coiffeur <router-link to="/" title="Termin online zu buchen">Termin online zu buchen</router-link>. Direkt im
          Anschluss senden wir Dir eine kurze Bestätigung zu – und erinnern Dich kurz vor dem Termin noch einmal per
          Mail. Und dann sind wir sehr gespannt, welche Erfahrungen ihr mit eurem Kinder Coiffeur gemacht habt. Die
          kannst Du anschliessend gerne mit anderen Mamas und Papas teilen und ihnen so die Suche erleichtern.
        </p>
        <div class="my-5 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Quiff zeigt Dir, wo Du den perfekten Kinder Coiffeur ganz in Deiner Nähe findest. Schliesslich willst Du Dein Kind gut aufgehoben wissen.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    },
    coiffeurNane() {
      return `${config.salonAppUrl}/Coiffeur-in-der-Nähe`
    }
  }
}
</script>
