<template>
  <div class="map-container">
    <gmap-map
      ref="gmap"
      :center="Object.keys(showSalonData.coords).length ? showSalonData.coords : center"
      :zoom="mapZoom"
      :style="{ width: styleMapContainer.width, height: styleMapContainer.height }"
      :options="options"
      @center_changed="clearCenter"
      @zoom_changed="setZoom"
    >
      <gmap-marker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :icon="!m.position.currentPlace ? salonMarker : ''"
        @click="showDetials(m)"
      ></gmap-marker>
    </gmap-map>
    <SalonMapCard v-if="salon" :salon="salon" :index="1" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SalonMapCard from './SalonMapCard'

export default {
  name: 'Map',
  components: {
    SalonMapCard
  },
  props: {
    signleMarker: Boolean,
    showSalonData: {
      type: Object,
      default: () => {
        return {
          radius: 18,
          isShowOnMapActive: false,
          coords: { lat: 46.948, lng: 7.4474 }
        }
      }
    },
    styleMapContainer: {
      type: Object,
      default: () => {
        return {
          width: '100%',
          height: '800px'
        }
      }
    },
    choosenSalon: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      center: { lat: 46.948, lng: 7.4474 },
      markers: [],
      mapZoom: 18,
      salonMarker: {
        url: require('../assets/images/icons/gmarker.png'),
        size: { width: 60, height: 60, f: 'px', b: 'px' },
        scaledSize: { width: 60, height: 60, f: 'px', b: 'px' }
      },
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
        componentRestrictions: {
          fields: ['geometry', 'formatted_address', 'name'],
          country: 'ch'
        },
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      },
      directionsService: null,
      directionsDisplay: null,
      salon: null
    }
  },
  computed: {
    ...mapGetters({
      currentPlace: 'salons/getCurrentPlace',
      salons: 'salons/getSalons',
      newSearchDataInfo: 'salons/getNewSearchDataInfo'
    })
  },
  watch: {
    showSalonData: {
      deep: true,
      handler() {
        this.setAllMarkers()
      }
    },
    markers: {
      deep: true,
      handler(newVal) {
        if (this.$route.name === 'salon-page' && newVal.length > 1) setTimeout(this.updateMap, 0)
      }
    }
  },
  mounted() {
    if (Object.keys(this.showSalonData.coords).length) this.center = this.showSalonData.coords
    this.setAllMarkers()
  },
  beforeDestroy() {
    this.markers = []
  },
  methods: {
    ...mapActions('salons', {
      setCurrentPlace: 'setCurrentPlace',
      setNewSearchDataFlag: 'setNewSearchDataFlag'
    }),
    setZoom(val) {
      this.mapZoom = val
    },
    setAllMarkers() {
      this.clearOverlays()

      let currentPlaceCoords
      if (this.$route.name === 'salons-page') {
        currentPlaceCoords =
          this.currentPlace !== null && typeof this.currentPlace === 'object' && Object.keys(this.currentPlace).length
            ? this.currentPlace.geometry.location
            : this.showSalonData.coords
        this.addCurrentPlaceMarkerAndCenter(currentPlaceCoords, 'newCurrentPlace')
        setTimeout(this.updateMap, 0)
      } else {
        this.addMarkersOnDetailPage()
      }

      this.setNewSearchDataFlag({ component: '', isNewDataSet: false })
    },
    // TODO: errors in console, because Gmap cannot read center of empty object
    clearCenter(data) {
      this.center = {
        lat: typeof data.lat === 'function' ? data.lat() : data.lat,
        lng: typeof data.lng === 'function' ? data.lng() : data.lng
      }
    },
    addCurrentPlaceMarkerAndCenter(place, type) {
      this.markers = []
      if (place && type === 'newCurrentPlace') {
        const marker = {
          lat: typeof place.lat === 'function' ? +place.lat() : +place.lat,
          lng: typeof place.lng === 'function' ? +place.lng() : +place.lng,
          currentPlace: true
        }
        this.markers.push({ position: marker })
      } else if (!place) {
        this.markers.push({ position: { ...this.center, currentPlace: true } })
      } else if (place.lat && place.lng && type === 'oldCurrentPlace') {
        this.markers.push({ position: { ...place, currentPlace: true } })
      }

      this.addSalonsMarkers()
    },
    addSalonsMarkers() {
      if (this.salons.total) {
        for (let salon of this.salons.data) {
          const marker = {
            lat: Number(salon.address.latitude),
            lng: Number(salon.address.longitude),
            salon: salon,
            currentPlace: false
          }

          this.markers.push({ position: marker })
        }
      }
    },
    addMarkersOnDetailPage() {
      if (Object.keys(this.showSalonData.coords).length) {
        const Salonmarker = {
          lat: Number(this.showSalonData.coords.lat),
          lng: Number(this.showSalonData.coords.lng),
          salon: this.choosenSalon,
          currentPlace: false
        }
        this.markers.push({ position: Salonmarker })
      }
      if (
        this.currentPlace !== null &&
        typeof this.currentPlace === 'object' &&
        Object.keys(this.currentPlace).length
      ) {
        const UserLocationmarker = {
          lat:
            typeof this.currentPlace.geometry.location.lat === 'function'
              ? +this.currentPlace.geometry.location.lat()
              : +this.currentPlace.geometry.location.lat,
          lng:
            typeof this.currentPlace.geometry.location.lng === 'function'
              ? +this.currentPlace.geometry.location.lng()
              : +this.currentPlace.geometry.location.lng,
          currentPlace: true
        }
        this.markers.push({ position: UserLocationmarker })
        this.updateMap()
      }
    },
    updateMap() {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new google.maps.LatLngBounds()
        for (let m of this.markers) {
          bounds.extend(m.position)
        }

        map.fitBounds(bounds)
        map.panToBounds(bounds)
      })
    },
    toSalon(position) {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new google.maps.LatLngBounds()

        bounds.extend(position)

        map.fitBounds(bounds)
        map.panToBounds(bounds)
      })
    },
    clearOverlays() {
      this.markers = []
    },
    showDetials(marker) {
      if (this.$route.name === 'salon-page') return

      this.salon = {
        ...marker.position.salon
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.map-wrapper {
  > div {
    height: 100%;
  }
  height: 100%;
}
.vue-map-container,
.map {
  width: 100% !important;
  height: 100% !important;
}
</style>
