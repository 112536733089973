const getSalons = state => state.salons
const getFavorites = state => state.favorites
const getSalon = state => state.salon
const getSalonServices = state => state.salonServices
const getSalonReviews = state => state.salonReviews
const getStaffReviews = state => state.staffReviews
const getSalonStaff = state => state.salonStaff
const getSalonStaffList = state => state.salonStaffList
const getSalonsForSearch = state => state.salonsForSearch
const getSearchRadius = state => (state.radius !== 0 ? state.radius : 18)
const getCurrentPlace = state => state.currentPlace
const getLocationModalDataSavedFlag = state => state.isLocationModalDataSaved
const getNewSearchDataInfo = state => state.newSearchDataInfo
const getSalonsStaffFilter = state => state.salonsStaffFilter
const getSelectedSalonCardIndex = state => state.selectedSalonCardIndex
const getBookingHistory = state => state.bookingHistory
const getSalonCombination = state => state.salonCombination
const getRequestNewSalonsData = state => state.requestNewSalonsData
const getRequestSalonsSent = state => state.requestSalonsSent
const getAvaliableSalons = state => state.avaliableSalons
const getsalonProductCategories = state => state.salonProductCategories
const getSalonProducts = state => state.salonProducts
const getProduct = state => state.choosenProduct

export default {
  getSalons,
  getFavorites,
  getSalon,
  getSalonServices,
  getSalonReviews,
  getSalonStaff,
  getSalonsForSearch,
  getSearchRadius,
  getCurrentPlace,
  getSalonStaffList,
  getNewSearchDataInfo,
  getSalonsStaffFilter,
  getSelectedSalonCardIndex,
  getBookingHistory,
  getSalonCombination,
  getLocationModalDataSavedFlag,
  getRequestNewSalonsData,
  getRequestSalonsSent,
  getStaffReviews,
  getAvaliableSalons,
  getsalonProductCategories,
  getProduct,
  getSalonProducts
}
