<template>
  <main class="product-detail-page">
    <div v-if="Object.keys(getProduct).length && dataIsLoaded" class="row w-100 mx-0">
      <div class="d-none d-lg-block">
        <div class="d-flex cursor-pointer" @click="goToMarket">
          <font-awesome-icon :icon="['far', 'arrow-left']" class="icon-arrow-left pt-0-2"></font-awesome-icon>
          <p class="pl-1">{{ $t('market.back-to') }}</p>
        </div>
      </div>
      <div class="d-block d-md-none col-12 mt-1-5 mb-1-5 d-flex flex-wrap productCarousel px-0">
        <b-carousel
          v-model="slide"
          
          :interval="0"
          controls
          :indicators="isIndicatorsVisible"
          background="#ababab"
          img-width="100%"
          img-height="100"
          navigation-next-label="<i class='material-icons'>keyboard_arrow_right</i>"
          navigation-prev-label="<i class='material-icons'>keyboard_arrow_left</i>"
          class="mx-1-5 rounded-block images-carousel salonCardCarousel"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide v-for="(image, key) in imagesArr" :key="`image-${key}`" class="slide">
            <template v-slot:img>
              <img :src="image.path ? image.path : require('@/assets/images/default/default-product.png')" class="d-block h-100 w-100 image-carousel salonCardCarouselImage" alt="..." />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div class="d-flex flex-wrap w-100">
        <div class="d-none d-md-block col-lg-7 col-xl-6 col-12 pl-0 pr-xl-5 pr-0 gallery block">
          <div class="gallery w-100 d-flex justify-content-center">
            <ul id="thumbs" @click="galleryChangeImage($event)">
              <li v-for="image in imagesArr" :key="image.id">
                <a
                  :href="image.path"
                  class="imageMini"
                  :class="{ activeMiniImage: image.id === imagesArr[0].id }"
                  title="Image 2"
                  ><img :src="image.path" class="product-images-fit" width="100%" height="100%"
                /></a>
              </li>
            </ul>
            <p class="imgBlock mb-0"><img id="largeImg" :src="imagesArr[0].path" alt="Large image" class="product-images-fit" /></p>
          </div>
        </div>
        <div class="col-lg-5 col-xl-6 col-12 pl-0 pl-xl-2 pr-0 info-block">
          <h3 class="mb-1-5 titleLineHeight px-1-5 px-md-0">{{ getProduct.title }}</h3>
          <p class="mb-3 px-1-5 px-md-0">{{ getProduct.short_description }}</p>
          <div class="d-flex mb-2 px-1-5 px-md-0">
            <span>{{ $t('market.price') }}</span>
            <p class="h5 text-primary mb-0 ml-1">{{ getProduct.price }}CHF</p>
          </div>
          <div class="mt-2-5 mb-1-5 my-md-2 my-lg-3 border mx-0-3"></div>
          <div class="px-1-5 px-md-0">
          <MultiSelect
            v-if="getProduct.variations.length"
            v-model="choosenVariation"
            :options="getProduct.variations"
            :searchable="false"
            :allow-empty="true"
            :close-on-select="true"
            :show-labels="true"
            :open-direction="'bottom'"
            label="title"
            
            @select="variationIsSelected"
          >
            <template slot="caret" slot-scope="{ toggle }">
              <div
                class="multiselect__select d-flex align-items-center justify-content-center"
                @mousedown.prevent.stop="toggle()"
              >
                <font-awesome-icon :icon="['far', 'angle-down']" class="text-gray"></font-awesome-icon>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="option" class="bookingSelect">
              <div class="option__desc d-flex w-100 multiselect-text-color multiselect-choosen-position">
                <div class="d-flex justify-content-around">
                  <img
                    :src="
                      option.option.avatar
                        ? option.option.avatar.path
                        : require(`@/assets/images/default/default_avatar.png`)
                    "
                    width="28px"
                    height="28px"
                    class="mr-2 product-images-fit"
                    style="border-radius:50%"
                    alt=""
                  />
                  <span>{{ option.option.title }}</span>
                </div>
              </div>
            </template>

            <template slot="option" slot-scope="option">
              <div class="option__desc multiselect-text-color">
                <img
                  :src="
                    option.option.avatar
                      ? option.option.avatar.path
                      : require(`@/assets/images/default/default_avatar.png`)
                  "
                  width="28px"
                  height="28px"
                  class="product-images-fit mr-2"
                  style="border-radius:50%"
                  alt=""
                />
                <span>{{ option.option.title }}</span>
              </div>
            </template>
          </MultiSelect>
          </div>
          <div class="px-1-5 px-md-0 w-100">
          <button :disabled="isDisabledReserveButton" class="mt-2 btn btn-lg btn-primary btn-block" @click="reserve">
            <span>{{ $t('market.reserve-product') }}</span>
          </button>
        </div>
        </div>
      </div>
      </div>
      <div class="col-12 px-0 mb-3 description-block">
        <b-collapse
          :id="`favorite-content-1`"
          class="collapse mt-1 px-0-6 px-md-0"
          accordion="my-accordion"
          :visible="true"
        >
          <b-tabs content-class="mt-2" align="">
            <b-tab
              :title="$t('market.description')"
              title-item-class="NavProductDescription"
              title-link-class=" justify-content-center"
            >
              <p>{{ getProduct.description }}</p>
            </b-tab>
          </b-tabs>
        </b-collapse>
      </div>
    </div>
    <div v-if="!dataIsLoaded" class="col">
      <Spinner />
    </div>
  </main>
</template>
<script>
import MultiSelect from 'vue-multiselect'
import Spinner from '@/views/Spinner'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    MultiSelect,
    Spinner
  },
  props: {
    productId: {
      type: Number,
      default: 0
    },
    salonId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      productPayload: {
        product_id: null
      },
      choosenVariation: [],
      reservedProduct: {
        salon_id: this.salonId
      },
      successInfo: {
        type: this.$t('market.success-type'),
        messageI18Key: this.$t('market.success-type')
      },
      dataIsLoaded: true,
      isDisabledReserveButton: false,
      isIndicatorsVisible: true,
      slide: 0,
      sliding: null
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'salons/getProduct',
      getClientBooking: 'payment/getClientBooking'
    }),
    imagesArr() {
      return this.getProduct.images
        ? this.getProduct.images.length
          ? this.getProduct.images
          : [{ path: require(`@/assets/images/default/default-product.png`), id: 1 }]
        : [{ path: require(`@/assets/images/default/default-product.png`), id: 1 }]
    }
  },
  mounted() {
    if (this.productId) {
      this.dataIsLoaded = false
      this.productPayload.product_id = this.productId
      this.fetchProduct(this.productPayload)
        .then(() => {
          if (!Object.keys(this.getProduct).length) this.$router.push('/not-found')
          this.dataIsLoaded = true
        })
        .catch(() => this.$router.push('/not-found'))
    }
  },
  methods: {
    ...mapActions({
      fetchProduct: 'salons/getProduct'
    }),
    ...mapActions('userMessages', {
      setError: 'setError',
      setSuccess: 'setSuccess'
    }),
    ...mapMutations({
      setReservedProduct: 'payment/SET_RESERVED_PRODUCT'
      // setClientBookingProducts: 'payment/SET_CLIENT_BOOKING_PRODUCTS'
    }),
    goToMarket() {
      let path = this.$route.path.substring(0, this.$route.path.length - 8)
      this.$router.push(path)
    },
    galleryChangeImage(event) {
      let imagesArr = document.querySelectorAll('.imageMini')
      if (imagesArr.length)
        imagesArr.forEach(el => {
          el.classList.remove('activeMiniImage')
        })
      let thumbnail = event.target.closest('a')
      thumbnail.classList.add('activeMiniImage')

      if (!thumbnail) return
      this.showThumbnail(thumbnail.href, thumbnail.title)
      event.preventDefault()
    },
    showThumbnail(href, title) {
      let largeImg = document.querySelector('#largeImg')
      largeImg.src = href
      largeImg.alt = title
    },
    variationIsSelected(val) {
      Object.assign(this.reservedProduct, { variation: val })
    },
    reserve() {
      this.isDisabledReserveButton = true
      Object.assign(this.reservedProduct, this.getProduct)

      this.setReservedProduct(this.reservedProduct)
      this.setSuccess(this.successInfo)
      setTimeout(this.goToMarket, 1000)
    },
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>
