<template>
  <main v-if="salon" class="navbar-offset detailMainContent">
    <div class="w-100 d-block d-md-none">
      <router-link to="/salons">
        <font-awesome-icon :icon="['far', 'chevron-left']" class="left-chevron mb-1 mt-1-5 ml-1-5"></font-awesome-icon>
      </router-link>
    </div>
    <section class="main position-relative d-none d-md-block">
      <VueGallery :images="imagesArr" :index="index" @close="index = null"></VueGallery>
      <div
        v-if="imagesArr.length"
        class="position-absolute d-flex "
        style="z-index:1;margin-top:1.8%;right: 10%;left: auto;display:flex"
      >
        <div class="galleryBtn" @click="index = 0">
          <p>{{ $t('common.forms.button.view_galerry') }}</p>
        </div>
        <button
          v-if="isAuthorized"
          :class="{ favorite: isFavorite }"
          type="button"
          class="btn fav_btn rounded-circle ml-2"
          @click="toggleFavorite"
        >
          <font-awesome-icon :icon="['far', 'bookmark']"></font-awesome-icon>
        </button>
      </div>
      <TopSlider :images="salon.images" />
      <div class="row">
        <div class="container">
          <div class="main-info text-white position-absolute">
            <h1 class="mb-1">{{ salon.title }}</h1>
            <p class="mb-1 text-white">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="text-white mr-0-5"></font-awesome-icon>

              {{ fullSalonAddress }}
            </p>
            <p class="mb-1 text-white d-flex">
              <span class="rating-block">
                <FaRating
                  :glyph="iconStar"
                  :border-width="0"
                  :active-color="'#ff6b09'"
                  :inactive-color="'#FFE1CD'"
                  :show-rating="false"
                  :item-size="13"
                  :read-only="true"
                  :increment="0.5"
                  :rating="salonRate"
                  :max-rating="5"
                ></FaRating>
              </span>

              <small style="margin-top:2px" class="ml-2">
                {{ salon.reviews_num }}
                {{ $t('salon.section.gallery.reviews') }}
              </small>
            </p>
            <button v-if="salon.hasProducts" class="mt-1 btn btn-block btn-primary" @click="goToMarket">
              <span>{{ $t('market.go-to-marketplace') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="image-shdow-layer"></div>
    </section>
    <section class="d-block d-md-none w-100">
      <button
        v-if="isAuthorized"
        :class="{ favorite: isFavorite }"
        type="button"
        class="position-absolute btn fav_btn_card  rounded-circle favoriteBtn"
        @click="toggleFavorite"
      >
        <font-awesome-icon :icon="['far', 'bookmark']" class="ml-0"></font-awesome-icon>
      </button>
      <div v-if="salon.images.length > 0" class="w-100 h-100 ml-md-0">
        <SalonCardCarousel :images="salon.images" :is-indicators-visible="false" />
      </div>
      <div class="row mt-1-5 mx-0-8">
        <div class="col">
          <div class="main-info">
            <h1 class="mb-1">{{ salon.title }}</h1>
            <p class="mb-1 responseModalText text-black">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="mr-0-5 text-gray"></font-awesome-icon>

              {{ fullSalonAddress }}
            </p>
            <p class="mb-0 d-flex responseModalText text-black">
              <span class="rating-block">
                <FaRating
                  :glyph="iconStar"
                  :border-width="0"
                  :active-color="'#ff6b09'"
                  :inactive-color="'#FFE1CD'"
                  :show-rating="false"
                  :item-size="13"
                  :read-only="true"
                  :increment="0.5"
                  :rating="salonRate"
                  :max-rating="5"
                ></FaRating>
              </span>

              <small style="margin-top:2px" class="ml-1">
                {{ salon.reviews_num }}
                {{ $t('salon.section.gallery.reviews') }}
              </small>
            </p>
          </div>
          <button
            v-if="salon.hasProducts"
            type="submit"
            class="btn btn-lg btn-primary btn-block mt-1-5"
            @click="goToMarket"
          >
            <p class="h6 font-weight-medium mb-0 text-white">
              {{ $t('common.forms.button.marketplace_button') }}
            </p>
          </button>
        </div>
      </div>
    </section>
    <section>
      <!-- salon description zone -->
      <div class="container py-3 px-md-0 px-1-5 py-md-6">
        <Booking
          ref="salonBooking"
          class="d-none d-md-block d-lg-none"
          :service-combination-data="serviceCombinationData"
          :salon="salon"
        />
        <div class="row">
          <div class="col-lg-7 col-xxl-8 col">
            <div class="content mb-3 mb-md-3 mb-xxl-6">
              <h3 class="mb-1-5 mb-xxl-3">
                {{ $t('salon.section.content.about_us_title') }}
              </h3>
              <TextLoader :data-descr="salon.description" :text-color="`text-gray`" :string-length="425" />
            </div>
            <ServiceAccordion :salon="salon" @set-choosen-combination="chooseCombination" />
            <ServicesTabs :salon="salon" :salon-services="salonServices" />

            <StaffModal :staff="selectedStaff" :reviews-total="salon.reviews_num"></StaffModal>

            <Specialists :salon="salon" @fetchStaffToSelect="fetchStaffToSelect" />

            <div class="content mb-6">
              <h3 class="mb-1-5 mb-xxl-3">
                {{ $t('salon.section.content.location_title') }}
              </h3>
              <div class="row">
                <div class="col px-0-5">
                  <div class="h-100" style="border-radius:10px">
                    <Map
                      v-if="salon.address"
                      ref="salonMap"
                      :show-salon-data="showSalonData"
                      :style-map-container="mapSize"
                      :choosen-salon="salon"
                      class="rounded-lg mapBorder radius h-100 mapSizeValue pt-0"
                    ></Map>
                  </div>
                </div>

                <div class="col-12 px-0-4 px-md-1-5 col-md col-lg-5 col-xxl-4 mt-md-0 mt-1-5">
                  <div class="card py-md-1-5 py-0-5 text-black font-weight-medium radius">
                    <div class="card-header bg-transparent px-2 pb-0-5 border-0 font-weight-bold">
                      <font-awesome-icon :icon="['far', 'clock']" class="text-primary"></font-awesome-icon>
                      {{ $t('common.card.working_hours') }}
                    </div>
                    <ul
                      v-for="(hours, key) in salon.workingHours"
                      :key="`hours-${key}`"
                      class="list-group list-group-flush px-2"
                    >
                      <li
                        class="list-group-item responsiveDateItem d-flex justify-content-between align-items-center px-0"
                      >
                        {{ weekdayList[key] }}
                        <span class="font-weight-normal">{{ hours.start_time }} - {{ hours.end_time }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="reviewsContent">
              <div class="col-lg-5 col-12 col-xl-4 px-0">
                <div class="container mb-2 px-0-5 px-md-0">
                  <div class="row align-items-center">
                    <div class="col-12 pl-md-1 px-0">
                      <h3 class="mb-1 d-block d-md-none d-lg-block">
                        {{ $t('salon.section.content.feedback_title') }}
                      </h3>

                      <div class="reviewsRateData flex-wrap">
                        <div class="rateValue col-md col-6 pl-0">
                          <div
                            class="col-12 px-0 d-flex justify-content-start justify-content-md-between justify-content-lg-start"
                          >
                            <div>
                              <h3 class="mb-1 d-none d-md-block d-lg-none">
                                {{ $t('salon.section.content.feedback_title') }}
                              </h3>
                              <div class="mr-1-5">
                                <span class="h1">{{ salonRate }}</span>
                                <span>/5</span>
                              </div>

                              <div>
                                <FaRating
                                  :glyph="iconStar"
                                  :border-width="0"
                                  :active-color="'#ff6b09'"
                                  :inactive-color="'#FFE1CD'"
                                  :show-rating="false"
                                  :item-size="18"
                                  :read-only="true"
                                  :increment="0.01"
                                  :rating="salonRate"
                                  :max-rating="5"
                                  :spacing="3"
                                ></FaRating>
                                <p class="mb-0">
                                  {{ $t('salon.section.content.feedback_rating_count', { count: salon.reviews_num }) }}
                                </p>
                              </div>
                            </div>
                            <div class="col-6 d-none d-md-block d-lg-none">
                              <div class="col-12 mb-1-5 mb-md-0 px-0">
                                <label>{{ $t('common.forms.label.select_staff_for_reviews') }}</label>
                                <Multiselect
                                  v-model="selectedReviewsOption"
                                  :options="staffListOptions"
                                  :searchable="false"
                                  :select-label="''"
                                  :allow-empty="false"
                                  placeholder="Stylist"
                                  class="bookingSelect"
                                >
                                  <template slot="caret" slot-scope="{ option, toggle }">
                                    <div
                                      class="multiselect__select d-flex align-items-center justify-content-center"
                                      @mousedown.prevent.stop="toggle()"
                                    >
                                      <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
                                    </div>
                                  </template>

                                  <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                                    <div
                                      class="option__desc multiselect-text-color multiselect-choosen-position d-flex flex-nowrap"
                                    >
                                      <img :src="staffAvatar(option.option)" alt="" class="staff-avatar-size" />
                                      <span class="pl-1-5 pt-1 text-nowrap text-truncate">{{
                                        staffFullName(option.option)
                                      }}</span>
                                    </div>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                    <div class="option__desc multiselect-text-color">
                                      <img :src="staffAvatar(option.option)" alt="" class="staff-avatar-size" />
                                      <span class="pl-1-5 text-nowrap">{{ staffFullName(option.option) }}</span>
                                    </div>
                                  </template>
                                </Multiselect>
                              </div>
                            </div>
                          </div>
                        </div>

                        <RatingCarousel
                          :salon-rate="salon.rating"
                          :total-count="salon.reviews_num"
                          :width-dependence="'col-6 mt-0 mt-md-2 col-md-12 px-md-0 pr-0-8'"
                        />
                        <div class="d-block d-md-none d-lg-block row mt-2 mx-0 col-12 px-0">
                          <div class="col-12 mb-1-5 mb-md-0 px-0">
                            <label>{{ $t('common.forms.label.select_staff_for_reviews') }}</label>
                            <Multiselect
                              v-model="selectedReviewsOption"
                              :options="staffListOptions"
                              :searchable="false"
                              :select-label="''"
                              :allow-empty="false"
                              placeholder="Stylist"
                              class="bookingSelect"
                            >
                              <template slot="caret" slot-scope="{ option, toggle }">
                                <div
                                  class="multiselect__select d-flex align-items-center justify-content-center"
                                  @mousedown.prevent.stop="toggle()"
                                >
                                  <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
                                </div>
                              </template>

                              <template slot="singleLabel" slot-scope="option" class="bookingSelect">
                                <div
                                  class="option__desc multiselect-text-color multiselect-choosen-position d-flex flex-nowrap"
                                >
                                  <img :src="staffAvatar(option.option)" alt="" class="staff-avatar-size" />
                                  <span class="pl-1-5 pt-1 text-nowrap text-truncate">
                                    {{ staffFullName(option.option) }}
                                  </span>
                                </div>
                              </template>

                              <template slot="option" slot-scope="option">
                                <div class="option__desc multiselect-text-color">
                                  <img :src="staffAvatar(option.option)" alt="" class="staff-avatar-size" />
                                  <span class="pl-1-5 text-nowrap text-truncate">
                                    {{ staffFullName(option.option) }}
                                  </span>
                                </div>
                              </template>
                            </Multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ReviewsTabs
                :type="selectedReviewsOption.staff_id ? 'staff' : 'salon'"
                :salon-id="salonId"
                :staff-id="selectedReviewsOption.staff_id || 0"
                :class="'col col-md-12 pl-lg-3 pl-xxl-10 pr-md-0'"
              />
            </div>
          </div>

          <Booking
            ref="salonBooking"
            :service-combination-data="serviceCombinationData"
            class="d-none d-lg-block"
            :salon="salon"
          />
        </div>
      </div>
      <b-modal
        id="booking-modal"
        ref="bookingModal"
        header-class="modal-header border-bottom-0 px-0 pt-0 mt-1-5 pb-0"
        body-class=""
        centered
        hide-footer
      >
        <template v-slot:modal-header="{ close }">
          <div class="top-block d-block d-md-none w-100">
            <div class="d-flex justify-content-between">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-mobile ml-1-5"
                @click="close()"
              ></font-awesome-icon>

              <h3>
                {{ $t('salon.section.booking.title') }}
              </h3>

              <p class="collapsed change-pass-btn mr-2-5" @click="activatedClearAllFlag()">
                {{ $t('common.forms.button.clear_all') }}
              </p>
            </div>
          </div>
        </template>
        <div class="mt-1-5">
          <Booking
            ref="salonBooking"
            class=""
            :salon="salon"
            :clear-data-flag="clearAllFlag"
            :service-combination-data="serviceCombinationData"
            @activated-clear-all-flag="activatedClearAllFlag"
            @disabled-clear-all-flag="disabledClearAllFlag"
          />
        </div>
      </b-modal>
    </section>
    <div class="col px-1-5 d-md-none d-block bookBtn">
      <button v-b-modal.booking-modal type="submit" class="btn btn-lg btn-primary btn-block">
        <p class="h6 font-weight-medium mb-0 text-white">
          {{ $t('common.forms.button.book_now') }}
        </p>
      </button>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Map from '../components/Map'

import { FaRating } from 'vue-rate-it'
import Star from 'vue-rate-it/glyphs/star'
import StaffModal from '@/components/salon/StaffModal'
import TopSlider from '@/components/salon/TopSlider'
import ServicesTabs from '@/components/salon/services-section/ServicesTabs'
import ServiceAccordion from '@/components/salon/services-section/ServicesAccordion'
import Specialists from '@/components/salon/Specialists'
import RatingCarousel from '@/components/salon/RatingCarousel'
import Multiselect from 'vue-multiselect'
import Booking from '@/components/salon/Booking'
import TextLoader from '@/components/salon/TextLoader'
import SalonCardCarousel from '@/components/SalonCardCarousel'
import VueGallery from 'vue-gallery'
import ReviewsTabs from '@/components/salon/ReviewsTabs'
import moment from 'moment'
import { isEmpty } from 'lodash'

export default {
  components: {
    Map,
    FaRating,
    StaffModal,
    TopSlider,
    ServicesTabs,
    ServiceAccordion,
    Specialists,
    RatingCarousel,
    Multiselect,
    Booking,
    VueGallery,
    TextLoader,
    SalonCardCarousel,
    ReviewsTabs
  },
  data() {
    return {
      salonData: {
        parameters: [],
        id: null
      },
      showSalonData: {
        isShowOnMapActive: false,
        coords: {}
      },
      mapSize: { width: '650px', height: '360px' },
      rate: 4,
      iconStar: '',
      isFavorite: false,
      isActive: true,
      selectedStaff: {},
      imagesArr: [],
      index: null,
      parametersString: '',
      clearAllFlag: false,
      selectedReviewsOption: {
        fullName: 'common.forms.label.reviews_all_option',
        avatar: null,
        staff_id: null
      },
      serviceCombinationData: null,
      visible: false
    }
  },
  watch: {
    selectedStaff: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.selectedReviewsOption = null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      salon: 'salons/getSalon',
      salonStaffList: 'salons/getSalonStaffList',
      salonServices: 'salons/getSalonServices',
      salonStaff: 'salons/getSalonStaff',
      favorites: 'salons/getFavorites',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      isAuthorized: 'user/getAuthorizedFlag',
      siteLang: 'user/getSiteLang'
    }),
    staffListOptions() {
      const defaultOption = {
        fullName: 'common.forms.label.reviews_all_option',
        avatar: null
      }

      return [defaultOption].concat(this.salonStaffList)
    },
    weekdayList() {
      moment.updateLocale(this.siteLang, {
        week: {
          dow: 1
        }
      })

      return moment.localeData(this.siteLang).weekdays(true)
    },
    servicesArray() {
      return this.salon.services
    },
    salonId() {
      return this.$route.params.id
    },
    fullSalonAddress() {
      return this.salon.address != null ? this.salon.address.full_address : ''
    },
    salonRate() {
      return this.salon.evaluation_rating !== null ? this.salon.evaluation_rating.value : 0
    },
    isHaircutParamsEmpty() {
      const { allSelectedHaircutParams } = this

      for (let key in allSelectedHaircutParams) {
        if (allSelectedHaircutParams[key] === null || allSelectedHaircutParams[key].length === 0) {
          return true
        }
      }
      return false
    }
  },
  created() {
    this.iconStar = Star
  },
  beforeCreate: function() {
    this.$emit('toggle-loaded', false)
  },
  mounted() {
    this.setParametersData()

    this.fetchSalon(this.salonData).then(data => {
      document.title = `Quiff | ${this.salon.title}`

      this.$emit('toggle-loaded', true)

      this.setImagesToArray()
      this.showSalonOnMap()

      this.$refs.salonBooking.setCombinationData()
    })
    this.clearSalonServices()
    this.fetchSalonServices(this.salonId)
    this.fetchSalonStaffsList({ salon_id: this.salonId })

    if (this.isAuthorized) {
      this.getFavoritesUser().then(() => {
        this.checkFavoriteSalon()
      })
    }
    let isIos = navigator.platform.includes('iPhone') ? true : false
    if (isIos) {
      window.innerWidth < 400
        ? this.$refs.bookingModal.bodyClasses.push('mt-1-5')
        : this.$refs.bookingModal.bodyClasses.push('mt-3')
    }
    this.disabledClearAllFlag()
  },
  methods: {
    ...mapMutations('salons', {
      clearSalonServices: 'CLEAR_SALON_SERVICES'
    }),
    ...mapActions({
      fetchSalon: 'salons/getSalon',
      fetchSalonServices: 'salons/getSalonServices',
      fetchSalonStaff: 'salons/getSalonStaff',
      fetchSalonStaffsList: 'salons/getSalonStaffsList',
      addToFavorites: 'salons/getAddToFavorites',
      removeFromFavorites: 'salons/getRemoveFromFavorites',
      getFavoritesUser: 'salons/getFavorites'
    }),
    showSalonOnMap() {
      const coords = {
        lat: +this.salon.address.latitude,
        lng: +this.salon.address.longitude
      }

      this.showSalonData = {
        isShowOnMapActive: true,
        coords: coords
      }

      this.$refs.salonMap.setAllMarkers()
    },
    staffAvatar(staff) {
      return staff.avatar ? staff.avatar.path : require('@/assets/images/default/default_avatar.png')
    },
    staffFullName(staff) {
      return staff.staff_id ? staff.fullName : this.$t(staff.fullName)
    },
    fetchStaffToSelect(id) {
      this.fetchSalonStaff(id).then(data => {
        this.selectedStaff = data
        this.$nextTick(() => this.$modal.show(`staffModal-${this.selectedStaff.id}`))
      })
    },
    checkFavoriteSalon() {
      this.favorites.data.forEach(elem => {
        if (elem.id == this.salonId) this.isFavorite = true
      })
    },
    toggleFavorite() {
      if (this.isFavorite) {
        this.isFavorite = false
        this.removeFromFavorites(this.salonId).then(data => {
          if (!data) this.isFavorite = true
        })
      } else {
        this.isFavorite = true
        this.addToFavorites(this.salonId).then(data => {
          if (!data) this.isFavorite = false
        })
      }
      return false
    },
    setImagesToArray() {
      this.salon.images.forEach(el => {
        this.imagesArr.push(el.path)
      })
    },
    setParametersData() {
      this.salonData.id = this.salonId

      if (!this.isHaircutParamsEmpty) {
        if (!isEmpty(this.allSelectedHaircutParams.gender)) {
          this.salonData.parameters.push(this.allSelectedHaircutParams.gender.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.length)) {
          this.salonData.parameters.push(this.allSelectedHaircutParams.length.id)
        }

        if (!isEmpty(this.allSelectedHaircutParams.age)) {
          this.salonData.parameters.push(this.allSelectedHaircutParams.age.id)
        }
      }
    },
    activatedClearAllFlag() {
      this.clearAllFlag = true
    },
    disabledClearAllFlag() {
      this.clearAllFlag = false
    },
    goToMarket() {
      this.$router.push(`/marketplace/${this.salon.id}`)
    },
    chooseCombination(data) {
      this.disabledClearAllFlag()
      if (window.innerWidth < 767) {
        this.$bvModal.show('booking-modal')
        setTimeout(() => (this.serviceCombinationData = JSON.parse(JSON.stringify(data))), 100)
      } else this.serviceCombinationData = JSON.parse(JSON.stringify(data))
    }
  }
}
</script>
<style scoped>
.s {
  margin-top: 10rem;
}
.SalonTitles {
  border: 1 px solid black;
  padding-left: 20px;
}
.formComponent {
  width: 30%;
  height: 40%;
  margin-left: 10%;
  padding-bottom: 10rem;
  margin-top: 50px;
}
.galleryBtn {
  width: 147px;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #e0e0e0;
  background-color: #fbfbfb;
  cursor: pointer;
}

.galleryBtn p {
  margin-top: 0.8rem;
  margin-left: 1.3rem;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8d9fa7;
}
.mapBorder {
  border: 1px solid #e0e0e0;
}
.card:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #e0e0e0;
}
.radius {
  border-radius: 7px;
}
@media screen and (max-width: 1650px) {
  .galleryBtn p {
    font-size: 14px;
  }
}
.rounded-block {
  border-radius: 0 !important;
}
.reviewsContent {
  display: flex;
  flex-wrap: wrap;
}
.rateValue {
  display: flex;
}
.bookBtn {
  position: fixed;
  bottom: 4rem;
  z-index: 100;
}
</style>
