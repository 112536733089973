<template>
  <div class="signup-page">
    <div class="row no-gutters vh-100-lg desktop-sign-in-bg">
      <AuthSlider class="sale-offset" />

      <div class="col-md-12 col-lg col-xxl-6">
        <div class="row no-gutters h-100 px-1-5">
          <div class="col-12 mb-3 md-lg-0 mt-3 align-self-start px-lg-3 d-none d-lg-block sale-offset">
            <p class="mb-0 text-center text-lg-right">
              <span class="text-secondary">{{ $t('signup.content.login_link_text') }}</span>
              <router-link to="/sign-in" class="ml-1" :title="$t('signup.content.login_link')">
                {{ $t('signup.content.login_link') }}
              </router-link>
              <LanguageDropdown :class="'pl-1-5'"></LanguageDropdown>
            </p>
          </div>

          <div class="col-12 col-md-8 col-lg-12 signup-form-bg col-xl-8 mx-auto py-3">
            <div class="my-lg-auto my-lg-2-5 mx-lg-0 mx-auto w-100">
              <div class="text-center mb-2 mb-lg-3">
                <h1 class="h2 mb-2">
                  {{ $t('signup.content.title') }}
                </h1>
                <p>
                  {{ $t('signup.content.subtitle') }}
                </p>

                <p class="mb-0 text-center d-block d-lg-none">
                  <span class="text-secondary">{{ $t('signup.content.login_link_text') }}</span>
                  <router-link to="/sign-in" class="ml-1" :title="$t('signup.content.login_link')">
                    {{ $t('signup.content.login_link') }}
                  </router-link>
                  <LanguageDropdown :class="'ml-0-5'" :btn-class="'py-1 px-0-5'"></LanguageDropdown>
                </p>
              </div>

              <div class="row mt-2-5">
                <div class="col-6">
                  <button class="btn--google font-weight-medium w-100" type="button" @click="signUp('google')">
                    <i class="google_icon mr-1"></i>
                    {{ $t('common.forms.button.google') }}
                  </button>
                </div>

                <div class="col-6">
                  <fb-signin-button
                    class="fb-signin-button font-weight-medium"
                    :params="fbSignInParams"
                    @success="onFacebookAuthSuccess"
                    @error="onFacebookAuthError"
                  >
                    <font-awesome-icon :icon="['fab', 'facebook-f']" class="mr-1"></font-awesome-icon>
                    {{ $t('common.forms.button.facebook') }}
                  </fb-signin-button>
                </div>
              </div>

              <div class="mt-2-5 d-flex mb-2-5">
                <div class="border-line col mb-1"></div>
                <span class="mx-3 text-gray"> {{ $t('common.forms.label.or') }}</span>
                <div class="border-line col mb-1"></div>
              </div>

              <form class="form-row no-gutters" autocomplete="off" @submit.prevent="signUp('manual')">
                <div class="col-lg-12">
                  <label for="email" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(emailField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="email"
                      v-model="emailField.value"
                      v-validate="emailField.validateRules"
                      :data-vv-as="$t(emailField.i18KeyLabel)"
                      :type="emailField.type"
                      :name="emailField.name"
                      class="form-control w-100 px-1-5"
                      :placeholder="$t(emailField.i18KeyPlaceholder)"
                    />
                    <span v-if="errors.has(emailField.name) && getSiteLang === 'en'" class="error-message mt-1">{{
                      errors.first(emailField.name)
                    }}</span>
                    <span v-if="errors.has(emailField.name) && getSiteLang === 'de'" class="error-message mt-1">
                      Email ist ungültig
                    </span>
                  </div>
                </div>
                <div v-for="(field, index) in namesFields" :key="`${index}-field`" class="col-6">
                  <label for="firstName" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(field.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      v-model="field.value"
                      v-validate="field.validateRules"
                      :data-vv-as="$t(field.i18KeyLabel)"
                      :name="field.name"
                      type="text"
                      :placeholder="$t(field.i18KeyPlaceholder)"
                      class="form-control w-100 px-1-5"
                    />
                    <span v-if="errors.has(field.name)" class="error-message mt-1">{{ errors.first(field.name) }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <label for="password" class="poppins-font text-black mb-1 font-weight-medium">
                    {{ $t(passwordField.i18KeyLabel) }}
                  </label>
                  <div class="input-group input-group-lg mb-2-5">
                    <input
                      id="password"
                      v-model="passwordField.value"
                      v-validate="passwordField.validateRules"
                      :data-vv-as="$t(passwordField.i18KeyLabel)"
                      :type="passwordField.type"
                      :name="passwordField.name"
                      class="form-control px-1-5 border-right-0 pass-input"
                      :placeholder="$t(passwordField.i18KeyPlaceholder)"
                    />
                    <div class="input-group-append" @click="changeType">
                      <span class="input-group-text text-gray bg-white px-1-5 py-1 border-left-0 cursor-pointer">
                        <font-awesome-icon :icon="['fas', passwordIcon]"></font-awesome-icon>
                      </span>
                    </div>
                    <span v-if="errors.has(passwordField.name)" class="error-message mt-1">{{
                      errors.first(passwordField.name)
                    }}</span>
                  </div>
                </div>
                <div class="col-12 form-group mb-1 form-check">
                  <div class="checkbox">
                    <label class="mb-0">
                      <input
                        v-model="confirmCondition"
                        v-validate="'required:true'"
                        type="checkbox"
                        name="terms"
                        :data-vv-as="'Terms'"
                      />
                      <i class="input-helper"></i>
                      <p class="text-dark mb-0">
                        {{ $t('signup.content.agree_start') }}
                        <router-link to="/agb" :title="$t('signup.content.terms_link')">
                          {{ $t('signup.content.terms_link') }}
                        </router-link>
                        {{ $t('signup.content.term_and') }}
                        <router-link to="/privacy-policy" :title="$t('signup.content.terms_link')">
                          {{ $t('signup.content.term_link_final') }}
                        </router-link>
                        {{ $t('signup.content.agree_start_final') }}
                      </p>
                    </label>
                  </div>
                </div>

                <span v-if="errors.has('terms') && getSiteLang === 'en'" class="error-message mb-1-5 w-100">
                  {{ errors.first('terms') }}
                </span>
                <span v-if="errors.has('terms') && getSiteLang === 'de'" class="error-message mb-1-5 w-100">
                  Pflichtfeld
                </span>

                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block btn-lg mt-2-5">
                    {{ $t('common.forms.button.get_started') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VerificationPopup />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import AuthSlider from './../../components/AuthSlider'
import { mapValues, keyBy, values } from 'lodash'
import LanguageDropdown from '@/components/LanguageDropdown'
import AuthorizationRedirect from '@/_mixins/AuthorizationRedirect'
import VerificationPopup from '@/views/verification/VerificationPopup'

export default {
  name: 'AuthPage',
  components: {
    AuthSlider,
    LanguageDropdown,
    VerificationPopup
  },
  mixins: [AuthorizationRedirect],
  data() {
    return {
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      confirmCondition: false,
      namesFields: [
        {
          i18KeyLabel: 'common.forms.label.auth.first_name',
          name: 'first_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.auth.first_name'
        },
        {
          i18KeyLabel: 'common.forms.label.auth.last_name',
          name: 'last_name',
          value: '',
          validateRules: 'required|alpha|max:40',
          i18KeyPlaceholder: 'common.forms.placeholder.auth.last_name'
        }
      ],
      emailField: {
        i18KeyLabel: 'common.forms.label.auth.email',
        name: 'email',
        type: 'email',
        value: '',
        validateRules: 'required|email|min:5',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.email'
      },
      passwordField: {
        i18KeyLabel: 'common.forms.label.auth.password',
        name: 'password',
        type: 'password',
        value: '',
        validateRules: 'required|min:8|max:16',
        i18KeyPlaceholder: 'common.forms.placeholder.auth.password'
      },
      createBookingErrorMessage: {
        type: 'Error',
        errorMessageI18Key: 'payment.booking_failed_title'
      }
    }
  },
  computed: {
    ...mapGetters({
      isRedirectedFromBooking: 'user/getRedirectedAuthFlag',
      accountInfo: 'user/getAccountInfo',
      clientBooking: 'payment/getClientBooking',
      getMainTemplate: 'user/getMainTemplate',
      getSiteLang: 'user/getSiteLang',
      allSelectedHaircutParams: 'haircutParams/getAllSelectedHaircutParams',
      salon: 'salons/getSalon',
      getReservedProducts: 'payment/getReservedProducts'
    }),
    signInRequiredFields() {
      return [this.emailField, this.passwordField, this.namesFields[0], this.namesFields[1]]
    },
    passwordIcon() {
      return this.passwordField.type === 'password' ? 'eye' : 'eye-slash'
    }
  },
  mounted() {
    if (!this.isRedirectedFromBooking) {
      this.resetUserState()
      this.resetUserMessageState()
      this.resetShopState()
      this.resetHaircutrState()
    }
  },
  methods: {
    registerRequiredFields() {
      let resultArray = JSON.parse(JSON.stringify(this.namesFields))
      resultArray.push(this.emailField, this.passwordField)
      return resultArray
    },
    ...mapActions('user', {
      register: 'register',
      login: 'login',
      getAccountInfo: 'getAccountInfo',
      socialAuth: 'socialAuth',
      setAuthStatus: 'setAuthStatus'
    }),
    ...mapActions('userMessages', {
      setSuccess: 'setSuccess',
      setError: 'setError'
    }),
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
      resetUserMessageState: 'userMessages/RESET_STATE',
      resetShopState: 'shop/RESET_STATE',
      resetSalonsState: 'salons/RESET_STATE',
      resetHaircutrState: 'haircutParams/RESET_STATE'
    }),
    onFacebookAuthSuccess() {
      // eslint-disable-next-line no-undef
      FB.api('/me?fields=id,name,email', res => {
        const dividedName = res.name ? res.name.split(' ') : ['', ''] // front error prevention
        let registerPayload = {
          email: res.email,
          source: 'facebook',
          source_id: res.id,
          first_name: dividedName[0],
          last_name: dividedName[1],
          profile_url: null
        }

        if (this.salon) {
          if (this.getFormatedReservedProductsBySalonId(this.salon.id).products.length) {
            registerPayload = {
              ...registerPayload,
              ...this.getFormatedReservedProductsBySalonId(this.salon.id)
            }
          }
        }

        if (registerPayload.email !== undefined && registerPayload.email !== null) {
          this.socialAuth(registerPayload)
            .then(() => {
              this.getAccountInfo().then(() => {
                this.setAuthStatus({ isAuthorized: true, socialAuthType: 'facebook' })
                this.authorizationRedirect()
              })
            })
            .catch(error => {
              let errorMessage =
                error.code === 414
                  ? 'signup.user-messages.email_has_been_taken'
                  : 'signup.user-messages.social_auth_error'
              this.setError({
                type: 'social_sign_up',
                messageI18Key: errorMessage
              })
            })
        } else {
          this.setError({
            type: 'social_sign_in',
            messageI18Key: this.$t('signup.user-messages.social_auth_mobile_error')
          })
        }
      })
    },
    onFacebookAuthError() {
      this.setError({
        type: 'socual_error',
        messageI18Key: 'signup.user-messages.social_auth_error'
      })
    },
    signUp(type) {
      if (type === 'google') {
        this.$gAuth
          .signIn()
          .then(userData => {
            const clientProfileData = userData.getBasicProfile() // get object with profile data (is Google response object changing???)
            const [source_id, first_name, last_name, profile_url, email] = Object.values(clientProfileData)
            let registerPayload = {
              source: 'google',
              source_id,
              email,
              first_name,
              last_name,
              profile_url
            }

            if (this.salon) {
              if (this.getFormatedReservedProductsBySalonId(this.salon.id).products.length) {
                registerPayload = {
                  ...registerPayload,
                  ...this.getFormatedReservedProductsBySalonId(this.salon.id)
                }
              }
            }

            this.socialAuth(registerPayload)
              .then(() => {
                this.getAccountInfo().then(() => {
                  this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
                  this.authorizationRedirect()
                })
              })
              .catch(error => {
                let errorMessage =
                  error.code === 414
                    ? 'signup.user-messages.email_has_been_taken'
                    : 'signup.user-messages.social_auth_error'
                this.setError({
                  type: 'social_sign_up',
                  messageI18Key: errorMessage
                })
              })
          })
          .catch(() => {
            this.setAuthStatus({ socialAuthType: '', isAuthorized: false })
            this.setError({
              type: 'social_sign_up',
              messageI18Key: 'signup.user-messages.social_auth_error'
            })
          })
      } else if (type === 'manual') {
        this.$validator.validateAll().then(result => {
          if (result) {
            let registerPayload = mapValues(keyBy(this.registerRequiredFields(), 'name'), 'value')

            if (this.salon) {
              if (this.getFormatedReservedProductsBySalonId(this.salon.id).products.length) {
                registerPayload = {
                  ...registerPayload,
                  ...this.getFormatedReservedProductsBySalonId(this.salon.id)
                }
              }
            }

            this.register(registerPayload)
              .then(() => {
                this.getAccountInfo().then(() => {
                  this.setAuthStatus({ isAuthorized: true, socialAuthType: 'manual' })
                  this.authorizationRedirect()
                })
              })
              .catch(errors => {
                let errorFields = Object.keys(errors.messages)

                errorFields.map(field => {
                  let errorString = errors.messages[field].join(', ')
                  this.$validator.errors.add({ field: field, msg: errorString })
                })
              })
          }
        })
      }
    },
    changeType() {
      this.passwordField.type === 'password'
        ? (this.passwordField.type = 'text')
        : (this.passwordField.type = 'password')
    }
  }
}
</script>

<style lang="scss">
.btn--google {
  height: 48px;
  border-radius: 5px;
  font-family: Poppins;
  text-transform: uppercase;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
}
body .fb-signin-button {
  height: 48px;
  border-radius: 5px;
  font-family: Poppins;
  text-transform: uppercase;
  background-color: #3b5998;
  color: #ffffff;
  svg {
    font-size: 1.25rem;
  }
}
</style>
