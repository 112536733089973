<template>
  <main>
    <div class="navbar-offset marketplace-main-info">
      <div class="container w-100">
        <h1 class="mt-5">
          Finde deinen Coiffeur in Aarau und Umgebung
        </h1>
        <p class="mb-2">
          Du suchst einen Coiffeur in Aarau? Dann bist du bei Quiff goldrichtig! Vertrauen, Wohlfühlen und ein ideales
          Preis-Leistungs-Verhältnis. Wer auf der Suche nach einem Coiffeur ist, der berücksichtigt oft ganz
          unterschiedliche Kriterien. Um dir bei deiner Suche zu helfen, halten wir hier für dich eine umfangreiche
          Auswahl bereit, in der du sicherlich auch deinen Lieblings-Coiffeur in Aarau und der näheren Umgebung findest.
          Und der Clou – Du kannst sofort Deinen Wunschtermin online buchen.
        </p>
        <p class="mb-2">
          Wir bieten dir die Möglichkeit, dir die Dienstleistungen der einzelnen Coiffeure bzw. Friseure ganz in Ruhe
          anzuschauen – und dann deine Entscheidung zu treffen. Wenn du den Coiffeur in Aarau gefunden hast, der dir am
          meisten zusagt, bist du nur noch wenige Mausklicks von deinem Termin und damit auch von deiner neuen Frisur
          entfernt. Denn über Quiff kannst du einen Termin beim
          <router-link to="/Coiffeur-online-buchen" title="Coiffeur online buchen">Coiffeur online buchen</router-link>
          und profitierst von vielen Extras. Zum Beispiel erhältst Du mit jeder Buchung auch noch attraktive
          Treuepunkte, die du anschliessend hier im
          <router-link to="/" title="Online-Shop">Online-Shop</router-link> deines Coiffeurs in Aarau einlösen kannst.
          Reserviere Dein Lieblings-Pflegeprodukt oder Deine Styling Creme und bei Deinem Coiffeur Termin steht es
          bereits für Dich zur Mitnahme bereit!
        </p>
        <h2 class="mb-2">
          Haarverlängerung in Aarau? Mit Quiff kein Problem!
        </h2>
        <p class="mb-2">
          Die einen mögen es kurz und praktisch, die anderen bevorzugen eine Haarverlängerung, Extensions, eine neue,
          ausgefallene Trendfarbe oder eine Hochsteckfrisur für einen festlichen Anlass. Für jeden dieser Wünsche halten
          wir den passenden Dienstleister bereit. Und so findet sich jeder mit seinen Ansprüchen und seinen
          Vorstellungen wieder. Hier sind nicht nur die Friseure mit Voranmeldung, sondern auch der
          <router-link to="/" title="Coiffeur ohne Voranmeldung">Coiffeur ohne Voranmeldung</router-link> vertreten.
          Schliesslich setzen wir auf Vielfalt und damit auf eine grosse Auswahl. Coiffeure ohne Voranmeldung verlieren
          jedoch Ihren Sinn und Zweck, wenn sich aufgrund hoher Beliebtheit eine Warteschlange bildet. Das lässt sich
          mit Quiff umgehen, deshalb schnell und einfach den Wunschtermin beim Coiffeur in Aarau reservieren!
        </p>
        <p class="mb-2">
          Du bist beispielsweise auf der Durchreise? Oder es steht eine Hochzeit an einem speziellen Datum bevor? Du
          möchtest einen neunen Barbier in Aarau ausprobieren, kannst aber immer nur in den Abendstunden? Dann
          erleichtert Quiff dir die Planung. Kurz- und langfristig. Das Buchungssystem ist so konzipiert, dass du direkt
          siehst, ob dein bevorzugter Coiffeur in Aarau zu deinem Wunschtermin Zeit hat. Du erhältst direkt im Anschluss
          eine Bestätigung – und schon steht einer Typveränderung nichts mehr im Weg.
        </p>
        <div class="my-10 col d-flex justify-content-center">
          <div class="col col-md-6  col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary py-1-5">
              <a class="text-white" :href="`${salonAppUrl}`" :title="$t('footer-links.register')" target="_blank">
                {{ $t('footer-links.register') }}
              </a>
            </button>
          </div>
          <div class="col col-md-6 col-lg-4 col-xxl-3">
            <button class="btn btn-block btn-primary mt-0 py-1-5">
              <router-link to="/" class="text-white" :title="$t('footer-links.book')">
                {{ $t('footer-links.book') }}</router-link
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import config from 'config'
export default {
  metaInfo: {
    meta: [
      {
        description:
          'Coiffeur in Aarau und Umgebung – mit Quiff findest du garantiert den passenden Salon für deine neue Frisur. Mit wenigen Klicks zum Wunschtermin.'
      }
    ]
  },
  computed: {
    salonAppUrl() {
      return config.salonAppUrl
    }
  }
}
</script>
