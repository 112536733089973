<template>
  <section class="navbar-offset mb-3 mb-md-0 pt-0" :class="getAuthInfo ? 'pt-md-8' : 'pt-md-6'">
    <div class="container-fluid salon-catalog-wrapper d-flex flex-column mt-1-5 mt-md-0">
      <div
        class="row align-items-center filter  mb-1-5 mt-1-5 pr-5 border-top border-bottom d-none d-md-flex"
        style="height:50px"
      >
        <UserLocationBar
          :search-salons-data="searchSalonsData"
          :mounted-request-flag="mountedRequestIsTransfered"
          @clearPlaceEmitFlag="setClearPlaceFlag"
          @setPendingFlag="isSearchInProgress"
        />
      </div>
      <div class="row h-100">
        <div class="col-md-6 col">
          <div class="salon-list-nav w-100 d-flex flex-wrap justify-content-md-between">
            <div
              class="col-lg-9 pl-0-5 col-11 col-md-12 pr-0 mb-1 mb-md-1-5 pr-lg-4-5"
              :class="isDeutchLang ? 'multiselectWidthDE' : 'multiselectWidthEN'"
            >
              <div id="multiselect" tabindex="-1" class="multiselect has-icon quiff ml-0 ml-md-2 ml-lg-3-5">
                <Multiselect
                  v-model="salonSearchTag"
                  :options="foundedSalons"
                  label=""
                  :searchable="true"
                  :internal-search="false"
                  :placeholder="isDeutchLang ? 'Nach Name suchen' : 'Type to search'"
                  :loading="isLoading"
                  :show-labels="false"
                  :custom-label="customLabel"
                  :limit="1"
                  :clear-on-select="false"
                  @search-change="searchForSalons"
                  @select="updateSalonsList"
                >
                  <span slot="noResult">
                    {{ $t('common.forms.label.no_total_queries') }}
                  </span>
                  <span slot="noOptions">
                    {{ $t('common.forms.label.list_is_empty') }}
                  </span>
                </Multiselect>
                <div class="multiselect__actions d-flex flex-wrap align-items-center">
                  <font-awesome-icon :icon="['far', 'search']" class="icon-search"></font-awesome-icon>
                  <span class="icon-sep mx-1"></span>
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    class="icon-close"
                    @click="cleanSalonList"
                  ></font-awesome-icon>
                </div>
              </div>
            </div>
            <i class="filter-icon d-md-none mt-1-5 ml-0-5 filterIcon" @click="openFilter"></i>
            <div class="col d-flex mb-0 d-md-none">
              <div class="col-6 pr-0-8 pl-0">
                <button
                  :class="
                    `btn btn-outline-secondary-custom collapsed btn-block mt-0  ${!mainShowFlag ? 'btnList' : ''}`
                  "
                  @click="listView"
                >
                  <font-awesome-icon :icon="['fas', 'list']" class="list-icon"></font-awesome-icon>
                  {{ $t('common.forms.button.list_view') }}
                </button>
              </div>
              <div class="col-6 pl-0-8 pr-0-5">
                <button
                  :class="`btn btn-outline-secondary-custom collapsed btn-block  ${mainShowFlag ? 'btnList' : ''}`"
                  @click="mapView"
                >
                  <font-awesome-icon :icon="['far', 'map']" class="map-icon"></font-awesome-icon>
                  {{ $t('common.forms.button.map_view') }}
                </button>
              </div>
            </div>
            <hr class="mt-1 mb-0 d-block d-md-none salonsListLine" />
            <div class="d-none d-md-block col-12 col-lg-3 pr-0-3 btns">
              <div ref="btnBlock" class="d-flex justify-content-end mb-1 pl-md-1-5 pl-lg-0">
                <button
                  ref="rate"
                  style="font-size:14px;white-space: nowrap;"
                  class="col btn btn-outline-secondary-custom collapsed left-btn"
                  @click="getSalonsByRating"
                >
                  {{ $t('common.forms.button.total_rating') }}
                </button>
                <!-- btn-primary -->
                <button
                  ref="actual"
                  style="font-size:14px; white-space: nowrap;"
                  class="col btn btn-outline-secondary-custom  collapsed right-btn"
                  @click="getSalonsByActual"
                >
                  {{ $t('common.forms.button.actual_rating') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="!mainShowFlag" class="row salon-list pb-1 salon-list-container custom-scroll">
            <div v-if="salonsData && salonsData.length > 0" class="col-12 pl-lg-5 pl-md-3-5 mb-md-4 mb-0 mt-lg-0 mt-1">
              <SalonCard
                v-for="(item, index) in salonsData"
                :key="item.id"
                :salon="item"
                :index="parseInt(index)"
                :show-map-btn="true"
                @showSalonOnMap="showSalonOnMap"
              ></SalonCard>

              <div class="row">
                <pagination
                  :data="salons"
                  :show-disabled="true"
                  class="mt-md-3 ml-md-3 ml-0-5"
                  @pagination-change-page="getSalonsPagination"
                >
                  <span slot="prev-nav">
                    <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
                  </span>
                  <span slot="next-nav">
                    <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
                  </span>
                </pagination>
              </div>
            </div>
            <div v-else class="col-12 pl-md-5 mb-md-4 text-center mt-2 mt-xl-6">
              <img src="@/assets/images/bg/salons_not_found.svg" class="empty-salons-img w-100 mb-3" alt="" />
              <div v-if="!requestSalonsSent">
                <h2>{{ $t('common.modal.salon_request.block.title') }}</h2>
                <p>{{ $t('common.modal.salon_request.block.description') }}</p>
                <button
                  class="btn btn-primary btn-lg px-xl-6"
                  :disabled="!currentPlace || searchPendingFlag"
                  @click.prevent="showRequestSalonModal"
                >
                  {{ $t('common.modal.salon_request.block.button') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="windowWidth > 767" class="d-none d-md-block col-md-6 col-lg pr-0 map-wrapper">
          <Map ref="salonMap" :show-salon-data="mapData"></Map>
        </div>
      </div>
    </div>
    <Map v-if="mainShowFlag && windowWidth < 768" ref="salonMapMobile" class="px-0" :show-salon-data="mapData"></Map>
    <b-modal
      id="request-salons-modal"
      ref="requestSalonsModal"
      header-class="modal-header border-bottom-0 px-0 pt-0 mt-1-5 pb-0"
      body-class=""
      centered
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <div class="top-block d-block d-md-none w-100">
          <div class="d-flex justify-content-between">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="icon-close-mobile ml-1-5"
              @click="close()"
            ></font-awesome-icon>
          </div>
        </div>
        <div class="d-none d-md-block w-100">
          <div class="d-flex justify-content-end">
            <font-awesome-icon
              :icon="['fal', 'times']"
              class="mr-1-5 cursor-pointer fa-2x text-gray"
              @click="close()"
            ></font-awesome-icon>
          </div>
        </div>
      </template>
      <SalonsRequestForm></SalonsRequestForm>
    </b-modal>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import SalonCard from './../components/SalonCard'
import Map from '../components/Map'
import UserLocationBar from '../components/UserLocationBar'
import SalonsRequestForm from '@/components/SalonsRequestForm'

import moment from 'moment'

export default {
  name: 'SalonsPage',
  components: { Multiselect, SalonCard, Map, UserLocationBar, SalonsRequestForm },
  data() {
    return {
      searchPendingFlag: false,
      salonSearchTag: '',
      isLoading: false,
      salonsForSearch: null,
      mountedRequestIsTransfered: false,
      foundedSalons: [],
      mapData: {
        coords: { lat: 46.948, lng: 7.4474 },
        radius: 18,
        isGivenAutomatically: false,
        isShowOnMapActive: false
      },
      salonsPagination: {
        page: 1
      },
      responsiveMapSizeValue: {
        width: '100%',
        height: 'auto'
      },
      salonSearchPrintedValue: '',
      isChangedValue: false,
      mainShowFlag: 0,
      clearPlaceFlag: false,
      repeatedClickOnPrimaryButton: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    ...mapGetters({
      salons: 'salons/getSalons',
      currentPlace: 'salons/getCurrentPlace',
      storedRadius: 'salons/getSearchRadius',
      requestSalonsSent: 'salons/getRequestSalonsSent',
      formattedHaircutData: 'haircutParams/getFormattedHaircutData',
      newSearchDataInfo: 'salons/getNewSearchDataInfo',
      userPosition: 'user/getUserPosition',
      getAuthInfo: 'user/getAuthorizedFlag',
      getChoosenDate: 'haircutParams/getChoosenDate'
    }),
    salonsData() {
      return this.salons.data
    },
    searchSalonsData() {
      const { currentPlace, storedRadius, formattedHaircutData } = this
      let lat = this.userPosition.lat ? this.userPosition.lat : 46.948
      let lng = this.userPosition.lng ? this.userPosition.lng : 7.4474
      if (currentPlace !== null && typeof currentPlace === 'object' && Object.keys(currentPlace).length) {
        let location = currentPlace.geometry.location
        lng = typeof location.lng === 'function' ? location.lng() : location.lng
        lat = typeof location.lat === 'function' ? location.lat() : location.lat
      }
      const coords = {
        latitude: lat,
        longitude: lng,
        radius: +storedRadius * 1000
      }
      let formattedBookDate = moment(this.getChoosenDate).format('Y-MM-DD')

      const searchSalonsData = Object.assign({}, coords, formattedHaircutData, { book_date: formattedBookDate })

      return searchSalonsData
    },
    isDeutchLang() {
      return this.$root.$i18n.locale === 'de'
    }
  },
  watch: {
    storedRadius(newVal) {
      this.mapData.radius = newVal
    },
    mainShowFlag(newVal) {
      let navbar = document.querySelector('.bottom-mobile-nav')
      if (navbar) {
        newVal ? navbar.classList.remove('d-flex') : navbar.classList.add('d-flex')
        newVal ? navbar.classList.add('d-none') : navbar.classList.remove('d-none')
      }
    },
    salonsData: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          if (this.$refs.salonMap) {
            this.$refs.salonMap.setAllMarkers()
          }
        }
        if (newVal && newVal.length) this.searchPendingFlag = false
      }
    },
    newSearchDataInfo: {
      deep: true,
      handler(newVal) {
        if (!this.clearPlaceFlag) {
          if (newVal.isNewDataSet) {
            this.getSalons(this.searchSalonsData).then(data => {
              if (data.total) {
                this.$refs.salonMap.setAllMarkers()
              } else {
                this.$refs.salonMap.setAllMarkers()
                if (this.currentPlace) {
                  let location = this.currentPlace.geometry.location

                  this.mapData.coords.lng = typeof location.lng === 'function' ? location.lng() : location.lng
                  this.mapData.coords.lat = typeof location.lat === 'function' ? location.lat() : location.lat
                  this.mapData.radius = 10
                }
              }
            })
          }
        } else {
          this.$refs.salonMap.setAllMarkers()
          this.clearPlaceFlag = false
        }
      }
    }
  },
  beforeCreate: function() {
    this.$emit('toggle-loaded', false)
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth)

    this.mapData.coords.lng = this.userPosition.lng
    this.mapData.coords.lat = this.userPosition.lat
    this.onMountedSalonRequest()

    if (this.isAuthorized) this.getFavoritesUser()
  },
  beforeRouteLeave(to, from, next) {
    this.clearSalons()

    if (to.name === 'salon-page') {
      this.saveChoosenServices()
      this.saveChoosenDate()
    }
    next()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowWidth)
  },
  methods: {
    ...mapActions('userMessages', {
      setError: 'setError',
      setSuccess: 'setSuccess'
    }),
    ...mapActions('salons', {
      getSalonsBySalonName: 'getSalonsByName',
      getSalons: 'getSalons',
      updateSalons: 'updateSalons',
      setNewSearchDataFlag: 'setNewSearchDataFlag',
      getFavoritesUser: 'getFavorites'
    }),
    ...mapMutations({
      resetSalonsState: 'salons/RESET_STATE',
      resetSalonsData: 'salons/RESET_SALONS',
      saveChoosenServices: 'haircutParams/SAVE_CHOOSEN_SERVICES',
      saveChoosenDate: 'haircutParams/SAVE_CHOOSEN_DATE_VALUE',
      clearSalons: 'salons/CLEAR_SALONS'
    }),
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
    permissionDenied() {
      this.mapData.coords.isGivenAutomatically = false
    },
    getCoords() {
      if (navigator.geolocation) {
        this.mapData.coords.isGivenAutomatically = true
        navigator.geolocation.getCurrentPosition(position => {
          this.mapData.coords.lng = position.coords.longitude
          this.mapData.coords.lat = position.coords.latitude
          this.mapData.radius = this.storedRadius
        }, this.permissionDenied())
      } else {
        this.mapData.coords.isGivenAutomatically = false
      }
    },
    showSalonOnMap(salonCoords) {
      if (window.innerWidth < 767) {
        this.mainShowFlag = 1

        this.$nextTick(() => this.$refs.salonMapMobile.toSalon(salonCoords))
      } else {
        this.$refs.salonMap.toSalon(salonCoords)
      }
    },
    searchForSalons(salonName) {
      if (salonName.length > 2) {
        this.isLoading = true
        this.salonSearchPrintedValue = salonName
        Object.assign(this.searchSalonsData, { salon: this.salonSearchPrintedValue })

        this.getSalonsBySalonName(this.searchSalonsData)
          .then(data => {
            this.salonsForSearch = data
            this.foundedSalons = []
            this.foundedSalons.push(`founded ${data.total} salons`)
            this.isLoading = false
          })
          .catch(err => {
            const errorInfo = {
              type: err.statusText,
              message: err.data.message,
              code: err.status
            }
            this.setError(errorInfo)
            this.isLoading = false
          })
      }
    },
    updateSalonsList() {
      this.salonSearchTag = ''
      this.foundedSalons = []
      this.updateSalons(this.salonsForSearch)
    },
    cleanSalonList() {
      this.salonSearchTag = ''
      this.salonSearchPrintedValue = ''
      this.foundedSalons = []
      this.resetSalonsData()
      delete this.searchSalonsData.salon
      this.getSalons(this.searchSalonsData)
    },
    getSalonsPagination(page = 1) {
      this.salonsPagination.page = page
      Object.assign(this.searchSalonsData, this.salonsPagination)
      if (this.salonSearchPrintedValue.length > 2) {
        this.getSalonsBySalonName(this.searchSalonsData).then(() => {
          delete this.searchSalonsData.page
        })
      } else {
        this.getSalons(this.searchSalonsData).then(() => {
          delete this.searchSalonsData.page
        })
      }
    },
    getSalonsByRating() {
      this.repeatedClickOnPrimaryButton = false
      this.$refs.rate.classList.forEach(el => {
        if (el === 'btn-primary') this.repeatedClickOnPrimaryButton = true
      })
      delete this.searchSalonsData.distance_sort
      if (this.repeatedClickOnPrimaryButton) {
        delete this.searchSalonsData.rating_total_sort
        delete this.searchSalonsData.rating_actual_sort
      } else Object.assign(this.searchSalonsData, { rating_total_sort: 0, rating_actual_sort: 1 })
      if (this.salonSearchPrintedValue) {
        this.searchSalonsData.salon = this.salonSearchPrintedValue
        this.getSalonsBySalonName(this.searchSalonsData)
      } else {
        this.getSalons(this.searchSalonsData)
      }

      this.classWork(this.$refs.rate, this.$refs.actual)
    },
    getSalonsByActual() {
      this.repeatedClickOnPrimaryButton = false
      this.$refs.actual.classList.forEach(el => {
        if (el === 'btn-primary') this.repeatedClickOnPrimaryButton = true
      })
      if (this.repeatedClickOnPrimaryButton) {
        delete this.searchSalonsData.distance_sort
      } else Object.assign(this.searchSalonsData, { distance_sort: 1 })
      delete this.searchSalonsData.rating_total_sort
      delete this.searchSalonsData.rating_actual_sort
      if (this.salonSearchPrintedValue) {
        this.searchSalonsData.salon = this.salonSearchPrintedValue
        this.getSalonsBySalonName(this.searchSalonsData)
      } else {
        this.getSalons(this.searchSalonsData)
      }

      this.classWork(this.$refs.actual, this.$refs.rate)
    },
    classWork(btnToAdd, btnToRemove) {
      btnToRemove.classList.add('btn-outline-secondary-custom', 'collapsed')
      btnToRemove.classList.remove('btn-primary')
      if (!this.repeatedClickOnPrimaryButton) {
        btnToAdd.classList.add('btn-primary')
        btnToAdd.classList.remove('btn-outline-secondary-custom', 'collapsed')
      } else {
        btnToAdd.classList.remove('btn-primary')
        btnToAdd.classList.add('btn-outline-secondary-custom', 'collapsed')
      }
    },
    openFilter() {
      delete this.searchSalonsData.distance_sort
      delete this.searchSalonsData.actual_sort
      delete this.searchSalonsData.total_sort
      this.$bvModal.show('filter-modal')
    },
    listView() {
      this.mainShowFlag = 0
      if (window.innerWidth < 767) {
        let menu = document.querySelector('.bottomNavBar')
        menu.classList.add('d-block')
        menu.classList.remove('d-none')
      }
    },
    mapView() {
      this.mainShowFlag = 1
      if (window.innerWidth < 767) {
        let menu = document.querySelector('.bottomNavBar')
        menu.classList.remove('d-block')
        menu.classList.add('d-none')
      }
    },
    onMountedSalonRequest() {
      let errorInfo = {
        type: this.$t('common.forms.alert.empty_fields_type'),
        messageI18Key: 'common.forms.alert.empty_fields_message'
      }
      if (
        ((this.searchSalonsData.services && this.searchSalonsData.services.length) ||
          (this.searchSalonsData.categories && this.searchSalonsData.categories.length)) &&
        this.searchSalonsData.parameters.length &&
        this.searchSalonsData.latitude &&
        this.searchSalonsData.longitude &&
        this.searchSalonsData.radius
      ) {
        this.$emit('toggle-loaded', false)
        this.getSalons(this.searchSalonsData)
          .then(result => {
            this.$emit('toggle-loaded', true)
            this.mountedRequestIsTransfered = true
          })
          .catch(err => {
            this.$emit('toggle-loaded', true)

            this.setError(errorInfo)
          })
      } else {
        this.setError(errorInfo)
        this.$router.push('/')
      }
    },
    customLabel(option) {
      let number = parseInt(option.match(/\d+/))

      return number
        ? `${number} ${this.$t('common.forms.label.founded_salons')}`
        : this.$t('common.forms.label.no_total_queries')
    },
    setClearPlaceFlag(val) {
      this.clearPlaceFlag = val
    },
    showRequestSalonModal() {
      this.$bvModal.show('request-salons-modal')
    },
    isSearchInProgress(val) {
      this.searchPendingFlag = val
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 1650px) {
  .card-body {
    width: 80%;
    margin-left: 0;
    height: auto;
  }
}
.right-btn {
  border-radius: 0 4px 4px 0;
  height: 48px;
}
.left-btn {
  border-radius: 4px 0 0 4px;
  height: 48px;
}
.filterIcon {
  position: relative;
  right: 0.1rem;
}
.btnList svg {
  color: #ff6b09 !important;
}
.btnList {
  color: #111517 !important;
  border-color: #ff6b09 !important;
}
.icon-close {
  font-size: 31px !important;
}
@media screen and (max-width: 1520px) {
  .multiselectWidthDE {
    padding-right: 4rem !important;
  }
  .multiselectWidthEN {
    padding-right: 3rem !important;
  }
}
@media screen and (max-width: 1320px) {
  .multiselectWidthDE {
    padding-right: 5rem !important;
  }
  .multiselectWidthEN {
    padding-right: 3rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .multiselectWidthDE {
    padding-right: 6rem !important;
  }
  .multiselectWidthEN {
    padding-right: 4rem !important;
  }
}
@media screen and (max-width: 1100px) {
  .multiselectWidthDE {
    padding-right: 7rem !important;
  }
  .multiselectWidthEN {
    padding-right: 5rem !important;
  }
}
@media screen and (max-width: 992px) {
  .multiselectWidthDE {
    padding-right: 1.6rem !important;
  }
  .multiselectWidthEN {
    padding-right: 1.5rem !important;
  }
}
@media screen and (max-width: 767px) {
  .multiselectWidthDE {
    padding-left: 0 !important;
  }
  .multiselectWidthEN {
    padding-left: 0 !important;
  }
}
</style>
