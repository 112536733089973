import { SalonsRepository } from '@/api/salons.repository'
import router from '@/router'

const getSalons = ({ commit }, payload) => {
  return SalonsRepository.getSalons(payload).then(data => {
    let result = data.data
    commit('SET_SALONS', result)

    if (result.total === 0) {
      commit('SET_REQUEST_NEW_SALONS_STATUS', false)

      commit('SET_REQUEST_NEW_SALONS_DATA', payload)
    } else {
      commit('CLEAR_REQUEST_NEW_SALONS_DATA')
    }

    return result
  })
}
const getSalonsByName = ({ commit }, payload) => {
  return SalonsRepository.getSalonsByName(payload).then(({ data }) => {
    if (payload.page) {
      commit('SET_SALONS', data.data)
    }
    return data.data
  })
}
const getFavorites = ({ commit }, payload) => {
  return SalonsRepository.getFavorites(payload).then(({ data }) => {
    commit('SET_FAVORITES', data)
  })
}
const getAddToFavorites = ({ commit }, payload) => {
  return SalonsRepository.getAddToFavorites(payload)
    .then(({ data }) => {
      commit('SET_FAVORITES', data.data)
      return data.data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}
const getRemoveFromFavorites = ({ commit }, payload) => {
  return SalonsRepository.getRemoveFromFavorites(payload)
    .then(({ data }) => {
      commit('SET_FAVORITES', data.data)
      return data.data
    })
    .catch(errors => {
      return Promise.reject(errors.data.data)
    })
}

const getBookingHistory = ({ commit }, payload) => {
  return SalonsRepository.getBookingHistory(payload).then(({ data }) => {
    commit('SET_BOOKING_HISTORY', data)
  })
}
const getSalon = ({ commit }, payload) => {
  return SalonsRepository.getSalon(payload)
    .then(data => {
      let result = data.data

      commit('SET_SALON', result)
      return result
    })
    .catch(errors => {
      router.push('/not-found')
    })
}

const getSalonsBySalonName = ({ commit }, salon) => {
  return SalonsRepository.getSalonsBySalonName({ salon }).then(({ data }) => {
    commit('SET_SALONS_FOR_SEARCH', data.data)
    return data.data
  })
}
const getSalonReviews = ({ commit }, payload) => {
  return SalonsRepository.getSalonReviews(payload).then(data => {
    commit('SET_SALON_REVIEWS', data)
    return data
  })
}

const getStaffReviews = ({ commit }, payload) => {
  return SalonsRepository.getStaffReviews(payload).then(data => {
    commit('SET_STAFF_REVIEWS', data)
    return data
  })
}

const getSalonCombination = ({ commit }, payload) => {
  return SalonsRepository.getSalonCombination(payload).then(({ data }) => {
    commit('SET_SALON_COMBINATION', data)
    return data.data
  })
}
const getSalonsStaffFilter = ({ commit }, payload) => {
  return SalonsRepository.getSalonsStaffFilter(payload).then(({ data }) => {
    if (payload.staff_id == undefined) {
      commit('SET_SALONS_STAFF_FILTER', data)
    }

    return data
  })
}

const getSalonServices = ({ commit }, payload) => {
  return SalonsRepository.getSalonServices(payload).then(({ data }) => {
    commit('SET_SALON_SERVICES', data.data)
    return data.data
  })
}
const getSalonStaff = ({ commit }, payload) => {
  // TODO: No need to add promise here
  return new Promise((resolve, reject) => {
    SalonsRepository.getSalonStaff(payload)
      .then(response => {
        let data = response.data.data
        commit('SET_SALON_STAFF', data)
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const updateSalons = ({ commit }, payload) => {
  commit('CLEAR_SALONS')
  commit('SET_SALONS', payload)
}
const toggleSalonsMoreInfo = ({ commit }, salonsIndex) => {
  commit('TOGGLE_SALONS_MORE_INFO', salonsIndex)
}
const setSearchRadius = ({ commit }, radius) => {
  commit('SET_SEARCH_RADIUS', radius)
}
const clearCurrentPlace = ({ commit }) => {
  commit('CLEAR_CURRENT_PLACE')
}
const setCurrentPlace = ({ commit }, currentPlace) => {
  commit('SET_CURRENT_PLACE', currentPlace)
}
const setNewSearchDataFlag = ({ commit }, payload) => {
  commit('SET_NEW_SEARCH_DATA_FLAG', payload)
}
const getSalonStaffsList = ({ commit }, payload) => {
  return SalonsRepository.getSalonStaffsSelect(payload).then(({ data }) => {
    let result = data.data

    if (result) {
      commit('SET_SALON_STAFF_LIST', result)
    }

    return data
  })
}

const сreateSalonsRequestUrl = ({ commit }, payload) => {
  return SalonsRepository.сreateSalonsRequestUrl(payload).then(({ data }) => {
    commit('SET_REQUEST_NEW_SALONS_STATUS', true)

    return data.data
  })
}
const getAvaliableSalons = ({ commit }, payload) => {
  return SalonsRepository.getAvaliableSalons(payload).then(({ data }) => {
    commit('SET_AVALIABLE_SALONS', data)

    return data.data
  })
}
const getSalonProducts = ({ commit }, payload) => {
  return SalonsRepository.getSalonProducts(payload).then(({ data }) => {
    commit('SET_SALON_PRODUCTS', data.data)

    return data.data
  })
}
const getSalonCategories = ({ commit }, payload) => {
  return SalonsRepository.getSalonCategories(payload).then(({ data }) => {
    commit('SET_SALON_CATEGORIES', data.data)

    return data.data
  })
}
const getProduct = ({ commit }, payload) => {
  return SalonsRepository.getProduct(payload).then(({ data }) => {
    commit('SET_PRODUCT', data.data)

    return data.data
  })
}
export default {
  getSalons,
  getFavorites,
  getAddToFavorites,
  getRemoveFromFavorites,
  getSalon,
  getSalonsBySalonName,
  updateSalons,
  toggleSalonsMoreInfo,
  setSearchRadius,
  setCurrentPlace,
  setNewSearchDataFlag,
  getSalonReviews,
  getStaffReviews,
  getSalonServices,
  getSalonStaff,
  clearCurrentPlace,
  getBookingHistory,
  getSalonsStaffFilter,
  getSalonCombination,
  getSalonsByName,
  getSalonStaffsList,
  сreateSalonsRequestUrl,
  getAvaliableSalons,
  getSalonCategories,
  getProduct,
  getSalonProducts
}
