const getDefaultState = () => {
  return {
    products: []
  }
}

const SET_PRODUCT = (state, payload) => {
  state.products = payload
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

export default {
  getDefaultState,
  SET_PRODUCT,
  RESET_STATE
}
