<template>
  <div v-if="getReservedProductsBySalonId(id).length" class="col mb-2">
    <div class="d-flex">
      <p class="text-black font-weight-medium payment-text poppins-font">
        {{ $t('market.reserved-products') }}
      </p>
      <font-awesome-icon
        class="fa-w-16 ml-0-5 mt-0-3 tooltipInfoIcon"
        style="font-size:16px;"
        data-toggle="tooltip"
        :icon="['fas', 'info-circle']"
      ></font-awesome-icon>
    </div>
    <div v-for="(product, key) in getReservedProductsBySalonId(id)" :key="`product-${key}`" class="mb-2">
      <div class="d-flex">
        <img
          :src="product.images.length ? product.images[0].path : require(`@/assets/images/default/default_avatar.png`)"
          width="54px"
          height="54px"
          class="product-image product-images-fit"
          style="border-radius: 7px"
          alt=""
        />
        <div class="col px-0">
          <div class="d-flex mt-1-5 flex-nowrap justify-content-between">
            <p class="pl-1 mb-0-5 w-75 text-truncate">{{ product.title }}</p>

            <p class="pl-1 mb-0-5 w-25 text-truncate text-right">{{ product.price }} CHF</p>
          </div>
          <div v-if="product.variation" class="d-flex">
            <img
              :src="
                product.variation.avatar
                  ? product.variation.avatar.path
                  : require(`@/assets/images/default/default_avatar.png`)
              "
              class="ml-1 product-images-fit"
              width="20px"
              height="20px"
              alt=""
              style="border-radius:50%;object-fit: contain;"
            />
            <span class="ml-1">{{ product.variation.title }}</span>
          </div>
        </div>
        <div class="col-auto mt-1 pt-0-2 px-0">
          <font-awesome-icon
            :icon="['fal', 'times']"
            class="ml-1 mt-0-5 cursor-pointer"
            @click="deleteProduct(product)"
          ></font-awesome-icon>
        </div>
      </div>
      <hr class="mt-1-5 my-2 infoBlockLine" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ReservedProducts',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getReservedProductsBySalonId: 'payment/getReservedProductsBySalonId',
      clientBooking: 'payment/getClientBooking'
    })
  },
  methods: {
    ...mapActions({
      removeReservedProduct: 'payment/removeReservedProduct',
      removeBookingProduct: 'payment/removeBookingProduct'
    }),
    ...mapMutations({
      removeBookingStore: 'payment/REMOVE_RESERVED_PRODUCT'
    }),
    deleteProduct(product) {
      let payload = null

      if (product.reservedFlag) {
        payload = {
          requestData: {
            reserved_product_id: product.deleteId
          },
          productId: product.deleteId
        }

        this.removeReservedProduct(payload)
          .then(() => {})
          .catch(() => {})
      } else if (product.bookingFlag) {
        payload = {
          requestData: {
            book_id: this.clientBooking.id,
            products: [product.deleteId]
          },
          productId: product.deleteId
        }

        this.removeBookingProduct(payload)
          .then(() => {})
          .catch(() => {})
      } else {
        this.removeBookingStore(product.deleteId)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
