<template>
  <div class="row">
    <div class="col-12">
      <p class="text-danger text-center">
        {{ $t('payment.booking_failed_title') }}
      </p>
      <button type="button" class="btn btn-lg btn-primary w-100" @click="backToPaymentPage">
        <p class="h6 font-weight-medium mb-0 text-white">
          {{ $t('payment.booking_back_to_payment') }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FailedPayment',
  methods: {
    backToPaymentPage() {
      this.$emit('closeFailedPage')
    }
  }
}
</script>
