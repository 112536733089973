<template>
  <main class="position-relative">
    <div class="row card-content block_shadow px-0-5 py-1-5">
      <div class="col d-flex">
        <div>
          <img v-if="salon.images.length" :src="salon.images[0].path" alt="" class="imgWidth img-cover" />
        </div>
        <div class="col px-1-5">
          <div>
            <h5 class="mb-0 pb-0">{{ salon.title | truncate(15) }}</h5>
            <div class="d-inline-block">
              <span class="addressText">{{ salon.address.full_address | truncate(15) }}</span>
              <span class="text-gray">
                <i class="location-icon"></i>
                {{ distanceToSalon() }}km
              </span>
            </div>
          </div>
          <span class="rating-block">
            <FaRating
              :glyph="iconStar"
              :border-width="0"
              :active-color="'#ff6b09'"
              :inactive-color="'#FFE1CD'"
              :show-rating="false"
              :item-size="10"
              :read-only="true"
              :increment="0.01"
              :rating="salonRate"
              :max-rating="5"
              :spacing="2"
            ></FaRating>
          </span>
          <div class="col-auto d-flex align-items-center p-0">
            <span class="text-gray text-small p-0">
              {{ $t('common.forms.label.from') }}
            </span>
            <span class="text truncate h3 font-weight-medium ml-0-5 mb-0" :title="salonFormattedPrice(salonPrice)">
              {{ salonFormattedPrice(salonPrice) }} CHF
            </span>
          </div>
        </div>
        <div v-b-modal.card-modal class="chevron-icon d-md-none">
          <font-awesome-icon :icon="['far', 'chevron-up']" class="chevron-up ml-0-5"></font-awesome-icon>
        </div>
      </div>
      <div v-if="salon.id" class="col-12 mt-1 buttonChoose">
        <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
          {{ $t('common.forms.button.choose') }}
        </router-link>
      </div>
    </div>
    <b-modal
      v-if="salon"
      id="card-modal"
      header-class="modal-header pt-1-5 px-1-5 pb-0 border-bottom-0"
      body-class="px-1"
      centered
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <div class="d-block w-100">
          <div class="d-flex justify-content-end">
            <div class="chevron-icon">
              <font-awesome-icon
                :icon="['fal', 'times']"
                class="icon-close-card ml-0-8 mt-0-5"
                @click="close()"
              ></font-awesome-icon>
            </div>
          </div>
        </div>
      </template>
      <div class="h-auto">
        <div class="card-body p-0 mt-0-5">
          <div class="col d-block px-0-3 d-flex justify-content-between d-md-none">
            <div class="pb-1">
              <h5 class="">{{ salon.title | truncate(20) }}</h5>
              <div class="d-inline-block">
                <span class="addressText">{{ salon.address.full_address | truncate(15) }}</span>
                <span class="text-gray ml-1">
                  <i class="location-icon"></i>
                  {{ distanceToSalon() }}
                  {{ $t('common.forms.label.distance_value') }}
                </span>
              </div>
            </div>
            <div>
              <span class="rating-block">
                <FaRating
                  :glyph="iconStar"
                  :border-width="0"
                  :active-color="'#ff6b09'"
                  :inactive-color="'#FFE1CD'"
                  :show-rating="false"
                  :item-size="13"
                  :read-only="true"
                  :increment="0.01"
                  :rating="salonRate"
                  :max-rating="5"
                  :spacing="2"
                  class="justify-content-end"
                ></FaRating>
              </span>
              <div class="col-auto d-flex align-items-center ml-0-5 p-0">
                <span class="text-gray text-small p-0">
                  {{ $t('common.forms.label.from') }}
                </span>
                <span class="text truncate h3 font-weight-medium ml-0-5 mb-0" :title="salonFormattedPrice(salonPrice)">
                  {{ salonFormattedPrice(salonPrice) }} CHF
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-auto">
              <button
                v-if="isAuthorized"
                :class="{ favorite: isFavorite }"
                type="button"
                class="position-absolute btn fav_btn_card  rounded-circle favoriteBtn"
                @click="toggleFavorite"
              >
                <font-awesome-icon :icon="['far', 'bookmark']" class="ml-0"></font-awesome-icon>
              </button>
              <div v-if="hasImages" class="w-100 h-100 ml-md-0">
                <SalonCardCarousel :images="salon.images" :is-indicators-visible="false" />
              </div>
              <div v-else class="carousel rounded bg-light h-100"></div>
            </div>
          </div>
          <b-collapse
            :id="`favorite-content-${salon.id}`"
            class="collapse mt-1"
            accordion="my-accordion"
            :visible="isSalonNotEmpty"
          >
            <b-tabs v-model="tabIndex" content-class="mt-2" align="between">
              <b-tab title="Main info" active>
                <div class="d-flex align-items-baseline flex-wrap">
                  <p class="h6 text-dark mb-0 font-weight-medium">
                    {{ $t('common.card.working_hours') }}
                  </p>
                  <b-button
                    v-b-toggle="`mob-wh-collapse-${salon.id}`"
                    class="text-dark ml-3"
                    variant="link"
                    @click="changeChevronFlag"
                  >
                    {{ $t('common.card.open_now') }}

                    <font-awesome-icon
                      v-if="workHoursChevronFlag"
                      :icon="['far', 'chevron-up']"
                      class="ml-1 icon-chevron"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      v-if="!workHoursChevronFlag"
                      :icon="['far', 'chevron-down']"
                      class="ml-1 icon-chevron"
                    ></font-awesome-icon>
                  </b-button>
                  <b-collapse :id="`mob-wh-collapse-${salon.id}`" class="w-100">
                    <div class="ml-0 mt-2 ml-md-3 grafficPosition">
                      <div
                        v-for="(day, key) in salonWorkingHours"
                        :key="`day-key ${key}`"
                        class="d-flex mt-1 px-1 px-md-0 day-title"
                      >
                        <div class="list-unstyled w-50 align-content-md-center pr-0">
                          <span class="m-0 p-0" :class="currentDayClass(key)">
                            {{ weekdayList[key] }}
                          </span>
                        </div>
                        <div>
                          <span>{{ workingDayTimeText(day) }}</span>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <hr class="my-2" />
                <p class="h6 text-dark font-weight-medium">{{ $t('common.card.services') }}:</p>
                <div v-if="salon.salonServices.length" class="tag-list d-flex wrap">
                  <div v-for="(service, key) in salon.salonServices" :key="`service ${key}`">
                    <span class="badge badge-secondary-light mr-1 mb-1 text-gray text-small font-weight-normal">{{
                      service.title | truncate(50)
                    }}</span>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Reviews" @click="reviewDataMethod(salon.id)">
                <div class="row align-items-center pb-2 border-bottom">
                  <div class="col-4 col-md-3">
                    <div class="mr-1-5">
                      <span class="h1">{{ salonRate }}</span>
                      <span>/5</span>
                    </div>
                    <div>
                      <FaRating
                        :glyph="iconStar"
                        :border-width="0"
                        :active-color="'#ff6b09'"
                        :inactive-color="'#FFE1CD'"
                        :show-rating="false"
                        :item-size="18"
                        :read-only="true"
                        :increment="0.01"
                        :rating="salonRate"
                        :max-rating="5"
                        :spacing="3"
                      ></FaRating>
                      <p class="mb-0">
                        {{ $t('salon.section.content.feedback_rating_count', { count: salonReviewsCount }) }}
                      </p>
                    </div>
                  </div>

                  <RatingCarousel
                    :salon-rate="salon.rating"
                    :total-count="salon.reviews_num"
                    :width-dependence="carouselSize"
                  />
                </div>
                <div v-if="loadReviews && salonReviews.data.length > 0">
                  <Reviews
                    :get-reviews-data="salonReviews.data"
                    :star-size-value="starSizeValue"
                    :review-size-value="'col-12 mt-1-5'"
                  />
                  <div v-if="salonReviews.data.length === 10" class="row mt-3">
                    <pagination :data="salonReviews" :show-disabled="true" @pagination-change-page="getReviews">
                      <span slot="prev-nav">
                        <font-awesome-icon :icon="['far', 'chevron-left']"></font-awesome-icon>
                      </span>
                      <span slot="next-nav">
                        <font-awesome-icon :icon="['far', 'chevron-right']"></font-awesome-icon>
                      </span>
                    </pagination>
                  </div>
                </div>
                <div v-if="noReviewsFlag">
                  <div>
                    <i class="rating-icon mt-2"></i>
                    <h5 class="mt-2 pt-1 pb-2 ml-50 text-center">
                      {{ $t('common.card.empty_service_text') }}
                    </h5>
                  </div>
                </div>
              </b-tab>
              <b-tab title="About us">
                <div>{{ salon.description }}</div>
              </b-tab>
            </b-tabs>
            <div class="row d-block d-md-none mt-2 mb-0-5">
              <div class="col-12 d-flex flex-wrap">
                <div class="col-12 buttonChoose mb-1-5 d-block d-md-none">
                  <router-link :to="`/salon-page/${salon.id}`" class="btn btn-primary btn-block py-1">
                    {{ $t('common.forms.button.choose') }}
                  </router-link>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-modal>
  </main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { FaRating } from 'vue-rate-it'
import Star from 'vue-rate-it/glyphs/star'
import SalonCardCarousel from '@/components/SalonCardCarousel'
import RatingCarousel from '@/components/salon/RatingCarousel'
import Reviews from '@/components/salon/Reviews'
import moment from 'moment'

export default {
  components: {
    FaRating,
    Reviews,
    SalonCardCarousel,
    RatingCarousel
  },
  filters: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text
    }
  },
  props: {
    salon: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isFavorite: false,
      workHoursChevronFlag: false,
      noReviewsFlag: false,
      reviewsParams: {
        page: 1,
        parameters: [1]
      },
      loadReviews: false,
      carouselSize: 'col col-md-9 pr-2-5',
      tabIndex: 0
    }
  },
  computed: {
    ...mapGetters({
      currentPlace: 'salons/getCurrentPlace',
      userPosition: 'user/getUserPosition',
      isAuthorized: 'user/getAuthorizedFlag',
      favorites: 'salons/getFavorites',
      salonReviews: 'salons/getSalonReviews',
      siteLang: 'user/getSiteLang'
    }),
    isSalonNotEmpty() {
      return this.salon ? true : false
    },
    salonRate() {
      return this.salon.evaluation_rating ? this.salon.evaluation_rating.value : 0
    },
    hasImages() {
      return this.salon.images.length > 0
    },
    salonWorkingHours() {
      // TODO Need to recall something that remade the keys in the API
      return this.salon.workingHours !== undefined ? this.salon.workingHours : this.salon.working_hours
    },
    salonReviewsCount() {
      return this.salon.reviews_num ? this.salon.reviews_num : 0
    },
    weekdayList() {
      moment.updateLocale(this.siteLang, {
        week: {
          dow: 1
        }
      })

      return moment.localeData(this.siteLang).weekdays(true)
    },
    salonPrice() {
      if (this.salon.combination !== undefined) {
        return this.salon.combination.min_price
          ? this.salon.combination.min_price
          : this.salon.combination.default_price
      } else return this.salon.price_min
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', () => {
      this.tabIndex = 0
    })
    if (this.isAuthorized) this.checkFavoriteSalon()
  },
  created() {
    this.iconStar = Star
  },
  methods: {
    ...mapActions({
      removeFromFavorites: 'salons/getRemoveFromFavorites',
      addToFavorites: 'salons/getAddToFavorites',
      fetchSalonReviews: 'salons/getSalonReviews'
    }),

    salonFormattedPrice(price) {
      if (price) return Number(price).toFixed(2)
      else return 0
    },
    workingDayTimeText(day) {
      let startTime = day.start_time
      let endTime = day.end_time

      return startTime === null || endTime === null ? this.$t('common.card.closed') : `${startTime} - ${endTime}`
    },
    checkFavoriteSalon() {
      if (this.salon) {
        if (!this.favorites.data) return
        this.favorites.data.forEach(elem => {
          if (elem.id == this.salon.id) this.isFavorite = true
        })
      } else return
    },
    distanceToSalon() {
      const { latitude: salonLat, longitude: salonLng } = this.salon.address
      let { lat: userLat, lng: userLng } = this.userPosition

      if (this.currentPlace && typeof this.currentPlace === 'object') {
        let location = this.currentPlace.geometry.location

        userLat = typeof location.lat === 'function' ? location.lat() : location.lat
        userLng = typeof location.lng === 'function' ? location.lng() : location.lng
      }

      let radius = 6378137 // Earth�s mean radius in meter
      let dLat = this.radius(salonLat - userLat)
      let dLong = this.radius(salonLng - userLng)
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.radius(salonLat)) * Math.cos(this.radius(userLat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2)
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      let d = radius * c

      let disatanceKM = Number(d / 1000)
      return disatanceKM < 1 ? disatanceKM.toFixed(1) : disatanceKM.toFixed(0)
    },
    radius(x) {
      return (x * Math.PI) / 180
    },
    toggleFavorite() {
      if (this.isFavorite) {
        this.removeFromFavorites(this.salon.id).then(() => (this.isFavorite = false))
      } else {
        this.addToFavorites(this.salon.id).then(() => (this.isFavorite = true))
      }
      return false
    },
    changeChevronFlag() {
      return !this.workHoursChevronFlag ? (this.workHoursChevronFlag = true) : (this.workHoursChevronFlag = false)
    },
    getReviews(page = 1) {
      this.reviewsParams.page = page
      this.fetchSalonReviews(this.reviewsParams)
    },
    reviewDataMethod(id, page = 1) {
      this.reviewsParams.id = id
      this.reviewsParams.page = page
      this.loadReviews = false

      this.fetchSalonReviews(this.reviewsParams).then(data => {
        if (data.data.data.length) this.loadReviews = true
        else this.noReviewsFlag = true
      })
    },
    currentDayClass(key) {
      let date = new Date()
      return date.getDay() === key + 1 ? 'text-primary' : ''
    }
  }
}
</script>
<style scoped>
.card-content {
  width: auto;
  height: auto;
  left: 1rem;
  right: 1rem;
  background: white;
  position: absolute;
  z-index: 1000;
  bottom: 1rem;
  border-radius: 7px;
}
.imgWidth {
  width: 90px;
  height: 84px;
  border-radius: 7px;
}
.addressText {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
}
.chevron-icon {
  width: 25px;
  height: 25px;
  background-color: #e0e0e0;
  border-radius: 50%;
}
</style>
