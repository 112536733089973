<template>
  <div v-if="combinations.length" class="col-12 px-0 text-center">
    <div
      v-for="(combination, idx) in combinations"
      :key="idx"
      class="col-12 d-flex px-0 table-row-height  column"
      :class="combinations.length === 1 ? 'border-bottom' : 'combination-column'"
    >
      <div :class="`col-4 p-0 border-right justify-content-center d-flex align-items-center combi`">
        <ColumnText
          :combination="returnCombination('Short', combination.combination)"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
      <div :class="`col-4 p-0 border-right justify-content-center d-flex align-items-center`">
        <ColumnText
          :combination="returnCombination('Medium', combination.combination)"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
      <div :class="`col-4 p-0 justify-content-center d-flex align-items-center`">
        <ColumnText
          :combination="returnCombination('Long', combination.combination)"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ColumnText from './../ColumnText'
export default {
  components: {
    ColumnText
  },
  props: {
    name: {
      type: String,
      default: null
    },
    combinations: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    returnCombination(length, combination) {
      let lengths = {
        Short: {
          id: 21,
          value: 'Short'
        },
        Medium: {
          id: 22,
          value: 'Medium'
        },
        Long: {
          id: 23,
          value: 'Long'
        }
      }
      let requiredCombination = null
      combination.forEach(combination => {
        if (combination.name.includes(this.name)) {
          if (combination.parameter_values.length > 1 && Number(combination.parameter_values[1].value)) {
            requiredCombination = {
              ...combination,
              ...{ lengthParameters: lengths[length] }
            }
          } else {
            combination.parameter_values.forEach(parameter => {
              if (parameter.id === lengths[length].id) {
                requiredCombination = {
                  ...combination
                }
              }
            })
          }
        }
      })
      return requiredCombination
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
