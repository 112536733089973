<template>
  <div>
    <b-carousel
      v-model="slide"
      :interval="0"
      controls
      :indicators="isIndicatorsVisible"
      background="#ababab"
      img-width="266"
      img-height="137"
      navigation-next-label="<i class='material-icons'>keyboard_arrow_right</i>"
      navigation-prev-label="<i class='material-icons'>keyboard_arrow_left</i>"
      class="rounded-block images-carousel salonCardCarousel"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(image, key) in images" :key="`image-${key}`" class="slide">
        <template v-slot:img>
          <img :src="image.path" class="d-block w-100 image-carousel salonCardCarouselImage" alt="..." />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
export default {
  name: 'SalonCardCarousel',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    isIndicatorsVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
}
</script>
<style lang="scss">
.rounded-block {
  border-radius: 7px;
  overflow: hidden;
}
</style>
