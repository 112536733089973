import Repository from './repository'

const resourse = '/services'
export const ServicesRepository = {
  get() {
    return Repository.get(resourse)
  },
  getServicesByName(payload) {
    return Repository.post(`${resourse}/search`, payload)
  }
}
