<template>
  <section class>
    <div class="container pt-lg-14 pt-7">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <img src="@/assets/images/client-lp/client-lp-1.png" alt class="mw-100" />
        </div>
        <div class="col-lg-5 offset-lg-1 py-3">
          <h2 class="mb-2-5">{{ $t('main.another-sections.section_1.title') }}</h2>
          <p class="mb-2-5">
            {{ $t('main.another-sections.section_1.description') }}
          </p>
          <p class="mb-4">
            {{ $t('main.another-sections.section_1.description_2') }}
          </p>
          <div class="row">
            <div class="col-sm-6 mb-2 mb-lg-4">
              <i class="mb-2 icon-24-hours"></i>
              <h5 class="mb-1-5">{{ $t('main.another-sections.section_1.card_1.title') }}</h5>
              <p>{{ $t('main.another-sections.section_1.card_1.description') }}</p>
            </div>
            <div class="col-sm-6 mb-2 mb-lg-4">
              <i class="mb-2 icon-phone"></i>
              <h5 class="mb-1-5">{{ $t('main.another-sections.section_1.card_2.title') }}</h5>
              <p>{{ $t('main.another-sections.section_1.card_2.description') }}</p>
            </div>
            <div class="col-sm-6 mb-2 mb-lg-4">
              <i class="mb-2 icon-cards"></i>
              <h5 class="mb-1-5">{{ $t('main.another-sections.section_1.card_3.title') }}</h5>
              <p>{{ $t('main.another-sections.section_1.card_3.description') }}</p>
            </div>
            <div class="col-sm-6 mb-0">
              <i class="mb-2 icon-salon"></i>
              <h5 class="mb-1-5">{{ $t('main.another-sections.section_1.card_4.title') }}</h5>
              <p>{{ $t('main.another-sections.section_1.card_4.description') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'FirstSection'
}
</script>
