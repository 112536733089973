<template>
  <main>
    <slot v-if="isDesktopWidth" name="desktopTable">
      <div class="col-12 p-0 text-center border">
        <TableHeader v-if="serviceCategoryHaircutParams == 1" />
        <AdultTableColumn
          v-if="ladies.length"
          :name="'Lady'"
          :combinations="ladies"
          :service-category-haircut-params="serviceCategoryHaircutParams"
          :is-other-parameter-empty="gents.length ? true : false"
          @set-choosen-combination="setChoosenCombination"
        />
        <AdultTableColumn
          v-if="gents.length"
          :name="'Gentleman'"
          :combinations="gents"
          :service-category-haircut-params="serviceCategoryHaircutParams"
          :is-other-parameter-empty="[...girls, ...boys].length ? true : false"
          @set-choosen-combination="setChoosenCombination"
        />
        <KidTableColumn
          :kids-combination="[...girls, ...boys]"
          :girls="girls"
          :boys="boys"
          :service-category-haircut-params="serviceCategoryHaircutParams"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
    </slot>
    <slot v-else>
      <GenderOptionsMobile
        :adult-options="adultOptions"
        :kids-combination="[...girls, ...boys]"
        :service-category-haircut-params="serviceCategoryHaircutParams"
        @choosen-gender-value="choosenGenderValue"
        @set-choosen-combination="setChoosenCombination"
      />
      <div
        class="col-12 p-0 text-center"
        :class="choosenGender && choosenGender.value !== 'Kids' ? 'border' : 'd-none'"
      >
        <TableHeader
          v-if="serviceCategoryHaircutParams == 1 && choosenGender && (choosenGender.id == 1 || choosenGender.id == 2)"
        />
        <AdultTableColumn
          v-if="choosenGender && choosenGender.id == 1"
          :name="'Lady'"
          :combinations="ladies"
          :service-category-haircut-params="serviceCategoryHaircutParams"
          @set-choosen-combination="setChoosenCombination"
        />
        <AdultTableColumn
          v-if="choosenGender && choosenGender.id == 2"
          :name="'Gentleman'"
          :combinations="gents"
          :service-category-haircut-params="serviceCategoryHaircutParams"
          @set-choosen-combination="setChoosenCombination"
        />
      </div>
    </slot>
  </main>
</template>
<script>
import TableHeader from './TableHeader'
import AdultTableColumn from './AdultTableColumn'
import KidTableColumn from './KidTableColumn'
import GenderOptionsMobile from './GenderOptionsMobile'
export default {
  components: {
    TableHeader,
    AdultTableColumn,
    KidTableColumn,
    GenderOptionsMobile
  },
  props: {
    serviceCategoryHaircutParams: {
      type: Number,
      default: 0
    },
    combinations: {
      type: Array,
      default: () => []
    },
    choosenService: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      choosenGender: null
    }
  },
  computed: {
    isDesktopWidth() {
      return this.windowWidth > 767 ? true : false
    },
    ladies() {
      // вернёт от 0  до 3 комбинаций
      return this.combinations.filter(combination => combination.parameter_values[0].value === 'Lady')
    },
    gents() {
      // вернёт от 0  до 3 комбинаций
      return this.combinations.filter(combination => combination.parameter_values[0].value === 'Gentleman')
    },
    girls() {
      // вернёт от 0  до 48 комбинаций
      return this.combinations.filter(combination => combination.parameter_values[0].value === 'Girl')
    },
    boys() {
      // вернёт от 0  до 48 комбинаций
      return this.combinations.filter(combination => combination.parameter_values[0].value === 'Boy')
    },
    adultOptions() {
      let genderOptions = []
      if (this.ladies.length) {
        genderOptions.push({ id: 1, value: 'Lady' })
      }
      if (this.gents.length) {
        genderOptions.push({ id: 2, value: 'Gentleman' })
      }
      return genderOptions
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    choosenGenderValue(gender) {
      if (gender && Object.keys(gender).length) {
        this.choosenGender = gender
      }
    },
    setChoosenCombination(combination) {
      this.$emit('set-choosen-combination', combination)
    }
  }
}
</script>
